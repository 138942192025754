<template>
    <div class="modal fade looker-modal" :id="`lookerExams-${student.id}`" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title">Internal Exams & Tests Schedule</h5>
                    <button ref="closeButton" type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">

                    <!-- <div class="modal-actions d-flex justify-content-end">
                        <button
                            class="new-btn new-btn-dark w-auto px-3"
                            @click.prevent="openDctModal"
                        >Apply for deferred cycle test/exam</button>
                    </div> -->

                    <vi-looker
                        :looker-url="lookerUrl"
                        id="looker-exams"
                        :is-production="isProduction"
                    ></vi-looker>
                </div>

                <div v-if="examsData.examScheduleGoogleCalendarLink" class="modal-footer">
                    <a class="btn btn-primary btn-outline-uct" :href="examsData.examScheduleGoogleCalendarLink" target="_blank" title="Link to Google Calendar Exam Schedule">Exam Calendar</a>
                </div>

            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: "UpcomingExamsModal",
        data: () => ({
            loading: false,
        }),
        props: {
            examsData: {
                type: Object,
                default: () => ({}),
            },
            lookerUrl: {
                type: String,
                required: true
            },
            isProduction: {
                type: Boolean,
                required: true,
                default: true
            },
            student: {
                type: Object,
                required: true,
                default: () => ({}),
            }
        },
        methods: {
            openDctModal() {
                // const openEvent = new CustomEvent('dct:open', {
                //     detail: {
                //         studentId: this.student.id,
                //     }
                // });

                // document.body.dispatchEvent(openEvent);
                // this.$refs.closeButton.click();
            }
        },
        mounted() {
            /**
             * ViLooker Pace Data open modal clicked.
             * Trigger event to select the correct exams.
             */
            $(`[data-bs-target="#lookerExams-${this.student.id}"]`).on('click', (event) => {

                let studentEmail = event.target.dataset['studentEmail'];

                const lookerEvent = new CustomEvent('vi-looker.open', {
                    detail: {
                        studentEmail,
                        id: 'looker-exams'
                    }
                });

                // Dispatch the event.
                document.body.dispatchEvent(lookerEvent);
            });
        }
    }
</script>
