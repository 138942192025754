<template>
    <div>
        <div
            class="mb-3 card generic-widget-card general-widget-card p-0 d-flex flex-column flex-md-row overflow-hidden"
        >
            <div class="generic-widget-left-col py-3" :class="{'w-100' : progressionApplication.hasCompetedProgressionSubjectSelection}">
                <div class="card-body d-flex justify-content-center flex-column">
                        <h5 v-if="!progressionApplication.hasCompetedProgressionSubjectSelection" class="card-title mb-0">Select subjects for {{ progressionApplication.progressionApplicationYear }}</h5>
                        <h5 v-else class="card-title mb-0">Subject selection for Grade 10 complete</h5>
                </div>
            </div>

            <div v-if="!progressionApplication.hasCompetedProgressionSubjectSelection" class="text-center generic-widget-right-col py-3 px-1">
                <a :href="progressionApplication.route" class="h-100 w-100 d-flex justify-content-center align-items-center text-uppercase">
                    Add
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ProgressionApplication",
        props: {
            progressionApplication: {
                type: Object,
                required: true
            }
        }
    }
</script>