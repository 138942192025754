<template>
    <div class="modal" id="reenrolmentUnsavedChanges" tabindex="-1" aria-labelledby="reenrolmentUnsavedChangesLabel" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Unsaved Changes</h5>
            </div>
            <div class="modal-body text-center">
                <p>You have not submitted your response. Are you sure you want to leave without submitting?</p>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="button" class="new-btn new-btn-dark new-btn-lg mx-3" @click="backToSurvey()">No, let me finish my response</button>
              <button type="button" class="new-btn new-btn-dark new-btn-lg mx-3" @click="continueWithUnsavedChanges()">Yes, I will come back later</button>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
    export default {
        name: "ReEnrolmentUnsavedChanges",
        methods: {
            backToSurvey() {
                $('#reenrolmentUnsavedChanges').modal('hide');
                $('#reenrolmentSurvey').modal('show');
            },
            continueWithUnsavedChanges() {
                $('#reenrolmentUnsavedChanges').modal('hide');
                console.log('survey-closed');
            }
        }
    }
</script>
