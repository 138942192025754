<template>
    <div class="vi-success">
        <h3>Subject Change Request Submitted</h3>

        <p>Subject change request successfully submitted! Our Student Records Office is reviewing your request and you can expect to receive an outcome in approximately 10 school days within the academic term.</p>

        <p>Please check your emails regularly for further communication regarding the outcome of your request.</p>

        <p>Feel free to contact your Support Coach for further clarity and assistance.</p>
    </div>
</template>

<script>
export default {
    name: "Success",
    props: {},
    data() {
        return {};
    },
    methods: {}
}
</script>

<style l    ng="scss" scoped>
h3 {
    margin-bottom: 0.5em;
}

p {
    margin-bottom: 1em;
}

.vi-success {
    max-width: 75ch;
    margin: 0 auto;
}
</style>
