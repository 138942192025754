<template>
    <div class="applications">
        <loading-data v-if="loading"></loading-data>

        <div v-if="applications" class="applications-container">
            <vi-application-details
                v-for="(application, idx) in applications" :key="idx"
                :application="application"
                :eligibility-url="buildEligibilityUrl(eligibilityUrl, application)"
            ></vi-application-details>

            <vi-application-details
                :is-new="true"
                :new-url="newUrl"
                :button-text="'Start a New Application'"
            ></vi-application-details>

            <vi-application-details
                v-if="llsApplicationsActive"
                :is-new="true"
                :new-url="newUrl + '?lls=true'"
                :button-text="'Start a new Bursary Application (Grade 8-11)'"
            ></vi-application-details>
            <vi-application-details
                v-if="amReapplicationLink !== null"
                :is-new="true"
                :new-url="amReapplicationLink"
                :button-text="'Re-join Adult Matric Prep Programme'"
            ></vi-application-details>
        </div>

        <div v-if="pageLoadingError" class="alert mb-3 text-center alert-danger text-center" role="alert">
            Failed to load page. Please contact support for assistance.
        </div>
    </div>
</template>

<script>
export default {
    name: "Applications",
    props: {
        applicationsUrl: {
            type: String,
            required: true,
        },
        new: {
            type: Boolean,
            default: false,
        },
        newUrl: {
            type: String,
            required: true,
        },
        eligibilityUrl: {
            type: String,
            required: true,
        },
        llsApplicationsActive: {
            type: Boolean,
            default: false,
        },
        amReapplicationLink: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            applications: null,
            loading: true,
            error: false,
            pageLoadingError: false,
        };
    },
    mounted() {
        this.init();

        window.document.addEventListener('applications:init', (event) => {
            this.applications = null;
            this.error = false;
            this.pageLoadingError = false;

            this.init();
        });
    },
    methods: {
        init() {
            this.loading = true;

            let initProcesses = [];

            initProcesses.push(this.loadApplicationData());

            Promise.all(initProcesses)
                .catch(error => {
                    this.pageLoadingError = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadApplicationData() {
            return axios.get(this.applicationsUrl)
                .then(response => {
                    this.applications = response.data.applications;

                    this.applications = response.data.applications.sort((a, b) => {
                        let isCompleteA = a.step && a.step.status === 'Closed';
                        let isCompleteB = b.step && b.step.status === 'Closed';

                        if (isCompleteA === isCompleteB) {
                            return 0;
                        }

                        if (isCompleteA) {
                            return 1;
                        }

                        return -1;
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        buildEligibilityUrl(url, application) {
            return `${url}?appName=${application.name}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.applications-container {
    display: flex;
    flex-wrap: wrap;
}
</style>
