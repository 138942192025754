<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-10">
            <p class="placeholder-glow">
                <span class="placeholder col-10"></span>
            </p>
            <p class="placeholder-glow">
                <span class="placeholder col-8"></span>
            </p>
            <p class="placeholder-glow">
                <span class="placeholder col-12"></span>
            </p>
            <p class="placeholder-glow">
                <span class="placeholder col-6"></span>
            </p>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingData"
    }
</script>