<template>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" :id="`falSubjectDeadlinePassed-${studentId}`" tabindex="-1" role="dialog" :aria-labelledby="`falSubjectDeadlinePassed-${studentId}`" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center px-5">
                    <p>We regret to inform you that you are unable to make subject changes as the deadline for submitting a subject change request has passed for this academic year.</p>
                    <p>For additional information on subject changes and submissions dates please visit our info hub article <a target="_blank" href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE">here</a>.</p>
                    <p>If you have any questions or need further clarity, please contact your Support Coach.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-light" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FalDeadlinePassed",
        props: {
            studentId: {
                type: String,
                required: true,
            },
            deadlines: {
                type: Array,
                required: true,
            },
        },
        methods: {
            closeModal() {
                $(`#falSubjectDeadlinePassed-${this.studentId}`).modal('hide');
            }
        }
    }
</script>
