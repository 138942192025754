<template>
    <div class="row justify-content-between align-items-center py-3 flex-column-reverse flex-md-row">
        <div class="col-12 col-md-4 button-wrapper px-1">
            <button v-if="backRoute" @click="backClick()" class="scholarship-button back-button m-auto" :class="{ 'd-none': hideBack }">
                <span class="scholarship-button-label back-label">
                    <span>Prev. Step</span>
                </span>
            </button>
        </div>

        <div :class="middleColumnClass" class="button-wrapper px-1 text-center">
            <button @click="returnToApplicationClick()" class="m-auto" :class="{ 'd-none': hideContinue }" v-if="returnToApplicationInformation">
                <p class="exit-btn-label mb-2" v-if="!hideContinueForRecipients">
                    {{ showMainApplicationStep ? 'Complete Enrolment' : 'Click here if you want to apply, but don\'t need a bursary' }}
                </p>
                <p class="exit-btn-text mb-0 fw-bold mx-auto">
                    {{ showMainApplicationStep ? continueText : 'Exit bursary application'  }}
                </p>
            </button>
        </div>

        <div class="col-12 col-md-4 button-wrapper px-1">
            <button @click="nextButtonClickAction()" class="scholarship-button next-button m-auto" :class="{ 'd-none': hideNext }">
                <span class="scholarship-button-label next-label">
                    <span>Next Step</span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { navigationRoutes } from '../../../router';
import scroller from '../../../mixins/scroller';

export default {
    name: "ScholarshipActionButtons",
    mixins: [scroller],
    props: {
        hideBack: {
            type: Boolean,
            default: false,
        },
        hideNext: {
            type: Boolean,
            default: false,
        },
        hideContinue: {
            type: Boolean,
            default: false,
        },
        showMainApplicationStep: {
            type: Boolean,
            default: false,
        },
        backButtonClick: {
            type: Function,
            default: undefined,
        },
        nextButtonClick: {
            type: Function,
            default: () => {},
        },
        continueButtonText: {
            type: String,
            default: undefined,
        },
    },
    data () {
        return {
            isSubmitting: false,
            continueText: '',
            isNormalLengthMiddleColumn: true,
        }
    },
    mounted() {
        $('#submitOverlay').modal({
            backdrop: 'static',
            keyboard: false,
        });
    },
    computed : {
        returnToApplicationInformation() {
            const currentRoute = this.$router.currentRoute.name;
            const applicationName = this.$endPointsConfig.returnToApplication.name;

            let text = applicationName;

            if (currentRoute === 'scholarship-application.recipient') {
                text = this.determineRecipientContinueText(applicationName);
            }

            this.continueText = text;
            return text;
        },
        backClick() {
            return this.backButtonClick ?? this.backButtonClickDefault;
        },
        nextClick() {
            return this.$router.push({ name: this.nextRoute.name });
        },
        backRoute() {
            let currentRoute = this.$route.name;
            let currentRouteIndex = navigationRoutes.findIndex(route => route.name === currentRoute);

            if (currentRouteIndex === 0) {
                return null;
            }

            if (currentRouteIndex === -1) {
                let childRoute = navigationRoutes.find(route => route.children && route.children.find(child => child.name === currentRoute));

                if (!childRoute) {
                    return null;
                }

                let childRouteIndex = childRoute.children.findIndex(child => child.name === currentRoute);

                if (childRouteIndex === 0) {
                    return null;
                }

                return childRoute.children[childRouteIndex - 1];
            }

            return navigationRoutes[currentRouteIndex - 1] ?? null;
        },
        nextRoute() {
            let currentRoute = this.$route.name;
            let currentRouteIndex = navigationRoutes.findIndex(route => route.name === currentRoute);

            if (currentRouteIndex === navigationRoutes.length - 1) {
                return null;
            }

            if (currentRouteIndex === -1) {
                let childRoute = navigationRoutes.find(route => route.children && route.children.find(child => child.name === currentRoute));

                if (!childRoute) {
                    return null;
                }

                let childRouteIndex = childRoute.children.findIndex(child => child.name === currentRoute);

                if (childRouteIndex === childRoute.children.length - 1) {
                    let currentMainIndex = navigationRoutes.findIndex(route => route.name === childRoute.name);
                    let nextMainIndex = navigationRoutes.findIndex((route, index) => index > currentMainIndex && route.children);

                    if (nextMainIndex === -1) {
                        return null;
                    }

                    return navigationRoutes[nextMainIndex].children.length ? navigationRoutes[nextMainIndex].children[0] : navigationRoutes[nextMainIndex];
                }

                return childRoute.children[childRouteIndex + 1];
            }

            return navigationRoutes[currentRouteIndex + 1] ?? null;
        },
        hideContinueForRecipients() {
            const currentRoute = this.$router.currentRoute.name;
            const applicationName = this.$endPointsConfig.returnToApplication.name;

            if (currentRoute === 'scholarship-application.recipient') {
                return this.shouldHideContinueForRecipients(applicationName);
            }
            return false;
        },
        isLeftColumnVisible() {
            return !!this.backRoute && !this.hideBack;
        },
        isRightColumnVisible() {
            return !this.hideNext;
        },
        middleColumnClass() {
            return this.isLeftColumnVisible || this.isRightColumnVisible ? 'col-12 col-md-4' : 'col-12';
        }
    },
    methods: {
        backButtonClickDefault() {
            if (!this.backRoute) {
                return;
            }

            this.$router.push({ name: this.backRoute.name });
        },
        returnToApplicationClick() {
            window.location.href = this.returnToApplicationInformation.destinationLink;
        },
        nextButtonClickDefault() {
            return this.nextButtonClick ? this.nextButtonClick() : this.nextClick();
        },
        nextButtonClickAction() {
            $('#submitOverlay').modal('show');

            Promise.resolve(this.nextButtonClickDefault()).then(() => {
                $('#submitOverlay').modal('hide')
                this.scrollToClass('invalid-feedback');
            })
        },
        determineRecipientContinueText(name) {
            switch (name) {
                case 'Application Under Review':
                    return 'NSC Application Under Review';
                case 'Pay Placement Fee':
                    return name;
                case 'Closed - Offer Accepted':
                    return 'Enrolment complete, no additional action required';
                default:
                    return name;
            }
        },
        shouldHideContinueForRecipients(name) {
            const allowedNames = [
                'Application Under Review',
                'Pay Placement Fee',
                'Closed - Offer Accepted',
            ];

            return allowedNames.includes(name);

        },
    }
}
</script>
<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.scholarship-button {
    border: solid 1px $pastel-blue;
    border-radius: 20px;
    background-color: $white;
    color: $pastel-blue;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;

    .scholarship-button-label {
        font-size: 14px;
        font-weight: 600;
        background-color: $pastel-blue;
        color: $white;
        text-transform: uppercase;
        display: block;
        padding: 10px;
    }

    .scholarship-button-destination {
        font-size: 14px;
        font-weight: 600;
        color: $pastel-blue;
        display: block;
        padding: 10px 15px;
    }
}

.back-button, .next-button {
    .scholarship-button-label, .scholarship-button-destination {
        height: 40px;
        display: flex;
        align-items: center;

        span {
            line-height: 1;
            display: block;
        }
    }
}

.next-button {
    min-height: 40px;
}

.back-button {
    border: solid 1px $pastel-light-blue;
    min-height: 40px;

    .back-label {
        background-color: $pastel-light-blue;
    }

    .back-destination {
        color: $pastel-light-blue;
    }
}

.continue-button {
    border: solid 1px $white;
    border-radius: 20px;
    background-color: $pastel-light-blue;
    color: $white;
    flex-direction: column;

    .continue-label {
        padding: 15px 20px;
    }

    .continue-destination {
        font-size: 12px;
        color: $white;
    }
}

.exit-btn-label {
    font-size: 0.875rem; // 14px
    color: $grey-92;
    line-height: 1.3;
}

.exit-btn-text {
    font-size: 1rem; // 16px
    color: $pastel-blue;
    text-transform: uppercase;
    border-bottom: 2px solid $pastel-blue;
    width: max-content;
}
</style>
