<template>
    <div class="vi-looker-admin px-2">
      <i class="bi bi-gear position-relative float-right" @click.prevent="toggleAdmin"></i>
      <div v-if="showAdmin" class="vi-looker-admin-body">
        <div class="form-group pb-2">
          <label class="form-label" for="substituteEmail">Substitute Email</label>
          <input
            class="form-control"
            type="email"
            name="substituteEmail"
            id="substituteEmail"
            placeholder="Email to use for this looker dashboard"
            v-model="substituteEmail"
          >
        </div>

        <div>
          <button type="button" class="btn btn-info" @click.prevent="adminReloadData">Reload</button>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name : 'TestEmailField',
        data() {
            return {
                substituteEmail: '',
                showAdmin: false,
                currentSourceUrl: '',
            }
        },
        methods: {
            toggleAdmin() {
                this.showAdmin = !this.showAdmin;
            },
            adminReloadData() {
                this.$emit('reload-data', this.substituteEmail);
            }
        },
    }
</script>
