<template>
    <form :id="formId">
        <div
            v-for="(subscription, index) in subscriptions"
            :key="index"
            class="row learner-invoice-row m-0"
        >
            <div class="col col-md-4 ps-0">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        :value="subscription"
                        name="invoiceRadio"
                        :id="subscription.id"
                        v-model="selectedSubscription"
                        @change="emitSelectedSubscription"
                    >

                    <label
                        class="form-check-label"
                        :for="subscription.id">
                            <p class="text-white mb-0">{{ subscription.learnerName }}</p>
                    </label>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'SubscriptionInvoiceStatus',
    data() {
        return {
            selectedSubscription: null,
        };
    },
    props: {
        formId: {
            type: String,
            default: null,
        },
        subscriptions: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        if (this.subscriptions.length == 1) {
            this.selectedSubscription = this.subscriptions[0];
            this.emitSelectedSubscription();
        }
    },
    methods: {
        emitSelectedSubscription() {
            this.$emit('selectedSubscription', this.selectedSubscription);
        },
    },
  };
</script>

<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';

.learner-invoice-row {
    border-bottom: 1px solid $white;

    &:last-of-type {
        border-bottom: none;
    }
}
</style>
