<template>
    <nav class="mb-2 p-3" v-if="!hideProgressBar">
        <div class="wrapper py-2 d-flex justify-content-around align-items-end">
            <template v-for="(step, key) in routes">
                <div v-if="!pagesToExcludeFromProgressBar.includes(step.name)" class="scholarship-progress-step d-flex flex-column align-items-center text-center position-relative" :class="getClassName(step.name, routes, step.children)" :key="`application-step-${key}`" role="button">
                    <p class="title-text mb-1 text-uppercase px-2">{{ step.title }}</p>

                    <div class="progress-row w-100 h-auto" :class="{ 'is-first-route': isFirstRoute(step.name), 'is-last-route': isLastRoute(step.name) }">
                        <div class="px-1 px-md-2 p-2 w-100 progress-back d-flex" :class="{ 'is-first-route': isFirstRoute(step.name), 'is-last-route': isLastRoute(step.name) }">
                            <span class="indicator rounded-circle d-flex justify-content-center align-items-center m-auto"></span>
                        </div>
                    </div>
                </div>

                <div v-for="(childStep, childKey) in step.children" class="scholarship-progress-step d-flex flex-column align-items-center text-center position-relative child-step" :class="getChildClassName(childStep.name, step.children, step)" :key="`application-child-step-${childStep.name}-${childKey}`" role="button">
                    <div class="progress-row w-100 h-auto">
                        <div class="px-1 px-md-2 p-2 w-100 progress-back d-flex">
                            <span class="indicator rounded-circle d-flex justify-content-center align-items-center m-auto"></span>
                        </div>
                    </div>

                </div>

            </template>
        </div>

        <div class="d-flex justify-content-between px-3 text-pastel-blue mt-2 current-next">
            <div v-if="currentStepInformation">
                <p class="mb-1">Current Step, <strong>{{ currentStepInformation.title }}</strong><span v-if="currentStepInformation.step">: <strong>{{ currentStepInformation.step }}</strong> of <strong> {{ currentStepInformation.totalSteps }} </strong></span> </p>
                <p class="mb-0" v-if="currentStepInformation.activeChildTitle"><strong>{{ currentStepInformation.activeChildTitle }}</strong></p>
            </div>
            <div v-if="nextStepInformation && nextStepInformation.title != 'Ineligible'">
                <p class="mb-1">Next Step, <strong>{{ nextStepInformation.title }}</strong><span v-if="nextStepInformation.step">: <strong>{{ nextStepInformation.step }}</strong> of <strong>{{ nextStepInformation.totalSteps }}</strong></span></p>
                <p class="mb-0" v-if="nextStepInformation.activeChildTitle"><strong>{{ nextStepInformation.activeChildTitle }}</strong></p>
            </div>
        </div>
    </nav>
</template>
<script>
import { navigationRoutes } from '../../../router';

export default {
    name: "ScholarshipProgressBar",
    data: () => ({
        routes: navigationRoutes,
        pagesToExcludeFromProgressBar: ['scholarship-application.ineligible', 'scholarship-application.applications-closed'],
        childPagesToExcludeFromProgressBar: ['scholarship-application.applicant.we-review'],
    }),
    computed: {
        hideProgressBar() {
            return this.pagesToExcludeFromProgressBar.includes(this.currentRouteName);
        },

        currentStepInformation() {
            let currentRoute = this.routes.find((route) => route.name == this.currentRouteName);

            if (currentRoute) {
                return {
                    title: currentRoute.title,
                    step: null,
                    totalSteps: null,
                    activeChildTitle: null,
                }
            }

            currentRoute = this.routes.find((route) => route.children && route.children.find((child) => child.name == this.currentRouteName));

            if (currentRoute) {
                return {
                    title: currentRoute.title,
                    step: currentRoute.children.findIndex((child) => child.name == this.currentRouteName) + 1,
                    totalSteps: currentRoute.children.length,
                    activeChildTitle: currentRoute.children.find((child) => child.name == this.currentRouteName).title,
                }
            }

            return null;
        },

        nextStepInformation() {
            let currentRouteIndex = this.routes.findIndex((route) => route.name == this.currentRouteName);

            if (currentRouteIndex > -1) {
                let nextRoute = this.routes[currentRouteIndex + 1];

                if (nextRoute) {
                    if (nextRoute.children && nextRoute.children.length > 0) {
                        return {
                            title: nextRoute.title,
                            step: 1,
                            totalSteps: nextRoute.children.length,
                            activeChildTitle: nextRoute.children[0].title,
                        }
                    } else {
                        return {
                            title: nextRoute.title,
                            step: null,
                            totalSteps: null,
                            activeChildTitle: null,
                        }
                    }
                }
            }

            let parentRouteIndex = this.routes.findIndex((route) => route.children && route.children.find((child) => child.name == this.currentRouteName));

            if (parentRouteIndex > -1) {
                let currentRouteIndex = this.routes[parentRouteIndex].children.findIndex((child) => child.name == this.currentRouteName);
                let nextRoute = this.routes[parentRouteIndex].children[currentRouteIndex + 1];

                if (nextRoute) {
                    return {
                        title: this.routes[parentRouteIndex].title,
                        step: currentRouteIndex + 2,
                        totalSteps: this.routes[parentRouteIndex].children.length,
                        activeChildTitle: nextRoute.title,
                    }
                } else {
                    let nextParentRoute = this.routes[parentRouteIndex + 1];

                    if (nextParentRoute) {
                        if (nextParentRoute.children.length) {
                            return {
                                title: nextParentRoute.title,
                                step: 1,
                                totalSteps: nextParentRoute.children.length,
                                activeChildTitle: nextParentRoute.children[0].title,
                            }
                        } else {
                            return {
                                title: nextParentRoute.title,
                                step: null,
                                totalSteps: null,
                                activeChildTitle: null,
                            }
                        }
                    }
                }
            }

            return null;
        },

        currentRouteName() {
            return this.$route.name;
        },
        activeChildRoutes() {
            let activeRoute = this.routes.find((route) => route.name == this.currentRouteName);

            if (activeRoute && activeRoute.children) {
                return activeRoute.children;
            } else {
                activeRoute = this.routes.find((route) => route.children && route.children.find((child) => child.name == this.currentRouteName));
                if (activeRoute && activeRoute.children) {
                    return activeRoute.children;
                }
            }

            return [];
        }
    },
    methods: {
        isActiveStep(routeName) {
            let route = this.routes.find((route) => route.name == routeName);

            if (route && route.name == this.currentRouteName) {
                return true;
            } else if (route && route.children) {
                return route.children.find((child) => child.name == this.currentRouteName);
            }

            return false;
        },
        isCompletedStep(routeName, routes) {
            let routeIndex = routes.findIndex((route) => route.name == routeName);
            let currentRouteIndex = routes.findIndex((route) => this.isActiveStep(route.name));
            return routeIndex < currentRouteIndex;
        },
        getClassName(routeName, routes, children) {
            if (this.currentRouteName === 'scholarship-application.ineligible') {
                return 'ineligible';
            } else if (this.isActiveStep(routeName) && !children) {
                return 'active';
            } else if (this.isCompletedStep(routeName, routes)) {
                return 'complete';
            } else if (this.isActiveParentRoute(routeName)) {
                return 'active-parent'
            }

            return '';
        },
        getChildClassName(routeName, routes, parentStep) {
            if (routeName == this.currentRouteName) {
                return 'active';
            } else {
                let stepIndex = routes.findIndex((route) => route.name == routeName);
                let currentStepIndex = routes.findIndex((route) => route.name == this.currentRouteName);

                if (stepIndex < currentStepIndex || this.isCompletedStep(parentStep.name, this.routes)) {
                    return 'complete';
                }
            }

            return '';
        },
        isFirstRoute(routeName) {
            return routeName == this.routes[0].name;
        },
        isLastRoute(routeName) {
            return routeName == this.routes[this.routes.length - 1].name;
        },
        isActiveParentRoute(routeName) {
            let route = this.routes.find((route) => route.name == routeName);

            if (route && route.name == this.currentRouteName) {
                return true;
            } else if (route && route.children) {
                return route.children.find((child) => child.name == this.currentRouteName);
            }

            return false;
        }
    }
}
</script>

<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';

nav {

    .scholarship-progress-step {
        flex: 1 1 20%;

        .title-text {
            font-size: 14px;
            color: $pastel-blue;

            @media screen and (max-width: 768px) {
                left: 0;
            }
        }

        &.complete,
        &.active-parent {

            .progress-back {
                background-color: $pastel-blue;

                .indicator {
                    border: none;
                    background-color: $white;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        &.active {

            .title-text {
                font-weight: bold;
            }

            .progress-back {
                background-color: $pastel-blue;
                border-bottom-right-radius: 1rem;
                border-top-right-radius: 1rem;

                .indicator {
                    height: 16px;
                    width: 16px;
                    border-radius: 10px;
                    border: solid 1px $green;

                    &::before {
                        height: 6px;
                        width: 6px;
                        background-color: $green;
                    }
                }
            }
        }

        &.active-parent,
        &.active {
            .title-text {
                font-weight: 600;
            }
        }

        &.child-step {
            .indicator {
                width: 0.4rem;
                height: 0.4rem;
            }

            &:not(:last-child)::after {
                top: 0.9rem;
            }
        }

        @media (max-width: 767px) {
            .title-text {
                font-size: 14px;
                position: absolute;
                top: -1.4rem;
            }

            &:not(.active-parent) {
                .title-text {
                    display: none;
                }
            }
        }
    }

}

.current-next {
    font-size: 14px;
    line-height: 1.5;
}

.is-first-route {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.is-last-route {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.progress-row {
    background-color: $white;
    border-top: solid 1px rgba($pastel-blue, 0.5);
}

.progress-back {
    background-color: transparent;
    height: 2rem;

    .indicator {
        background-color: $pastel-blue;
        height: 0.6rem;
        width: 0.6rem;
        z-index: 1;

        &::before {
            content: '';
            display: block;
            width: 0.1rem;
            height: 0.1rem;
            background-color: $pastel-blue;
            border-radius: 0.2rem;
        }
    }
}
</style>