<template>
    <div class="row table-row w-100 m-0 mb-0 mb-md-2 d-flex rounded flex-nowrap gx-2 justify-content-around gx-md-0 border-0">

        <div
            v-for="(mod) in modulesList"
            :key="mod"
            class="p-0 me-2 me-md-0 table-cell-col"
        >
            <progress-table-cell
                :class="{ 'active': parseInt(currentModule) === mod, 'highlight': parseInt(currentModuleHover) === mod}"
                :state="modules.hasOwnProperty(mod) ? modules[mod] : null"
                :active="parseInt(currentModule) === mod"
                :mod="mod"
                :student-email="studentEmail"
            ></progress-table-cell>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProgressTableRow",
    props: {
        modulesList: {
            type: Array,
            required: true,
        },
        modules: {
            type: Object,
        },
        currentModule: {
            type: String,
            required: true,
        },
        studentEmail: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            active: "active",
            complete: true,
            upcoming: true,
            outstanding: true,
            currentModuleHover: null,
        }
    },
    mounted() {
        document.addEventListener('moduleHover', (e) => {
            this.currentModuleHover = e.detail;
        });
    }
}

</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';
@import 'resources/sass/abstract/term-progress.scss';

.table-row {
    background-color: $white;
    box-shadow: 0 2px 3px $shadow-1;
    height: $tpCellHeight;

    @media (max-width: 767.98px) {
        background-color: transparent;
        box-shadow: none;
    }

    .table-cell-col {
        height: $tpCellHeight;
        // width: $tpCellHeight;
        flex-basis: $tpCellHeight;
        flex-shrink: 0;
    }

    .highlight {
        background-color: rgba($light-blue-F0, 0.5);

        @media (max-width: 767.98px) {
            background-color: rgba($light-blue-F0, 0.5);
        }
    }
}

</style>
