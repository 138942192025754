<template>
    <div ref="successModal" class="modal fade success-modal" id="successModal" aria-hidden="true" aria-labelledby="successModalLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border border-3 border-white">
                <div class="modal-header border-0 pb-0 position-relative">
                    <img class="success-modal-tick" :src="'/portal-data/uct/tick.svg'" alt="Tick Icon">

                    <h6 class="modal-title text-white text-center w-100 fw-bolder" id="successModalLabel">
                        {{ hasSuccessfulSignUps ? 'Success!' : 'Error!' }}
                    </h6>

                    <button type="button" class="btn-close btn-close-white position-absolute modal-close-icon" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center pb-4 pt-2">
                    <!-- Has signed up Virtual Clubs -->
                    <template v-if="hasSuccessfulSignUps">
                        <p class="text-white mb-2">You have successfully signed up for</p>

                        <div v-for="virtualClub in responseData.signedUpVirtualClubs" :key="virtualClub.id" class="mb-1">
                            <i class="bi bi-check-lg d-inline me-1 text-white"></i> <span class="text-white">{{ virtualClub.name}}</span>
                        </div>

                        <vi-error-flash
                            v-if="typeof responseData.error === 'string'"
                            :error-message="responseData.error"
                        ></vi-error-flash>

                        <template v-if="responseData.error != null && typeof responseData.error === 'object'">
                            <vi-error-flash
                                v-for="(errorMessage, id) in responseData.error"
                                :key="id"
                                :class="['mb-3']"
                                :error-message="errorMessage"
                            ></vi-error-flash>
                        </template>
                    </template>

                    <!-- Has no successful Virtual Clubs sign ups -->
                    <vi-error-flash
                        v-else
                        errorMessage="Oops! Something has gone wrong. Please refresh the page and try again."
                    ></vi-error-flash>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ViErrorFlash from './ViErrorFlash.vue';

export default {
  components: { ViErrorFlash },
    name: "VirtualClubsSuccessModal",
    data: function() {
        return {
            modalInstance: null,
            responseData: null,
        };
    },
    computed: {
        hasSuccessfulSignUps() {
            return this.responseData && Array.isArray(this.responseData.signedUpVirtualClubs);
        }
    },
    methods: {
        setResponseData(data) {
            this.responseData = data;
        },
        show() {
            this.modalInstance.show();
        },
        hide() {
            this.modalInstance.hide();
        },
    },
    mounted() {
        this.modalInstance = new bootstrap.Modal(this.$refs.successModal, {});
    },
}
</script>
