<template>
  <div class="vi-table-cell" :class="{'flex-column' : heading.type == 'resubmit'}" :style="cellStyle">
    <span class="vi-table-cell-heading text-wrap" v-if="showHeading && heading.title">{{ heading.title }}:&nbsp;</span>

    <template v-if="heading.title != 'Assessment Query' && !(heading.hasOwnProperty('type') && heading.type == 'resubmit')">
      <template v-if="value">
        <span class="vi-table-cell-value" v-html="value.hasOwnProperty('title') ? value.title : cellValue"></span>
      </template>
      <span v-else-if="!customContent" class="vi-table-cell-value"> - </span>
      <slot v-else></slot>
    </template>

    <template v-if="heading.type == 'resubmit' && heading.title != 'Assessment Query'">
      <template v-if="value">
        <button @click="resubmit" class="uct-btn-link open-modal-btn mt-2">{{ heading.actionName }}</button>
        <small class="deadline-day my-1">Until: {{ value }}</small>
      </template>
      <span v-else class="vi-table-cell-value"></span>
    </template>

    <template v-if="heading.title == 'Assessment Query'">
      <template v-if="value">
        <span class="vi-table-cell-value" v-html="value.hasOwnProperty('title') ? value.title : cellValue"></span>
      </template>

      <template v-if="!value">
        <vi-query-button
          v-if="showQueryButton"
          :query-url="queryUrl"
          :email-for-grading-query="emailForGradingQuery"
          :item-id="itemId"
          :assessment="assessment"
          @show-modal="handleShowModal"
        />
      </template>
    </template>

    <vi-grading-query-reasons
      v-if="showModal"
      :show="true"
      @update:show="showModal = $event"
      :dynamic-fields="dynamicFields"
      :selected-query-reason="selectedOption"
      :query-type="selectedQueryType"
      @option-selected="handleOptionSelected"
      :assessment="assessment"
      :query-url="queryUrl"
      :email-for-grading-query="emailForGradingQuery"
      :assessment-submissions-view="assessmentSubmissionsView"
      :grading-query-upload-url="gradingQueryUploadUrl"
      :is-resubmit="resubmissionClicked"
      :queries-active="showQueryButton"
      :resubmissions-active="(heading.hasOwnProperty('type') && heading.type == 'resubmit') && value"
    >
      <div class="options-container container-fluid">
          <div class="row">
              <div v-for="(option, key) in options" :key="key" class="col-12 col-sm-6 col-md-4 col-lg-4">
                <vi-selectable-card
                    :label="option.label"
                    :trueValue="key"
                    :value="selectedOption"
                    :isChecked="option.label === selectedOption"
                    @input="selectOption(option.label, option.type)"
                    :disabled="!isActiveAction(option.label)"
                ></vi-selectable-card>
              </div>
          </div>
      </div>
    </vi-grading-query-reasons>
  </div>
</template>

<script>
import viGradingQueryConfig from "../../config/vi-grading-query";

export default {
  props: {
    heading: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
    isHeading: {
      type: Boolean,
      default: false,
    },
    numColumns: {
      type: Number,
      required: true,
    },
    customContent: {
      type: Boolean,
      default: false,
    },
    queryUrl: {
      type: String,
      required: false,
      default: null,
    },
    emailForGradingQuery: {
      type: String,
      required: false,
      default: null,
    },
    itemId: {
      type: String,
      required: false,
      default: null,
    },
    assessment: {
      type: Object,
      required: false,
      default: null,
    },
    termYearQueryData: {
      type: Array,
      required: false,
      default: () => [],
    },
    assessmentSubmissionsView: {
      type: String,
      required: false,
      default: 'uct_assessment_submissions',
    },
    gradingQueryUploadUrl: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      cellValue: this.value,
      showModal: false,
      dynamicFields: {},
      options: [],
      selectedOption: null,
      showAdditionalInfoFields: false,
      selectedQueryType: null,
      resubmissionClicked: false,
    };
  },
  computed: {
    cellStyle() {
      let styleObj = {
        'flex-basis': 'calc(100% / ' + this.numColumns + ')',
      };

      if (this.isHeading) {
        return styleObj;
      }

      if (this.heading.hasOwnProperty('style')) {
        for (const cssProp in this.heading.style) {
          if (Object.hasOwnProperty.call(this.heading.style, cssProp)) {
            styleObj[this.kebabize(cssProp)] = this.heading.style[cssProp];
          }
        }
      }

      if (this.heading.hasOwnProperty('valueMap') && this.heading.valueMap) {
        let styleConfig = this.heading.valueMap;

        if (Array.isArray(this.cellValue) && this.cellValue.length > 1) {
          this.cellValue.forEach(val => {
            for (const valueCheck in styleConfig.map) {
              if (Object.hasOwnProperty.call(styleConfig.map, valueCheck)) {
                const element = styleConfig.map[valueCheck];
                if (val === valueCheck) {
                  styleObj[this.kebabize(cssProp)] = styleConfig.map[valueCheck];
                }
              }
            }
          });

          this.cellValue = this.value.join(', ');

        } else {
          let cssProp = styleConfig.property;
          this.cellValue = Array.isArray(this.value) ? this.value[0] : this.value;

          for (const valueCheck in styleConfig.map) {
            if (Object.hasOwnProperty.call(styleConfig.map, valueCheck)) {
              const element = styleConfig.map[valueCheck];
              if (this.cellValue === valueCheck) {
                styleObj[this.kebabize(cssProp)] = styleConfig.map[valueCheck];
              }
            }
          }
        }
      }

      return styleObj;
    },
    showQueryButton() {
      if (
        (this.assessment?.[`${this.assessmentSubmissionsView}.assignment_type`] !== 'Formal Assessment'
        && this.assessment?.[`${this.assessmentSubmissionsView}.assignment_type`] !== 'Exam'
        && this.assessment?.[`${this.assessmentSubmissionsView}.assignment_type`] !== 'Cycle Test')
        || this.isHeading
      ) {
        return false;
      }

      const submissionDateStr = this.assessment?.[`${this.assessmentSubmissionsView}.scheduled_submission_at_date`];
      const submissionDate = new Date(submissionDateStr.replace(/\//g, '-'));
      const isSubmissionDatePast = submissionDate <= new Date();
      if (!isSubmissionDatePast) {
        return false;
      }

      const submissionYear = this.assessment?.[`${this.assessmentSubmissionsView}.scheduled_submission_at_year`];
      const submissionTermStr = this.assessment?.[`${this.assessmentSubmissionsView}.term`];
      const submissionTerm = submissionTermStr ? parseInt(submissionTermStr.split(' ')[1]) : null;

      const match = this.termYearQueryData.find(entry =>
        entry.year === submissionYear && entry.term === submissionTerm && entry.open_for_queries
      );

      return !!match;
    }
  },
  methods: {
    // Source: https://stackoverflow.com/a/63116134
    kebabize(str) {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
          : letter;
      }).join('');
    },
    isActiveAction(label) {
      if (label === 'Resubmission Request') {
        return this.heading.hasOwnProperty('type') && this.heading.type == 'resubmit' && this.value;
      }

      if (
        this.assessment?.[`${this.assessmentSubmissionsView}.item_grade`] === '-'
        && label !== 'Excusals'
      ) {
        return false;
      }

      return this.showQueryButton;
    },
    handleShowModal(show) {
      this.resubmissionClicked = false;
      this.showModal = show;
    },
    selectOption(label, type) {
      if (!this.isActiveAction(label)) {
        return;
      }
      this.showAdditionalInfoFields = true;
      this.selectedOption = label;
      this.selectedQueryType = type;
    },
    handleOptionSelected({ itemId, option }) {
      this.$emit("option-selected", { itemId, option });
    },
    resubmit() {
      this.selectedOption = 'Resubmission Request';
      this.selectedQueryType = 'resubmissionRequest';
      this.resubmissionClicked = true;
      this.showModal = true;
    },
  },
  created() {
    this.dynamicFields = viGradingQueryConfig.dynamicFields;
    this.options = viGradingQueryConfig.dynamicFields.map((option) => {
      return {
        label: option.label,
        type: option.type,
      };
    });
  },
}
</script>

<style lang="scss">
  @import '../../../sass/abstract/colours.scss';

  .vi-table-cell {
    flex-grow: 0;
    padding: 0.25rem 0.5rem;
    color: $pastel-dark-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }

    &-heading {
      display: none;
      overflow-wrap: break-word;
    }
  }

  @media screen and (max-width: 768px) {
    .vi-table-cell {
      border: none;

      &:last-child {
        border-right: none;
      }
    }

    .vi-table-cell-heading {
      display: inline-block;
      font-weight: 600;
      padding-right: 5px;
    }
  }
</style>
