<template>
    <div
        ref="modalNode"
        id="defferedTestsModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="defferedTestsModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <form @submit.prevent="submit" class="modal-content" enctype='multipart/form-data'>
                <div class="modal-header">
                    <h5 class="modal-title" id="defferedTestsModalLabel">Deffered cyle test/exam</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body" v-if="hasResponse">
                    <p>The following rules apply when filling out this form:</p>

                    <ul class="dash-style">
                        <li>This form must be filled out by the primary guardian.</li>
                        <li>The application must be made by the deadline of 24 hours after the end of the applicable assessment window. Applications received after this time will not be considered.</li>
                        <li>Applications may be made prior to the scheduled assessment date.</li>
                        <li>All accompanying evidence must be included in the application for a deferred assessment (see below for examples of evidence). Any applications that do not have supporting evidence will not be considered. Please do not share any graphic images as evidence.</li>
                        <li>The outcome of an application for a deferred assessment will be communicated to the learner and guardian</li>
                    </ul>

                    <p class="fw-bold">The following are valid reasons that will be considered in the application for a deferred assessment (including the evidence needing to be attached to this application):</p>

                    <dl>
                        <dt>1. Illness (physical or psychological):</dt>
                        <dd>A valid medical certificate as issued by a registered medical practitioner must be submitted.</dd>

                        <dt>2. Family bereavement:</dt>
                        <dd>The death of an immediate family member as supported by a death certificate.</dd>

                        <dt>3. Appointment at court, social services or any other official agency:</dt>
                        <dd>Written evidence from the legal team, presiding officer, social worker or other official handling the case to be submitted as proof.</dd>

                        <dt>4. Giving birth:</dt>
                        <dd>Subject to written confirmation by a registered medical practitioner.</dd>

                        <dt>5. Attending a religious or cultural obligation:</dt>
                        <dd>Learners attending religious or cultural obligations, require a letter from the parent(s) / guardians.</dd>

                        <dt>6. Representing the country or province in a sporting or cultural event:</dt>
                        <dd>Official letter of invitation to the event required or from the convenor of the sports or cultural team.</dd>

                        <dt>7. Acts of nature:</dt>
                        <dd>These refer to events outside of human control. Institutions might be required to submit evidence of such event(s) having occurred and/or reports from counsellors, psychiatrists etc as needed.</dd>

                        <dt>8. Load shedding/power outage</dt>
                        <dd>To accommodate load shedding, UCT OHS has implemented two staggered start times for Cycle tests and exams. Loadshedding will only be considered a reason for deferral from stage 6 onwards. Please provide proof of the load shedding schedule affecting your area/evidence of the power outage to be considered for a deferred test or exam.</dd>

                        <dt>9. External exam/test clash</dt>
                        <dd>Please provide proof of a clash with an exam/test that is external to UCT Online High School by providing the timetable of this external test.</dd>

                        <dt>10. Error on The Invigilator App</dt>
                        <dd>Please include a screenshot of the error message on The Invigilator App.</dd>

                        <dt>11. 502/504 System or Timetable error</dt>
                        <dd>Please include a screenshot of the 502/504 or Timetable error message.</dd>

                        <dt>12. Time concessions</dt>
                        <dd>Only available to learners who have been approved for time concessions.</dd>
                    </dl>

                    <p>The following reasons will NOT be considered as a valid reason for an application:</p>

                    <ul class="dash-style">
                        <li>Running out of time to submit</li>
                        <li>Wifi issues experienced during the exam for any reason other than Stage 6 load shedding causing the learner to fail to submit</li>
                        <li>Submitting the wrong document</li>
                        <li>Submitting only a portion of the answer paper</li>
                        <li>Submitting an illegible paper</li>
                        <li>Going on holiday</li>
                    </ul>

                    <p>Approval is at the discretion of UCT OHS and these terms are subject to change.</p>

                    <div v-if="papers" class="form-group mb-3">
                        <label for="papers" class="form-label">Paper Name</label>

                        <select
                            id="papers"
                            :disabled="loading"
                            class="form-select new-input ps-0"
                            aria-label="Paper Name"
                            required
                            name="paperId"
                            v-model="form.paperId"
                        >
                            <option selected disabled>Please select a reason</option>
                            <option v-for="(paper) in papers" :key="paper.value" :value="paper.value">{{ paper.option }}</option>
                        </select>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['papers']" v-html="errors['papers'][0]"></p>
                    </div>

                    <div v-else class="spinner-wrapper d-flex justify-content-center p-2">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div class="form-group mb-3">
                        <label class="form-label" for="reasons">Reasons</label>

                        <select
                            id="reasons"
                            :disabled="!papers || loading"
                            class="form-select new-input ps-0"
                            aria-label="Reasons"
                            required
                            name="reason"
                            v-model="form.reason"
                        >
                            <option selected value="" disabled>Please select a paper</option>
                            <option v-for="(reason, key) in reasons" :key="key" :value="reason">{{ reason }}</option>
                        </select>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['reasons']" v-html="errors['reasons'][0]"></p>
                    </div>

                    <div class="form-group mb-3">
                        <label for="explanation" class="mb-2">Explanation</label>

                        <p class="supporting-text">Please motivate your application by giving a description of why you are applying for your learner to write a deferred paper. Please include all the relevant details relating to the circumstances around why the learner could not write the original paper.</p>

                        <textarea
                            id="explanation"
                            :disabled="!papers || loading"
                            class="form-control"
                            placeholder="Please enter your explanantion here"
                            name="explanation"
                            rows="4"
                            required
                            v-model="form.explanation"
                        ></textarea>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['explanation']" v-html="errors['explanation'][0]"></p>
                    </div>

                    <div class="form-group">
                        <label for="supportingEvidence" class="mb-2">Supporting Evidence</label>

                        <p class="supporting-text">Please submit supporting evidence (Eg: screenshot of the load shedding schedule) as per the description at the top of this form. Note: Failure to submit supporting evidence will mean that this application will be denied.</p>

                        <p class="supporting-text">* Please do NOT share any graphic images as supporting evidence.</p>

                        <vi-file-selector
                            :disabled="!papers || loading"
                            upload-field-name="evidence[]"
                            :handle-upload="(formData) => handleUpload(formData)"
                            :max-file-size="8000000"
                            accepted-file-types="image/*, .pdf"
                            id="supportingEvidence"
                            class="new-input"
                            uploaded-text="File(s) selected."
                            :required="true"
                        ></vi-file-selector>
                    </div>
                </div>

                <div class="modal-footer d-flex justify-content-between flex-row flex-wrap" v-if="hasResponse">
                    <button class="new-btn new-btn-sm new-btn-dark" data-bs-dismiss="modal" aria-label="Close">Cancel</button>

                    <vi-button-spinner
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                            'new-btn-lg': false,
                            'new-btn-sm': true,
                            'mt-4': false,
                        }"
                        buttonText="Confirm"
                        :loading="loading"
                        :disabled="!papers"
                    ></vi-button-spinner>
                </div>

                <div v-if="isSuccessful" class="modal-body text-center" >
                    <h4 class="text-light-blue">Deferred application submitted for:</h4>

                    <p><strong>Paper:</strong> {{ selectedPaperName }} </p>
                    <p><strong>Reason:</strong> {{ form.reason }} </p>
                    <p><strong>Supporting Evidence:</strong> </p>

                    <div v-if="Array.isArray(uploadedFileBlobs) && uploadedFileBlobs.length > 0" class="container">
                        <div class="row g-2 justify-content-center">
                            <div v-for="(file, key) in uploadedFileBlobs" :key="key" class="col-6" >
                                <embed
                                    :src="createObjectURL(file)"
                                    :type="file.type"
                                    class="image-gallery-slide mw-100"
                                >
                            </div>
                        </div>
                    </div>

                    <p>You will receive confirmation of a successful application submission via email.</p>

                    <p>The outcome of the application will be communicated to you via email once it has been reviewed by UCT OHS.</p>
                </div>

                <div class="modal-body text-center" v-if="isFailed">
                    <template v-if="Array.isArray(papers) && papers.length === 0">
                        <p>We are not currently taking applications for your learner to write a deferred cycle test or exam.</p>
                    </template>

                    <template v-else>
                        <h4 class="text-danger">An error has occurred</h4>

                        <p>Please reach out to your support coach for assistance.</p>
                    </template>
                </div>

                <div class="modal-body text-center" v-if="hasDuplicate">
                    <p>You already have an application for this paper. Please reach out to your support coach if you would like to make changes.</p>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DefferedTestApplicationForm',
    data() {
        return {
            form: {
                reason: null,
                paperId: null,
                explanation: null,
            },
            errors: null,
            isSuccessful: false,
            isFailed: false,
            hasDuplicate: false,
            loading: true,
            modalHtmlNode: null,

            papers: null,
            studentId: null,
            filesFormData: null,
        }
    },
    props: {
        papersUrl: {
            type: String,
            required: true,
        },
        reasons: {
            type: Array,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        reset() {
            this.form = {
                reason: null,
                paperId: null,
                explanation: null,
            };
            this.errors = null;
            this.isSuccessful = false;
            this.isFailed = false;
            this.hasDuplicate = false;
            this.loading = false;
            this.modalHtmlNode = null;
            this.papers = null;
            this.studentId = null;
            this.filesFormData = null;
        },
        loadPapers() {
            return axios.get(
                    this.papersUrl,
                    {
                        params: {
                            studentId: this.studentId,
                        },
                    }
                )
                .then(response => {
                    this.papers = response.data;
                })
                .catch(error => {
                    if (error.response.status === 404 && error.response.data.hasOwnProperty('papers')) {
                        this.papers = [];
                    }

                    this.isFailed = true;
                });
        },
        handleUpload(formData) {
            this.filesFormData = formData;

            return Promise.resolve();
        },
        submit(event) {
            this.loading = true;

            let formData = new FormData(event.target);

            formData.append('studentId', this.studentId);

            if (this.filesFormData) {
                for (const [key, value] of this.filesFormData.entries()) {
                    formData.append(key, value);
                }
            }

            axios
                .post(
                    this.submitUrl,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccessful = true;
                    }
                })
                .catch(error => {
                    const data = error.response.data;

                    if (error.response.status === 422) {
                        this.errors = data.errors
                    } else if (error.response.status === 409) {
                        this.hasDuplicate = true;
                    } else {
                        this.isFailed = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        init() {
            document.body.addEventListener('dct:open', event => {
                this.reset(); // Reset the component to the initial state.

                this.studentId = event.detail.studentId;

                let modalInstance = new bootstrap.Modal(this.$refs.modalNode);
                modalInstance.show();
            });
        },
        createObjectURL(file) {
            return URL.createObjectURL(file);
        }
    },
    mounted() {
        this.init();

        this.$refs.modalNode.addEventListener('show.bs.modal', event => {
            this.loadPapers();
        });
    },
    computed: {
        hasResponse() {
            return !(this.isSuccessful == true || this.isFailed == true || this.hasDuplicate == true);
        },
        selectedPaperName() {
            if (!this.form.paperId) {
                return '';
            }

            return this.papers.find(paper => paper.value === this.form.paperId).option;
        },
        uploadedFileBlobs() {
            if (!this.filesFormData) {
                return [];
            }

            let blobs = [];

            for (const file of this.filesFormData.values()) {
                blobs.push(file);
            }

            return blobs;
        }
    }
}
</script>

<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';

textarea, textarea:focus {
    color: $pastel-blue;
    resize: none;
}

.supporting-text {
    color: $pastel-blue;
    font-size: 14px;
}

.dash-style {
    list-style-type: '- ';
}

.color-uct-primary {
    color: $pastel-blue;
}

</style>