<template>
  <p v-if="message" class="invalid-feedback d-block m-0">{{ message }}</p>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: {
        message: {
            type: String,
        }
    }
}
</script>

<style>

</style>