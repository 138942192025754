<template>
    <div class="mb-5">
        <h4 class="mb-3 text-dark">Payment Methods</h4>

        <div class="payment-card-container p-3 rounded mb-3 shadow-sm d-flex justify-content-between">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="card-payment-method"
                    value="paymentsCredit"
                    id="creditCardPaymentMethod"
                    checked="true"
                    :disabled="isActive"
                >
                <label class="form-check-label" for="creditCard">
                    Credit or Debit Card
                </label>
            </div>
            <i class="bi bi-credit-card"></i>
        </div>

        <div v-if="card" class="payment-card-container p-3 rounded mb-3 ms-5 shadow-sm d-flex justify-content-between">
            <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="card"
                    value="paymentsCredit"
                    id="card"
                    checked="true"
                    :disabled="isActive"
                >

                <div class="card-info">
                    <p class="card-info-fullname">Name on card: {{ card.firstName }} {{ card.lastName }}</p>

                    <div class="card-info-status"><span :class="[ card.status ]">{{ card.status }}</span></div>

                    <p class="card-info-masked-number">Number: {{ card.maskedNumber }}</p>

                    <p class="card-info-expiry">Expiry: {{ card.expiryMonth }} / {{ card.expiryYear }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PaymentMethods",
        data: () => ({}),
        props: {
            isActive: {
                type: Boolean,
                default: false,
            },
            card: {
                type: Object,
                default: null,
                required: false,
            }
        },
        methods: {},
        mounted() {},
    }
</script>

<style lang="scss" scoped>
@import '../../../sass/abstract/colours.scss';

.payment-card-container {
    background-color: $white;

    .form-check {
        width: 100%;
        margin: 0;
        position: relative;

        input {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
        }
    }
}

.card-info {
    display: grid;
    grid-template-areas: "name status"
                         "number expiry";

    &-fullname {
        grid-area: name;
    }

    &-masked-number {
        grid-area: number;
    }

    &-expiry {
        grid-area: expiry;
        text-align: right;
    }

    &-status {
        text-align: right;
        text-transform: uppercase;
        letter-spacing: 1px;
        grid-area: status;

        span {
            border: 1px solid black;
            font-size: 12px;
            font-weight: normal;
            border-radius: 5px;
            padding: 0.1em 0.5em;
        }

        .valid {
            background-color: $green6C;
            color: $white;
            border-color: darken($green6C, 5%);
        }
    }

    p {
        margin: 0;
        font-weight: normal;
        font-size: 0.8rem;
    }


}
</style>
