<template>
    <div>
        <guardian-basic-info-section
            :guardian-object="guardianObject.contact"
            :submit-url="submitUrl"
            :home-link="homeLink"
            :update-email-url="updateEmailUrl"
            :dial-codes="dialCodes"
            :provinces="provinces"
            :countries="countries"
        ></guardian-basic-info-section>
    </div>
</template>

<script>
import GuardianBasicInfoSection from './GuardianBasicInfoSection.vue';

export default {
    name: "guardianAccountSection",
    props: {
        guardianObject: {
            type: Object,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        homeLink: {
            type: String,
        },
        updateEmailUrl: {
            type: String,
            required: true,
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        student: {
            type: Object,
            required: true,
        },
        homeLink: {
            type: String,
        },
        countries: {
            type: Object,
            required: true,
        },
        provinces: {
            type: Array,
            required: true,
        }
    },
    mounted() {
        // console.log(typeof student)
    },
}

</script>

<style scoped>

</style>