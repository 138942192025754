<template>
    <div class="vi-manage-account">
        <div v-if="initializing === false" class="container">
            <div class="row">
                <div class="col text-center">
                    <div class="status-indicator" :class="{'disabled': !canResume || isProcessing || midnightLock}">
                        <div :class="['status-slider', {'active': isPaused}]">
                            <div class="status-slider-slide">
                                <i v-if="isPaused" @click.prevent="openUnpauseModal" class="bi bi-play fix-play-position"></i>
                                <i v-else @click.prevent="openPauseModal" class="bi bi-pause"></i>
                            </div>
                        </div>
                    </div>

                    <button v-if="isPaused" @click.prevent="openUnpauseModal" class="new-btn new-btn-dark w-auto px-5">Resume my learning</button>

                    <button v-else class="new-btn new-btn-dark w-auto px-5" @click.prevent="openPauseModal">Pause my learning</button>

                    <div v-if="reinstatementDate" class="col col-12">
                        <p class="text-center m-0 text-white">Your learning will resume on {{ reinstatementDate }}.</p>
                    </div>

                    <div v-if="isUpcoming" class="col col-12">
                        <p class="text-center m-0 text-white">Pause scheduled for {{ pendingRequest.startDate }}.</p>
                    </div>

                    <div v-if="isPaused && itemPrice && !isUpcoming && !isSuspended" class="col col-12">
                        <p class="text-center m-0 text-white">You will be charged R {{ (itemPrice.price / 100).toFixed(2)  }} as soon as your learning is resumed.</p>
                    </div>

                    <div v-if="isSuspended && canResume" class="col col-12">
                        <p class="text-center m-0 text-white">Your account has been suspended. Select to Resume to resume your learning now. You will be charged R {{ (itemPrice.price / 100).toFixed(2)  }} as soon as your learning is resumed.</p>
                    </div>

                    <div v-if="isProcessing" class="col col-12">
                        <p class="text-center m-0 text-white">Your pause request has been processed, and cannot be changed at this stage.</p>
                    </div>

                    <div v-if="midnightLock" class="col col-12">
                        <p class="text-center m-0 text-white">Pause requests for the upcoming month were closed at 21:00. Please log a request for the next month after midnight.</p>
                    </div>
                </div>

                <div v-if="!canResume" class="col col-12">
                    <p class="text-center m-0 text-white">You cannot resume learning after the 25th of the current month. Please resume between the 1st and 25th.</p>
                </div>
            </div>

            <div v-if="submitSuccess && successMessage" class="row alert alert-success">
                <div class="col-12 p-0">
                    {{ successMessage }}
                    <br>
                    If you have any queries please contact <a href="mailto:admissions@uctohs.valentureonline.com">admissions@uctohs.valentureonline.com</a>.
                </div>
            </div>

            <div v-if="submitError" class="row alert alert-danger">
                <div class="col-12 p-0">
                    An error occured. Please refresh the page and try again. Please contact support of this problem persists at <a href="mailto:techsupport@uctohs.valentureonline.com">techsupport@uctohs.valentureonline.com</a>.
                </div>
            </div>
        </div>

        <div v-else-if="initializing === true" class="placeholder-glow">
            <span class="placeholder w-75"></span>
            <span class="placeholder w-50"></span>
            <span class="placeholder w-75"></span>
            <span class="placeholder w-25"></span>
            <span class="placeholder w-25"></span>
        </div>

        <div v-else class="alert alert-danger">
            An error occured loading this section. Please contact support of this problem persists at <a href="mailto:techsupport@uctohs.valentureonline.com">techsupport@uctohs.valentureonline.com</a>.
        </div>

        <!-- Pause Modal -->
        <div ref="pauseModal" class="modal fade" id="pauseModal" tabindex="-1" aria-labelledby="pauseModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <form @submit.prevent="submitPause" class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="pauseModalLabel">Pause your subscription</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <div class="date-pause mb-3">
                            <label class="mb-1" for="resumeDate">Select date you would like to resume your learning:</label>

                            <select class="d-block form-select w-100" name="resumeDate" id="resumeDate" v-model="chosenResumeDate" @change="pauseIndefinitely = null">
                                <option selected disabled :value="null">Select a date</option>
                                <option v-for="(date, display) in resumeDateOptions" :key="date" :value="date">{{ display }}</option>
                            </select>
                        </div>

                        <div class="indefinite-pause mb-3">
                            <input v-model="pauseIndefinitely" @change="chosenResumeDate = null" class="form-check-input" type="checkbox" :value="true" name="indefinitePause" id="indefinitePause">

                            <label for="indefinitePause">
                                I want to pause indefinitely <br>
                                <span>(Reactive your subscription whenever you are ready)</span>
                            </label>
                        </div>

                        <div class="pause-reason mb-3">
                            <label class="mb-1" for="pauseReason">Select a reason for pausing your learning:</label>

                            <select v-model="selectedPauseReason" required name="pauseReason" id="pauseReason" class="form-select w-100 d-block mb-2">
                                <option selected disabled :value="null">Select a reason</option>
                                <option v-for="(value, display) in pauseReasons" :key="value" :value="value">{{ display }}</option>
                            </select>

                            <template
                                v-if="pauseReasons && pauseReasons.hasOwnProperty('Other') && selectedPauseReason === pauseReasons['Other']"
                            >
                                <label class="mb-1" for="otherPauseReason">Please explain your reason:</label>

                                <textarea
                                    v-model="otherPauseReason"
                                    required
                                    class="form-control"
                                    name="otherPauseReason"
                                    id="otherPauseReason"
                                    rows="4"
                                ></textarea>
                            </template>
                        </div>

                        <div v-if="pauseStartDate && (pauseIndefinitely === true || chosenResumeDate)" class="mt-4 mb-2 alert alert-info">
                            <template v-if="pauseIndefinitely === true">
                                Your learning will be paused indefinitely from {{ pauseStartDate }}.
                            </template>

                            <template v-if="chosenResumeDate">
                                Your learning will be paused from {{ pauseStartDate }} till {{ Object.fromEntries(Object.entries(resumeDateOptions).map(a => a.reverse()))[chosenResumeDate] }}.
                            </template>
                        </div>

                        <div v-if="submitError" class="alert alert-danger">
                            We've failed to submit your pause request. Please refresh and try again. If the problem persists, please contact support.
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="new-btn new-btn-dark w-auto px-5" type="button" data-bs-dismiss="modal">Back</button>

                        <vi-button-spinner
                            :buttonClass="{
                                'new-btn': true,
                                'new-btn-dark': true,
                                'w-auto': true,
                                'px-5': true,
                            }"
                            buttonText="Confirm"
                            :loading="loading"
                            :disabled="!chosenResumeDate && !pauseIndefinitely"
                        ></vi-button-spinner>
                    </div>
                </form>
            </div>
        </div>

        <!-- Unpause Modal -->
        <div ref="unpauseModal" class="modal fade" id="unpauseModal" tabindex="-1" aria-labelledby="unpauseModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="unpauseModalLabel">Unpause my learning</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <p v-if="!isUpcoming" class="text-center">Once we have verified your payment, you may resume your learning.</p>
                        <p v-else class="text-center">Please confirm that you wish to cancel the existing request to pause your learning?</p>
                    </div>

                    <form @submit.prevent="submitUnpause" class="modal-footer">
                        <button class="new-btn new-btn-dark w-auto px-5" type="button" data-bs-dismiss="modal">Back</button>

                        <vi-button-spinner
                            :buttonClass="{
                                'new-btn': true,
                                'new-btn-dark': true,
                                'w-auto': true,
                                'px-5': true,
                            }"
                            buttonText="Confirm"
                            :loading="loading"
                        ></vi-button-spinner>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

const PAUSED = 'paused';
const UNPAUSED = 'unpaused';

export default {
    name: 'ManageAccount',
    props: {
        enrolledMatricStudentId: {
            type: String,
            required: true,
        }
    },
    mounted() {
        this.onMounted();
    },
    data() {
        return {
            status: UNPAUSED,
            resumeDateOptions: null,
            submitError: false,
            submitSuccess: false,
            successMessage: null,
            itemPrice: null,
            pauseStartDate: null,
            pauseReasons: null,
            pendingRequest: null,
            isUpcoming: false,
            isProcessing: false,
            isSuspended: false,
            midnightLock: false,

            // Submission state
            chosenResumeDate: null,
            pauseIndefinitely: null,
            reinstatementDate: null,
            canResume: null,
            selectedPauseReason: null,
            otherPauseReason: null,

            // Component State
            initializing: false,
            loading: false,
            pauseModalInstance: null,

            // Expose to template
            PAUSED,
            UNPAUSED,
        };
    },
    methods: {
        clearState() {
            this.status = UNPAUSED;
            this.resumeDateOptions = null;
            this.submitError = false;
            this.submitSuccess = false;
            this.successMessage = null;
            this.itemPrice = null;
            this.pauseStartDate = null;
            this.pauseReasons = null;
            this.pendingRequest = null;
            this.isUpcoming = false;
            this.isProcessing = false;
            this.chosenResumeDate = null;
            this.pauseIndefinitely = null;
            this.reinstatementDate = null;
            this.canResume = null;
            this.selectedPauseReason = null;
            this.otherPauseReason = null;
            this.loading = false;
            this.pauseModalInstance = null;
            this.isSuspended = false;
            this.initializing = false;
        },
        onMounted() {
            let initProcesses = [];

            this.initializing = true;

            initProcesses.push(this.init());

            Promise.all(initProcesses)
                .then(() => {
                    this.initializing = false;
                })
                .catch(() => {
                    this.initializing = null;
                });

            this.pauseModalInstance = new bootstrap.Modal(this.$refs.pauseModal, {});
            this.unpauseModalInstance = new bootstrap.Modal(this.$refs.unpauseModal, {});
        },
        init() {
            return axios.get(`${window.endpoints.manageAccount.init}?enrolledMatricStudentId=${this.enrolledMatricStudentId}`)
                .then(response => {
                    let data = response.data;
                    let isPaused = data.isPaused;

                    if (isPaused) {
                        this.status = PAUSED;
                    } else {
                        this.status = UNPAUSED;
                    }

                    this.resumeDateOptions = data.resumeDateOptions;
                    this.itemPrice = data.itemPrice;
                    this.pauseStartDate = data.pauseStartDate;
                    this.pauseReasons = data.pauseReasons;
                    this.isSuspended = data.isSuspended;

                    if (this.isSuspended) {
                        this.status = PAUSED;
                    }

                    if (data.hasOwnProperty('pendingRequest') && data.pendingRequest) {
                        this.isUpcoming = true;
                        this.isProcessing = data.pendingRequest.status === 'Processing';
                        this.pendingRequest = data.pendingRequest;

                        if (this.isUpcoming) {
                            this.status = PAUSED;
                        }
                    }

                    if (data.hasOwnProperty('reinstatementDate')) {
                        this.reinstatementDate = data.reinstatementDate;
                    }

                    if (data.hasOwnProperty('canResume')) {
                        this.canResume = data.canResume;
                    }

                    if (data.hasOwnProperty('midnightLock')) {
                        this.midnightLock = data.midnightLock;
                    }
                });
        },
        changeStatus() {
            if (this.status === PAUSED) {
                this.status = UNPAUSED;
                return;
            }

            this.status = PAUSED;
        },
        submitPause(event) {
            let formData = new FormData(event.target);

            this.submitError = false;
            this.loading = true;
            this.successMessage = null;

            axios.post(`${window.endpoints.manageAccount.pause}?enrolledMatricStudentId=${this.enrolledMatricStudentId}`, formData)
                .then(response => {
                    this.closePauseModal();

                    let message = `We've received your request. Your learning will be paused indefinitely from ${response.data.startDate}.`;

                    if (response.data.hasOwnProperty('endDate')) {
                        message = `We've received your request. Your learning will be paused from ${response.data.startDate} till ${response.data.endDate}.`;
                    }

                    this.clearState();
                    this.onMounted();

                    this.submitSuccess = true;
                    this.successMessage = message;
                })
                .catch(err => {
                    this.submitError = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        submitUnpause() {
            this.submitError = false;
            this.loading = true;
            this.successMessage = null;

            axios.post(this.getUnpauseUrl())
                .then(response => {
                    let data = response.data;

                    this.closeUnpauseModal();

                    let message = `We've received your request. Your learning will be unpaused momentarily.`;

                    if (data.hasOwnProperty('message') && data.message) {
                        message = data.message;

                        this.clearState();
                        this.onMounted();
                    }

                    this.submitSuccess = true;
                    this.successMessage = message;
                })
                .catch(err => {
                    this.submitError = true;
                    this.closeUnpauseModal();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getUnpauseUrl() {
            if (this.isUpcoming) {
                return window.endpoints.manageAccount.unpause + '?id=' + this.pendingRequest.id + `&enrolledMatricStudentId=${this.enrolledMatricStudentId}`;
            }

            return window.endpoints.manageAccount.unpause + `?enrolledMatricStudentId=${this.enrolledMatricStudentId}`;
        },

        openPauseModal() {
            this.pauseModalInstance.show();
        },
        closePauseModal() {
            this.pauseModalInstance.hide();
        },
        openUnpauseModal() {
            this.unpauseModalInstance.show();
        },
        closeUnpauseModal() {
            this.unpauseModalInstance.hide();
        },

        disposePauseModal() {
            this.pauseModalInstance.dispose();
        },
        disposeUnpauseModal() {
            this.unpauseModalInstance.dispose();
        },
    },
    destroyed() {
        this.disposePauseModal();
        this.disposeUnpauseModal();
    },
    computed: {
        isPaused() {
            return this.status === PAUSED;
        },
        isUnpaused() {
            return this.status === UNPAUSED;
        }
    },
    watch: {
        pauseIndefinitely() {
            this.selectedPauseReason = null;
            this.otherPauseReason = null;
        },
        chosenResumeDate() {
            this.selectedPauseReason = null;
            this.otherPauseReason = null;
        },
        selectedPauseReason() {
            this.otherPauseReason = null;
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.status-indicator {
    display: flex;
    justify-content: center;
    padding: 8px;
    margin-bottom: 24px;

    &.disabled {

        .status-slider-slide,
        & + button {
            pointer-events: none;
        }

        .status-slider-slide {
            background-color: $grey;
        }

        & + button {
            background-color: $grey;
            border-color: $grey;
        }
        // .status-slider, .status-slider:hover,
        // & + button {
        //     // filter: grayscale(1) !important;
        //     pointer-events: none;
        // }
    }
}

.status-slider {
    $slideMultiplier: 2;

    background-color: $white;
    width: 32px * $slideMultiplier;
    overflow: visible;
    position: relative;
    height: 16px * $slideMultiplier;
    border-radius: 16px;

    i {
        line-height: 1;
        font-size: 32px;
        display: block;
        max-height: 100%;
    }

    &-slide {
        padding: 4px * $slideMultiplier;
        max-width: 32px * $slideMultiplier;
        max-height: 32px * $slideMultiplier;
        border-radius: 50%;
        transition: all 300ms ease;
        border: 2px solid $white;
        background-color: $pastel-blue;
        color: $white;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);

        &:hover {
            cursor: pointer;
            color: $pastel-blue;
            background-color: $primary;

            i {
                color: $white;
            }
        }
    }

    &.active {
        .status-slider-slide {
            left: 100%;
        }
    }
}

.fix-play-position {
    position: relative;
    left: 2px;
}

.date-pause {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
        margin-right: 1rem;
    }
}

.indefinite-pause {
    display: flex;
    align-items: center;

    input {
        margin-right: 1rem;
    }

    span {
        font-size: 14px;
    }
}

textarea {
    resize: none;
}
</style>
