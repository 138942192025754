<template>
    <div class="time-table-container">
        <div v-if="!isProduction" class="admin-controls border border-dark">
            <button @click.prevent="refresh" type="button" class="btn btn-primary"><i class="bi bi-arrow-clockwise"></i></button>

            <br>

            <form @submit.prevent="adminRequestUpdate">
                <label class="form-label">Week Modifier:</label>
                <input type="number" name="weekModifier" id="weekModifier" class="bg-light border border-dark p-2 rounded" default="0" step="1">

                <button type="submit" class="btn btn-primary">Reload</button>
            </form>
        </div>
        <!-- Busy initializing -->
        <template v-if="initializing && !pageLoadingError">
            <div class="spinner-wrapper">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>

        <!-- Error Occured during initialization -->
        <template v-if="pageLoadingError && !initializing">
            <div class="alert" :class="{'alert-danger': isErrorMessage, 'alert-info': !isErrorMessage}">
                {{  pageErrorMsg ? pageErrorMsg : 'An error occured loading your data. If this error persists please contact support.' }}
            </div>
        </template>

        <!-- Successfully initialized -->
        <template v-if="!initializing && !pageLoadingError">
            <div class="content-block-wrapper">
                <div class="content-block">
                    <h2>Timetable Details</h2>

                    <hr>

                    <ul>
                        <li v-for="(colors, type) in colorLegend" :key="type">
                            <span class="color-block" :style="{ 'background-color': colors.background, 'border': `1px solid ${colors.border}` }"></span>
                            {{ headingMap[type] }}
                        </li>
                    </ul>
                </div>

                <div class="content-block">
                    <p>Displaying live session data for the time period {{ from }} till {{ to }}.</p>
                </div>
            </div>

            <div
                class="time-table"
            >
                <hr v-for="time in times" :key="time" class="line">
                <div class="header-bg"></div>
                <div class="times" :style="{ 'grid-template-rows': `repeat(${times.length}, calc(30px * 1.45))` }">
                    <div v-for="time in times" :key="time" class="time"><span>{{ time }}</span></div>
                </div>

                <template v-for="(day, index) in days">
                    <div :class="[ `day-${day.toLowerCase()}`, 'day', {'current': activeDay === day, 'active': currentDay === day} ]" >
                        <span v-if="hasPreviousDay" @click="() => moveDay(-1)" class="previous-day"><i class="bi bi-arrow-left-circle"></i></span>
                        {{ day }}
                        <div v-if="hasNextDay" @click="() => moveDay(1)" class="next-day"><i class="bi bi-arrow-right-circle"></i></div>
                    </div>

                    <div
                        v-if="events.hasOwnProperty(day)"
                        :class="[ `day-${day.toLowerCase()}` + '-slot', 'slot' ]"
                        :key="day"
                        :style="{ 'height': `${height}px` }"
                    >
                        <div
                            v-for="(event, index) in events[day]"
                            :key="index"
                            class="event"
                            :style="{
                                'top': `${event.top + 2}px`,
                                'height': `${event.height - 4}px`,
                                'background-color': colorLegend[event.type].background,
                                'border': `2px solid ${colorLegend[event.type].border}`,
                            }"
                        >{{ getEventName(event) }}</div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'TimeTable',
    props: {
        url: {
            type: String,
            required: true,
        },
        isProduction: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            initializing: true,
            pageLoadingError: false,
            pageErrorMsg: null,
            isErrorMessage: true,

            times: null,
            height: null,
            events: null,
            nameMap: null,
            currentDay: null,
            activeDay: null,
            from: null,
            to: null,

            days: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
            ],

            colorLegend: {
                'AMA': {
                    background: '#EBEFFA',
                    border: '#2E60D8',
                },
                'HR-SP': {
                    background: '#EAF9F0',
                    border: '#30C76C',
                },
                'Assembly': {
                    background: '#FEE5EB',
                    border: '#FD7B9C',
                },
                'HR-LCI': {
                    background: '#F4E3FE',
                    border: '#C573FA',
                },
            },
            headingMap: {
                'HR-LCI': 'Learner Check-In',
                'HR-SP': 'Support Pitstop',
                'Assembly': 'Assembly',
                'AMA': 'Ask Me Anything (AMA)',
            },
        };
    },
    mounted() {},
    methods: {
        refresh() {
            this.resetState();

            this.init();
        },
        setMsg(msg, isErrorMessage) {
            this.pageErrorMsg = msg;
            this.isErrorMessage = isErrorMessage;
            this.initializing = false;
            this.pageLoadingError = true;
        },
        init() {
            let initProcesses = [];

            this.initializing = true;
            this.pageLoadingError = false;

            initProcesses.push(this.fetchEvents(this.url));

            Promise.all(initProcesses)
                .catch(error => {
                    this.pageLoadingError = true;

                    let data = error.response.data;

                    if (data.hasOwnProperty('error')) {
                        let isError = error.response.status !== 404;

                        this.setMsg(data.error, isError);
                    }
                })
                .finally(() => {
                    this.initializing = false;
                });
        },
        fetchEvents(url) {
            return axios.get(url)
                .then(response => {
                    this.events = response.data.events;
                    this.height = response.data.height;
                    this.times = response.data.times;
                    this.nameMap = response.data.nameMap;
                    this.currentDay = response.data.currentDay;
                    this.activeDay = response.data.currentDay;
                    this.from = response.data.from;
                    this.to = response.data.to;
                });
        },
        moveDay(move) {
            let currentIndex = this.days.findIndex(day => day === this.currentDay);

            this.currentDay = this.days[currentIndex + move];
        },
        getEventName(event) {
            let identifier = null;

            if (event.hasOwnProperty('course_instance')) {
                identifier = event.course_instance;
            }

            if (event.hasOwnProperty('homeroom')) {
                identifier = event.homeroom;
            }

            return this.nameMap[identifier] ?? event.type;
        },
        resetState() {
            this.initializing = true;
            this.pageLoadingError = false;
            this.pageErrorMsg = null;
            this.times = null;
            this.height = null;
            this.events = null;
            this.nameMap = null;
            this.isErrorMessage = true;
        },
        adminRequestUpdate(event) {
            let data = new FormData(event.target);
            let newUrl = `${this.url}&weekModifier=${data.get('weekModifier')}`;

            let initProcesses = [];

            this.resetState();

            this.initializing = true;
            this.pageLoadingError = false;

            initProcesses.push(this.fetchEvents(newUrl));

            Promise.all(initProcesses)
                .catch(error => {
                    this.pageLoadingError = true;

                    let data = error.response.data;

                    if (data.hasOwnProperty('error')) {
                        let isError = error.response.status !== 404;

                        this.setMsg(data.error, isError);
                    }
                })
                .finally(() => {
                    this.initializing = false;
                });
        }
    },
    computed: {
        hasNextDay() {
            let currentIndex = this.days.findIndex(day => day === this.currentDay);

            return this.days.length - 1 !== currentIndex;
        },
        hasPreviousDay() {
            let currentIndex = this.days.findIndex(day => day === this.currentDay);

            return currentIndex > 0;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../../sass/abstract/colours.scss';

$scale: 1.45; // Search for this value in the template as well.

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  column-span: all;

  .spinner-border {
    color: $pastel-blue;
  }
}

.time-table {
    display: grid;
    grid-template-columns: 4.5rem repeat(5, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas: "whitespace monday tuesday wednesday thursday friday"
                         "times mondaySlot tuesdaySlot wednesdaySlot thursdaySlot fridaySlot";
    column-gap: 8px;
    row-gap: 2rem;
    position: relative;
    background-color: $white;
    padding: 0 20px;

    @media screen and (max-width: 1300px) {
        overflow-x: auto;
    }

    @media screen and (max-width: 426px) {
        grid-template-columns: 4.5rem auto;
        grid-template-areas: "day day"
                             "times slot";
    }
}

.day {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    position: relative;
    padding: 27px;
    font-weight: 600;
    color: $pastel-blue;

    &.current::after {
        content: '';
        display: block;
        height: 4px;
        width: 75%;
        background-color: $pastel-blue;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -100%);
    }

    &:not(.active),
    &:not(.active) + .slot {
        @media screen and (max-width: 426px) {
            display: none;
        }
    }

    &.active {
        @media screen and (max-width: 426px) {
            grid-area: day !important;
        }

        & + .slot {
            @media screen and (max-width: 426px) {
                grid-area: slot !important;
            }
        }
    }

    &-monday {
        grid-area: monday;

        &-slot {
            grid-area: mondaySlot;
        }
    }
    &-tuesday {
        grid-area: tuesday;

        &-slot {
            grid-area: tuesdaySlot;
        }
    }
    &-wednesday {
        grid-area: wednesday;

        &-slot {
            grid-area: wednesdaySlot;
        }
    }
    &-thursday {
        grid-area: thursday;

        &-slot {
            grid-area: thursdaySlot;
        }
    }
    &-friday {
        grid-area: friday;

        &-slot {
            grid-area: fridaySlot;
        }
    }
}

.slot {
    position: relative;

    .event {
        position: absolute;
        width: 100%;

        display: flex;
        align-items: center;
        text-align: left;
        padding: 0 0.5rem;

        font-size: 14px;
        color: $pastel-dark-grey;
        font-weight: 500;
        line-height: 1.2;
    }
}

.times
{
    display: grid;
    column-gap: 10px;
    row-gap: 0px;
    grid-row-start: 2;
    grid-area: times;

    .time {
        position: relative;
    }

    span {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
        font-size: 12px;
    }
}

.event {
    border: 1px solid transparent;
    border-radius: 3px;

    font-size: 1em;
}

.content-block {
    padding: 20px;
    max-width: 100%;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 3px 6px 0px #00000029;

    @media screen and (max-width: 1103px) {
        max-width: 100%;
    }

    &-wrapper {
        padding: 2rem 3rem;
        display: grid;
        grid-template-columns: calc(50% - 1.5rem) calc(50% - 1.5rem);
        column-gap: 3rem;
        row-gap: 1rem;

        @media screen and (max-width: 1300px) {
            grid-template-columns: 100%;

        }

        @media screen and (max-width: 426px) {
            padding: 2rem 1rem;
        }
    }

    h2 {
        color: $pastel-blue;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
        font-weight: 600;
    }

    p {
        line-height: 22px;
        margin: 0;
        font-weight: 500;
        font-size: 12px;
    }

    hr {
        margin: 18px 0;
        width: 100%;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;

        @media screen and (min-width: 600px) {
            grid-template-columns: auto auto;
        }
    }

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        padding: 0.25rem 0.5rem;

        .color-block {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            display: block;
            margin-right: 10px;
        }
    }
}

.header-bg {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: $white;
    margin: 0 -20px;
    box-shadow: 0px 1px 15px 0px #00000026;

    @media screen and (max-width: 426px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.line {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 2;
    background-color: $black;
    margin: 0 -0.5rem;

    @media screen and (max-width: 426px) {
        grid-column-start: 2;
        grid-column-end: 3;
    }
}

.line:nth-of-type(even) {
    max-width: 1rem;
}

@for $i from 2 through 24 {
    .line:nth-of-type(#{$i}) {
        margin-top: ($i * (30px * $scale)) - (30px * $scale);
    }
}

.previous-day,
.next-day {
    position: absolute;
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
    background-color: #A6B4CC;
    color: $pastel-blue;
    border-radius: 50%;

    @media screen and (min-width: 426px) {
        display: none;
    }
}

.previous-day {
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
}

.next-day {
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
}
</style>
