<template>
    <div class="guardian-loging-form-container mx-auto w-100">
        <h4 class="text-pastel-blue mb-2">Reset Password</h4>
        <div class='alert alert-danger' v-if='errorMessage' v-html="errorMessage"></div>
        <form @submit.prevent="submit" class="text-start" id="form" v-else>
            <p class="text-black-50 mb-4">Please ensure that your password is at least 8 characters in length and contains at least one number or symbol.</p>
            <div class="form-floating mb-3">
                <vi-password-input
                    fieldId="passwordInput"
                    label="Account Password"
                    v-model="form.password"
                    :errorMessage="passErrors('password')"
                ></vi-password-input>
            </div>
            <vi-password-strength-meter :password="form.password"></vi-password-strength-meter>
            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to reset your password right now. Please try again later."
            ></vi-error-flash>
            <div class="text-left w-100 my-4">
                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-sm': true,
                        'mx-auto': true
                    }"
                    buttonText="Next"
                    :loading="loading"
                ></vi-button-spinner>
            </div>
        </form>
    </div>
</template>

<script>
    import ViPasswordInput from './ViPasswordInput.vue';

    export default {
        name: "ResetPasswordForm",
        data: () => ({
            form: {
                password: null,
                token: null,
                email: null,
            },
            loading: false,
            unexpectedError: false,
            errors: null,
        }),
        props: ['passwordToken','email','postRoute','redirectRoute','errorMessage'],
        methods: {
            async submit() {
                this.loading = true;
                this.unexpectedError = false;

                try {
                    let response = await axios.post(this.postRoute, this.form);
                    if (response.status === 200){
                        location.href = this.redirectRoute;
                    }
                } catch (error) {
                    const data = error.response.data;
                    if (error.response.status === 422 && data.errors.email) {
                        this.unexpectedError = true;
                    } else if (error.response.status === 422) {
                        this.errors = data.errors
                    } else {
                        this.unexpectedError = true;
                    }
                    this.loading = false
                }
            },
            passErrors(field) {
                return this.errors && this.errors[field] && this.errors[field][0] ? this.errors[field][0] : '';
            },
        },
        mounted() {
            this.form.token = this.passwordToken
            this.form.email = this.email
        },
    };
</script>