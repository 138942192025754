<template>

    <div v-if="application">
        <article class="mb-3 position-relative" style="max-width: 540px;">
            <div class="card application-widget-card general-widget-card p-0 d-flex flex-column flex-md-row overflow-hidden">
                <div class="app-widget-left-col py-4 position-relative pe-2">

                    <div v-if="loading" class="loader-md loader d-block m-auto">
                        <span class="visually-hidden"> Loading...</span>
                    </div>

                    <div v-else class="card-body d-flex justify-content-center flex-column p-3 pe-4">
                        <div class="d-flex flex-row align-items-center">

                            <div>
                                <h4>{{ application.type }}</h4>
                                <p class="card-title"> {{ application.title }}</p>
                                <p v-html="application.blurb" class="card-text"></p>
                            </div>

                            <div v-if="application.canEdit" class="flex-fill align-self-end">
                                <button class="btn btn-delete-application p-0 position-absolute bottom-0 end-0" data-bs-toggle="modal" :data-bs-target="`#confirm-delete-${ application.id }`">
                                    <i class="bi bi-trash3-fill"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <div v-if="!loading" class="text-center app-widget-right-col py-3 px-1">

                    <a v-if="application.step" class="h-100 w-100 d-flex justify-content-center align-items-center text-uppercase" :href="application.step.destinationLink ? application.step.destinationLink : '#'">{{ application.step.name }}</a>

                    <span v-else class="look-like-link h-100 w-100 d-flex justify-content-center align-items-center text-uppercase">
                        Error. Please contact us for help.
                    </span>

                </div>

            </div>
            <a v-if="application.isEligibleForScholarship" class="continue-with-application" :href="application.scholarshipLink">Complete Scholarship Application</a>
        </article>


        <div v-if="application" :id="`confirm-delete-${application.id}`" class="modal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Application</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you wish to delete this application?</p>
                    </div>
                    <div class="modal-footer">
                        <form :action="application.deleteRoute" method="POST">
                            <input class="btn btn-danger" type="submit" value="Delete" />
                        </form>

                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "DashboardActionCard",

        data: () => ({
            loading: false,
            application: {},
            errorMessage: null,
        }),

        props: {
            fetchUrl: {
                type: String,
                required: true,
            }
        },
        async mounted() {
            await this.fetchApplication();
        },
        methods: {
            async delete() {
                this.loading = true;

                try {
                    var response = await axios.post(this.application.deleteRoute);

                } catch(error) {
                    this.errorMessage = "We had an unexpected error d. Please try refreshing deleting this application.";
                    console.error(error);
                    this.loading = false
                    return;
                }

                this.loading = false;
            },
            async fetchApplication() {
                this.loading = true;

                try {
                    var response = await axios.get(this.fetchUrl);

                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('data')) {
                        this.application = response.data.data;
                    }

                } catch(error) {
                    this.errorMessage = "We had an unexpected error loading this application. Please try refreshing the page or contact us for support.";
                    console.error(error);
                    this.loading = false
                    return;
                }

                this.loading = false;
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import 'resources/sass/abstract/colours.scss';

    .continue-with-application {
        height: 50px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        bottom: -20px;
        position: absolute;
        width: 100%;
        text-align: center;
        background-color: $green4F;
        color: $white;
        font-weight: bold;
        text-decoration: none;
        padding: 14px 0;
    }
</style>
