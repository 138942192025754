<template>
    <div :class="['alert', type]" role="alert">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ViAlert",
    props: {
        type: {
            type: String,
            default: 'vi-alert'
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.vi-alert {
    background-color: $primary-40;
    border: 1px solid $primary;
}

.vi-warn {
    background-color: darkseagreen;
    border: 1px solid $medium-grey;
}

.vi-error {
    background-color: $error3;
    border: 1px solid $error2;
    color: $black;
}
</style>
