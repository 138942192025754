<template>
    <form @submit.prevent="onSubmitHandler">
        <label class="form-label text-white">Dialing Code</label>
        <div class="rounded-pill w-100 d-flex mb-3 dial-code-outer tool-tip" :class="inputClass" id="mobileToolTip" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Click edit below to edit this">
            <select
                class="form-select dial-codes-select"
                type="text"
                :disabled="isDisabled"
                v-model="mobileNumberCountry"
            >
            <option :value="null" selected disabled>Please select</option>
            <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
            </select>
        </div>
        <label class="form-label text-white">Mobile Number</label>
        <div class="rounded-pill border w-100 d-flex" :class="inputClass">
            <input
                class="form-control"
                type="number"
                :disabled="isDisabled"
                v-model="mobileNumber"
                id="numberInput"
                :required="required"
            />
            <button type="submit" @click="onButtonClickHandler" class="btn edit-btn px-3 text-white">
                <span v-if="!loading">{{ isDisabled ? 'Edit' : 'Save' }}</span>
                <div v-if="loading" class="spinner-border text-light spinner" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
        </div>
        <div class="invalid-feedback d-block" v-html="error"></div>
    </form>
</template>

<script>
  export default {
    name: 'PhoneNumberInput',
    data() {
        return {
            isDisabled: true,
            mobileNumberCountry: this.initDialCodeValue ? this.initDialCodeValue : "ZA",
            mobileNumber: this.initMobileNumberValue,
            errors: {},
        };
    },
    props: {
        error: {
            type: String,
            required: false,
            default: null
        },
        initDialCodeValue: {
            type: String,
            required: false,
            default: null,
        },
        initMobileNumberValue: {
            type: String,
            required: false,
            default: null,
        },
        inputClass: {
            type: String,
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false
        },
        required: {
            type: String,
            default: false,
        }
    },
    methods: {
        toggleDisable() {
            this.isDisabled = !this.isDisabled;
            this.$emit('update-is-disabled', this.isDisabled);
        },

        onSubmitHandler() {
            this.$emit('on-submit-handler', {mobileCode: this.mobileNumberCountry, mobileNumber: this.mobileNumber, loading: this.loading});
            window.unsaved = false;
        },

        onButtonClickHandler(event) {
            if (this.isDisabled) {
                event.preventDefault();
                event.stopPropagation();
                this.toggleDisable();
            };
            if(!this.isDisabled) {
                window.unsaved = true;
                function unloadPage(event) {
                    if (window.unsaved) {
                        event.preventDefault();
                        return event.returnValue = "Are you sure you want to exit?";
                    }
                }
                window.onbeforeunload = unloadPage;
            } else {
                window.unsaved = false;
            };

            // toggle tooltip
            $('#mobileToolTip').tooltip('toggleEnabled')
        },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    @import 'resources/sass/abstract/colours.scss';
    .edit-icon {
        font-size: 1.125rem;
    }

    input, input:focus {
        outline: none;
        border: none;
        box-shadow: none;
        color: $uctDarkBlue;
        border: none;
        background-color: $white;
        border-radius: 0;
        padding-left: 10px;
        transition: 0.3s ease;
        border-radius: 50rem 0 0 50rem;

        &::placeholder {
            color: $white;
        }

        &:disabled, &[readonly] {
            outline: none;
            border: none;
            box-shadow: none;
            color: $white;
            background-color: transparent;
            border-radius: 0;
            transition: 0.3s ease;
        }
    }

    .edit-btn {
        border: none;
        border-radius: 0;
        border-left: 1px solid $white;
        outline: none;
        box-shadow: none;
        background-color: $pastel-light-blue;
        border-radius: 0 50rem 50rem 0;
        min-width: 80px;
    }

    .btn-white {
        background-color: $white;
        color: $pastel-blue;
        padding: 10px 40px;
        margin-top: 30px;
    }

    .spinner {
      width: 20px;
      height: 20px;
    }

    .dial-codes-select {
        border-radius: 50rem;

        &:focus {
            box-shadow: none;
        }
    }

    .account-input-container-light + .invalid-feedback {
        color: $white;
    }


    .tool-tip [disabled] {
        pointer-events: none;
    }

</style>