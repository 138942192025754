<template>
    <div>
        <label class="mb-2 d-flex">
            Upload a photo of yourself here:
            <div class="d-flex">
                <img class="ms-3 visible-notice-image" :src="'/portal-data/uct/visible.svg'" alt="Eye Icon" width="25">
                <span class="visible-notice visible-notice-left p-2 rounded shadow border">This field will be visible to others on your Online Campus profile.</span>
            </div>
        </label>

        <!-- slot for parent component to activate the file changer -->
        <slot name="activator"/>

        <div id="cropperToolbar" class="btn-group w-100">
            <div id="btnUpload" class="btn btn-secondary w-50" @click="launchFilePicker()">Change Photo</div>
            <div id="btnSave" class="btn btn-primary ml-2 w-50 hidden" :disabled="canSavePhoto" @click="crop()">Save Photo</div>
        </div>

        <cropper class="cropper hidden" ref="cropper" id="cropper"
                 @change="onChange"
                 image-restriction="stencil"
                 :src="image.src"
                 :stencil-props="{ aspectRatio: 1, resizable: false, movable: false }"
                 :stencil-size="{ width: 300, height: 300 }"
                 :canvas="{ height: 300, width: 300 }"
                 style="height: 300px; background: #fff; margin-bottom: 1em;"/>

        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input type="file" ref="file" :name="uploadFieldName" accept="image/png, image/jpeg, image/jpg" capture="camera"
               @change="loadImage($event)"
               style="display:none">

        <!-- error dialog displays any potential error messages -->
        <v-dialog class="d-block" v-model="errorDialog" max-width="300">
            <v-card class="p-3 text-center">
                <p class="mb-3">{{ errorText }}</p>
                <v-card-actions class="flex-column justify-content-center p-0">
                    <button class="btn-outline-uct py-1 px-3 rounded" @click="errorDialog = false" flat>Got it!</button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {Cropper} from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';

    export default {
        name: "ImageUploader",
        data: () => ({
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            image: '',
        }),
        props: {
            // Use "value" to enable using v-model
            value: Object,
        },
        components: {
            Cropper
        },
        computed: {
            canSavePhoto: function () {
                return this.image.length === 0;
            }
        },
        methods: {
            launchFilePicker() {
                this.$refs.file.click();
            },
            onChange({coordinates, canvas}) {
                this.coordinates = coordinates;
            },
            handleFileLoad(event) {
                this.img = Buffer.from(event.target.result).toString('base64');
            },
            getMimeType(file, fallback = null) {
                const byteArray = (new Uint8Array(file)).subarray(0, 4);
                let header = '';
                for (let i = 0; i < byteArray.length; i++) {
                    header += byteArray[i].toString(16);
                }
                switch (header) {
                    case "89504e47":
                        return "image/png";
                    case "47494638":
                        return "image/gif";
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                        return "image/jpeg";
                    default:
                        return fallback;
                }
            },
            crop() {
                const { coordinates, canvas } = this.$refs.cropper.getResult();
                this.coordinates = coordinates;

                if (!canvas) {
                    return;
                }

                const form = new FormData();
                canvas.toBlob((blob) => {
                    // Emit the FormData and image URL to the parent component
                    form.append('file', blob);
                    const imageURL = URL.createObjectURL(blob);
                    this.$emit('input', {form, imageURL});

                    this.$emit('uploaded', blob);

                }, this.image.type);

                // lastly hide the cropper + toolbar until the user chooses to change their pic again
                document.getElementById("btnSave").classList.add('hidden');
                document.getElementById("cropper").classList.add('hidden');
                document.getElementById("cropperToolbar").classList.add('hidden');
            },
            reset() {
                this.image = {
                    src: null,
                    type: null
                }
            },
            loadImage(event) {
                // Reference to the DOM input element
                const {files} = event.target;
                // Ensure that you have a file before attempting to read it
                if (files && files[0]) {
                    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                    if (this.image.src) {
                        URL.revokeObjectURL(this.image.src)
                    }
                    // 2. Create the blob link to the file to optimize performance:
                    const blob = URL.createObjectURL(files[0]);

                    // Create a new FileReader to read this image binary data
                    const reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                        this.image = {
                            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                            src: blob,
                            // Determine the image type to preserve it during the extracting the image from canvas:
                            type: this.getMimeType(e.target.result, files[0].type),
                        };
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsArrayBuffer(files[0]);
                }

                // Now show the image
                document.getElementById("btnSave").classList.remove('hidden');
                document.getElementById("cropper").classList.remove('hidden');
                document.getElementById("cropperToolbar").classList.remove('hidden');
            },
            destroyed() {
                // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src)
                }
            }
        }
    };
</script>
