<template>
    <div>
        <form @submit.prevent="validateAndConfirm" method="POST" class="text-start">
            <template v-if="!isProgressionApplication">
                <p class="text-center">Based on the previous information you have given us, you are selecting subjects for the following qualification and year of study.</p>
                <input type="text" class="form-control mb-3" disabled :value="application.is_caps_fet_phase ? 'Further Education & Training (FET) Phase' : 'Senior Phase'"/>
                <input  type="text" class="form-control mb-3" disabled :value="application.grade_of_entry"/>
            </template>

            <template v-if="application.is_caps_fet_phase || isProgressionApplication">
                <p v-if="isFinalSubjectSelection">Select the subjects you <strong>want to take</strong> when you join UCT Online High School. The following subjects are pre-selected with no alternatives.</p>

                <template v-if="!isFinalSubjectSelection && !((isGrade(application, 10) || isGrade(application, 11)) && electives)">
                    <subject-selection-notice
                        :grade-of-entry="application.grade_of_entry"
                    ></subject-selection-notice>

                    <p class="font-weight-bold">Compulsory Subject Selection:</p>

                    <p class="mb-3">The following two subjects are mandatory (compulsory):</p>
                </template>

                <template v-else-if="(isGrade(application, 10) || isGrade(application, 11)) && electives">
                    <p class="mb-3 fw-bold">A note on subject groupings: </p>

                    <p class="mb-3">For our NSC offering (for which your learner is currently enrolled), from Grade 10 learners need to take a minimum of 7 subjects. Learners need to take 4 compulsory subjects (as outlined below) and may choose an additional 3 subjects. Please select your learner’s elective subjects based on the instructions below. These restrictions on certain subject combinations have been carefully chosen to ensure learners take a combination of electives that meet popular university entrance requirements.</p>

                    <p class="mb-3">The following two subjects are mandatory (compulsory):</p>
                </template>

                <p v-else class="mb-3">The following two subjects are mandatory (compulsory):</p>
            </template>

            <template v-if="application.is_caps_senior_phase && !isFinalSubjectSelection">
                <subject-selection-notice
                    :grade-of-entry="application.grade_of_entry"
                ></subject-selection-notice>
            </template>

            <template v-if="application.is_caps_senior_phase && !!isProgressionApplication">
                <p class="mb-1 text-center">Based on your grade, you will be enrolled into the following subjects when you join the UCT Online High School SA National Senior Certificate (CAPS). These subjects are compulsory.</p>
            </template>

            <ul class="list-unstyled px-3">
                <li v-for="(subjectName, subjectCode) in preSelectedSubjects" :key="`pre-selected-${subjectCode}`" class="fw-bold">
                    <i class="bi bi-check-circle"></i> {{subjectName}}
                </li>
            </ul>

            <div v-if="isGrade(application, 12) && requiresSubjectChangeValidation" class="mb-3">
                Does the learner currently take Life Orientation? Select 'No' if the learner currently takes Life Skills and not Life Orientation.

                <br>

                <label>
                    <input type="radio" required :name="`life-orientation-change`" :id="`life-orientation-yes`" :value="true" v-model="isTakingLifeOrientation">
                    Yes
                </label>

                <label>
                    <input type="radio" required :name="`life-orientation-change`" :id="`life-orientation-no`" :value="false" v-model="isTakingLifeOrientation">
                    No
                </label>
            </div>

            <template v-if="!(isGrade(application, 12) && requiresSubjectChangeValidation && (isTakingLifeOrientation == null || isTakingLifeOrientation === false))">
                <p v-if="application.is_caps_fet_phase || isProgressionApplication" class="my-3">Select one subject from each group below. Please note that two subjects cannot be selected from the same group.</p>

                <p v-if="!(isGrade(application, 12) && requiresSubjectChangeValidation && isTakingLifeOrientation == null) && application.is_caps_senior_phase" class="my-3">Please select a provisional First Additional Language subject below. There will still be an opportunity to review and finalise the selection of this subject during the Onboarding phase.</p>

                <div class="form-floating mb-3 mt-5 mt-sm-0">
                    <select class="form-select new-input" name="additional_language" v-model="selectedLanguage">
                        <option value="null" selected disabled>Please select</option>
                        <option v-for="(subjectName, subjectCode) in languageSubjects" :value="subjectCode" :key="`langage-${subjectCode}`">{{subjectName}}</option>
                    </select>

                    <label class="long-label-select" for="additional_language">Please select a First Additional Language (FAL)</label>
                    <span v-if="showErrors && !selectedLanguage" class="text-danger invalid-feedback d-block">You must select a First Additional Language</span>
                </div>

                <div v-if="requiresSubjectChangeValidation && selectedLanguage && !(isGrade(application, 8) || isGrade(application, 9))" class="mb-3">
                    Is the learner currently taking {{ languageSubjects[selectedLanguage] }}?

                    <br>

                    <label>
                        <input type="radio" required :name="`fal-change`" :id="`fal-yes`" :value="false" v-model="changingFalSubject">
                        Yes
                    </label>

                    <label>
                        <input type="radio" required :name="`fal-change`" :id="`fal-no`" :value="true" v-model="changingFalSubject">
                        No
                    </label>
                </div>

                <div v-else-if="application.is_caps_fet_phase && !isProgressionApplication" class="mb-3">
                    Please select the desired First Additional Language (FAL).
                </div>

                <div v-if="application.is_caps_fet_phase || isProgressionApplication">
                    <div class="form-floating mb-3">
                        <select
                            v-model="selectedMathematics"
                            name="mathematics_subject"
                            class="form-select new-input"
                        >
                            <option value="null" selected disabled>Please select</option>

                            <template
                                v-for="(subjectName, subjectCode) in mathematicsSubjects"
                                >
                                <option
                                    :value="subjectCode"
                                    :disabled="disabledSubjects.hasOwnProperty(subjectCode)"
                                    :key="`mathematics-${subjectCode}`"
                                    v-if="isSubjectAllowed(subjectName)"
                                >{{ subjectName }}</option>
                            </template>
                        </select>

                        <label for="mathematics_subject">Please select a Mathematics Subject</label>

                        <span v-if="showErrors && !selectedMathematics" class="text-danger invalid-feedback d-block">You must select a Mathematics Subject</span>

                    </div>

                    <div v-if="requiresSubjectChangeValidation && selectedMathematics" class="mb-3">
                        Is the learner currently taking {{ mathematicsSubjects[selectedMathematics] }}?

                        <br>

                        <label>
                            <input type="radio" required :name="`math-change`" :id="`math-yes`" :value="false" v-model="changingMathSubject">
                            Yes
                        </label>

                        <label>
                            <input type="radio" required :name="`math-change`" :id="`math-no`" :value="true" v-model="changingMathSubject">
                            No
                        </label>
                    </div>

                    <div v-else-if="!isProgressionApplication" class="mb-3">
                        Please select the desired Mathematics Subject.
                    </div>

                    <div v-if="selectedMathematics">
                        <template v-if="electives">
                            <p class="fw-bold mb-3">Subject Grouping Selection (Electives):</p>

                            <p>When selecting your electives, please note that learners cannot take the following subject combinations together:</p>

                            <ol class="mr-3 mb-3">
                                <li>Geography & History</li>
                                <li>Business Studies & Economics</li>
                                <li>Learners can only take one of the following: CAT, Visual Arts or Information Technology</li>
                            </ol>

                            <p v-if="isGrade(application, 11)">Please note that Accounting and Physical Sciences cannot be taken for the first time in Grade 11. </p>

                            <p>Please note that learners must take at least one of the following subjects:</p>

                            <ul>
                                <li>Physical Sciences</li>
                                <li>Life Sciences</li>
                                <li>Geography</li>
                                <li>History</li>
                            </ul>

                            <p ref="electivesHeading" class="fw-bold mb-3">Please select your elective subjects:</p>

                            <vi-subject-selection
                                class="px-3 mb-3"
                                :subject-selection="electives"
                                :grade-of-entry="application.grade_of_entry"
                                @subjects-selected="selectedExtras = $event"
                                :subject-change-validation-required="requiresSubjectChangeValidation"
                                @subject-change="onSubjectChange"
                            ></vi-subject-selection>

                            <span v-if="electivesError" class="text-danger invalid-feedback d-block mb-3">{{ electivesError }}</span>

                            <div v-if="allSelectedSubjects.length > 7" class="alert alert-danger">
                                <p  class="fw-bold mb-3 text-center">You have selected additional subject(s)</p>

                                 <div class="acknowledge-extra-field">
                                    <input type="checkbox" name="acknowledgeAdditionalSubjectsMessage" required id="acknowledgeAdditionalSubjects">
                                    <label for="acknowledgeAdditionalSubjects" class="inherit-color">I acknowledge and understand that additional subjects are taken over and above the required minimum. This selection will incur additional fees, and increase workload, time management demands, and academic pressure.</label>
                                 </div>
                            </div>
                        </template>

                        <template v-else>
                            <p class="my-3">Please select a minimum of 3 of the following elective subjects. You may select more than 1 subject from each category. Please note that any subjects taken additionally to the minimum of 7 will affect your school fees. For more information, please refer to the fees handbook.</p>

                            <div v-for="(subjects, groupName, index) in extraSubjects" :key="`extras-${index}`">
                                <p class="text-dark fw-bold mb-1">{{groupName}}:</p>

                                <ul class="list-unstyled">
                                    <li v-for="(subjectName, subjectCode) in subjects" :key="`extra-${subjectCode}`">
                                        <div v-if="isAllowed(subjectCode)" class="form-check">
                                            <input type="checkbox" class="form-check-input" :id="`select-subject-${subjectCode}`" :value="subjectCode" v-model="selectedExtras">
                                            <label class="form-check-label" :for="`select-subject-${subjectCode}`">
                                                {{ subjectName }}
                                            </label>

                                            <div v-if="selectedExtras.includes(subjectCode)" class="subject-change-wrapper">
                                                Does the learner currently take this subject?

                                                <label>
                                                    <input type="radio" required :name="`${subjectCode}-change`" :id="`${subjectCode}-yes`" :value="false" @change="() => onSubjectChange({change: false, code: subjectCode})">
                                                    Yes
                                                </label>

                                                <label>
                                                    <input type="radio" required :name="`${subjectCode}-change`" :id="`${subjectCode}-no`" :value="true" @change="() => onSubjectChange({change: true, code: subjectCode})">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>

                        <span v-if="showErrors && selectedExtras.length < 3 && !electivesError" class="text-danger">Please choose a minimum of 3 elective subjects</span>
                    </div>
                </div>
            </template>

            <div class="changing-subjects-wrapper">
                <template v-if="(isGrade(application, 8) || isGrade(application, 9)) && requiresSubjectChangeValidation">
                    <p class="fw-bold">Subject Changes</p>

                    <p>In your current school, do you currently take all the subjects you have selected above?</p>

                    <label class="d-block">
                        <input type="radio" required :name="`grade-8-9-change`" :id="`grade-8-9-yes`" :value="false" v-model="isCurrentlyTakingAllSubjects">
                        Yes
                    </label>

                    <label class="d-block m-0">
                        <input type="radio" required :name="`grade-8-9-change`" :id="`grade-8-9-no`" :value="true" v-model="isCurrentlyTakingAllSubjects">
                        No
                    </label>
                </template>

                <!-- Non-compliant -->
                <template v-if="complianceStatus === NON_COMPLIANT">
                    <p class="text-danger">
                        Unfortunately, as per our examination management requirements, based on the differences between your current school subjects and those selected for UCT Online High School, you will not be eligible to join the school based on these subject selections.
                    </p>

                    <p class="text-danger">
                        Please read our <a class="text-danger" href="https://www.uctonlinehighschool.com/policies" target="_blank">Subject Selection Policy</a> for more information, or if you need more guidance on subject selection, please reach out to one of our Admissions Counsellors.
                    </p>

                    <p class="text-danger">
                        You will need to amend the subjects you have selected for UCT Online High School in order to proceed with your application.
                    </p>
                </template>

                <template v-if="complianceStatus === CHANGING_SUBJECTS">
                    <template v-if="isFinalSubjectSelection">
                        <p class="font-weight-bold">Subject Changes</p>

                        <p>You have indicated that you do not currently take all the subjects you have selected above. Therefore, please follow the steps below to submit a subject change request.</p>

                        <p>Two subject changes are permitted for Grade 11, and in exceptional circumstances, 1 subject change is permitted for Grade 12. If the subjects you have chosen above exceed the number of subject changes permitted, you will need to amend your selections.</p>

                        <p>Our exam body therefore requires to you download and submit a <a :href="subjectChangeDownload" target="_blank">Subject Change Form</a>. <a :href="subjectChangeDownload" target="_blank">Please click</a> here to download the document, complete it and upload it to the box below. After you have done that, you can proceed with your application and your subject changes will be processed by our SRO team.</p>

                        <vi-file-selector
                            upload-field-name="changingSubjectDocument"
                            :handle-upload="handleSubjectChangeFormUpload"
                            :max-file-size="3000000"
                            accepted-file-types="image/jpeg, image/png, .pdf"
                        ></vi-file-selector>
                    </template>

                    <template v-else>
                        <p class="font-weight-bold">Subject Changes</p>

                        <p>Based on your selections, we've identified that in order to be eligible to enrol with us with these subject selections, you will be required to complete a subject change. You will only be required to do this at a later stage in the process.</p>

                        <label>
                            <input type="checkbox" required name="requiresSubjectChange" id="requiresSubjectChange" v-model="requiresSubjectChangeAcknowledgement">

                            I acknowledge that I will be required to complete a subject change form before my learner joins UCT Online High School.
                        </label>
                    </template>
                </template>
            </div>

            <p v-if="isCurrentlyTakingAllSubjects" class="mt-3 text-danger">
                Unfortunately you need to select the subjects you are currently taking in order to be eligible to join us in term 3.
            </p>

            <div class="hidden" style="display: none" ref="errorMessageTarget"></div>

            <vi-error-flash
                v-if="unexpectedError && !errorMessage"
                errorMessage="We are unable to save your subject selection right now. Please try again later."
            ></vi-error-flash>

            <vi-error-flash
                v-if="unexpectedError && errorMessage"
                :errorMessage="errorMessage"
            ></vi-error-flash>

            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100 mt-5">
                <a :href="backButtonUrl" class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-sm': true,
                        'mt-2': true,
                    }"
                    buttonText="Confirm"
                    :loading="loading"
                    :disabled="complianceStatus === NON_COMPLIANT || (requiresSubjectChangeValidation && subjectChangeLogged) || isCurrentlyTakingAllSubjects"
                ></vi-button-spinner>
            </div>

        </form>

        <div class="modal fade" id="confirmSubjectsModal" tabindex="-1" aria-labelledby="confirmSubjectsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">

                    <div v-if="isFinalSubjectSelection" class="modal-body text-center p-4">
                        <p>Thank you for making your subject selections. Please confirm your selection of the following subjects:</p>

                        <ul class="list-unstyled">
                            <li v-for="(subjectName, index) in allSelectedSubjects" :key="`selected-confirm-${index}`">
                                {{subjectName}}
                            </li>
                        </ul>

                        <p>Please confirm you would like to proceed with the subject confirmations selected. A member of Student Records will review these selections and be in touch if anything needs to be discussed.</p>
                    </div>

                    <div v-else class="modal-body text-center p-4">
                        <p>Thank you for making your subject selections. Please confirm your selection of the following subjects:</p>

                        <ul class="list-unstyled">
                            <li v-for="(subjectName, index) in allSelectedSubjects" :key="`selected-confirm-${index}`">
                                {{subjectName}}
                            </li>
                        </ul>

                        <template v-if="isProgressionApplication">
                            <p class="mb-2">If you would like to revisit your subject selection, click 'Go back to subject selection. If you are happy with your subject selection, click 'Confirm subject selection.'</p>

                            <p class="mb-0">Please note that your enrolment into these subjects is dependent on meeting the grade 9 promotional requirements. For more information, please refer to the <a href="https://www.uctonlinehighschool.com/policies" target="_blank">Academic Handbook</a></p>
                        </template>

                        <template v-else>
                            <p v-if="complianceStatus === CHANGING_SUBJECTS" class="fw-bold">Important Note:</p>

                            <p v-if="complianceStatus === CHANGING_SUBJECTS" class="fw-bold">Based on the differences between your current school subjects and those selected for UCT Online High School, you will be required to log a subject change request with our accreditation body, SACAI. Given that in line with our examination management requirements not all subject changes are approved, by proceeding you acknowledge that you may need to amend your subject selection as part of the admissions process before being eligible to join UCT Online High School.</p>

                            <p class="fw-bold text-dark my-3">What happens next?</p>

                            <p>After you click 'Confirm Subject Selection', your application will be reviewed based on the information you have given us. You will then receive an email within 72 hours about the next steps.</p>

                            <p class="mb-0">If you would like to revisit your subject selection, click 'Go back to subject selection'. If you are happy with your subject selection, click 'Confirm subject selection'.</p>
                        </template>
                    </div>

                    <div class="modal-footer p-3 mb-2 justify-content-center">
                        <button type="button" class="btn-outline-uct rounded-pill px-5 py-2 text-decoration-none mt-2 me-sm-2 me-0 ms-0" data-bs-dismiss="modal">Go back to subject selection</button>

                        <button type="button" class="btn-outline-uct-dark rounded-pill px-5 py-2 text-decoration-none mt-2 mx-0" @click="submit" :disabled="loading">Confirm subject selection</button>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import error_handling from '../mixins/error_handling';
import helperMixin from "../mixins/helper_mixin";
import scroll_to from '../mixins/scroll_to';
import ChangingSubjectRulesService, { COMPLIANT, NON_COMPLIANT, CHANGING_SUBJECTS } from "../services/ChangingSubjectRulesService";
import ViErrorFlash from './ViErrorFlash.vue';

export default {
  components: { ViErrorFlash },
    name: 'GuardianLearnerAppCapsSubjectSelection',
    props: {
        submitUrl: {
            type: String,
            default: ''
        },
        grade12SubjectChangeDownload: {
            type: String,
            default: ''
        },
        gradeSubjectChangeDownload: {
            type: String,
            default: ''
        },
        backButtonUrl: {
            type: String,
            default: ''
        },
        userId: {
            type: Number,
        },
        subjectSelectionType: {
            type: String,
            default: ''
        },
        application: {
            type: Object,
            default : () => ({})
        },
        preSelectedSubjects: {
            type: Object,
            default : () => ({})
        },
        languageSubjects : {
            type: Object,
            default : () => ({})
        },
        mathematicsSubjects : {
            type: Object | Array,
            default : () => ({})
        },
        extraSubjects : {
            type: Object | Array,
            default : () => ({})
        },
        uploadUrl: {
            type: String,
            default: '',
        },
        isProgressionApplication : {
            type: Boolean,
            default: false
        },
        electives: {
            type: Object,
            default: null,
        },
        disabledSubjects: {
            type: Object,
            default: () => ({})
        },
        applicationStartDate: {
            type: Object,
            default: null,
        },
        subjectChangeUrl: {
            type: String,
            required: false,
        },
        subjectChangeUploadUrl: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        selectedLanguage: null,
        selectedMathematics: null,
        selectedExtras: [],
        showErrors : false,
        loading: false,
        unexpectedError: false,
        isDisabled: true,
        currentlyTakeAllSubjects: true,
        isFileUploaded: false,
        errorMessage : null,
        showMisingFileUpload:  false,

        electivesError: null,

        // Service Instances
        changingSubjectRulesService: null,

        // Changing Subjects Compliance
        requiresSubjectChangeValidation: false,
        subjectChanges: [],
        changingMathSubject: null,
        changingFalSubject: null,
        isTakingLifeOrientation: null,
        complianceStatus: COMPLIANT,
        requiresSubjectChangeAcknowledgement: false,
        uploadedFormContentDocumentId: null,
        subjectChangeLogged: false,
        isCurrentlyTakingAllSubjects: null,

        // Constants
        COMPLIANT: COMPLIANT,
        NON_COMPLIANT: NON_COMPLIANT,
        CHANGING_SUBJECTS: CHANGING_SUBJECTS,
    }),
    mixins: [
        helperMixin,
        scroll_to,
        error_handling,
    ],
    mounted() {
        this.initServices();

        // Only require validation if the application has a start date in portal.
        if (this.applicationStartDate) {
            this.requiresSubjectChangeValidation = this.changingSubjectRulesService
                .requiresValidation(this.application.grade_of_entry, this.applicationStartDate.term);
        } else {
            this.requiresSubjectChangeValidation = false;
        }

        this.selectedLanguage = this.application.additional_language ?? null;
        this.selectedMathematics = this.application.mathematics_subject ?? null;
        this.selectedExtras = this.application.additional_subjects ?? [];
    },
    computed: {
        isFinalSubjectSelection() {
            return this.subjectSelectionType == 'final';
        },

        subjectChangeDownload() {
            if (this.application.grade_of_entry === "Grade 12") {
                return this.grade12SubjectChangeDownload;
            }

            return this.gradeSubjectChangeDownload;
        },

        /**
         * Check if form is valid
         *
         * @return {boolean}
         */
        formIsValid() {
            if (this.application.is_caps_fet_phase || this.isProgressionApplication) {
                if (this.selectedMathematics == 'MAL') {
                    this.selectedExtras = this.selectedExtras.filter(subjectCode => subjectCode != 'PHS');
                }

                // Validate electives
                if (this.electives) {
                    let hasValidElectives = this.validateElectives(this.electives, this.selectedExtras);

                    if (!hasValidElectives) {
                        return false;
                    }
                }

                return this.selectedLanguage != null &&
                        this.selectedMathematics != null &&
                        this.selectedExtras.length >= this.minElectives;
            }

            return this.selectedLanguage != null;
        },

        /**
         * Get extra subjects without the subject group
         *
         * @return {object}
         */
        combinedExtraSubjects() {
            let subjects = {};
            if (this.extraSubjects) {
                for(const [key, value] of Object.entries(this.extraSubjects)) {
                    subjects = {
                        ...subjects,
                        ...value
                    }
                }
            }

            return subjects;
        },

        /**
         * Get all subjects
         *
         * @return {object}
         */
        allSubjects() {
            return {
                ...this.preSelectedSubjects,
                ...this.languageSubjects,
                ...(this.mathematicsSubjects ?? {}),
                ...this.combinedExtraSubjects
            };
        },

        /**
         * Get all selected subjects
         *
         * @return {string[]}
         */
        allSelectedSubjects() {
            let allSubjectKeys = new Set([
                ...Object.keys(this.preSelectedSubjects),
                ...[this.selectedLanguage],
                ...[this.selectedMathematics],
                ...this.selectedExtras
            ]);

            let allSelectedSubjects = Object.keys(this.allSubjects).filter(key => allSubjectKeys.has(key) ).map(key => this.allSubjects[key]);

            return allSelectedSubjects;
        },

        minElectives() {
            if (this.electives) {
                return this.electives.min_subjects_total;
            }

            return 3;
        },
        mathWatchable() {
            return {
                subject: this.selectedMathematics,
                change: this.changingMathSubject,
            };
        },
        falWatchable() {
            return {
                subject: this.selectedLanguage,
                change: this.changingFalSubject,
            };
        }
    },
    methods: {
        initServices() {
            this.changingSubjectRulesService = new ChangingSubjectRulesService();
        },

        /**
         * Check if subject is allowed for Caps Fet phase
         *
         * @param {string} subjectCode
         * @return {boolean}
         */
        isAllowed(subjectCode) {
            return this.selectedMathematics == 'MAT' || subjectCode != 'PHS';
        },

        /**
         * Validate form and show confirmation modal
         *
         * @return {void}
         */
        async validateAndConfirm() {
            if (!this.formIsValid) {
                this.showErrors = true;

                this.$nextTick(() => {
                    let firstError = this.firstError();

                    if (this.electivesError && this.selectedMathematics) {
                        this.scrollIntoView(this.$refs.electivesHeading);

                        return;
                    }

                    if (firstError) {
                        this.scrollIntoView(firstError);
                    }
                });

                return;
            }

            if (this.complianceStatus === CHANGING_SUBJECTS) {
                try {
                    await this.submitSubjectChangeRequest();
                } catch (error) {
                    this.unexpectedError = true;
                    this.errorMessage = 'Please fill in the required information under Subject Changes.';

                    this.scrollIntoView(this.$ref.errorMessageTarget);

                    return;
                }
            }

            var confirmSubjectsModal = new bootstrap.Modal(document.getElementById('confirmSubjectsModal'), {
                keyboard: false
            });

            confirmSubjectsModal.show();
        },

        /**
         * Submit subject selection form
         *
         * @return {void}
         */
        async submit() {
            this.loading = true;
            this.unexpectedError = false;

            let subjectData = {
                id : this.application.id,
                user_id: this.userId,
                subject_selection_type: this.subjectSelectionType,
                subjects: Object.keys(this.preSelectedSubjects),
                additional_language: this.selectedLanguage,
                mathematics_subject: this.selectedMathematics,
                additional_subjects: this.selectedExtras,
                currently_take_all_subjects: this.currentlyTakeAllSubjects,
            };

            if (this.isProgressionApplication) {
                subjectData.student_id = this.application.hed__Applicant__c
            }

            try {
                var response = await axios.post(this.submitUrl, subjectData);

                if (response.status == 200) {
                    if (response.data) {
                        window.unsaved = false;
                        if (!this.isProgressionApplication) {
                            this.triggerGa();
                        }
                        this.setCookie('success-alert', true);
                        window.location.href = response.data;
                    }
                } else {
                    // alert(response.data);
                    this.$nextTick(() => {
                        let firstError = this.firstError();

                        if (firstError) {
                            this.scrollIntoView(firstError.parentNode);
                        }
                    });
                }
                this.loading = true

            } catch(error) {
                this.unexpectedError = true;
                this.loading = true
            }
        },
        triggerGa() {
            window.dataLayer = window.dataLayer || [];
            let gradeParts = this.application.grade_of_entry.split(' ');

            window.dataLayer.push({
                'event': 'preliminarySubjectSelectionCAPS',
                'grade': gradeParts.pop(),
                'userid': window.valenture.userId,
            });
        },
        setSubjectSelectable(code, selectable) {
            if (!this.electives) {
                return;
            }

            this.electives.subject_groups.forEach(group => {
                group.subjects.forEach(subject => {
                    if (subject.code === code) {
                        subject.is_selectable = selectable;

                        if (!selectable) {
                            subject.selected = false;
                        }
                    }
                });
            });
        },
        isGrade(application, grade) {
            if (!/^grade/i.test(application.grade_of_entry)) {
                return false;
            }

            let gradeNumber = application.grade_of_entry.match(/\d+$/gm);

            if (!gradeNumber) {
                return false;
            }

            try {
                gradeNumber = gradeNumber.shift();

                return parseInt(gradeNumber) === grade;
            } catch (error) {
                return false;
            }
        },
        isSubjectAllowed(subjectName) {
            if (this.isOpenScholarshipApplication && subjectName === 'Mathematics Literacy') {
                return false;
            }

            return true;
        },
        validateElectives(electives, selectedExtras) {
            this.electivesError = null;

            let {
                max_subjects_total: max,
                min_subjects_total: min,
            } = electives;
            let numSelected = selectedExtras.length;

            if (typeof min === 'number' && numSelected < min) {
                this.electivesError = `Please select a minimum of ${min} elective subjects.`;

                return false;
            }

            if (typeof max === 'number' && numSelected > max) {
                this.electivesError = `Please select a maximum of ${max} elective subjects.`;

                return false;
            }

            for (const group of electives.subject_groups) {
                let groupSubjects = group.subjects.filter(subject => subject.is_selectable).map(subject => subject.code);
                let selectedGroupSubjects = selectedExtras.filter(extra => groupSubjects.includes(extra));

                // If user selected less than the minimum required by the group,
                if (typeof group.min === 'number' && selectedGroupSubjects.length < group.min || (selectedGroupSubjects.length < group.min && group.is_compulsory)) {
                    let subjectNames = group.subjects.map(subject => subject.name);
                    this.electivesError = `Please select a minimum of ${group.min} from the following list of elective subjects: ${subjectNames.join(', ')}.`;

                    return false;
                }

                if (typeof group.max === 'number' && selectedGroupSubjects.length > group.max) {
                    let subjectNames = group.subjects.map(subject => subject.name);
                    this.electivesError = `Please select a maximum of ${group.max} from the following list of elective subjects: ${subjectNames.join(', ')}.`;

                    return false;
                }
            }

            return true;
        },
        onSubjectChange(event) {
            if (event.change) {
                this.subjectChanges.push(event.code);
            } else {
                this.subjectChanges = this.subjectChanges.filter(subject => subject != event.code);
            }
        },
        async submitSubjectChangeRequest() {
            let data = {
                subjectSelectionType: this.subjectSelectionType,
                requiresSubjectChange: this.requiresSubjectChangeAcknowledgement,
                sfApplicationId: this.application.zoho_application_id,
            };

            if (this.subjectSelectionType === 'final' && this.uploadedFormContentDocumentId) {
                data['uploadedFormContentDocumentId'] = this.uploadedFormContentDocumentId;
                data.requiresSubjectChange = true;
            }

            return await axios.post(this.subjectChangeUrl, data);
        },
        handleSubjectChangeFormUpload(formData) {
            this.isFileUploaded = true;
            this.errorMessage = null;
            this.loading = true;
            this.unexpectedError = false;

            formData.append('sfApplicationId', this.application.zoho_application_id);

            return axios.post(this.subjectChangeUploadUrl, formData)
                .then(response => {
                    this.loading = false;

                    this.uploadedFormContentDocumentId = response.data.data.uploadedFormContentDocumentId;
                }).catch(error => {
                    this.loading = false;
                    this.isFileUploaded = false;

                    if (error.response.data.success == false) {
                        this.unexpectedError = true;
                    }

                    this.unexpectedError = true;
                });
        }
    },
    watch: {
        selectedMathematics(val) {
            if (val === 'MAT') {
                this.setSubjectSelectable('PHS', true);
                return;
            }

            this.setSubjectSelectable('PHS', false);
        },
        selectedExtras(newValue, oldVal) {
            if (newValue.includes('HIS')) {
                this.setSubjectSelectable('GEO', false);
                return;
            }

            this.setSubjectSelectable('GEO', true);

            if (newValue.includes('GEO')) {
                this.setSubjectSelectable('HIS', false);
                return;
            }

            this.setSubjectSelectable('HIS', true);
        },
        subjectChanges(newVal) {
            if (this.requiresSubjectChangeValidation) {
                let complianceStatus = this.changingSubjectRulesService.isCompliant(
                    this.application.grade_of_entry,
                    this.applicationStartDate.term,
                    this.subjectChanges,
                    this.isTakingLifeOrientation
                );

                this.complianceStatus = complianceStatus;
            }

            this.requiresSubjectChangeAcknowledgement = null;
        },
        mathWatchable(newVal) {
            // Remove all possible Math subjects first.
            this.subjectChanges = this.subjectChanges.filter(subjectChange => !Object.keys(this.mathematicsSubjects).includes(subjectChange));

            if (newVal.change) {
                this.subjectChanges.push(newVal.subject);
            }
        },
        falWatchable(newVal) {
            // Remove all possible Language subjects first.
            this.subjectChanges = this.subjectChanges.filter(subjectChange => !Object.keys(this.languageSubjects).includes(subjectChange));

            if (newVal.change) {
                this.subjectChanges.push(newVal.subject);
            }
        },
        isTakingLifeOrientation(newVal) {
            let lifeOrientation = 'LOR';

            if (!newVal) {
                this.subjectChanges.push(lifeOrientation);
            } else {
                this.subjectChanges = this.subjectChanges.filter(subjectChange => subjectChange != lifeOrientation);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
label {
    vertical-align: text-bottom;

    input[type="radio"] {
        vertical-align: middle;
    }

    & + & {
        margin-left: 0.5rem;
    }
}

.acknowledge-extra-field {
    display: grid;
    grid-template-columns: min-content auto;
    column-gap: 0.5rem;
}
</style>
