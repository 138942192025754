<template>
    <tr class="border-0 w-100">
        <td class="border-0">
            <img :src="'/portal-data/uct/tick.svg'" class="fade-in-element me-2 tick-icon" alt="Tick Icon">
            <slot></slot>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "ScholarshipTableRow",
        mounted() {
            this.fadeInElementOnScroll();
        },
        methods: {
            fadeInElementOnScroll() {
                const fadeElements = document.querySelectorAll(".fade-in-element");

                const options = {
                    root: null,
                    rootMargin: "0px",
                    threshold: 0.2
                };

                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add("fade-in");
                            observer.unobserve(entry.target);
                        }
                    });
                }, options);

                fadeElements.forEach(element => {
                    observer.observe(element);
                });
            }
        }
    }
</script>