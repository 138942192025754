<template>
    <form @submit.prevent="submit" class="vi-change-request">
        <p>A subject change allows the Learner to replace one of their current subjects with a different one that better aligns with their academic goals or interests.</p>

        <p class="m-0">{{ name }}</p>

        <p>{{ grade }}</p>

        <p>Before uploading a completed subject change form, please read the information below carefully:</p>

        <p>UCT Online High School has discretion and final say on subject change requests for Grade 10 - 12 as per the information in our <a href="https://www.uctonlinehighschool.com/policies" target="_blank">Academic Handbook</a> that is available on the school's website. UCT Online High School must submit all Grade 12 Subject Change Requests to the Head of NSC at SACAI for final approval.</p>

        <p>Please note the following rules pertaining to subject changes:</p>

        <p><span class="fw-bold">Grade 10:</span> Mathematics Literacy to Mathematics is not permitted. Mathematics is a prerequisite for Physical Sciences.</p>

        <p><span class="fw-bold">Grade 11:</span> Mathematics Literacy to Mathematics is not permitted. Physical Sciences & Accounting are not permitted. </p>

        <p><span class="fw-bold">Grade 12:</span> Economics, Geography & Business Studies may be taken for the first time in Grade 12</p>

        <p>For additional information on subject changes and submissions dates please visit our info hub article <a href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE" target="_blank" rel="noopener noreferrer">here</a>.</p>

        <p class="fw-bold">Subject Change Form upload</p>

        <p>Download the template for the Subject Change Form <a :href="subjectChangeFormUrl" target="_blank" rel="noopener noreferrer">here <i class="bi bi-cloud-arrow-down"></i></a>.</p>

        <!-- Change Request Form Upload -->
        <vi-file-selector
            upload-field-name="requestForm"
            :handle-upload="handleRequestUpload"
            :max-file-size="8000000"
            accepted-file-types=".pdf"
            :on-reset="onChangeRequestFormReset"
        >
            <template v-slot:uploadText>
                <div class="vi-file-selector-upload-text">
                    <i class="bi bi-cloud-upload"></i>
                    <span>Please download the Subject Change Request form above and fill it out.</span>
                    <span>Once completed, drag your form here or click to upload it.</span>
                </div>
            </template>
        </vi-file-selector>

        <input type="hidden" name="uploadRequestFormId" v-model="uploadRequestFormId">

        <template v-if="hasError('requestForm')">
            <error-message
                v-for="message in getErrors('requestForm')"
                :key="`requestForm: ${message}`"
                :message="message"
                class="text-start alert alert-danger mb-3"
            ></error-message>
        </template>

        <template v-if="hasError('uploadRequestFormId')">
            <error-message
                v-for="message in getErrors('uploadRequestFormId')"
                :key="`uploadRequestFormId: ${message}`"
                :message="message"
                class="text-start alert alert-danger mb-3"
            ></error-message>
        </template>

        <!-- Motivation Letter Upload -->
        <template v-if="motivationLetterRequired">
            <p class="fw-bold">Motivation Letter upload</p>

            <p>Download the template for the Motivation Letter <a :href="motivationLetterTemplateUrl" target="_blank" rel="noopener noreferrer">here</a>.</p>

            <vi-file-selector
                upload-field-name="motivationLetter"
                :handle-upload="handleMotivationLetterUpload"
                :max-file-size="8000000"
                accepted-file-types=".pdf"
                :on-reset="onMotivationLetterReset"
            >
                <template v-slot:uploadText>
                    <div class="vi-file-selector-upload-text">
                        <i class="bi bi-cloud-upload"></i>
                        <span>Please download the Motivation Letter Template above and fill it out.</span>
                        <span>Once completed, drag your form here or click to upload it.</span>
                    </div>
                </template>
            </vi-file-selector>

            <input type="hidden" name="motivationRequestLetterId" v-model="motivationRequestLetterId">

            <template v-if="hasError('motivationLetter')">
                <error-message
                    v-for="message in getErrors('motivationLetter')"
                    :key="`motivationLetter: ${message}`"
                    :message="message"
                    class="text-start alert alert-danger mb-3"
                ></error-message>
            </template>

            <template v-if="hasError('motivationRequestLetterId')">
                <error-message
                    v-for="message in getErrors('motivationRequestLetterId')"
                    :key="`motivationRequestLetterId: ${message}`"
                    :message="message"
                    class="text-start alert alert-danger mb-3"
                ></error-message>
            </template>
        </template>

        <div class="form-field">
            <input type="checkbox" name="academicAndHandbooksPolicy" id="academicAndHandbooksPolicy" class="form-check-input">
            <label for="academicAndHandbooksPolicy" class="form-label">I have read the <a href="https://www.uctonlinehighschool.com/policies" target="_blank">Academic Handbook</a> and understand and agree the guidelines outline therein.</label>
        </div>

        <template v-if="hasError('academicAndHandbooksPolicy')">
            <error-message
                v-for="message in getErrors('academicAndHandbooksPolicy')"
                :key="message"
                :message="message"
                class="text-start alert alert-danger mb-3"
            ></error-message>
        </template>

        <div class="form-field">
            <input type="checkbox" name="notExceededNumberOfChanges" id="notExceededNumberOfChanges" class="form-check-input">
            <label for="notExceededNumberOfChanges" class="form-label">I confirm that I have not exceeded the number of subject changes for this academic year.</label>
        </div>

        <template v-if="hasError('notExceededNumberOfChanges')">
            <error-message
                v-for="message in getErrors('notExceededNumberOfChanges')"
                :key="message"
                :message="message"
                class="text-start alert alert-danger mb-3"
            ></error-message>
        </template>

        <div v-if="hasError('generic')" class="alert alert-danger invalid-feedback d-block mb-3">
            <p
                v-for="message in getErrors('generic')"
                :key="`generic: ${message}`"
                v-html="message"
                class="text-start"
            ></p>
        </div>

        <div class="buttons">
            <!-- <button type="submit">Submit Subject Change Request</button> -->
            <vi-button-spinner
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'new-btn-sm': false,
                }"
                :disabled="false"
                :buttonText="'Submit Subject Change Request'"
                :loading="loading"
            ></vi-button-spinner>
        </div>

        <small><span class="fw-bold">Note:</span> This is just a subject change request. Once submitted, the request will need to be reviewed and processed by the Student Records Office.</small>
    </form>
</template>

<script>
import error_handling from '../../mixins/error_handling';
import scroll_to from '../../mixins/scroll_to';

export default {
    name: "ChangeRequest",
    mixins: [
        error_handling,
        scroll_to,
    ],
    props: {
        learnerSfId: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        grade: {
            type: String,
            required: true,
        },
        requestFormUploadUrl: {
            type: String,
            required: true,
        },
        motivationLetterUploadUrl: {
            type: String,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        motivationLetterRequired: {
            type: Boolean,
            default: false,
        },
        subjectChangeFormUrl: {
            type: String,
            required: true,
        },
        motivationLetterTemplateUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            errors: null,
            loading: false,

            uploadRequestFormId: null,
            motivationRequestLetterId: null,
            academicAndHandbooksPolicy: null,
            notExceededNumberOfChanges: null,
        };
    },
    computed: {
        // submitDisabled() {
        //     return !!this.errors;
        // }
    },
    methods: {
        submit(event) {
            let data = new FormData(event.target);

            // No need to send them again as they should've been uploaded already.
            data.delete('requestForm');
            data.delete('motivationLetter');

            this.loading = true;

            axios.post(this.submitUrl, data)
                .then(response => this.$emit('success', true))
                .catch(error => {
                    this.errors = error.response.data.errors;

                    this.$nextTick(() => {
                        let first = this.firstError();

                        if (first) {
                            this.scrollIntoView(first);
                        }
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async handleRequestUpload(formData) {
            this.requestFormError = false;

            try {
                let response = await axios.post(this.requestFormUploadUrl, formData);

                this.uploadRequestFormId = response.data.contentDocumentId;
                console.log('uploadRequestFormId:',this.uploadRequestFormId)

                return Promise.resolve('Upload successful!');
            } catch (error) {
                let data = error.response.data;

                this.errors = data.errors;

                this.$nextTick(() => {
                    this.scrollIntoView(this.firstError());
                });

                return Promise.reject('An error has occured!');
            }
        },
        async handleMotivationLetterUpload(formData) {
            this.requestFormError = false;

            try {
                let response = await axios.post(this.motivationLetterUploadUrl, formData);

                this.motivationRequestLetterId = response.data.contentDocumentId;
                console.log('motivationRequestLetterId:',this.motivationRequestLetterId)

                return Promise.resolve('Upload successful!');
            } catch (error) {
                let data = error.response.data;

                this.errors = data.errors;

                this.$nextTick(() => {
                    this.scrollIntoView(this.firstError());
                });

                return Promise.reject('An error has occured!');
            }
        },
        onChangeRequestFormReset() {
            this.uploadRequestFormId = null;
        },
        onMotivationLetterReset() {
            this.motivationRequestLetterId = null;
        }
    },
    // watch: {
    //     errors(val) {
    //         console.log(val);
    //     }
    // }
}
</script>

<style lang="scss" scoped>
.vi-change-request {
    max-width: 75ch;
    margin: 0 auto;
}

.form-field {
    display: grid;
    grid-template-columns: 1rem auto;
    column-gap: 0.5rem;
}

.buttons {
    display: flex;
    justify-content: center;
}

.new-btn {
    max-width: max-content;
    padding-left: 3rem;
    padding-right: 3rem;
}

p,
.form-field,
::v-deep .vi-file-selector {
    margin-bottom: 1em;
}

small {
    margin-top: 1rem;
    display: block;
}

::v-deep .vi-file-selector-upload-text {
    display: flex;
    flex-direction: column;

    i {
        font-size: 2rem;
    }

    span {
        font-size: 14px;
    }
}

.bi.bi-cloud-arrow-down {
    vertical-align: middle;
}
</style>
