<template>
    <div class="guardian-loging-form-container mx-auto w-100">
        <h4 class="mb-4 text-dark">We're thrilled you've signed up!</h4>
        <div class="text-pastel-blue">
            <p>
                Please enter the code sent to your <span class="fw-bold">email address</span>:
            </p>
            <form class="mb-3">
                <div>
                    <vi-security-code-input
                        @full-code="getCode"
                        @submit-code="verifyCode"
                        :blocks="6"
                        :block-class="'input-block'"
                        :block-active-class="'input-block-active'"
                        :block-inactive-class="'input-block-inactive'"
                        :block-focus-class="'input-block-focus'"
                    ></vi-security-code-input>
                </div>
            </form>
            <p v-if="!resendDisabled" class="vi-text-left">
                Didn't receive a code? Click <span @click="resendCode" class="span-link">here</span> to get a new code.
            </p>
            <div class="mt-2">
                <button @click="verifyCode" class="btn btn-primary vi-btn-green">Verify Code</button>
            </div>
            <div v-if="incorrectCode" class="my-4 p-2 bg-danger text-white">
                The code you entered is incorrect.
            </div>
            <div v-if="resentMessage" class="mt-4 p-2 bg-warning text-white">
                A new code has been sent.
            </div>
            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to save your application right now. Please try again later."
            ></vi-error-flash>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmailCodeVerification",
        data: () => ({
            code: null,
            incorrectCode: false,
            resentMessage: false,
            errors: null,
            resendDisabled: false,
            form: {
                email: null,
                password: null,
            },
            unexpectedError: false,
        }),
        props: {
            verifyRoute: {
                type: String,
                required: true
            },
            resendRoute: {
                type: String,
                required: true
            },
            email: {
                type: String,
                required: true
            },
        },
        methods: {
            async verifyCode() {
                this.resentMessage = false;
                this.incorrectCode = false;

                if (this.code === null || this.code.length !== 6) {
                    this.incorrectCode = true;
                    return;
                }

                try {
                    const formData = new FormData();
                    formData.append('email', this.email);
                    formData.append('code', this.code);

                    const response = await axios.post(this.verifyRoute, formData);
                    if (response.status === 200) {
                        location.href = response.data.redirect;
                    } else {
                        this.incorrectCode = true;
                    }
                } catch (error) {
                    this.incorrectCode = true;
                }
            },

            async resendCode() {
                try {
                    this.resentMessage = false;
                    this.incorrectCode = false;
                    this.resendDisabled = true;

                    setTimeout(() => {
                        this.resentMessage = false;
                        this.resendDisabled = false;
                    }, 30000);

                    const formData = new FormData();
                    formData.append('email', this.email);

                    let response = await axios.post(this.resendRoute, formData);
                    if (response.status === 200) {
                        this.resentMessage = true;
                    }
                } catch (error) {
                    console.log(error);
                    this.unexpectedError = true;
                }
            },
            getCode(value) {
                this.code = value;
            },

            triggerGaEvent() {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'createPassword',
                    'userid': window.valenture.userId,
                });
            }
        },
        mounted() {
            this.form.email = this.email;
        }
    }
</script>