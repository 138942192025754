<template>
    <div class="vi_progress_bar progress my-2 px-0" style="width: 0">
        <div
            :id="id"
            :class="progressBarClass"
            :style="progressBarStyle"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ViProgressBar',
    props: {
        id: {
            type: String,
            required: true,
        },
        progressBarClass: {
            type: String,
            required: false,
            default: 'progress-bar',
        },
        progressBarStyle: {
            type: String,
            required: false,
            default: 'width: 0',
        },
    },
}
</script>