<template>
    <div v-if="showTimer" class="countdown-timer mt-2 mb-0 px-3 py-2 fw-bold rounded">
        <p class="mb-1 text-center">
            {{ isDashboard ? `${time} left` : `Don't miss out. Only ${time} left to apply!` }}
        </p>
        <div class="progress" v-if="showProgress">
            <div class="progress-bar bg-danger" role="progressbar" :style="`width : ${progressPercentage}%`" :aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: 'CountdownTimer',
    props: {
        countdownDate: {
            type: String,
            required: true,
        },
        isDashboard: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            countdownEnds: null,
            showTimer: true,
            showProgress: false,
        }
    },
    mounted() {
        this.countdownEnds = moment(this.countdownDate);

        setInterval(() => {
            this.countdownEnds = moment(this.countdownEnds)
        }, 1000);
    },
    computed: {
        progressPercentage() {

            if (!this.countdownEnds) {
                return 0;
            }

            const totalSeconds = 24 * 60 * 60;
            const secondsLeft = this.countdownEnds.diff(moment(), 'seconds');

            return (secondsLeft / totalSeconds) * 100;
        },
        time() {

            if (!this.countdownEnds) {
                return '';
            }

            if (this.countdownEnds.diff(moment(), 'days') > 0) {
                if (this.countdownEnds.diff(moment(), 'days') > 7) {
                    this.showTimer = false;
                }

                if (this.countdownEnds.diff(moment(), 'days') === 1) {
                    return `${this.countdownEnds.diff(moment(), 'days')} day`;
                }

                return `${this.countdownEnds.diff(moment(), 'days')} days`;
            }

            this.showProgress = true;

            let secondsLeft = this.countdownEnds.diff(moment(), 'seconds') % 60;

            if (secondsLeft < 0) {
                this.showTimer = false;
                return '';
            }

            return `${this.countdownEnds.diff(moment(), 'hours')} hours ${this.countdownEnds.diff(moment(), 'minutes') % 60} minutes ${this.countdownEnds.diff(moment(), 'seconds') % 60} seconds`
        },
    },
}
</script>
<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.countdown-timer {
    font-size: 10px;
    background-color: #ee2225;
    color: #ffffff;

    .progress {
        height: 5px;
    }
}
</style>
