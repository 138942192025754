<template>
    <div>
        <h5 class="text-pastel-blue fw-bold mb-3">Withdrawals</h5>

        <div v-if="student.withdrawalData.status == 'Pending Withdrawal'">
            <p>A withdrawal request for your learner has already been logged and is currently being processed. Please see the details below:</p>

            <ul>
                <li><span class="fw-bold">Status:</span> {{ student.withdrawalData.status }}</li>
                <li><span class="fw-bold">Withdrawal Date:</span> {{ student.withdrawalData.date }}</li>
                <li><span class="fw-bold">Reason:</span> {{ student.withdrawalData.reason }}</li>
                <li><span class="fw-bold">Final Cancellation Date:</span> {{ student.withdrawalData.finalCancellationDate }}</li>
                <li><span class="fw-bold">Billing End Date:</span> {{ student.withdrawalData.billingEndDate }}</li>
            </ul>
        </div>

        <div v-else-if="student.withdrawalData.status == 'Completed'">
            <p>A withdrawal request for your learner has already been processed and your learner is in the process of being withdrawn.</p>
        </div>

        <div v-else-if="canWithdrawStudent">
            <p>No withdrawal requests logged.</p>
        </div>

        <div v-else>
            <p>Withdrawal requests are only available for enrolled or suspended learners.</p>
        </div>

        <button v-if="(canWithdrawStudent || canCancelWithdrawal) && student.withdrawalData.status != 'Completed'" :id="`withdrawLearnerBtn-${student.id}`" class="btn btn-outline-danger rounded-pill w-100 px-4" @click="showModal">
          {{ student.withdrawalData.status == 'Pending Withdrawal' ? 'Cancel Withdrawal Request' : 'Withdraw My Learner' }}
        </button>

        <div class="modal fade" :id="`withdraw-learner-${student.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title h4 px-2 px-lg-5 fw-bold">{{ headerTitle }}</h1>
                        <button type="button" class="close close-modal" @click="hideModal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                <div class="modal-body">
                    <vi-withdrawal
                        v-if="modalVisible"
                        :student="student"
                        :key="`${student.id}-${modalKey}`"
                    ></vi-withdrawal>
                </div>
                <div class="modal-footer justify-content-start" :id="`cancelWithdrawalBtn-${student.id}`">
                    <button type="button" class="new-btn w-auto px-5 mx-2 mx-lg-5 btn-danger" @click="hideModal">Cancel</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "WithdrawalSection",
        data() {
            return {
                withdrawalModal: null,
                modalKey: 0,
                canWithdrawStudent: false,
                canCancelWithdrawal: false,
                modalVisible: false,
            }
        },
        props: {
            student: {
                type: Object,
                required: true,
            },
        },
        mounted() {
            this.withdrawalModal = new bootstrap.Modal(document.getElementById(`withdraw-learner-${this.student.id}`), {
                keyboard: false
            });

            this.canWithdrawStudent =  this.student.isInSchool;

            this.canCancelWithdrawal = this.student.withdrawalData.sfStatus == 'Approved';
        },
        computed: {
            headerTitle() {
                return this.student.withdrawalData.status == 'Pending Withdrawal' ? 'Cancel Withdrawal Request' : 'Withdraw My Learner';
            }
        },
        methods: {
            showModal() {
                this.modalKey++;
                this.modalVisible = true;
                this.withdrawalModal.show();
            },
            hideModal() {
               this.withdrawalModal.hide();
               this.modalVisible = false;
            },
        }

    }
</script>
<style scoped>
    .close-modal {
        font-size: 48px;
        line-height: 48px;
    }
</style>
