<template>
    <div>
        <div v-for="(student, index) in students" :key="student.id" class="accordion-item2">
            <h3 class="accordion-header2" :id="'heading-' + student.id">
                <div
                    :id="'student-list-' + student.id"
                    class="accordion-button2 pb-2 d-flex justify-content-between align-items-center w-100"
                    type="button"
                    :data-bs-toggle="'collapse'"
                    :data-bs-target="'#block-' + student.id"
                    :aria-controls="'block-' + student.id"
                    @click="closeModalAndClickStudent(student.studentEmail)"
                >
                    {{ student.name }}
                    <i class="bi bi-box-arrow-in-right"></i>
                </div>

            </h3>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'students': {
            type: Array,
            required: true
        },
    },
    methods: {
        closeModalAndClickStudent(studentEmail) {
            this.$emit('close-modal-and-click-student', studentEmail);
        }
    }
}
</script>

<style scoped lang="scss">

    @import 'resources/sass/abstract/colours.scss';

    .accordion-header2 {
        border-bottom: 1px solid $pastel-blue;
    }

    .accordion-button2 {
        font-size: 1.1875rem; // 19px
        color: $pastel-blue;

        &:hover {
            color: $pastel-light-blue;

            i {
                color: $pastel-light-blue;
            }
        }
    }

    .bi-box-arrow-in-right {
        color: $pastel-blue;
    }

</style>
