<template>
    <div ref="modalRoot" class="modal fade success-modal" id="llsSuccess" tabindex="-1" aria-labelledby="llsSuccessLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border border-3 border-white">
                <div class="modal-body text-center p-4 position-relative pb-2">
                    <div class="modal-icon-block success-modal-tick">
                        <img :src="'/portal-data/uct/information.png'" alt="Information Icon">
                    </div>
                    <h3 class="text-white fw-bold mb-3">Hi there!</h3>
                    <h5 class="mb-0 text-white mb-3">We noticed your profile is incomplete. Please fill in your ID information to complete your profile.</h5>
                </div>
                <div class="modal-footer text-center border-0 pb-4 flex-col">
                    <button
                        type="button"
                        class="new-btn ohs-white-outline-btn rounded-pill px-5 py-2 w-auto rounded-pill mx-auto"
                        @click.prevent="hideAndTriggerAlert"
                    >
                        Ok!
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "IdNumberPopUp",
    data() {
        return {
            modalInstance: null,
        };
    },
    mounted() {
        this.initModal();

        this.show();
    },
    computed: {},
    methods: {
        initModal() {
            this.modalInstance = new bootstrap.Modal(this.$refs.modalRoot);
        },
        show() {
            if (!this.modalInstance) {
                return;
            }

            this.modalInstance.show();
        },
        hide() {
            if (!this.modalInstance) {
                return;
            }

            this.modalInstance.hide();
        },
        hideAndTriggerAlert() {
            this.hide();

            document.body.dispatchEvent(new Event("id:alert"));
        }
    },
}

</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';
.modal-icon-block {
    background-color: $red26;
}
</style>