<template>
    <div> <!--Required for parent to be able to toggle layout and spacing.-->
        <div
            class="card generic-widget-card general-widget-card p-0 d-flex flex-column h-100 flex-md-row overflow-hidden"
        >
            <div class="generic-widget-left-col py-3" :class="[(action ?? '').toLowerCase(), {'w-100': !(showModal && (isModal || isLink))}]">
                <div class="card-body d-flex justify-content-center flex-column">
                    <h5 class="card-title mb-0">{{ heading }}</h5>
                </div>
            </div>

            <div class="text-center generic-widget-right-col py-3 px-1" v-if="showModal && (isModal || isLink)">

                <button
                    v-if="isModal"
                    class="h-100 w-100 d-flex justify-content-center align-items-center text-uppercase widget-card-action"
                    data-bs-toggle="modal"
                    :data-bs-target="`#${id}`"
                    :data-student-email="studentEmail"
                >
                    {{ action }}
                </button>

                <a
                    v-else-if="isLink"
                    :href="url"
                    class="h-100 w-100 d-flex justify-content-center align-items-center text-uppercase widget-card-action"
                >
                    {{ action }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardActionCard",

        data: () => ({
            loading: false,
        }),

        props: {
            type: {
                type: String,
                default: null,
            },
            studentEmail: {
                type: String,
                default: null,
            },
            heading: {
                type: String,
                required: true,
                default: null,
            },
            id: {
                type: String,
                required: true,
                default: null,
            },
            url: {
                type: String,
                default: null,
            },
            showModal: {
                type: Boolean,
                default: true,
            },
            studentKey: {
                type: Number,
                default: 0,
            },
            action: {
                type: String,
                default: "View"
            },
            isModal: {
                type: Boolean,
                default: true,
            },
            isLink: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style scoped lang="scss">
    .enroll {
        background-color: lightblue;
    }
</style>
