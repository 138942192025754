<template>
    <phone-number-input
        ref="phoneNumberInput"
        :dial-codes="dialCodes"
        :init-dial-code-value="guardianContact.mobile_number_country_code.mobile_number_country"
        :init-mobile-number-value="guardianContact.mobile_number.mobile_number"
        v-on:on-submit-handler="onSubmitHandler"
        :error="errors['mobile_number'] ? errors['mobile_number'][0] : null"
        inputClass="account-input-container-light"
        :guardianContact="guardianContact"
        :loading="this.loading"
        v-on:update-is-disabled="onUpdateIsDisabled"
        required="true"
    ></phone-number-input>
</template>

<script>
    import PhoneNumberInput from './PhoneNumberInput.vue';

    export default {
        name: 'PhoneNumberAccountInput',
        components: {
            PhoneNumberInput,
        },
        data() {
            return {
                isDisabled: false,
                isSuccessful: false,
                mobile_number_country: this.initDialCodeValue ? this.initDialCodeValue : "ZA",
                mobile_number: this.initMobileNumberValue,
                errors: {},
                loading: this.loading
            };
        },
        props: {
            guardianContact: {
                type: Object,
                required: true,
            },
            // Function passed as onSubmit handler should always return a promise.
            onSubmit: {
                type: Function,
                required: true,
            },
            error: {
                type: String,
                required: false,
                default: null
            },
            initDialCodeValue: {
                type: String,
                required: false,
                default: null,
            },
            initMobileNumberValue: {
                type: String,
                required: false,
                default: null,
            },
            inputClass: {
                type: String,
            },
            dialCodes: {
                type: Object,
                required: true,
            },
        },
        methods: {
            onUpdateIsDisabled(isDisabled) {
                this.isDisabled = isDisabled;
            },

            onSubmitHandler(event) {
                let payload  = {
                    'mobile_number_country' : event.mobileCode,
                    'mobile_number' : event.mobileNumber
                }

                this.loading = true;

                this.onSubmit(payload).finally((result) => {
                    this.loading = false;
                    this.$refs.phoneNumberInput.toggleDisable();

                    return result;
                });
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    @import 'resources/sass/abstract/colours.scss';
    .edit-icon {
        font-size: 1.125rem;
    }

    input, input:focus {
        outline: none;
        border: none;
        box-shadow: none;
        color: $uctDarkBlue;
        border: none;
        background-color: $white;
        border-radius: 0;
        padding-left: 10px;
        transition: 0.3s ease;
        border-radius: 50rem 0 0 50rem;

        &::placeholder {
            color: $white;
        }

        &:disabled, &[readonly] {
            outline: none;
            border: none;
            box-shadow: none;
            color: $white;
            background-color: transparent;
            border-radius: 0;
            transition: 0.3s ease;
        }
    }

    .edit-btn {
        border: none;
        border-radius: 0;
        border-left: 1px solid $white;
        outline: none;
        box-shadow: none;
        background-color: $pastel-light-blue;
        border-radius: 0 50rem 50rem 0;
        min-width: 80px;
    }

    .btn-white {
        background-color: $white;
        color: $pastel-blue;
        padding: 10px 40px;
        margin-top: 30px;
    }

    .spinner {
      width: 20px;
      height: 20px;
    }

    .dial-codes-select {
        border-radius: 50rem;

        &:focus {
            box-shadow: none;
        }
    }

    .account-input-container-light + .invalid-feedback {
        color: $white;
    }

</style>