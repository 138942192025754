<template>
    <div>
        <!-- edit profile form -->
        <form ref="profileForm">
            <fieldset id="update-profile-fieldset">
            <h5 class="mb-3 modal-header-l-blue">Visible to others on the Online Campus</h5>
            <!-- image uploader component -->
            <image-uploader v-model="avatar" @uploaded="receiveUploadedFile">
                <div slot="activator">
                    <v-avatar size="150px" v-ripple class="grey lighten-3 mb-3 border avatar-block">
                        <img :src="avatar.imageURL" class="avatar-img">
                    </v-avatar>
                </div>
            </image-uploader>
            <div class="mb-3">
                <label class="mb-2 d-flex" for="preferredName">
                    Preferred Name
                    <div class="d-flex">
                        <img class="ms-3 visible-notice-image" :src="'/portal-data/uct/visible.svg'" alt="Eye Icon"
                             width="25">
                        <span class="visible-notice p-2 rounded shadow border">This field will be visible to others on your Online Campus profile.</span>
                    </div>
                </label>
                <input type="text" class="form-control" id="preferredName" name="preferredName" v-model="preferredName" placeholder="Preferred name" required>
                <div v-if="errors.preferred_name">
                    <ul>
                        <li v-for="error in errors.preferred_name" class="mt-2 text-danger">{{ error }}</li>
                    </ul>
                </div>
            </div>
            <div class="mb-3">
                <label class="mb-2 d-flex" for="cityOrTown">
                    City/Town
                    <div class="d-flex">
                        <img class="ms-3 visible-notice-image" :src="'/portal-data/uct/visible.svg'" alt="Eye Icon"
                             width="25">
                        <span class="visible-notice p-2 rounded shadow border">This field will be visible to others on your Online Campus profile.</span>
                    </div>
                </label>
                <input type="text" class="form-control" id="cityOrTown" placeholder="Enter your city here" v-model="city">
            </div>
            <div class="mb-3">
                <label class="mb-2 d-flex" for="country">
                    Country
                    <div class="d-flex">
                        <img class="ms-3 visible-notice-image" :src="'/portal-data/uct/visible.svg'" alt="Eye Icon"
                             width="25">
                        <span class="visible-notice p-2 rounded shadow border">This field will be visible to others on your Online Campus profile.</span>
                    </div>
                </label>
                <select class="form-select" id="country" placeholder="Select a Country" v-model="country">
                    <option v-for="(option, key) in countryOptions" :value="key" :selected="key === country">{{ option }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label class="mb-2 d-flex" for="blurbBlock">
                    Who am I?
                    <div class="d-flex">
                        <img class="ms-3 visible-notice-image" :src="'/portal-data/uct/visible.svg'" alt="Eye Icon"
                             width="25">
                        <span class="visible-notice p-2 rounded shadow border">This field will be visible to others on your Online Campus profile.</span>
                    </div>
                </label>
                <textarea class="form-control" placeholder="Tell us about yourself..." id="blurbBlock"
                          style="height: 100px"
                          v-model="blurb"
                          maxlength="1000"></textarea>
            </div>

            <h5 class="mb-3 modal-header-l-blue">{{ isAdultMatric ? 'Visible to your Support Advisor only' : 'Visible to your Support Coach and Learning Facilitators only' }}</h5>
            <div class="mb-3">
                <label class="mb-2" for="pronoun">Pronoun</label>
                <select class="form-select" aria-label="Default select example" name="pronoun" v-model="pronoun" id="pronoun">
                    <option v-for="(item, key) in pronouns" v-bind:value="key" >{{ item }}</option>
                </select>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col-12 col-md-6">
                        <label for="mobile_number_country" class="col-form-label">Code</label>
                        <select class="form-select" id="mobile_number_country" v-model="mobileNumberCountry">
                            <option v-for="(option, key) in countryCodesOptions" :value="key" :selected="key === mobileNumberCountry">{{ option }}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6">
                        <label for="mobile_number" class="col-form-label">Mobile number</label>
                        <input type="tel" name="mobile_number" id="mobile_number" class="form-control" v-model="mobileNumber">
                        <span v-show="wrongNumber" class="text-danger mt-2" style="display:block;">Please enter a valid phone number (7 to 11 digits allowed)</span>
                        <div v-if="errors.mobile_number">
                            <ul>
                                <li v-for="error in errors.mobile_number" class="mt-2 text-danger">{{ error }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" id="update-profile-button" class="btn-uct rounded border-0 p-2 w-100 text-white d-flex justify-content-center" @click="uploadImage" :loading="saving">
                Update Details &nbsp;&nbsp;<span v-show="saving" class="spinner-border spinner-border-sm" role="status" style="margin-top: 3px;">&nbsp;</span>
            </button>
            </fieldset>
        </form>
    </div>
</template>

<script>
    import ImageUploader from './ImageUploader.vue'

    const numberRegex = /^[0-9]{7,11}$|^$/;

    export default {
        name: "ProfileEditForm",
        data() {
            return {
                avatar: { imageURL: null },
                saving: false,
                saved: false,
                wrongNumber: false,
                noName: false,
                fields: {},
                errors: {},
                imageFilename: '',
                imageData: '',
                file: '',
                pronouns: {
                    "NotSay": "Rather not say",
                    "He": "He/Him/His",
                    "She": "She/Her/Hers",
                    "They": "They/them/theirs",
                },
            }
        },
        props: [
            'userId',

            'preferredName',
            'pronoun',
            'mobileNumber',
            'mobileNumberCountry',
            'city',
            'country',
            'blurb',

            'imageSrc',

            'countries',
            'countryCodes',

            'curriculumOptions',
            'isAdultMatric',
            'isCaie',
            'isCaps'
        ],
        components: {
            'image-uploader': ImageUploader
        },
        watch: {
            avatar: {
                handler: function () {
                    this.saved = false
                },
                deep: true
            }
        },
        methods: {
            beginLoading() {
                // disable the button
                const submitButton = document.getElementById('update-profile-button');
                submitButton.classList.add('disabled');
                submitButton.disabled = true;

                // disable the form elements
                const fieldSet = document.getElementById('update-profile-fieldset');
                fieldSet.disabled = true;

                this.saving = true;
                this.errors = {};
            },
            endLoading() {
                // enable the button
                const submitButton = document.getElementById('update-profile-button');
                submitButton.classList.remove('disabled');
                submitButton.disabled = false;

                // enable the form elements
                const fieldSet = document.getElementById('update-profile-fieldset');
                fieldSet.disabled = false;

                this.saving = false;
                // this.errors will be populated by the POST response
            },
            uploadImage(event) {
                this.beginLoading();
                this.submitForm();
            },
            submitForm(e) {
                const formData = new FormData();
                formData.append("preferred_name", this.preferredName ?? '');
                formData.append("pronoun", this.pronoun ?? '');
                formData.append("mobile_number", this.mobileNumber ?? '');
                formData.append("mobile_number_country", this.mobileNumberCountry ?? '');
                formData.append("city", this.city ?? '');
                formData.append("country", this.country ?? '');
                formData.append("blurb", this.blurb ?? '');

                formData.append("image_filename", this.imageFilename ?? '');
                formData.append("file", this.file ?? '');

                let config = {
                    header : {
                        'Content-Type' : 'multipart/form-data'
                    }
                };

                axios
                    .post('/uct/students/profile/update', formData, config)
                    .then(response => {
                        this.endLoading();
                        alert('Profile Updated!');

                        // refresh the page
                        location.reload();

                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                            this.endLoading();
                            return;
                        }

                        this.endLoading();
                        alert('Sorry! An error has occurred while updating your profile. Please contact your support coach for assistance.');
                        // console.log(error);
                    });
            },
            receiveUploadedFile(file) {
                // console.log('got file from child:');
                // console.log(file);

                this.file = file;
                this.imageFilename = file.name ?? 'profile.jpg';

            },
        },
        mounted() {
            this.avatar = { imageURL: JSON.parse(this.imageSrc) };
            // console.log(this.avatar.imageURL);
        },
        computed: {
            countryOptions() {
                return this.countries.World;
            },
            countryCodesOptions() {
                return this.countryCodes;
            }
        }
    };
</script>
