<template>
   <div>
        <h4 class="mb-3 text-dark">Submit Additional Information</h4>

        <p>Please assist in providing some additional information regarding the Learner.</p>

        <div v-if="globalError" class="vi-looker-error alert alert-danger">
            {{ globalError }}
        </div>

        <form @submit.prevent="submit" method="POST" id="form" action="" class="text-start guardian-loging-form-container mx-auto" autocomplete="off">
            <h4 class="mb-3 text-dark">Learner Information</h4>

            <vi-address-field-group
                id="learner"
                :location-bounds="location ? location.getBounds() : null"
                :errors="learnerAddressErrors"
                :address-fields="form"
                v-bind.sync="form"
                :countries="countries"
                :provinces="provinceOptions"
            >
            </vi-address-field-group>

            <!-- keep address question -->
            <p class="form-label">Is this the same as the address you would like us to keep for the guardian?</p>

            <div class="mb-3">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="addressYes"  value="1" name="is_guardian_address_same" v-model.trim="form.is_guardian_address_same">

                    <label class="form-check-label" for="addressYes">Yes</label>
                </div>

                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="addressNo" value="0" name="is_guardian_address_same" v-model.trim="form.is_guardian_address_same">

                    <label class="form-check-label" for="addressNo">No</label>
                </div>

                <p class="invalid-feedback d-block m-0" v-if="hasError('is_guardian_address_same')" v-html="getErrors('is_guardian_address_same')[0]"></p>
            </div>

            <template v-if="!isGuardianAddressSame">
                <vi-address-field-group
                    id="guardian"
                    :location-bounds="location ? location.getBounds() : null"
                    :errors="guardianAddressErrors"
                    :address-fields="form.guardian"
                    v-bind.sync="form.guardian"
                    :countries="countries"
                    :provinces="provinceOptions"
                >
                </vi-address-field-group>
            </template>

            <!-- learner ethnicity -->
            <div class="form-floating mb-3">
                <select class="form-select new-input" name="ethnicity" id="ethnicity" v-model.trim="form.ethnicity">
                    <option value="null" selected disabled>Please select</option>
                    <option v-for="(option, key) in ethnicities" :value="option" :key="key">{{ option }}</option>
                </select>

                <label for="ethnicity">Learner's Population Group</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('ethnicity')" v-html="getErrors('ethnicity')[0]"></p>
            </div>

            <div class="form-floating mb-3">
                <div class="form-check">
                    <input type="checkbox"  class="form-check-input" v-model.trim="form.does_not_have_mobile_number" @change="toggleMobileGroup()">
                    <label for="admissions_policy" class="form-check-label">Learner does not have mobile number</label>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 col-md-4">
                    <div class="form-floating">
                        <select class="form-select new-input" id="dialCodeSelect" aria-label="Floating label select example" v-model.trim="form.mobile_number_country" :disabled="disabledMobile">
                            <option :value="null" disabled>Please select</option>
                            <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                        </select>
                        <label for="dialCodeSelect">Dialing Code</label>
                    </div>
                </div>

                <div class="col-12 col-md-8">
                    <div class="form-floating">
                        <input
                            id="mobileNumberInput"
                            type="tel"
                            class="form-control new-input"
                            placeholder="Mobile Number"
                            v-model.trim="form.mobile_number"
                            :disabled="disabledMobile"
                        />

                        <label for="mobileNumberInput">Mobile Number</label>

                        <p
                            v-if="hasError('mobile_number')"
                            class="invalid-feedback d-block m-0"
                            v-html="getErrors('mobile_number')[0]"
                        ></p>
                    </div>
                </div>
            </div>

            <template v-if="grade">
                <label class="form-label" for="retained">{{ getRetainedQuestion }}</label>
                <div class="mb-3">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="retainedYes"  value="1" v-model.trim="form.is_retained">

                        <label class="form-check-label" for="retainedYes">Yes</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="retainedNo" value="0" v-model.trim="form.is_retained">

                        <label class="form-check-label" for="retainedNo">No</label>
                    </div>

                    <p class="invalid-feedback d-block m-0" v-if="hasError('is_retained')" v-html="getErrors('is_retained')[0]"></p>
                </div>
            </template>

            <h4 class="mb-3 mt-4 text-dark">Previous School Information</h4>

            <div class="mb-3 large-dropdown">

                <!-- brand new school dropdown -->
                <div class="school-dropdown w-100">
                    <button @click.prevent="toggleSchoolDropdown" class="new-btn new-btn-dark rounded py-3 px-4 shadow w-100">Search for school <i class="bi bi-search ms-2"></i></button>

                    <div class="w-100 school-dropdown-content" :class="{ 'show': isDropdownVisible }">
                        <input
                            id="schoolSearchInput"
                            ref="dropdownSearchInput"
                            class="p-2 w-100"
                            type="text"
                            placeholder="Start typing to search..."
                            @keyup="debouncedGetSchool($event)"
                        >

                        <div class="school-dropdown-content-list">
                            <template
                                v-if="!loadingSchoolData"
                            >
                                <div
                                    v-for="(option, key) in activeSchoolData"
                                    class="dropdown-item"
                                    :data-value="option.schoolname"
                                    :key="key"
                                    @click="(event) => selectSchool(option)"
                                >{{ option.schoolname }}</div>

                                <div class="dropdown-item"
                                    @click.prevent="selectDefault"
                                >I'd like to enter my school manually</div>
                            </template>

                            <div v-if="loadingSchoolData" class="dropdown-item d-flex justify-content-center">
                                <div class="spinner-border school-loader" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- old school search box -->
                <!-- <select class="form-select new-input" name="schools" id="schools" size="15" v-model.trim="form.schools" @change="getEmailPhone">
                    <option value="null" selected disabled>School name search results</option>
                    <option value="My school is not in this list">My school is not in this list</option>
                    <option v-for="(option, key) in schoolData" :value="option.schoolname" :key="key">{{ option.schoolname }}</option>
                </select>
                <p v-if="schoolNotFound" class="invalid-feedback d-block m-0">No results</p>
                <p class="invalid-feedback d-block m-0" v-if="hasError('current_school')" v-html="getErrors('current_school')[0]"></p> -->
            </div>

                <!-- name of current school -->
                <div
                class="form-floating mb-3"
                data-bs-toggle="tooltip"
                data-bs-title="Please search for the school first"
                >
                <input
                    id="current-school"
                    :disabled="!isSchoolFieldsEditable"
                    type="text"
                    name="current-school"
                    class="form-control new-input"
                    placeholder="Current School"
                    v-model.trim="form.current_school"
                >

                <label for="current-school" class="form-label long-label">Name of learner's current school (required for learner portfolio)</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('current_school')" v-html="getErrors('current_school')[0]"></p>
            </div>

            <div class="form-floating mb-3"
                data-bs-toggle="tooltip"
                data-bs-title="Please search for the school first"
            >
                <input
                    id="school-email"
                    :disabled="!isSchoolFieldsEditable"
                    type="email"
                    name="school-email"
                    class="form-control new-input"
                    placeholder="School email address"
                    v-model.trim="form.current_school_email"
                >

                <label for="school-email" class="form-label long-label">School email address (required for learner portfolio)</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('current_school_email')" v-html="getErrors('current_school_email')[0]"></p>
            </div>

            <div
                class="form-floating mb-3"
                data-bs-toggle="tooltip"
                data-bs-title="Please search for the school first"
            >
                <input
                    id="school-number"
                    :disabled="!isSchoolFieldsEditable"
                    type="text"
                    name="school-number"
                    class="form-control new-input"
                    placeholder="School phone number"
                    v-model.trim="form.current_school_phone_number"
                >

                <label for="school-number" class="form-label long-label">School phone number (required for learner portfolio)</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('current_school_phone_number')" v-html="getErrors('current_school_phone_number')[0]"></p>
            </div>

            <div
                class="form-floating mb-3"
                data-bs-toggle="tooltip"
                data-bs-title="Please search for the school first"
                tabindex="0"
            >
                <select
                    id="current-school-province"
                    :disabled="!isSchoolFieldsEditable"
                    class="form-select new-input long-label-select"
                    name="current-school-province"
                    v-model.trim="form.current_school_province"
                >
                    <option value="null" selected disabled>Please select</option>
                    <option v-for="(option, key) in provinceOptions" :value="option" :key="key">{{ option }}</option>
                </select>

                <label class="long-label" for="current-school-province">Province of learner's current school (required for learner portfolio)</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('current_school_province')" v-html="getErrors('current_school_province')[0]"></p>
            </div>

            <h4 class="mb-3 mt-4 text-dark">Guardian and Household Information</h4>

            <div class="form-floating mb-3">
                <select class="form-select new-input" required name="guardian-identity-type" id="guardian-identity-type" v-model.trim="form.identity_type">
                    <option value="null" selected disabled>Please select</option>
                    <option
                        v-for="(option) in idTypeOptions"
                        :value="option.value"
                        :key="option.value"
                    >{{ option.text }}</option>
                </select>

                <label for="guardian-identity-type">Identification Number Type</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('identity_type')" v-html="getErrors('identity_type')[0]"></p>
            </div>

            <div class="form-floating mb-3">
                <input type="text" required name="guardian-identity-number" id="guardian-identity-number" class="form-control new-input" v-model.trim="form.identity_number">
                <label for="guardian-identity-number" class="form-label">ID or Passport</label>
                <p class="invalid-feedback d-block m-0" v-if="hasError('identity_number')" v-html="getErrors('identity_number')[0]"></p>
            </div>

            <!-- total annual household income -->
            <div class="form-floating mb-3">
                <select class="form-select new-input" name="total-household-income" id="total-household-income" v-model.trim="form.house_hold_annual_income">
                    <option value="null" selected disabled>Please select</option>
                    <option v-for="(option, key) in combinedIncome" :value="combinedIncomeOptions[key]" :key="key">{{ option }}</option>
                </select>

                <label for="total-household-income">Total annual household income</label>

                <p class="invalid-feedback d-block m-0" v-if="hasError('house_hold_annual_income')" v-html="getErrors('house_hold_annual_income')[0]"></p>
            </div>

            <vi-error-flash
                ref="errorFlash"
                v-if="unexpectedError"
                errorMessage="We are unable to save the additional information right now. Please try again later."
            ></vi-error-flash>

            <!-- page buttons -->
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
                <a :href="backUrl" class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-sm': true,
                        'mt-2': true,
                    }"
                    buttonText="Next"
                    :loading="loading"
                ></vi-button-spinner>
            </div>
        </form>
    </div>
</template>

<script>
import document_type_mixin from '../mixins/document_type_mixin';
import error_handling from '../mixins/error_handling';
import scroll_to from '../mixins/scroll_to';

export default {
    name: "LearnerInformationForm",
    mixins: [
        document_type_mixin,
        scroll_to,
        error_handling,
    ],
    props: {
        submitUrl: {
            type: String,
            required: true,
        },
        backUrl: {
            type: String,
            required: true,
        },
        applicationId:{
            type: String,
            required: true,
        },
        ethnicityOptions: {
            type: Array,
            required: true,
        },
        combinedIncomeOptions: {
            type: Array,
            required: true,
        },
        workIndustryOptions: {
            type: Array,
            required: true,
        },
        provinceOptions: {
            type: Array,
            required: true,
        },
        employmentOptions: {
            type: Array,
            required: true,
        },
        curriculum: {
            type: String,
            required: true,
        },
        learnerInformation: {
            type: Object ,
            required: true,
        },
        unemployedConstant: {
            type: String ,
            required: true,
        },
        searchSchoolUrl: {
            type: String ,
            required: true,
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        dialCodeSelected: {
            type: String,
            required: true,
        },
        grade: {
            type: String|null,
            required: true,
        },
        initialSchoolsData: {
            type: Array,
            required: true,
        },
        countries: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: this.learnerInformation,
            errors: {},
            guardianAddressErrors: {},
            learnerAddressErrors: {},
            loading: false,
            unexpectedError: false,
            globalError: false,
            schoolData: [],
            schoolNotFound: false,
            location: null,
            disabledMobile: false,

            isDropdownVisible: false,
            isSchoolFieldsEditable: false,
            idTypeOptions: null,
            loadingSchoolData: false,
            debouncedGetSchool: null,
        };
    },
    created() {
        this.getGeoLocate();

        this.form.mobile_number_country = this.learnerInformation.mobile_number_country ? this.learnerInformation.mobile_number_country : this.dialCodeSelected;

        this.toggleMobileGroup();

        this.idTypeOptions = this.getUploadDocumentTypeOptions(
            this.UploadDocumentTypes.BirthCertificate,
        );

        this.debouncedGetSchool = this.debounce(this.getSchool, 700);

        this.form.identity_number = this.learnerInformation.identity_number;
        this.form.identity_type = this.learnerInformation.identity_type;

        this.form.house_hold_annual_income = this.learnerInformation.house_hold_annual_income;
    },
    computed: {
        activeSchoolData() {
            if (this.schoolData.length > 0) {
                this.initialSchoolsData = []; // Empty initial school data once data is loaded from the API.

                return this.schoolData;
            }

            return this.initialSchoolsData;
        },
        combinedIncome() {
            return this.combinedIncomeOptions.sort((a, b) => {
                const aParts = /^([\d\s]+)\s/gm.exec(a);
                const bParts = /^([\d\s]+)\s/gm.exec(b);

                if (aParts === null) {
                    return 1;
                }

                if (bParts === null) {
                    return -1;
                }

                const aNum = Number(aParts[1].replaceAll(' ', ''));
                const bNum = Number(bParts[1].replaceAll(' ', ''));

                if (aNum === bNum) {
                    return 0;
                }

                return aNum < bNum ? -1 : 1;
            }).map((option, idx, arr) => {
                return /^[A-Za-z\s]+$/gm.test(option) ? option : `R ${option}`;
            });
        },
        ethnicities() {
            let option = 'Other';
            let ethnicities = this.ethnicityOptions;

            const index = ethnicities.indexOf(option);

            if (index > -1) {
                ethnicities.splice(index, 1);
                ethnicities.push(option)
            }

            return ethnicities;
        },
        isNotUnemployed() {
            return this.form.currently_employed && this.form.currently_employed != this.unemployedConstant;
        },
        isGuardianAddressSame() {
            return this.form.is_guardian_address_same == 0 ? false : true;
        },
        getRetainedQuestion() {

            switch(this.grade) {
                case 'Grade 8':
                    return "Has the learner previously been retained in Grade 7 or 8?"
                case 'Grade 9':
                    return "Has the learner previously been retained in Grade 7, 8 or 9?";
                case 'Grade 10':
                    return "Has the learner previously been retained in Grade 10?";
                case 'Grade 11':
                    return "Has the learner previously been retained in Grade 10 or 11?";
                default:
                    return "Has the learner previously been retained in Grade 10, 11 or 12?";
                }
        }
    },
    methods: {
        /**
        * Submit form
        *
        * @return {void}
        */
        async submit() {
            this.loading = true;
            this.globalError = false;
            this.unexpectedError = false;

            this.learnerAddressErrors = {};
            this.errors = null;
            this.guardianAddressErrors = {};

            try {
                var response = await axios.post(this.submitUrl, this.form);

                if (response.status == 200) {
                    if (response.data) {
                        window.location.href = response.data.redirect;
                    }
                } else {
                    this.globalError = response.data;
                }
                this.loading = false;

            } catch(error) {
                const data = error.response.data;

                if (error.response.status === 422) {
                    this.errors = data.errors;
                    this.learnerAddressErrors = this.getAddressError('learner', this.errors);
                    this.guardianAddressErrors = this.getAddressError('guardian', this.errors);

                    this.$nextTick(() => {
                        let firstError = this.firstError();

                        this.scrollIntoView(firstError);
                    });
                } else {
                    this.unexpectedError = true;

                    this.$nextTick(() => {
                        this.scrollIntoView(this.$refs.errorFlash.$el);
                    });
                }

                this.loading = false;
            }
        },
        getAddressError($addressType, errors) {
            if ($addressType == 'learner') {
                return {
                    address_line_1: errors.address,
                    suburb: errors.address_suburb,
                    city: errors.address_city,
                    province: errors.address_province,
                    country: errors.country,
                    postal_code: errors.address_postal_code,
                }
            }

            if ($addressType == 'guardian') {
                return {
                    address_line_1: errors['guardian.address'] ? errors['guardian.address'] : null,
                    suburb: errors['guardian.address_suburb'] ? errors['guardian.address_suburb'] : null,
                    city: errors['guardian.address_city'] ? errors['guardian.address_city'] : null,
                    province: errors['guardian.address_province'] ? errors['guardian.address_province'] : null,
                    country: errors['guardian.country'] ? errors['guardian.country'] : null,
                    postal_code: errors['guardian.address_postal_code'] ? errors['guardian.address_postal_code'] : null,
                }
            }

            return {};
        },
        getSchool(event) {
            const value = event.target.value;

            if (value.length > 2) {
                let url = `${this.searchSchoolUrl}?name=${value}`;

                this.schoolNotFound = false;
                this.loadingSchoolData = true;

                axios.get(url)
                    .then( (res)=> {
                        this.schoolData = res.data;

                        if (!this.schoolData.length) {
                            this.schoolNotFound = true;
                        }
                    }).catch(function (err) {
                        console.error(err);
                    })
                    .finally(() => {
                        this.loadingSchoolData = false;
                    });
            }
        },
        getEmailPhone() {
            let additionalFields = this.schoolData.filter((option) => {
                if(option.schoolname == this.form.schools) {
                    this.form.current_school_name = option.schoolname;
                    return option
                }
            });

            this.form.current_school_email = additionalFields[0]['schoolemail'];
            this.form.current_school_phone_number = additionalFields[0]['schoolphone'];
            this.form.current_school_province = additionalFields[0]['schoolprovince'];
            this.form.current_school = additionalFields[0]['schoolname'];
        },
        debounce(func, timeout = 700) {
            let timer;
            return (...args) => {
                clearTimeout(timer);
                timer = setTimeout(() => { func.apply(this, args); }, timeout);
            };
        },
        getGeoLocate() {
            //do we support geolocation
            if(!("geolocation" in navigator)) {
                console.log(err.message);
                return;
            }
            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                let geolocation = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude
                };
                this.location = new google.maps.Circle({
                    center: geolocation,
                    radius: pos.coords.accuracy
                });
            }, err => {
                console.log(err.message);
            })
        },
        toggleMobileGroup() {
            if (this.form.does_not_have_mobile_number) {
                this.disabledMobile = true;
                this.form.mobile_number = '';
            } else {
                this.disabledMobile = false;
            }
        },
        toggleSchoolDropdown() {
            this.isDropdownVisible = !this.isDropdownVisible;

            if (this.isDropdownVisible) {
                // setTimeout required to allow for input to become visible on the UI
                // can only focus on a form control element that's visible
                setTimeout(() => {
                    this.$refs.dropdownSearchInput.focus();
                }, 20);
            }
        },
        // Guardian chose a school.
        selectSchool(schoolOption) {
            this.toggleSchoolDropdown();

            this.isSchoolFieldsEditable = false;

            // Get selected province option.
            // Array.from(temp1.options).find(option => console.log(option.value))
            let standardizeName = (name) => name.toLowerCase().replace(/[^\w]*/gm, '');
            let province = this.provinceOptions.find(option => standardizeName(option) === standardizeName(schoolOption.schoolprovince));

            this.form.current_school_email = schoolOption.schoolemail;
            this.form.current_school_phone_number = schoolOption.schoolphone;
            this.form.current_school_province = province;
            this.form.current_school = schoolOption.schoolname;
        },
        // School is not in the list.
        selectDefault(event) {
            this.toggleSchoolDropdown();

            this.isSchoolFieldsEditable = true;

            const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

            tooltipElements.forEach((element) => {
                const tooltip = bootstrap.Tooltip.getInstance(element);
                if (tooltip) {
                    tooltip.dispose();
                }
            });

            this.form.current_school_email = null;
            this.form.current_school_phone_number = null;
            this.form.current_school_province = null;
            this.form.current_school = null;
        }
    },
    mounted() {
    },
};
</script>
<style scoped lang="scss">
    @import 'resources/sass/abstract/colours.scss';

    #schoolSearchInput {
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid $grey;

        &:focus {
            outline: none;
        }
    }

    .school-dropdown {
        position: relative;
        display: inline-block;
    }

    .school-dropdown-content {
        display: none;
        position: absolute;
        background-color: $white;
        min-width: 290px;
        border: 1px solid $border-grey;
        z-index: 1;
        border-radius: 5px;
        box-shadow: 6px 8px 16px -3px rgba(0,0,0,0.3);


        @media screen and (max-width: 768px) {
            min-width: 236px;
        }

        &-list {
            max-height: 180px; // Trial and error, allows for 5 items to display.
            overflow-y: auto;
        }
    }

    .school-dropdown-content .dropdown-item {
        color: $black;
        padding: 6px 10px;
        text-decoration: none;
        display: block;
    }

    .school-dropdown .dropdown-item:hover {
        background-color: $lightgrey;
        cursor: pointer;
    }

    .show {
        display: block;
    }

    input[type="checkbox"] {
        -webkit-appearance: initial;
        appearance: initial;
        position: relative;
        background-image: none !important;
    }

    input[type="checkbox"]:checked:after {
        content: "X";
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    select[disabled] {
        // Required for bootstrap tooltips to work on disabled controls.
        pointer-events: none;
    }

    .spinner-border {
        &.school-loader {
            width: 1.25rem; // 20px
            height: 1.25rem; // 20px
            border-width: 0.2rem;
            border-color: $pastel-blue;
            border-right-color: transparent;
        }
    }

    p.form-label {
        color: $pastel-blue;
    }
</style>
