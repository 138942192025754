<template>
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5">Confirm Application Cancellation</h1>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
            <template v-if="success">
                <p>Thank you. Your request to cancel {{ learnerFirstName }}'s application has been successfully submitted to the UCT Online High School Admissions team.</p>

                <p>An admissions consultant will be in contact with you to finalise your decision.</p>

                <p>If you have any questions or if we can assist you further, please don't hesitate to reach out to our Admissions team at <a href="mailto:admissions@uctohs.valentureonline.com">admissions@uctohs.valentureonline.com</a>.</p>
            </template>

            <template v-else>
                <p>We're sad to hear you've changed your mind about {{ learnerFirstName }} joining UCT Online High School.</p>

                <p>Please note that once you confirm this action, the cancellation will be <span class="fw-bold">final</span> and <span class="fw-bold">cannot be reversed</span>. If you decide to re-enroll your learner in the future, you'll need to <span class="fw-bold">submit a new application</span>.</p>

                <p>After cancellation, {{ learnerName }} will no longer be a part of the upcoming intake at UCT Online High School and going forward you will not be billed tuition fees. Any fees paid for this application will not be refunded.</p>
            </template>

            <div
                v-if="hasError('generic')"
                class="alert alert-danger mb-0"
                v-html="getErrors('generic')[0]"
            ></div>
        </div>

        <div class="footer buttons-group">
            <vi-button
                :label="'Close'"
                :theme="'ghost'"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="close-btn"
            ></vi-button>

            <vi-button
                v-if="!success"
                :disabled="success"
                :label="'Submit'"
                :loading="loading"
                :onClick="submit"
            ></vi-button>
        </div>
    </div>
</template>

<script>
import error_handling from '../../mixins/error_handling';
import scroll_to from '../../mixins/scroll_to';


export default {
    name: "CancelApplication",
    mixins: [
        scroll_to,
        error_handling,
    ],
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        learnerName: {
            type: String,
            required: true,
        },
        learnerFirstName: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            // Component State
            success: false,
            errors: null,
            loading: false,
            hasRegistered: false,
        };
    },
    mounted() {
        let modalNode = document.querySelector(`#nscCancelApplication-${this.applicationId}`);

        if (!this.hasRegistered) {
            modalNode.addEventListener('shown.bs.modal', () => {
                this.success = false;
                this.errors = null;
            });

            this.hasRegistered = true;
        }
    },
    methods: {
        submit(event) {
            this.loading = true;
            this.errors = null;

            axios.post(this.submitUrl, {})
                .then(response => {
                    let data = response.data;

                    if (data.hasOwnProperty('isImmediate') && data.isImmediate) {
                        const event = new Event('applications:init');

                        window.document.dispatchEvent(event);
                    }

                    this.success = true;
                })
                .catch(error => {
                    let responseData = error.response.data;

                    if (responseData.hasOwnProperty('errors') && responseData.errors) {
                        this.errors = responseData.errors;
                    } else {
                        this.errors = {
                            generic: 'An error occured',
                        };
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.buttons-group {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .close-btn {
        margin-right: auto;
    }
}

textarea {
    resize: none;
}
</style>
