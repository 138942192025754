<template>
    <div class="module-count-container px-2 px-lg-3 pb-3 pb-md-0 d-flex align-items-center align-content-stretch justify-content-center justify-content-md-start lh-1">
        <p class="text-white mb-0 me-0 me-md-4 py-2 fw-bold">Formal Assessments:</p>
        <div class="d-flex flex-row w-100 w-md-auto justify-content-around">
            <div class="me-0 me-md-4 d-flex align-items-center flex-row flex-grow-1 flex-md-grow-0">
                <p class="completed-block w-100 py-3 py-md-0 px-1 px-md-0 text-white mb-0 d-flex flex-wrap flex-md-nowrap align-items-center flex-column flex-lg-row">
                    <img class="module-icon mb-2 mb-md-0 me-0 me-lg-2" :src="'/portal-data/uct/tick.svg'" alt="Tick Icon">
                    <span class="pt-0 pt-md-1 pt-lg-0">Completed:</span>
                    <span class="count fw-bold flex-grow-1 flex-md-grow-0 completed py-1 py-md-3 px-0 px-md-2 pt-2 mt-0 mt-md-2 mt-lg-0">{{ summary.completed }}</span>
                </p>
            </div>
            <div class="me-0 me-md-4 d-flex align-items-center flex-row flex-grow-1 flex-md-grow-0">
                <p class="outstanding-block w-100 py-3 py-md-0 px-1 px-md-0 text-white mb-0 d-flex flex-wrap flex-md-nowrap align-items-center flex-column flex-lg-row">
                    <img class="module-icon mb-2 mb-md-0 me-0 me-lg-2" :src="'/portal-data/uct/x.svg'" alt="X Icon">
                    <span class="pt-0 pt-md-1 pt-lg-0">Outstanding:</span>
                    <span class="count fw-bold flex-grow-1 flex-md-grow-0 outstanding py-1 py-md-3 px-0 px-md-2 pt-2 mt-0 mt-md-2 mt-lg-0">{{ summary.outstanding }}</span>
                </p>
            </div>
            <div class="d-flex align-items-center flex-row flex-grow-1 flex-md-grow-0">
                <p class="upcoming-block w-100 py-3 py-md-0 px-1 px-md-0 text-white mb-0 d-flex flex-wrap flex-md-nowrap align-items-center flex-column flex-lg-row">
                    <img class="module-icon mb-2 mb-md-0 me-0 me-lg-2" :src="'/portal-data/uct/pencil.svg'" alt="Pencil Icon">
                    <span class="pt-0 pt-md-1 pt-lg-0">Upcoming:</span>
                    <span class="count fw-bold flex-grow-1 flex-md-grow-0 upcoming py-1 py-md-3 px-0 px-md-2 pt-2 mt-0 mt-md-2 mt-lg-0">{{ summary.upcoming }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ModuleCount",
    props: {
        summary: {
            type: Object,
            required: true,
        }
    }
}

</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';
@import 'resources/sass/fonts.scss';

.module-count-container {
    background-color: $blue9A;
    border-radius: 0 15px 0 0;
    flex-wrap: wrap;

    @media (max-width: 767.98px) {
        border-radius: 0;
        // flex-wrap: wrap;
    }

    @media (min-width: 1399.98px) {
        flex-wrap: nowrap;
    }

    p,
    span {
        font-family: $primary-font;
    }

    p {
        font-size: 0.75rem; // 12px

        .count {
            margin-left: 5px;
            border-bottom: 1px solid $white;

            @media (max-width: 767.98px) {
                margin-left: 0;
            }
        }
    }

    .module-icon {
        width: 20px;
    }
}

.completed {
    background-color: $green6C;
}

.completed-block {
    @media (max-width: 767.98px) {
        background-color: $green6C;
    }
}

.outstanding {
    background-color: $red26;
}

.outstanding-block {
    @media (max-width: 767.98px) {
        background-color: $red26;
    }
}

.upcoming {
    background-color: $primary;
}

.upcoming-block {
    @media (max-width: 767.98px) {
        background-color: $primary;
    }
}

</style>