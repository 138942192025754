<template>
    <div>
        <div class="form-floating mb-3">
            <input type="text" :id="`${id}-address-1`" class="form-control new-input" :placeholder="usePlaceholders ? 'Please fill in your address' : ''" :ref="id" v-model="address" @input="$emit('update:address', address)">

            <label :for="`${id}-address-1`" class="form-label">Address line 1</label>

            <p class="invalid-feedback d-block m-0" v-if="hasError('address')" v-html="getErrors('address')[0]"></p>
            <p class="invalid-feedback d-block m-0" v-else-if="hasError('address_line_1')" v-html="getErrors('address_line_1')[0]"></p>
        </div>

        <!-- address line 2 -->
        <div class="form-floating mb-3">
            <input type="text" :id="`${id}-address-2`"  class="form-control new-input" :placeholder="usePlaceholders ? 'Please fill in your address' : ''" v-model="address_line_2" @input="$emit('update:address_line_2', address_line_2)">

            <label :for="`${id}-address-2`" class="form-label">Address line 2</label>

            <p class="invalid-feedback d-block m-0" v-if="hasError('address_line_2')" v-html="getErrors('address_line_2')[0]"></p>
        </div>

        <!-- suburb -->
        <div class="form-floating mb-3">
            <input type="text" :id="`${id}-suburb`" class="form-control new-input" :placeholder="usePlaceholders ? 'Suburb' : ''" v-model="address_suburb"  @input="$emit('update:address_suburb', address_suburb)">

            <label :for="`${id}-suburb`" class="form-label">Suburb</label>

            <p class="invalid-feedback d-block m-0" v-if="hasError('suburb')" v-html="getErrors('suburb')[0]"></p>
        </div>

        <!-- city -->
        <div class="form-floating mb-3">
            <input type="text" :id="`${id}-address-city`" class="form-control new-input" :placeholder="usePlaceholders ? 'City' : ''" v-model="address_city" @input="$emit('update:address_city', address_city)">

            <label :for="`${id}-address-city`" class="form-label">City</label>

            <p class="invalid-feedback d-block m-0" v-if="hasError('city')" v-html="getErrors('city')[0]"></p>
        </div>

        <!-- country -->
        <div class="form-floating mb-3">
            <select class="form-select new-input" :id="`${id}-address-country`" v-model="country" @change="countryChangeHandler(country)">
                <optgroup v-for="(countryOption, idx) in countries" :label="idx" :key="idx">
                    <option v-for="(option, isoCode) in countryOption" :value="isoCode" :key="isoCode" :selected="country === isoCode">{{ option }}</option>
                </optgroup>
            </select>

            <label :for="`${id}-address-country`" class="form-label">Country</label>

            <p class="invalid-feedback d-block m-0" v-if="hasError('country')" v-html="getErrors('country')[0]"></p>
        </div>

        <!-- province -->
        <div class="form-floating mb-3">
            <select
                v-if="country === 'ZA'"
                class="form-select new-input"
                :id="`${id}-address-province`"
                v-model="address_province"
                @change="$emit('update:address_province', address_province)"
            >
                <option v-for="(province) in zaProvinceOptions" :value="province" :key="province" :selected="address_province === province">{{ province }}</option>
            </select>

            <input v-else type="text" :id="`${id}-address-province`" class="form-control new-input" v-model="address_province" :placeholder="usePlaceholders ? 'Province' : ''" @input="$emit('update:address_province', address_province)">

            <label :for="`${id}-address-province`" class="form-label">Province / Region</label>

            <p class="invalid-feedback d-block m-0" v-if="hasError('province')" v-html="getErrors('province')[0]"></p>
        </div>

        <!-- postcode -->
        <div class="form-floating mb-3">
            <input type="text" :id="`${id}-address-postcode`" class="form-control new-input" :placeholder="usePlaceholders ? 'Postcode' : ''" v-model="address_postal_code" @input="$emit('update:address_postal_code', address_postal_code)">
            <label :for="`${id}-address-postcode`" class="form-label">Postcode</label>
            <p class="invalid-feedback d-block m-0" v-if="hasError('postal_code')" v-html="getErrors('postal_code')[0]"></p>
        </div>
    </div>
</template>
<script>
import error_handling from '../mixins/error_handling';
export default {
    name: "ViAddressFieldGroup",
    mixins: [
        error_handling,
    ],
    props: {
        errors: {
            type: Object,
            default: null
        },
        addressFields: {
            type: Object ,
            required: true,
        },
        id: {
            type: String,
            required:true
        },
        locationBounds: {
            type: Object,
            default: null
        },
        usePlaceholders: {
            type: Boolean,
            default: true
        },
        countries: {
            type: Object,
            required: true,
        },
        provinces: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            autoComplete: null,
            address: this.addressFields.address,
            address_line_2: this.addressFields.address_line_2,
            address_suburb: this.addressFields.address_suburb,
            address_city: this.addressFields.address_city,
            address_province: this.addressFields.address_province,
            address_postal_code: this.addressFields.address_postal_code,
            country: this.getCountryIsoCode(this.addressFields.country),
        }
    },
    mounted() {
        const options = {
            types: ["address"],
            fields: ["address_components"],
            bounds: this.locationBounds
        };

        this.autoComplete = new google.maps.places.Autocomplete(
            this.$refs[this.id],
            options
        );

        google.maps.event.addListener(this.autoComplete, "place_changed", () => {

            let place = {
                address_components: [],
                ...this.autoComplete.getPlace()
            }

            this.populateAddress(place);
        });
    },
    beforeDestroy() {
        if (this.autoComplete) {
            google.maps.event.clearInstanceListeners(this.autoComplete);
        }
    },
    methods: {
        populateAddress(place) {
            const mapping = {
                route: {'address': this.address },
                sublocality_level_1: {'address_suburb': this.address_suburb},
                sublocality_level_2: {'address_suburb': this.address_suburb},
                locality: {'address_city': this.address_city},
                administrative_area_level_1: {'address_province': this.address_province },
                postal_code: {'address_postal_code': this.address_postal_code },
                country: {'country': this.country},
            }

            for (const type in mapping) {
                let addresskey = this.getFirstElementFromObject(mapping[type], 'key');

                this[addresskey] = "";

                this.$emit(`update:${addresskey}`, "");
            }

            let placeComponentValue = null;
            let street = null;

            // Set Country and Province.
            const countryComponent = place.address_components.find(component => component.types.includes('country'));
            const provinceComponent = place.address_components.find(component => component.types.includes('administrative_area_level_1'));

            this.setCountryAndProvince(countryComponent, provinceComponent);

            place.address_components.forEach(component => {
                if (component.types.includes('country') || component.types.includes('administrative_area_level_1')) {
                    // Skip. We manually set these.
                    return;
                }

                component.types.forEach((type) => {
                    if (type == 'street_number') {
                        street = component.long_name;
                    }

                    if (mapping.hasOwnProperty(type)) {
                        placeComponentValue = component.long_name;

                        if (type == 'route') {
                            placeComponentValue = this.createAddressLineFirst(street, placeComponentValue);
                        }

                        let addresskey = this.getFirstElementFromObject(mapping[type],'key');

                        this[addresskey] = placeComponentValue;

                        this.$emit(`update:${addresskey}`, placeComponentValue);
                    }
                })
            });
        },
        cleanUpName(name) {
            if (!name || typeof name !== 'string') {
                return name;
            }

            return name.toLowerCase().replace(/\W/gmi, '');
        },
        setCountryAndProvince(countryComponent, provinceComponent) {
            this.country = countryComponent.short_name;

            let selectedProvince = this.provinces.filter(province => this.cleanUpName(province) === this.cleanUpName(provinceComponent.long_name));

            this.address_province = selectedProvince.length ? selectedProvince.shift() : null;

            this.$emit('update:address_province', this.address_province);
            this.$emit('update:country', this.getCountryName(this.country));
        },
        getCountryName(countryIsoCode) {
            let countries = {
                ...this.countries['South Africa and Borders'],
                ...this.countries['World'],
            };

            return countries[countryIsoCode];
        },
        getCountryIsoCode(countryName) {
            let countries = {
                ...this.countries['South Africa and Borders'],
                ...this.countries['World'],
            };

            return Object.keys(countries).find(key => this.cleanUpName(countries[key]) === this.cleanUpName(countryName));
        },
        createAddressLineFirst(street, route) {
            return street ? `${street} ${route}` : `${route}`;
        },
        countryChangeHandler(isoCode) {
            this.$emit('update:country', this.getCountryName(isoCode));
        },
        getFirstElementFromObject(obj,$type = null) {
            if($type == 'key'){
                return Object.keys(obj)[0];
            }

            return Object.values(obj)[0];
        }
    },
    computed: {
        zaProvinceOptions() {
            return this.provinces.filter(province => province !== 'Outside of South Africa');
        }
    },
    watch: {
        locationBounds: function (val) {
            this.autoComplete.setBounds(val);
        },
        country: function (newVal, oldVal) {
            if (oldVal === 'ZA') {
                this.address_province = null;
            }
        }
    },
};
</script>