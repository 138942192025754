<template>
    <div class="vi-account-input" :class="{'form-floating': float}">
        <input
            class="form-control new-input ps-2"
            :class="{'error': error}"
            type="text"
            :disabled="disabled"
            :id="id"
            :value="value"
            @input="$emit('input', $event.target.value)"
            :required="required"
            :name="name"
            :placeholder="placeholder"
        />

        <label  :for="id" class="ps-2">{{ label }}</label>

        <div class="invalid-feedback d-block" v-if="error">
            {{ error ? error : `${label} is required.` }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ViAccountInput',
    props: {
        error: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
            required: false,
        },
        name: {
            type: String,
            default: null,
            required: false,
        },
        float: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            id: null,
        };
    },
    mounted() {
        this.id = `account-input-${this._uid}`;
    },
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.invalid-feedback {
    display: none;
}

input, input:focus {
    outline: none;
    border: none;
    box-shadow: none;
    color: $uctDarkBlue;
    border: none;
    background-color: $white;
    border-radius: 0;
    padding-left: 10px;
    transition: 0.3s ease;
    border-radius: 50rem;

    &::placeholder {
        color: $white;
    }

    &:disabled, &[readonly] {
        outline: none;
        border: none;
        box-shadow: none;
        color: $white;
        background-color: transparent;
        border-radius: 0;
        transition: 0.3s ease;
    }

    &.error:invalid + .invalid-feedback {
        color: $white;
        display: block;
    }
}
</style>