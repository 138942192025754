<template>
  <div class="vi-file-selector">

    <template v-if="isStatus(Status.Initial) || isStatus(Status.Saving)">
      <div class="dropbox" :class="{'disabled': disabled, 'uploaded' : isUploaded, 'rejected' : isRejected, 'accepted' : isAccepted}">
        <input
          type="file"
          :multiple="multiple"
          :name="uploadFieldName"
          :disabled="disabled || isStatus(Status.Saving) || isAccepted"
          @change="onFileChange"
          :accept="acceptedFileTypes"
          class="input-file"
          :required="required"
        >

        <p v-if="isUploaded" class="px-2 mt-3 mb-0">You have already uploaded this file. You can replace the uploaded file by uploading a new one here.</p>

        <template v-if="isRejected">
          <p class="px-2 mt-3 mb-0">Your uploaded file has been rejected</p>
          <p class="fw-bold px-2 mb-0">Reason: {{ rejectedReason }}</p>
          <p class="px-2 mb-0">Please upload a new file</p>
        </template>

        <p v-if="isAccepted" class="px-2 mt-3 mb-0">Your upload file has been accepted. You do not need to upload a new one.</p>

        <p v-if="isStatus(Status.Initial) && !isAccepted">
          <slot name="uploadText">As per the above requirements, drag your file(s) here to begin or click to browse.</slot>
        </p>

        <p v-if="isStatus(Status.Saving)">
          {{ uploadingText ? uploadedText : 'Uploading file(s)...' }}
        </p>
      </div>
    </template>

    <!--SUCCESS-->
    <template v-if="isStatus(Status.Success)">
      <div class="alert mb-3 alert-success text-center" role="alert">
        <p class="mb-0 w-100">{{ uploadedText ? uploadedText : successMsg }}</p>

        <a class="alert-link w-100" href="javascript:void(0)" @click="reset()">Upload again</a>
      </div>
    </template>

    <!--FAILED-->
    <template v-if="isStatus(Status.Failed)">
      <div class="alert mb-3 alert-danger text-center" role="alert">
        <p class="mb-0 w-100">{{ uploadError }}</p>

        <a class="alert-link w-100" href="javascript:void(0)" @click="reset()">Retry</a>
      </div>
    </template>

    <div v-if="showGallery && currentFileList.length > 0 && isStatus(Status.Success)" class="image-gallery">
      <embed
        v-for="(file, key) in currentFileList"
        :key="key"
        :src="createObjectUrl(file)"
        :type="file.type"
        class="image-gallery-slide"
      >
    </div>

  </div>
</template>

<script>
const Status = Object.freeze({
  Initial: 'Initial',
  Saving: 'Saving',
  Success: 'Success',
  Failed: 'Failed',
});

export default {
  name: "ViFileSelector",
  props: {
    uploadFieldName: {
      type: String,
      required: true,
    },
    handleUpload: {
      type: Function,
      required: true,
    },
    // Users can control whether a reset is allowed or not by returning a boolean
    // value. Returning nothing i.e. undefined, it is assumed that the reset is approved
    // and can continue.
    onBeforeReset: {
      type: Function,
      default: () => true,
    },
    onError: {
      type: Function,
    },
    onChange: {
      type: Function,
    },
    onReset: {
      type: Function,
    },
    acceptedFileTypes: {
      type: String,
      default: 'image/*',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFileSize: {
      type: Number,
      default: 2_000_000, // 2MB
    },
    showGallery: {
      type: Boolean,
      default: true,
      required: false,
    },
    uploadingText: {
      type: String,
      required: false,
      default: null,
    },
    uploadedText: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    uploadStatus: {
      type: String,
      default: null,
    },
    rejectedReason: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // Populated if upload failed. Replaces input.
      uploadError: null,
      currentStatus: null,
      successMsg: null,
      currentFileList: [],

      // Make status properties available to template.
      Status,
    }
  },
  computed: {
    isUploaded() {
      return this.uploadStatus === 'Uploaded';
    },
    isRejected() {
      return this.uploadStatus === 'Rejected';
    },
    isAccepted() {
      return this.uploadStatus === 'Accepted';
    },
  },
  methods: {
    isStatus(status) {
      this.isValidStatus(status);

      return this.currentStatus === status;
    },
    /**
     * Determines whether execution should be allowed to continue based on the
     * return value of a lifecycle hook.
     * Any value other than a boolean will be handled as "false".
     *
     * @param {boolean|undefined} result Resulting value of a lifecycle hook
     */
    shouldHaltExecution(result) {
      if (typeof result !== 'boolean') {
        return false;
      }

      return !result;
    },
    reset() {
      if (this.shouldHaltExecution(this.onBeforeReset())) {
        return;
      }

      // reset form to initial state
      this.setStatus(Status.Initial);
      this.successMsg = 'File(s) uploaded successfully.';
      this.uploadError = null;
      this.currentFileList = [];

      if (this.onReset) {
        this.onReset();
      }
    },
    save(formData) {
      // upload data to the server
      this.setStatus(Status.Saving);

      this.handleUpload(formData)
        .then(successMsg => {
          this.successMsg = successMsg;
          this.setStatus(Status.Success);
        })
        .catch(errorMsg => {
          this.uploadError = errorMsg;
          this.setStatus(Status.Failed);
        });
    },
    filesChange(fieldName, fileList) {
      const formData = new FormData();

      if (!fileList.length) {
        return;
      }

      this.currentFileList = Array.from(fileList);

      for (const file of Array.from(fileList)) {
        if (this.isFileSizeTooLarge(file)) {
          this.setStatus(Status.Failed);
          this.uploadError = 'File size too large.';
          return;
        }

        formData.append(fieldName, file, file.name);
      }

      // save it
      this.save(formData);
    },
    setStatus(newStatus) {
      this.isValidStatus(newStatus);

      this.currentStatus = newStatus;
    },
    isFileSizeTooLarge(file) {
      return file.size > this.maxFileSize;
    },
    isValidStatus(status) {
      if (!Object.keys(Status).includes(status)) {
        throw new Error(`${status} is not a valid upload Status.`);
      }

      return true;
    },
    createObjectUrl(file) {
      return URL.createObjectURL(file);
    },
    onFileChange(event) {
      this.filesChange(event.target.name, event.target.files);
      this.fileCount = event.target.files.length;

      if (this.onChange) {
        this.onChange(event);
      }
    }
  },
  mounted() {
    this.reset();
  },

}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstract/colours.scss';
  .dropbox {
    outline: 2px dashed $light-grey-60; /* the dash box */
    outline-offset: -10px;
    background-color: $grey-CF;
    color: $pastel-dark-grey;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
    transition: background-color 250ms linear;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    .dropbox.disabled & {
      cursor: not-allowed;
    }
  }

  .accepted {
    background-color: $dark-green-1;
    color: $white;
    border-color: $dark-green-1;
  }

  .rejected {
    background-color: $red;
    color: $white;
    border-color: $red;
  }

  .uploaded {
    background-color: $bluef0;
    color: $white;
    border-color: $bluef0;
  }

  .uploaded, .rejected {
    .input-file {
      height: 100%;
    }
  }

  .dropbox:not(.disabled):hover {
    background-color: darken($grey-CF, 10%); /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .alert {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .image-gallery {
    display: flex;
    justify-content: center;

    &-slide {
      width: 50%;
    }
  }

</style>