<template>
    <div class="text-start guardian-loging-form-container mx-auto">
        <form @submit.prevent="submit" class="text-start py-4 py-md-0 px-3 px-md-0" id="form">
            <div class='mb-5'>
                <h4 class="h4 text-center mb-3">Submit Learner Identity Document, Passport or Birth Certificate</h4>

                <h2 class="h6 mb-2 text-center">Please submit the learner's identity document, passport or birth certificate in the box below.</h2>

                <h2 class="h6 mb-2 text-center">If you're having trouble, please ensure the following:</h2>

                <ul class="body-txt-small">
                    <li>The birth certificate contains the learner's ID number.</li>
                    <li>The ID number is clearly visible on the document. A high quality or scanned copy or photograph is preferable.</li>
                    <li>The document text is oriented to read from left to right.</li>
                    <li>The document is submitted in one of the following formats: PNG, JPEG, PDF.</li>
                    <li>The document is less than 8MB in size.</li>
                </ul>
            </div>
            <div class='text-center'>
                <vi-file-selector
                    upload-field-name="file"
                    :handle-upload="handleUpload"
                    :max-file-size="8000000"
                    accepted-file-types="image/jpeg, image/png, .pdf"
                ></vi-file-selector>
                <p class="invalid-feedback d-block" v-if="errorMessage">{{ errorMessage }}</p>
            </div>

            <div class="mt-5">
                <p class="body-txt-small text-center">If you have any queries or concerns, please contact the Student Records Team at <a href="mailto:sro@uctohs.valentureonline.com" target="_blank" class="mt-1 d-block text-decoration-none">sro@uctohs.valentureonline.com</a></p>
            </div>

            <vi-error-flash
                ref="errorFlash"
                v-if="unexpectedError"
                errorMessage="We are unable to upload your document right now. Please try again later."
            ></vi-error-flash>

            <div class="mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap w-100 form">
                <a :href="backUrl"  class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-sm': true,
                        'mt-2': true,
                        'mx-2': true,
                        'mx-sm-0': true,
                    }"
                    buttonText="Next"
                    :loading="loading"
                ></vi-button-spinner>
            </div>
        </form>
    </div>
</template>


<script>
import error_handling from '../mixins/error_handling';
import scroll_to from '../mixins/scroll_to';

export default {
    name: "DocumentVerificationUpload",
    mixins: [
        scroll_to,
        error_handling,
    ],
    components: {},
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        backUrl: {
            type: String,
            required: true,
        },
        uploadUrl: {
            type: String,
            required: true,
        },
        isBirthdateVerified:{
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            unexpectedError: false,
            errorMessage : null,
            isFileUploaded : false,
            isUploadVerified : false,
        }
    },
    computed: {
        isBirthCertificateVerified() {
            return false;
        }
    },
    methods: {
        async handleUpload(formData) {
            this.isFileUploaded = true;
            this.errorMessage = null;
            this.loading = true;
            this.unexpectedError = false;

            formData.append('applicationId', this.applicationId);

            return axios.post(this.uploadUrl, formData).then(response => {
                this.loading = false;

                this.isUploadVerified = response.data.isUploadVerified;

                return response.data.message;
            }).catch(error => {
                this.loading = false;
                this.isFileUploaded = false;

                if (error.response.data.success == false) {
                    throw new Error(error.response.data.message);
                }

                this.unexpectedError = true;

                this.$nextTick(() => {
                    this.scrollIntoView(this.$refs.errorFlash.$el);
                });
            });
        },
        submit() {
            if (!this.isFileUploaded) {
                this.errorMessage = "Nothing has been uploaded. Please upload a copy of the learner's Identity document.";

                this.$nextTick(() => {
                    let firstError = this.firstError();

                    if (firstError) {
                        this.scrollIntoView(firstError);
                    }
                });

                return;
            }

            this.loading = true;

            axios.post(this.submitUrl,{
                'isBirthdateVerified' : this.isUploadVerified
            }).then(response => {
                this.loading = false;
                window.location.href = response.data.redirect;
            }).catch(error => {
                this.loading = false;

                let data = error.response.data;

                // Only set error message if one is returned from server.
                if (data.hasOwnProperty('message') && data.message) {
                    this.errorMessage = error.response.data.message;
                }

                this.unexpectedError = true;

                this.$nextTick(() => {
                    this.scrollIntoView(this.$refs.errorFlash.$el);
                });
            });
        }
    },
};
</script>
