<template>
    <div class="guardian-loging-form-container mx-auto w-100">
        <h4 class="mb-2 text-pastel-blue">Forgotten your Password?</h4>
        <p class="text-black-50 mb-4">Did you forget your password? Fill in your email address and we'll send you a link to reset your password.</p>
        <p class="mb-4 text-black-50">Please double check that your email address is correct before submitting.
         If you have an account with us, you will receive a password reset link.</p>
        <form @submit.prevent="submit" class="text-start" id= "form">
            <div class="form-floating mb-0">
                <input type="email" class="form-control new-input" id="emailInput" placeholder="Email Address"
                    v-model.trim="form.email" />
                <label for="emailInput">Email Address</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['email']" v-html="errors['email'][0]">
                </p>
            </div>
            <vi-error-flash
                v-if="unexpectedError"
                :errorMessage="errorMessage"
            ></vi-error-flash>
            <div class="text-left w-100">
                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-lg': true,
                        'mt-4': true,
                    }"
                    buttonText="Send Reset Link"
                    :loading="loading"
                ></vi-button-spinner>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: "ForgotPasswordForm",
        data: () => ({
            form: {
                email: null
            },
            errors: null,
            unexpectedError: null,
            loading: false,
            errorMessage: '',
        }),
        props: [
            'postRoute',
            'redirectRoute'
        ],
        mounted() {
            this.resetErrorMessage();
        },
        methods: {
            resetErrorMessage() {
                this.errorMessage = 'We are unable to set your password right now. Please try again later.';
            },
            async submit() {
                this.loading = true;
                this.unexpectedError = false;
                this.resetErrorMessage();

                try {
                    let response = await axios.post(this.postRoute, this.form);
                    if (response.status === 200){
                        location.href = this.redirectRoute;
                    }
                } catch (error) {
                    const data = error.response.data;

                    if (error.response.status === 422) {
                        if (data.hasOwnProperty('errors')) {
                            this.errors = data.errors;
                        }

                        if (data.hasOwnProperty('error')) {
                            this.errorMessage = data.error;
                            this.unexpectedError = true;
                        }
                    } else {
                        this.unexpectedError = true;
                    }

                    this.loading = false
                }
            },
        },
    };
</script>