export default {
    data() {
        return {
            UploadDocumentTypes: Object.freeze({
                BirthCertificate : 'Birth Certificate',
                Passport : 'Passport',
                SouthAfricanID : 'South African ID'
            }),
        };
    },
    methods: {
        getUploadDocumentTypeOptions(...exclude) {
            return Object.keys(this.UploadDocumentTypes).map(key => {
                if (exclude.includes(key) || exclude.includes(this.UploadDocumentTypes[key])) {
                    return null;
                }

                return {
                    text: this.UploadDocumentTypes[key],
                    value: this.UploadDocumentTypes[key],
                };
            }).filter(value => !!value);
        },
    }
}
