<template>
    <div v-if="password">
        <div class="progress">
            <div class="progress-bar text-light fw-bold" :class="strengthClass" role="progressbar" :aria-valuenow="currentStrengthValue" aria-valuemin="0" aria-valuemax="100">{{ this.strengthLabel }}</div>
        </div>
    </div>
</template>


<script>
    export default {
        name : 'ViPasswordStrengthMeter',
        data() {
            return {
                patterns: {
                    medium: /^.{8,}$/,
                    strong: /^(?=.*[a-zA-Z])(?=.*[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
                    reallyStrong: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{12,}$/
                }
            };
        },
        props : {
            password : {
                type : String,
                default : null,
                required : true
            }
        },
        computed: {
            strengthLabel() {
                if (this.password && this.password.length < 8) return "Weak";
                if (this.password && this.patterns.medium.test(this.password) && !this.patterns.strong.test(this.password)) return "Medium";
                if (this.password && this.patterns.strong.test(this.password) && !this.patterns.reallyStrong.test(this.password)) return "Strong";
                if (this.password && this.patterns.reallyStrong.test(this.password)) return "Really Strong";
                return "";
            },
            strengthClass() {
                return {
                    weak: this.strengthLabel === "Weak",
                    medium: this.strengthLabel === "Medium",
                    strong: this.strengthLabel === "Strong",
                    reallyStrong: this.strengthLabel === "Really Strong"
                };
            },
            currentStrengthValue() {
                if (this.strengthLabel === 'Weak') {
                    return 25;
                } else if (this.strengthLabel === 'Medium') {
                    return 50;
                } else if (this.strengthLabel == 'Strong') {
                    return 75;
                } else if (this.strengthLabel === 'Really Strong') {
                    return 100;
                }

                return 0
            }
        }
    };
</script>
<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';

    .progress {
        height: 35px;
    }
    .weak {
        background-color: $red;
        width: 25%;
    }

    .medium {
        background-color: $yellow;
        width: 50%;
    }

    .strong {
        background-color: $green;
        width: 75%;
    }

    .reallyStrong {
        background-color: $dark-green-1;
        width: 100%;
    }
</style>