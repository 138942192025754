<template>
   <div class="position-fixed bottom-0 end-0 p-3 custom-toast" style="z-index: 9999">
      <div id="liveToast" class="toast" :class="[showing, alertType]" role="alert" aria-live="assertive" aria-atomic="true">
         <div class="toast-header d-flex justify-content-between">
            <h4 class="mb-0">{{ alertType == 'danger' ? 'Alert!' : 'Success!' }}</h4>
            <button v-on:click="closeAlert" type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
         </div>

         <div class="toast-body">
            <template v-if="isProgressionApplication">
                <p class="mb-2">Thank you for making your provisional subject selections!</p>
                <p class="mb-3">You will hear from us via email regarding more details about the upcoming academic year.</p>
            </template>
            <template v-if="isApplicationFailedIdCheck">
                <p class="mb-2">This application has been closed for security reasons, please reach out to our <a class="text-white" href="mailto:admissions@uctohs.valentureonline.com">admissions team</a> for assistance.</p>
            </template>
            <template v-else-if="!isProgressionApplication">
               <p class="mb-2">Thank you! You have now completed your application.</p>
               <p class="mb-3">After we have reviewed it, you will receive an email confirming the outcome.</p>
            </template>
         </div>
      </div>
   </div>
</template>

<script>
import helperMixin from "../mixins/helper_mixin";
export default {
    name: "SuccessAlert",
    data() {
         return {
            showing: 'hide',
            isProgressionApplication : false,
            type: 'provisional',
            alertType: '',
            isApplicationFailedIdCheck: false,
            message: null
         }
    },
    mixins: [helperMixin],
    methods: {
        closeAlert: function () {
            this.showing = 'hide';
        }
    },
    mounted() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);

      if (this.getCookie('success-alert')) {
         if (params.has('confirmation-message')) {
            this.showing = 'show';
         }

         if (params.has('type')) {
            this.type = params.get('type');
         }

         if (params.has('progression-confirmation-message')) {
            this.showing = 'show';
            this.isProgressionApplication = true;
         }

         if (params.has('application-failed-id-check')) {
            this.showing = 'show';
            this.isApplicationFailedIdCheck = true;
            this.alertType = 'danger';
         }

         this.deleteCookie('success-alert');
      }
   }
}
</script>
