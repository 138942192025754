<template>
    <div>
        <span v-for="(iconSource, index) in iconSources"
            :key="`icon-${index}`"
            :class="`floating-character-all floating-character${index > 0 ? index + 1 : ''}-slower`"
        >
            <img :src="iconSource" alt="Icon" width="40">
        </span>
    </div>
</template>

<script>

export default {
    name: "FloatingIconsBackground",
    data: () => ({
        iconSources: [
            '/portal-data/uct/graduation-hat-blue.png',
            '/portal-data/uct/atom-blue.png',
            '/portal-data/uct/paint-brush-blue.png',
            '/portal-data/uct/computer-blue.png',
            '/portal-data/uct/open-book-blue.png',
            '/portal-data/uct/globe.png',
            '/portal-data/uct/test-blue.png'
        ]
    }),
}

</script>