<template>
    <div class="modal fade" :id="`guardianteachersModal-${studentId}`" tabindex="-1"
        aria-labelledby="teachersModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">

                    <nav>
                        <div class="nav nav-tabs modal-tabs pb-0 pb-md-3 flex-nowrap border-bottom-0" :id="`nav-tab-${studentId}`" role="tablist">
                            <h5
                                class="active nav-link modal-tab-header ps-0 ps-md-3 pt-0 pt-md-3"
                                :id="`senior-teachers-tab-${studentId}`"
                                data-bs-toggle="tab"
                                :data-bs-target="`#senior-teachers-${studentId}`"
                                type="button"
                                role="tab"
                                :aria-controls="`senior-teachers-${studentId}`"
                                aria-selected="false"
                            >My Subject Specialists</h5>
                        </div>
                    </nav>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                </div>

                <div class="modal-body">
                    <div class="tab-content" id="nav-tabContent">

                        <div class="tab-pane fade show active pt-3" :id="`normal-teachers-${studentId}`" role="tabpanel" :aria-labelledby="`normal-teachers-tab-${studentId}`">

                            <div v-for="teacher in teachers.student" class="teacher-block mb-3">

                                <div class="d-flex flex-row align-items-center justify-content-between w-100">
                                    <h5 class="mc-2">{{ teacher.subject }}</h5>
                                </div>

                                <div class="col mb-2">
                                    <div class="mb-1 d-flex flex-row justify-content-start align-items-center flex-wrap text-decoration-none w-100">

                                        <div class="position-relative trigger-large-img d-inline-block">

                                            <img :src="teacher.image" alt="User Icon" width="25" class="rounded-circle xs-user-photo">

                                            <div class="large-img-hover large-img-hover-2 border shadow rounded">
                                                <img :src="teacher.image" alt="User Icon" width="100">
                                            </div>
                                        </div>

                                        <span class="m-0 ps-3">{{ teacher.name }}</span>
                                    </div>
                                </div>

                            </div>

                            <div v-if="!teachers.student.length" class="teacher-block mb-3">
                                Something went wrong while trying to find this information for you. Sorry about that! Please try again a bit later. If the error persists, please <a href="mailto:techsupport@uctohs.valentureonline.com" class="text-decoration-underline" target='_blank'>contact our support team.</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Teachers",

        data: () => ({
            loading: false,
        }),

        props: {
            studentName: {
                type: String,
                required: true,
                default: null,
            },
            studentId: {
                type: String,
                required: true,
                default: null
            },
            teachers: {
                type: Object,
                required: true,
                default: () => ({}),
            },
            isCaie :{
                type: Boolean,
                default: false,
            }

        },
    }
</script>
