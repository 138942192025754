<template>
    <form @submit.prevent="submitAddress" ref="form" class="vi-account-address">
        <vi-address-group
            :guardian-user="guardianUser"
            v-on:address-updated="onAddressChanged"
            :disabled="isDisabled"
            class="rounded-white-input"
            :provinces="provinces"
            :countries="countries"
        ></vi-address-group>

        <button type="submit" @click="onButtonClickHandler" class="btn-uct px-4 py-2 rounded-pill text-white edit-btn mt-2">
            <span v-if="!loading">{{ isDisabled ? 'Edit' : 'Save' }}</span>
            <div v-if="loading" class="spinner-border text-light spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </button>
    </form>
</template>

<script>
import axios from 'axios';
import ViAddressGroup from './ViAddressGroup.vue';

export default {
    components: {
        ViAddressGroup,
    },
    name: 'ViAccountAddress',
    props: {
        guardianUser: {
            type: Object,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        countries: {
            type: Object,
            required: true,
        },
        provinces: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            address: null,
            isDisabled: true,
            loading: false,
        };
    },
    mounted() {
        const inputs = this.$refs.form.querySelectorAll("input, select, textarea");

        inputs.forEach(input => {
            input.addEventListener(
                "invalid",
                event => {
                    input.classList.add("error");
                },
                false
            );
        });
    },
    methods: {
        submitAddress() {
            this.loading = true;

            axios.post(this.url, this.address)
                .then(success => {
                    this.toggleDisable(true);
                    this.$emit('success', 'Address has been successfully updated');
                })
                .catch(error => {
                    let errors = error.response.data.hasOwnProperty('errors') ? error.response.data.errors : {};

                    this.toggleDisable(false);
                    this.$emit('error', this.getFirstError(errors));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getFirstError(errors) {
            let messages = Object.values(errors).reduce((arr, messages) => arr.concat(messages), []);

            if (messages.length <= 0) {
                return 'An error occured while trying to update your address.';
            }

            return messages.shift();
        },
        onAddressChanged(newAddress) {
            this.address = newAddress;
        },
        onButtonClickHandler(event) {
            if (this.isDisabled) {
                event.preventDefault();
                event.stopPropagation();

                this.toggleDisable();
            }

            // toggle tooltip
            $('.addressTooltip').tooltip('toggleEnabled')
        },
        toggleDisable(intendedValue = null) {
            this.isDisabled = intendedValue ?? !this.isDisabled;
        },
    },
}
</script>

<style lang="scss" scoped>
.spinner {
    width: 1.5rem;
    height: 1.5rem;
}
</style>