<template>
    <div>
        <h4 class="fw-bold billing-heading">Invoice history:</h4>

        <p>Please select a learner below to view invoice history:</p>

        <subscription-invoice-status
            :subscriptions="subscriptions"
            @selectedSubscription="getSelectedStudentSubscription"
        ></subscription-invoice-status>

        <invoice-table
            v-if="invoices"
            :invoices="invoices"
            :subscriptionId="selectedSubscriptionId"
            :download-invoice-url="downloadInvoiceUrl"
            :payment-url="paymentUrl"
            :can-pay="canPay"
            @show-add-payment-source="showAddPaymentSource"
            @show-payment-failure="showPaymentFailure"
            @refresh-invoices="refreshInvoices"
        ></invoice-table>
    </div>
</template>

<script>
    export default {
        name: 'InvoiceHistorySection',
        data() {
            return {
                selectedSubscriptionId: '',
            };
        },
        props: {
            subscriptions: {
                type: Array,
                required: true,
            },
            invoices: {
                type: Array,
                required: true,
            },
            downloadInvoiceUrl: {
                type: String,
                required: true,
            },
            paymentUrl: {
                type: String,
                required: true,
            },
            canPay: {
                type: Boolean,
                required: true,
            },
        },
        methods: {
            getSelectedStudentSubscription(subscription) {
                if (subscription) {
                    this.selectedSubscriptionId = subscription.id;
                }
            },
            showAddPaymentSource() {
                this.$emit('show-add-payment-source', true);
            },
            showPaymentFailure() {
                this.$emit('show-payment-failure', true);
            },
            refreshInvoices() {
                this.$emit('refresh-invoices', true);
            },
        },
        computed: {
            filteredInvoices() {
                return this.invoices.filter(invoice => invoice.subscriptionId === this.subscriptionId);
            },
        }
    };
</script>

<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';
</style>
