<template>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" :id="`falSubjectExisting-${studentId}`" tabindex="-1" role="dialog" :aria-labelledby="`falSubjectExistingLabel-${studentId}`" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header px-5">
                    <h1 class="modal-title h5" id="requestFalSubjectChange">Subject Change limit reached</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center px-5">
                    <p>We regret to inform you that the limit for subject change requests has been reached for this academic year, and we are unable to process any further changes.</p>
                    <p>For additional information on subject changes and submissions dates please visit our info hub article <a href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE" target="_blank">here.</a></p>
                    <p>If you have any questions or need further assistance, please contact your Support Coach.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-light" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FalExisting",
        props: {
            studentId: {
                type: String,
                required: true,
            },
        },
        methods: {
            closeModal() {
                $(`#falSubjectExisting-${this.studentId}`).modal('hide');
            }
        }
    }
</script>
