<template>
    <div v-if="enrolledMatricStudentId" class="vi-manage-section">
        <h4 class="fw-bold text-white billing-heading">Manage My Account:</h4>

        <vi-manage-account
            ref="account"
            class="pb-3"
            :enrolled-matric-student-id="enrolledMatricStudentId"
        ></vi-manage-account>

        <vi-change-subjects-or-bundle
            :enrolled-matric-student-id="enrolledMatricStudentId"
        ></vi-change-subjects-or-bundle>

        <vi-manage-exam-sitting></vi-manage-exam-sitting>
    </div>
</template>

<script>
export default {
    name: 'ManageSection',
    props: {
        enrolledMatricStudentId: {
            type: String,
            required: true,
        }
    },
    mounted() {
        console.log('Adult matric learner ID', this.enrolledMatricStudentId);
    },
    data() {
        return {};
    },
    methods: {},
    destroyed() {},
    computed: {},
    watch: {}
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.vi-manage-section {
    padding: 40px;
    background-color: $pastel-light-blue;
}

.new-btn,
::v-deep .new-btn {
    text-transform: none;
    border: 2px solid $pastel-blue;

    &:hover {
        border-color: $white;
        background-color: $primary;
    }
}
</style>
