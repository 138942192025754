<template>
    <div
        ref="modal"
        :id="`liveSessionTimeTable-${studentId}`"
        class="modal fade looker-modal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Live Session Timetable</h5>

                    <button ref="closeButton" type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <time-table
                        ref="timeTable"
                        :url="url"
                        :is-production="isProduction"
                    ></time-table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "LiveSessionTimeTableModal",
        data: () => ({
            loading: false,
        }),
        props: {
            url: {
                type: String,
                required: true,
            },
            studentId: {
                type: String,
                required: true,
            },
            isProduction: {
                type: Boolean,
                default: true,
            },
        },
        mounted() {
            this.$refs.modal.addEventListener('show.bs.modal', event => {
                this.$refs.timeTable.init();
            });
        }
    }
</script>

<style lang="scss" scoped>
@import '../../../sass/abstract/colours.scss';

.looker-modal .modal-header {
    background-color: $white !important;
    color: $pastel-blue;
}
</style>
