<template>
  <div
    v-html="errorMessage"
    :class="errorClass"
    role="alert"
  ></div>
</template>

<script>
export default {
  name: 'ViErrorFlash',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'Something went wrong. Please retry or <a href="mailto:techsupport@uctohs.valentureonline.com">contact us for help</a>.'
    },
    errorClass: {
      type: Object,
      required: false,
      default: function () {
        return {
          'alert': true,
          'alert-danger': true,
          'mt-4': true,
          'mb-3': true,
        }
      },
    },
  },
}
</script>