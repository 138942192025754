<template>
    <div class="modal fade looker-modal" :id="id" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header teacher-block">
                    <h2 class="modal-title">{{ heading }}</h2>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <vi-looker
                        :looker-url=lookerUrl
                        :id=id
                        :is-production="isProduction">
                    </vi-looker>

                    <div v-if="examScheduleGoogleCalendarLink" class="modal-footer">
                        <a class="btn btn-primary btn-outline-uct" :href="examScheduleGoogleCalendarLink" target="_blank" title="Link to Google Calendar Exam Schedule">Grade {{ gradeLevelAsNumber }} Exam Calendar</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LookerModal",

        data: () => ({
            loading: false,
        }),

        props: {
            type: {
                type: String,
                default: null,
            },
            heading: {
                type: String,
                required: true,
                default: null,
            },
            isProduction: {
                type: Boolean,
                required: true,
                default: true,
            },
            id: {
                type: String,
                required: true,
                default: null,
            },
            lookerUrl: {
                type: String,
                required: true,
                default: null,
            },
            examScheduleGoogleCalendarLink: {
                type: String,
                default: null,
            },
            gradeLevelAsNumber: {
                type: String,
                default: null,
            }
        },
    }
</script>
