export default {
    methods: {
        getQueryParams() {
            let currentUrl = new URL(window.location.href);
            let params = new URLSearchParams(currentUrl.searchParams);

            return params;
        },
        getQueryParamBoolean(key, def) {
            if (!this.hasQueryParam(key)) {
                return def;
            }

            let params = this.getQueryParams();
            let value = params.get(key);

            if (key === null) {
                return def;
            }

            if (typeof value === 'string') {
                return value === '1' || value === 'true';
            }

            return Boolean(value);
        },
        hasQueryParam(key) {
            let params = this.getQueryParams();

            return params.has(key);
        }
    }
}
