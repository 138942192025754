<template>
    <div>
        <div class="accordion" id="billingDetailsAccordion">
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="billingAccordion">
                    <button class="accordion-button d-block text-center position-relative" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBilling" aria-expanded="true" aria-controls="collapseBilling">
                        <span class="fw-bold billing-heading">Billing details:</span>
                    </button>
                </h2>
                <div id="collapseBilling" class="accordion-collapse collapse show" aria-labelledby="billingAccordion" data-bs-parent="#billingDetailsAccordion">
                    <div class="section-top-shadow-outer">
                        <div class="section-top-shadow"></div>
                    </div>

                <div class="accordion-body">
                    <div v-if="showAddPaymentSourceDiv" class="alert alert-danger text-center w-100">
                        Please enter card details in order to make payments.
                    </div>

                    <div v-if="showPaymentFailureDiv" class="alert alert-danger text-center w-100">
                        Unable to process payment using primary card details. Please check your card details and account below.
                    </div>

                    <card-details-section
                        :paymentSource="paymentSource"
                        :chargebee-key="chargebeeKey"
                        :chargebee-site="chargebeeSite"
                        :card-details-update-url="cardDetailsUpdateUrl"
                        @hide-add-payment-source="hideAddPaymentSource"
                        id="accountCardDetailsGroup"
                    ></card-details-section>

                    <div class="divider-line"></div>

                    <div v-if="noSubscriptions" class="text-center w-100">
                        <p class="text-pastel-blue">No subscriptions found.</p>
                    </div>

                        <div v-else-if="subscriptions.length === 0" class="text-center w-100">
                            <div class="spinner-border text-pastel-blue" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <invoice-history-section
                            v-if="subscriptions.length > 0"
                            :subscriptions="subscriptions"
                            :invoices="invoices"
                            :download-invoice-url="downloadInvoiceUrl"
                            :payment-url="paymentUrl"
                            :can-pay="paymentSource !== null"
                            @show-add-payment-source="showAddPaymentSource"
                            @show-payment-failure="showPaymentFailure"
                            @refresh-invoices="getInvoices"
                        ></invoice-history-section>

                        <vi-error-flash
                            v-if="unexpectedError"
                            errorMessage="An unexpected error has occurred. Please try again later."
                        ></vi-error-flash>
                    </div>
                </div>
            </div>

            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="An unexpected error has occurred. Please try again later."
            ></vi-error-flash>
        </div>
    </div>
</template>

<script>
export default {
    name: "BillingDetailsSection",
    data() {
        return {
            subscriptions: [],
            invoices: [],
            noSubscriptions: false,
            unexpectedError: false,
            showAddPaymentSourceDiv: false,
            showPaymentFailureDiv: false,
        }
    },
    props: {
        paymentSource: {
            type: Object,
            default: null,
        },
        chargebeeKey: {
            type: String,
        },
        chargebeeSite: {
            type: String,
        },
        cardDetailsUpdateUrl: {
            type: String,
        },
        getSubscriptionsUrl: {
            type: String,
            default: null,
            required: true
        },
        getInvoicesUrl: {
            type: String,
            default: null,
            required: true
        },
        downloadInvoiceUrl: {
            type: String,
            default: null,
            required: true
        },
        paymentUrl: {
            type: String,
            required: true,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.getSubscriptions();
        this.getInvoices();

        const accountAddressFeilds = Array.from(document.querySelectorAll('#accountCardDetailsGroup .vi-account-input'));
        accountAddressFeilds.forEach(accountAddressFeild => {
            accountAddressFeild.classList.remove('form-floating');
        });
    },
    methods: {
        getSubscriptions() {
            this.unexpectedError = false;

            axios.get(this.getSubscriptionsUrl)
                .then((response) => {
                    this.subscriptions = response.data;

                    if (this.subscriptions.length === 0) {
                        this.noSubscriptions = true;
                    }
                })
                .catch((error) => {
                    this.unexpectedError = true;
                });
        },
        getInvoices() {
            let url = this.getInvoicesUrl;
            axios.get(url)
                .then(response => {
                    this.invoices = response.data;
                })
                .catch(error => {
                    this.unexpectedError = true;
                });
        },
        showAddPaymentSource() {
            this.showAddPaymentSourceDiv = true;
        },
        hideAddPaymentSource() {
            this.showAddPaymentSourceDiv = false;
            this.showPaymentFailureDiv = false;
        },
        showPaymentFailure() {
            this.showPaymentFailureDiv = true;
        },
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.guardian-dashboard-content-container {

    .bgc-dark {
        .accordion-button, .accordion-body {
            background-color: $pastel-blue;
        }

        .section-top-shadow-outer {
            background-color: $pastel-blue;
        }

        .section-top-shadow {
            background: linear-gradient(rgba(84, 84, 84, 0.2), $pastel-blue 40%);
        }

        ::v-deep thead {
            background-color: $pastel-blue;
        }
    }

    .bgc-light {
        .accordion-button, .accordion-body {
            background-color: $pastel-light-blue;
        }

        .section-top-shadow-outer {
            background-color: $pastel-light-blue;
        }

        .section-top-shadow {
            background: linear-gradient(rgba(84, 84, 84, 0.2), $pastel-light-blue 40%);
        }

        ::v-deep thead {
            background-color: $pastel-light-blue;
        }
    }

    .accordion-button, .accordion-body {
        // background-color: $pastel-blue;
        color: $white;
    }

    .accordion-button {
        &:focus {
            box-shadow: none;
        }
    }

    .accordion-body {
        padding: 0 40px 40px 40px;
    }

    .divider-line {
        width: 100%;
        height: 1px;
        background-color: $white;
        margin: 40px 0;
    }
    .accordion-button:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }
    .accordion-button:not(.collapsed)::after, .accordion-button::after {
        position: absolute;
        color: $white;
        right: 40px;
    }

    .billing-heading {
        font-size: 1.1875rem; // 19px
    }

    .section-top-shadow-outer {
        // background-color: $pastel-blue;
    }

    .section-top-shadow {
        height: 30px;
        // background: linear-gradient(rgba(84, 84, 84, 0.2), $pastel-blue 40%);
    }
}

</style>
