<template>
    <div>
        <div class="table-responsive generic-table invoices-table">
            <table class="table align-middle table-bordered text-center table-striped mb-0">
                <thead>
                    <tr>
                        <th class="text-white fw-bold">Invoice Number</th>
                        <th class="text-white fw-bold">Status</th>
                        <th class="text-white fw-bold hide-col-mobile">Date</th>
                        <th class="text-white fw-bold">Total</th>
                        <th class="text-white fw-bold hide-col-mobile">Payments</th>
                        <th class="text-white fw-bold hide-col-mobile">Download</th>
                    </tr>
                </thead>
                <tbody class="border-top-0">
                    <tr v-if="filteredInvoices.length === 0">
                        <td colspan="5">
                            <span class="text-pastel-blue">No invoices found.</span>
                        </td>
                    </tr>

                    <tr v-else v-for="(invoice, index) in filteredInvoices" :key="invoice.invoiceNumber + '-' + index" class="border-top-0">
                        <td>
                            <span v-if="!isDownloadingInvoice(invoice.invoiceNumber)" @click="downloadInvoice(invoice.invoiceNumber, 'invoice')" class="text-pastel-blue download-link text-decoration-underline">
                                {{ invoice.invoiceNumber }}
                            </span>

                            <h4 v-else class="mb-0">
                                <div class="spinner-border text-pastel-blue spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </h4>
                        </td>

                        <td>
                            <div :class="[getChipClass(invoice), 'text-white', 'px-3', 'py-1', 'text-decoration-none', 'rounded', 'text-uppercase']">{{ invoice.status }}</div>
                        </td>

                        <td class="hide-col-mobile">
                            <span class="text-pastel-blue">{{ formatDate(invoice.date) }}</span>
                        </td>

                        <td>
                            <span class="text-pastel-blue">
                                {{ currencySymbol(invoice.currency) }}
                                {{ invoice.total }}
                            </span>

                            <button v-if="invoice.status === 'not_paid' || invoice.status === 'payment_due' || invoice.status === 'posted'" @click="payNow(invoice.invoiceNumber)" class="payment-button-mobile mt-2 btn-uct px-3 py-1 text-decoration-none rounded text-uppercase">Pay now</button>
                        </td>

                        <td class="hide-col-mobile">
                            <div v-if="currentlyPaying === invoice.invoiceNumber" class="spinner-border text-pastel-blue spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>

                            <button v-else-if="(invoice.status === 'not_paid' || invoice.status === 'payment_due'  || invoice.status === 'posted') && !(paymentSuccess && successfulPayment === invoice.invoiceNumber)" @click="payNow(invoice.invoiceNumber)" class="btn-uct px-3 py-1 text-decoration-none rounded text-uppercase">Pay now</button>

                            <span v-else-if="paymentSuccess && successfulPayment === invoice.invoiceNumber" class="chip-success text-white px-3 py-1 text-decoration-none rounded text-uppercase">Success!</span>

                            <span v-else class="text-pastel-blue">-</span>
                        </td>

                        <td class="text-white hide-col-mobile">
                            <h4 v-if="!isDownloadingIcon(invoice.invoiceNumber)" @click="downloadInvoice(invoice.invoiceNumber, 'icon')" class="mb-0">
                                <i class="bi bi-download text-pastel-blue download-link"></i>
                            </h4>

                            <h4 v-else class="mb-0">
                                <div class="spinner-border text-pastel-blue spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <vi-error-flash v-if="unexpectedError" errorMessage="An unexpected error has occurred. Please try again later."></vi-error-flash>
    </div>
</template>

<script>
export default {
    name: 'InvoiceTable',
    data() {
        return {
            downloading: {},
            currentlyPaying: null,
            unexpectedError: false,
            paymentSuccess: false,
            successfulPayment: null
        };
    },
    props: {
        invoices: {
            type: Array,
            default: null,
            required: true
        },
        subscriptionId: {
            type: String,
            default: null,
            required: true
        },
        downloadInvoiceUrl: {
            type: String,
            default: null,
            required: true
        },
        paymentUrl: {
            type: String,
            default: null,
            required: true
        },
        canPay: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    methods: {
        payNow(invoiceId) {
            if (!this.canPay) {
                this.$emit('show-add-payment-source', true);
                document.getElementById('billingAccordion').scrollIntoView({ behavior: 'smooth' });
                return;
            }

            this.paymentSuccess = false;
            this.currentlyPaying = invoiceId;

            axios.post(this.paymentUrl, {
                invoiceId: invoiceId
            })
                .then(response => {
                    this.paymentSuccess = true;
                    this.successfulPayment = invoiceId; // add this line

                    setTimeout(() => {
                        this.paymentSuccess = false;
                        this.successfulPayment = null; // add this line
                    }, 5000);

                    setTimeout(() => {
                        this.$emit('refresh-invoices', true);
                    }, 500);
                })
                .catch(error => {
                    this.$emit('show-payment-failure', true);
                    document.getElementById('billingAccordion').scrollIntoView({ behavior: 'smooth' });
                })
                .finally(() => {
                    this.currentlyPaying = null;
                });
        },
        async downloadInvoice(invoiceId, type) {
            if (!this.downloading[invoiceId]) {
                this.$set(this.downloading, invoiceId, {});
            }

            this.$set(this.downloading[invoiceId], type, true);

            this.unexpectedError = false;

            axios
                .post(this.downloadInvoiceUrl, {
                    invoiceId: invoiceId
                })
                .then((response) => {
                    if (response.data.error) {
                        this.unexpectedError = true;
                        return;
                    }

                    let downloadUrl = response.data.downloadUrl;

                    window.open(downloadUrl, '_blank');
                })
                .catch((error) => {
                    this.unexpectedError = true;
                })
                .finally(() => {
                    this.$set(this.downloading, invoiceId, false);
                });
        },
        isDownloadingInvoice(invoiceId) {
            if (!this.downloading[invoiceId] || !this.downloading[invoiceId].invoice) {
                return false;
            }

            return true;
        },
        isDownloadingIcon(invoiceId) {
            if (!this.downloading[invoiceId] || !this.downloading[invoiceId].icon) {
                return false;
            }

            return true;
        },
        getChipClass(invoice) {
            if (invoice.status === 'not_paid' || invoice.status === 'payment_due') {
                return "chip-danger";
            } else if (invoice.status === 'paid') {
                return "chip-success";
            } else {
                return "chip-normal";
            }
        },
        formatDate(timestamp) {
            let d = new Date(timestamp * 1000),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [day, month, year].join('/');
        },
        currencySymbol(code) {
            const currencySymbols = {
                'USD': '$',
                'ZAR': 'R',
            };

            return currencySymbols[code] || code;
        }
    },
    computed: {
        filteredInvoices() {
            return this.invoices.filter(invoice => invoice.subscriptionId === this.subscriptionId);
        },
    },
};
</script>

<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';
</style>
