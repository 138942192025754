<template>
    <div class="link-account">
        <div v-if="error">
            <div class="alert alert-danger" role="alert">
                An error occured please contact support.
            </div>
        </div>

        <div v-else>
            <template v-if="!success">
                <p>We've detected an existing user account matching your {{ provider }} email.</p>

                <p>Do you wish to link your existing account to your {{ provider }} login?</p>
            </template>

            <transition name="fade">
                <div v-if="success" class="success">
                    <div class="success-icon">
                        <i class="bi bi-check-lg"></i>
                    </div>

                    <p>Congrats! You've successfully linked your existing account to your {{ provider }} login.</p>
                </div>
            </transition>
        </div>

        <div v-if="!success && !error">
            <vi-button-spinner
                buttonId="providerAccountLinking"
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'new-btn-lg': true,
                    'mt-2': true,
                    'mb-3': true,
                }"
                :buttonText="'Yes, link my account'"
                :loading="loading"
                v-on:button-click="handleButtonClick"
            ></vi-button-spinner>

            <br>

            <button @click.prevent="close" class="btn-plain">No, don't link my account</button>
        </div>

        <div v-else>
            <p>You'll be redirected shortly...</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "LinkAccount",
    components: {},
    props: {
        linkUrl: {
            type: String,
            required: true,
        },
        provider: {
            type: String,
            required: true,
        },
        logOutUrl: {
            type: String,
            required: true,
        },
        dashboardUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            error: false,
            success: false,
        };
    },
    methods: {
        handleButtonClick() {
            this.loading = true;

            axios.get(this.linkUrl)
                .then(response => {
                    this.success = true;

                    setTimeout(() => this.redirectToDashboard(), 500);
                })
                .catch(error => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        close() {
            window.location.replace(this.logOutUrl);
        },
        redirectToDashboard() {
            window.location.replace(this.dashboardUrl);
        }
    },
};
</script>

<style lang="scss" scoped>

@import "../../../sass/abstract/colours.scss";

.link-account {
    margin: 0 auto;
    text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.success {
    text-align: center;
}

.bi-check-lg {
    font-size: 4rem;
    color: $green;
}

.btn-plain:hover {
    text-decoration: underline;
}
</style>