<template>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" :id="`requestFalSubjectChange-${studentId}`" tabindex="-1" :aria-labelledby="`requestFalSubjectChange`" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header px-5">
                    <h1 class="modal-title h4" id="requestFalSubjectChange">Change First Additional Language (FAL) Subject for Senior Phase Learner</h1>
                    <button type="button" class="btn-close" @click="closeModal"></button>
                </div>
                <div class="modal-body px-5">

                    <div class="container">
                        <form @submit.prevent="submit">
                            <p>A subject change allows the learner to replace their current FAL subject with a different one that better aligns with their academic goals or interests.</p>

                            <p class="mb-1"><span class="fw-bold">Learner Name :</span>  {{ studentName }}</p>
                            <p><span class="fw-bold">Learner Grade :</span> {{ studentGrade }}</p>

                            <h2 class="h5 fw-bold">Important Information:</h2>

                            <p>Please carefully read the following before proceeding with the subject change:</p>
                            <ul>
                                <li>Guardians can only make one FAL subject change per year for the learner.</li>
                                <li>Both the learner and the guardian are responsible for ensuring that the learner catches up on any missed coursework due to the subject change.</li>
                            </ul>

                            <p>For more details on subject changes and submission dates, please visit our Info Hub article <a href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE" target="_blank">here</a>.</p>

                            <div class="mb-3">
                                <p class="fw-bold mb-2">Current FAL:</p>
                                <div class="form-check ms-2">
                                    <input class="form-check-input" type="radio" name="fal" :value="originalFal" :id="originalFal" v-model="selectedFal">
                                    <label class="form-check-label" :for="originalFal">
                                        {{ currentFalName }}
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3">
                                <p class="fw-bold mb-2">Change FAL to:</p>
                                <div>
                                    <template v-for="(fal, key) in changeToFals">
                                        <div class="form-check d-flex ms-2">
                                        <input class="form-check-input" type="radio" name="fal" :id="key" :value="key" v-model="selectedFal" required>
                                            <label class="form-check-label ms-2" :for="key">{{ fal }}</label>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <p>By checking the boxes below, you confirm the following:</p>

                            <vi-alert>
                                <div class="form-check d-flex">
                                    <input class="form-check input" type="checkbox" value="" id="readHandBook" required>
                                    <label class="form-check-label ms-2" for="readHandBook">
                                        I have read the <a target="_blank" href="https://www.uctonlinehighschool.com/policies">Academic Handbook</a> and understand the policies regarding subject change requests.
                                    </label>
                                </div>
                            </vi-alert>

                            <vi-alert>
                                <div class="form-check d-flex">
                                    <input class="form-check input" type="checkbox" value="" id="acknowledgeCourseworkCompletion" required>
                                    <label class="form-check-label ms-2" for="acknowledgeCourseworkCompletion">
                                        I acknowledge that both the learner and the guardian are responsible for catching up up on any missed coursework.
                                    </label>
                                </div>
                            </vi-alert>

                            <vi-alert>
                                <div class="form-check d-flex align-items-start">
                                    <input class="form-check input" type="checkbox" value="" id="acknowledgeInstantChange" required>
                                    <label class="form-check-label ms-2" for="acknowledgeInstantChange">
                                        I understand that this change takes effect immediately. The previous FAL will not be included in this term's report, and  grades from the previous FAL will not count towards the new FAL.
                                    </label>
                                </div>
                            </vi-alert>

                            <vi-button-spinner
                                :buttonClass="{
                                    'new-btn': true,
                                    'new-btn-dark': true,
                                    'new-btn-sm': false,
                                    'mt-3': true,
                                }"
                                :disabled="!this.selectedFal"
                                :buttonText="'Confirm Subject Change'"
                                :loading="submiting"
                            ></vi-button-spinner>

                            <div v-if="error" class="alert alert-danger mt-3">
                                An error occured trying to submit your request. If this problem persists please contact support.
                            </div>
                        </form>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-light" @click="closeModal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { forEach } from 'lodash';


export default {
    name: "FalExisting",
    props: {
        studentId: {
            type: String,
            required: true,
        },
        studentName: {
            type: String,
            required: true,
        },
        studentGrade: {
            type: String,
            required: true,
        },
        changableFals: {
            type: Object,
            required: true,
        },
        currentFal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedFal: null,
            originalFal: null,
            error: false,
            submiting: false,
        };
    },
    mounted() {

        this.originalFal = this.currentFalKey
        this.selectedFal = this.currentFalKey
    },
    computed: {

        currentFalKey() {
            return Object.keys(this.currentFal)[0]
        },
        
        currentFalName() {
            let key = Object.keys(this.currentFal)[0]
            return this.currentFal[key]
        },
        changeToFals() {
            let currentFalKey = Object.keys(this.currentFal)[0]
            let allFalKeys = Object.keys(this.changableFals)

            let changableFalKeys = allFalKeys.filter(falKey => falKey !== currentFalKey)

            let allowedFals = {}
            forEach(changableFalKeys, falKey => {
                allowedFals[falKey] = this.changableFals[falKey]
            })

            return allowedFals;
        }
    },
    methods: {
        validateData() {
            if (!this.selectedFal  && this.selectedFal === this.originalFal) {
                this.error = true
                return false
            }
            return true
        },
        closeModal() {
            $(`#requestFalSubjectChange-${this.studentId}`).modal('hide');
        },
        async submit() {

            this.submiting = true

            if (!this.validateData()) {
                this.submiting = false
                return
            }

            let submitData = {
                learnerSfId: this.studentId,
                fal: this.selectedFal,
            }

            axios.post(window.endpoints.falSubjectChangeRequest.submit, submitData)
                .then(response => {
                    if (response.data.success === false) {
                        this.error = true
                    }

                    if (response.data.success === true) {
                        this.error = false

                        document.getElementById(`subjectChangeButton-${this.studentId}`).disabled = true

                        $(`#requestFalSubjectChange-${this.studentId}`).modal('hide')
                        $(`#falSubjectConfirmation-${this.studentId}`).modal('show')
                    }
                })
                .catch(error => {
                    this.error = true
                })
                .finally(() => {
                    this.submiting = false
                })
        }
    },

}
</script>
