<template>

    <div class="wrapper px-0 py-5 d-flex justify-content-around">

        <div
            v-for="(step , key) in steps"
            class="progress-step d-flex flex-column align-items-center text-center position-relative"
            :class="getClassName(step)"
            :key="`application-step-${key}`"
            @click="navigateTo(step)"
            role="button"
        >

            <div class="indicator rounded-circle d-flex justify-content-center align-items-center mb-3">
                <i class="bi bi-check-lg"></i>
            </div>
            <span class="title-text">{{ step }}</span>
        </div>

    </div>

</template>
<script>

export default {
    name: "ApplicationStepsProgress",
    props: {
        currentStepName: {
            type: String,
        },
        studentInformationLink: {
            type: String,
            default: null
        },
        subjectSelectionLink: {
            type: String,
            default: null,
        },
        applicationStage: {
            type: String,
            default: 'Initial Application'
        },
        isAdultMatricApplication: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        initialApplicationSteps: [
            "Create Account",
            "Submit Learner Info",
            "Subject Selection",
            "Pay Placement Fee"
        ],
        documentVerificationSteps: [
            'Document Verification',
            'Supporting Information',
            'Tuition Fee Setup'
        ],
        adultApplicationSteps: [
            'Create Account',
            'Learner Info',
            'Subject Selection',
            'Secure Your Placement',
        ],
    }),
    computed: {
        isInitialApplicationStage() {
            return this.applicationStage == 'Initial Application';
        },
        isDocumentVerificationStage() {
            return this.applicationStage == 'Document Verification';
        },
        steps() {
            if (this.isAdultMatricApplication) {
                return this.adultApplicationSteps;
            }

            return this.isInitialApplicationStage ? this.initialApplicationSteps : this.documentVerificationSteps;
        },
        canEditStudentInformation() {
            return this.isInitialApplicationStage && this.steps.indexOf(this.currentStepName) == this.steps.indexOf("Submit Learner Info");
        },
        canSelectSubjects() {
            return this.isInitialApplicationStage
                && (this.steps.indexOf(this.currentStepName) > this.steps.indexOf("Submit Learner Info"));
        },
    },
    methods: {
        isActiveStep(stepName) {
            return stepName == this.currentStepName;
        },

        isCompletedStep(stepName) {
            return this.steps.indexOf(stepName) < this.steps.indexOf(this.currentStepName);
        },

        getClassName(stepName) {
            if (this.isActiveStep(stepName)) {
                return 'active';

            } else if (this.isCompletedStep(stepName)) {
                return 'complete';
            }

            return '';
        },

        navigateTo(stepName) {

            if (stepName == "Submit Learner Info" && this.canEditStudentInformation && this.studentInformationLink) {
                location.href = this.studentInformationLink;

            }  else if (stepName == "Subject Selection" && this.canSelectSubjects && this.subjectSelectionLink) {
                location.href = this.subjectSelectionLink;
            }
        }
    }
}

</script>
