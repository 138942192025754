<template>
    <button class="query-button p-1 p-md-4" @click="handleClick">
        <i class="bi bi-search"></i>
    </button>
</template>

<script>
export default {
    props: {
        itemId: {
            type: [Number, String],
            required: true,
        },
        showAdditionalInfo: {
            type: Boolean,
            default: false,
        },
        assessment: {
            type: Object,
            default: () => ({}),
        },
        queryUrl: {
            type: String,
            required: false,
        },
        emailForGradingQuery: {
            type: String,
            required: false,
        },
    },
    watch: {
        show(newVal) {
            if (newVal) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        },
    },
    methods: {
        handleClick() {
            this.$emit('show-modal', true);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/abstract/colours.scss';
.options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto;
}

.grading-query-icon {
    height: 2rem;
}

.bi-search {
    font-size: 1.75rem; // 28px

    @media screen and (max-width: 768px) {
        font-size: 1.5rem; // 24px
    }
}

.query-button {
    &:hover {
        i {
            color: $pastel-light-blue;
        }
    }
}
</style>