<template>
     <div class="modal" id="submitOverlay" tabindex="-1" role="dialog" aria-labelledby="..." aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="spinner-border spinner-border-lg m-auto" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingOverlay",
}
</script>

<style lang="scss" scoped>
    @import 'resources/sass/abstract/colours.scss';

    .spinner-border {
        height: 90px;
        width: 90px;
        border: solid 20px $pastel-blue;
        border-right-color: $pastel-light-blue;
        border-bottom-color: $pastel-light-blue;
        animation: 1.5s linear infinite spinner-border;
    }
</style>