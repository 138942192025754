<template>
    <div class="dummy-upload-step">
        <vi-file-selector
            upload-field-name="passportDocument"
            :handle-upload="handleUpload"
            :max-file-size="8000000"
            accepted-file-types="image/*, .pdf"
        ></vi-file-selector>

        <pre class="my-3"><code>
            Application ID: {{ applicationId }}
            Passport Upload URL: {{ passportUploadUrl }}
        </code></pre>
    </div>
</template>

<script>

/*

Usage:

use \App\Constants\DocumentUploadTypesConstant;

<dummy-upload-step
    application-id="{{ $application ? $application->id : null }}"
    passport-upload-url="{{ route('portal.api.upload', [ 'uploadDocumentType' => DocumentUploadTypesConstant::PASSPORT ]) }}"
></dummy-upload-step>

*/
import axios from 'axios';

    export default {
        name: 'DummyUploadStep',
        props: {
            applicationId: {
                type: String,
                required: true,
            },
            passportUploadUrl: {
                type: String,
                required: true,
            }
        },
        methods: {
            async handleUpload(formData) {
                formData.append('applicationId', this.applicationId);

                return axios.post(this.passportUploadUrl, formData).then(msg => 'This was a success.');
            }
        },
    }
</script>