<template>
    <div class="termly-progree-container p-3">
        <template v-if="formalAssessments && currentTerm">
            <div class="d-flex justify-content-between align-items-center mb-3 mt-3 flex-wrap">
                <h5 class="mb-0 fw-bold termly-progress-heading">Termly Progress</h5>

                <div>
                    <p class="mb-0 updated-text mt-2 mt-md-0">Updated every 2 hours <span class="refresh-button" @click="$emit('refresh', true)"><i class="bi bi-arrow-clockwise"></i></span> | Last updated: <span class="fw-bold">{{ formalAssessments.cached_notice }}</span></p>
                </div>
            </div>

            <div class="progress-table-container">
                <div class="d-flex w-100 justify-content-md-center justify-content-lg-start align-items-center term-row">
                    <a
                        v-for="term in availableTerms"
                        :key="term"
                        @click.prevent="(event) => selectTerm(term)"
                        href="#"
                        ref="terms"
                        class="fw-bold me-2 me-md-4 text-center py-2 py-md-1 px-4 px-md-2 lh-1"
                        :class="{ 'current': currentTerm == term, }"
                    >Term {{ term }}</a>

                    <!-- Todo: Implement Summary section. -->
                    <!-- <a href="#" class="fw-bold text-pastel-blue lh-1 text-center">Summary</a> -->
                </div>

                <progress-subjects
                    :subjects="formalAssessments.subjects[currentTerm]"
                    v-on:view-more="(data) => $emit('view-more', data)"
                    class="subjects"
                ></progress-subjects>

                <module-count
                    :summary="formalAssessments[currentTerm].summary"
                    class="assessments"
                ></module-count>

                <div class="deadline">
                    <p class="mb-0">
                        Formal Assessment Deadline: <span class="fw-bold">{{ formalAssessments.term_deadlines[currentTerm] }}</span>
                        <i
                            v-if="formalAssessments.term_deadlines.hasOwnProperty(`${currentTerm}_last_modules`)"
                            @click="toggleToolTip"
                            class="bi bi-info-circle-fill px-2"
                            data-toggle="tooltip"
                            role="button"
                            :title="`Hard deadline for last 2 modules: ${formalAssessments.term_deadlines[`${currentTerm}_last_modules`]}`"
                        ></i>
                    </p>
                </div>

                <termly-progress-table
                    :subjects="formalAssessments.subjects[currentTerm]"
                    :modules="formalAssessments[currentTerm].modules"
                    :current-module="currentModule"
                    :modules-list="formalAssessments.term_modules[currentTerm]"
                    :student-email="studentEmail"
                    class="table"
                ></termly-progress-table>
            </div>
        </template>

        <template v-else>Something went wrong while trying to find this information for you. Sorry about that! Please try again a bit later. If the error persists, please <a href="mailto:techsupport@uctohs.valentureonline.com" class="text-decoration-underline" target='_blank'>contact our support team</a>.</template>
    </div>
</template>

<script>

export default {
    name: "TermlyProgress",
    data() {
        return {
            active: "active",
            iscurrentTerm: true,
            availableTerms: [],

            currentTerm: null,
            currentModule: null,
        }
    },
    props: {
        formalAssessments: {
            type: Object,
        },
        studentEmail: {
            type: String,
        },
    },
    mounted() {
        if (this.formalAssessments) {
            this.availableTerms = Object.keys(this.formalAssessments)
                .filter(key => parseInt(key))
                .sort();

            this.currentTerm = this.availableTerms[0];

            if (this.formalAssessments.hasOwnProperty('current_module')) {
                this.currentModule = this.formalAssessments.current_module;
            }

            for (const term in this.formalAssessments.term_modules) {
                if (Object.hasOwnProperty.call(this.formalAssessments.term_modules, term)) {
                    const modules = this.formalAssessments.term_modules[term];

                    if (modules.includes(parseInt(this.currentModule))) {
                        this.currentTerm = term;

                        break;
                    }
                }
            }
        }
    },
    methods: {
        selectTerm(term) {
            this.currentTerm = term;
        },
        toggleToolTip(e) {
            $(e.target).tooltip('toggle');
        },
    },
}

</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';
@import 'resources/sass/fonts.scss';

.progress-table-container {
    display: grid;
    grid-template-areas: "terms terms"
                         "assessments assessments"
                         "subjects deadline"
                         "subjects table";
    grid-gap: 12px;
    grid-template-rows: auto auto;
    grid-template-columns: 170px auto;

    @media (min-width: 1399.98px) {
        grid-template-areas: ". terms"
                             "subjects assessments"
                             "subjects deadline"
                             "subjects table";
    }
}

.deadline {
    grid-area: deadline;
    font-size: 0.75rem;
    margin-bottom: 0;
    text-align: right;
}

.term-row {
    grid-area: terms;
    margin-bottom: 0;

    @media (max-width: 767.98px) {
        margin-bottom: -12px;
        overflow-y: scroll;
        padding: 0 20px;
    }

    a {
        color: $grey-B7;

        @media (max-width: 767.98px) {
            background-color: $white;
            color: $pastel-blue;
            max-width: 100px;
        }

        &.current {
            border-bottom: 2px solid $pastel-blue;
            color: $pastel-blue;

            @media (max-width: 767.98px) {
                background-color: $pastel-blue;
                color: $white;
            }
        }
    }
}

.assessments {
    grid-area: assessments;
}

.subjects {
    grid-area: subjects;
}

.table {
    grid-area: table;
}

.termly-progree-container {
    background-color: $greyE5;
    border-radius: 1.875rem;

    h5, p {
        color: $pastel-blue;
        text-decoration: none;
    }

    .updated-text {
        font-size: 0.75rem; // 12px
        font-family: $primary-font;
    }
}

.termly-progress-heading {
    font-family: $primary-font;
}

a {
    text-decoration: none;
    font-size: 0.75rem; // 12px
}

.mobile-only {
    display: none;

    @media (max-width: 767.98px) {
        display: flex;
    }
}

.desktop-only {
    display: flex;

    @media (max-width: 767.98px) {
        display: none;
    }
}

</style>
