<template>
    <div>
        <div
            class="card general-widget-card p-0 d-flex flex-column flex-md-row overflow-hidden"
        >
            <div class="generic-widget-left-col py-3" >
                <div class="card-body d-flex justify-content-center flex-column">
                    <h5 class="card-title mb-0">Learning Liberated Scholarship</h5>
                </div>
            </div>

            <div v-if="progressionApplication.scholarshipLink" class="text-center generic-widget-right-col py-3 px-1">
                <a :href="progressionApplication.scholarshipLink" class="h-100 w-100 d-flex flex-column justify-content-center align-items-center text-uppercase">
                    <span>{{ progressionApplication.scholarshipInfo.status }} {{ progressionApplication.scholarshipInfo.position ? `: ${progressionApplication.scholarshipInfo.position}` : ''  }}</span><br>
                    <span class="pt-2" v-if="progressionApplication.scholarshipInfo.subStatus">{{ progressionApplication.scholarshipInfo.subStatus }}</span>
                </a>
            </div>

            <div v-if="progressionApplication.checkScholarshipEligibility && !progressionApplication.scholarshipLink" class="text-center generic-widget-right-col py-3 px-1">
                <a :href="progressionApplication.checkScholarshipEligibility" class="h-100 w-100 d-flex justify-content-center align-items-center text-uppercase">
                    Check Eligibility
                </a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ApplyForLLS",
        props: {
            progressionApplication: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style>

</style>