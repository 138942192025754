<template>
    <button
        @click="handleClick"
        :disabled="loading || disabled"
        :type="buttonType"
        :id="buttonId"
        :class="buttonClass"
      >
        <template v-if="!loading">
          <slot>{{ buttonText }}</slot>
        </template>

        <slot v-else name="loader">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </slot>
    </button>
</template>

<script>
export default {
  name: 'ViButtonSpinner',
  props: {
    buttonId: {
      type: String,
      required: false,
      default: 'vi-submit'
    },
    buttonClass: {
      type: Object,
      required: false,
      default: function () {
        return {
          'new-btn': true,
          'new-btn-dark': true,
          'new-btn-sm': true,
          'mt-2': true,
        };
      }
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Submit'
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'submit',
    },
  },
  methods: {
    handleClick() {
      this.$emit('button-click');
    }
  },
}
</script>