<template>
    <div>
        <h5 class="title text-center">Assessment Resubmission</h5>
        <router-view v-if="assessment"></router-view>
        <div class="form-group mt-4 grading-query-footer">
            <button
                @click="$emit('update:show', false)"
                type="button"
                class="new-btn btn-danger new-btn-sm mt-2"
            >
                Cancel
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "AssessmentResubmit",
    data () {
        return {
            cmId: null,
        }
    },
    props: {
        assessment: {
            type: Object,
            default: () => {}
        },
    },
    mounted () {
        this.cmId = this.getCmId();
        this.show();
    },
    methods: {
        getCmId() {
            let cmIdKey = Object.keys(this.assessment).find(key => key.includes('course_module_id'));
            return this.assessment[cmIdKey];
        },
        show() {
            if (
                this.$router.currentRoute.params.cmId &&
                this.$router.currentRoute.name === 'assessment-resubmission.details' &&
                this.$router.currentRoute.params.cmId === this.cmId
            ) {
                return;
            }

            if (this.$router.currentRoute.name === 'assessment-resubmission.eligibility' && this.cmId === this.$router.currentRoute.params.cmId) {
                return;
            }

            this.$router.push({name: 'assessment-resubmission.eligibility', params: {
                cmId: this.cmId,
            }}).catch();
        },
    }
}
</script>
