<template>
    <div v-if="gradeOfEntry === 'Grade 8' || gradeOfEntry === 'Grade 9'">
        <p class="text-center font-weight-bold">A note about subject changes:</p>
        <p><span class="font-weight-bold">For Term 1 enrolments:</span> There are no subject choice requirements for learners joining in Term 1.</p>
        <p><span class="font-weight-bold">For Term 2 enrolments:</span> Learners must have taken all 9 required subjects at their current school in order to join UCT Online High School. If UCT Online High School does not offer the First Additional Language your learner is currently taking, we can accommodate a subject change. Should you require subject changes please email <a href="mailto:sro@uctohs.valentureonline.com">sro@uctohs.valentureonline.com</a> as soon as possible to process a subject change request.</p>
        <p><span class="font-weight-bold">For Term 3 enrolments:</span> Learners must have taken all 9 required subjects at their current school in order to join UCT Online High School. We cannot accommodate First Additional Language subject changes for Term 3. If we do not offer the First Additional Language, guardians can request a subject change at their current school provided that this change is captured on the learners Term 2 report.</p>
        <p v-if="gradeOfEntry === 'Grade 8'">Please also note that the report you use to complete the application must include all subjects taken. If you are applying for Term 1 the learner’s end of year promotional status must be on the report. Your learner will not be able to enrol if the report does not reflect this.</p>
        <p class="font-weight-bold">Compulsory Subject Selection:</p>
    </div>
    <div v-else-if="gradeOfEntry === 'Grade 10'">
        <p class="text-center font-weight-bold">A note about subject changes:</p>
        <p><span class="font-weight-bold">For Term 1 & 2 enrolments:</span> Two subject changes are allowed in Grade 10, provided they are done before the SACAI deadline each year.</p>
        <p><span class="font-weight-bold">For Term 3 enrolments:</span> The subjects you select to take at UCT Online High School must match the subjects taken at the learner's current school in order to join UCT Online High School. We cannot accommodate subject changes for Term 3, however, guardians can request a subject change at their current school provided that this change is captured on the learners Term 2 report.</p>
        <p>Please also note that the report you use to complete the application must include all subjects taken. If you are applying for Term 1 the learner’s end of year promotional status must be on the report. Your learner will not be able to enrol if the report does not reflect this.</p>
        <!-- <p class="text-center font-weight-bold">A note on subject groupings:</p>
        <p>Grade 10 learners need to take a minimum of 7 subjects. Learners need to take 4 compulsory subjects (as outlined below) and may choose an additional 3 subjects. Please select your learners elective subjects based on the instructions below. These restrictions on certain subject combinations have been carefully chosen to ensure learners take a combination of electives that meet popular university entrance requirements.</p>
        <p>Please reach out to the admissions team should you have additional questions about making this choice by emailing <a href="mailto:sro@uctohs.valentureonline.com">sro@uctohs.valentureonline.com</a> or calling +27 21 140 1792.</p> -->
    </div>
    <div v-else-if="gradeOfEntry === 'Grade 11'">
        <p class="text-center font-weight-bold">A note about subject changes:</p>
        <p><span class="font-weight-bold">For Term 1 enrolments:</span> Two subject changes are allowed in Grade 11, provided they are done before the SACAI deadline each year.</p>
        <p><span class="font-weight-bold">For Term 2 & 3 enrolments:</span> The subjects you select to take at UCT Online High School must match the subjects taken at the learner's current school in order to join UCT Online High School. We cannot accommodate subject changes for Terms 2 and 3, however, guardians can request a subject change at their current school provided that this change is captured on the learners Term 1 report.</p>
        <p>Please also note that the report you use to complete the application must include all subjects taken. If you are applying for Term 1 the learner’s end of year promotional status must be on the report. Your learner will not be able to enrol if the report does not reflect this.</p>
    </div>
    <div v-else-if="gradeOfEntry === 'Grade 12'">
        <p class="text-center font-weight-bold">A note about subject changes:</p>
        <p><span class="font-weight-bold">Grade 12:</span> One subject change may be requested provided that this is done before the SACAI deadline each year. Should you require 1 subject change please email <a href="mailto:sro@uctohs.valentureonline.com">sro@uctohs.valentureonline.com</a> by <span class="font-weight-bold">26 January</span> to process a subject change request. Changing subjects in Grade 12 is not advisable. Requests will be considered on a case-by-case basis.</p>
        <p>Please also note that the report you use to complete the application must include all subjects taken. If you are applying for Term 1 the learner’s end of year promotional status must be on the report. Learners entering Grade 12 must have taken Life Orientation in Grade 11 and this must be captured on the report. Your learner will not be able to enrol if the report does not reflect this.</p>
    </div>
</template>

<script>
export default {
    name: "SubjectSelectionNotice",
    props: {
        gradeOfEntry: {
            type: String,
            required: true
        }
    }
}
</script>