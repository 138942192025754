<template>
    <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header">
            <h1 v-if="(initializing === false || initializing === true) && !success" class="modal-title fs-5" id="requestSubjectChange">Confirm Enrolment Cancellation</h1>

            <!-- Successfully Submitted -->
            <h1 v-if="initializing === false && success" class="modal-title fs-5" id="requestSubjectChange">Cancellation Request Submitted</h1>

            <!-- Error -->
            <h1 v-if="initializing === null" class="modal-title fs-5" id="requestSubjectChange">Cancellation Request Failed</h1>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="initializing === false" class="modal-body">
            <template v-if="!success">
                <p>We're sad to hear you've changed your mind about {{ learnerFirstName }} joining UCT Online High School.</p>

                <p>Please note that once you confirm this action, the cancellation will be <span class="fw-bold">final</span> and <span class="fw-bold">cannot be reversed</span>. If you decide to re-enroll your learner in the future, you'll need to <span class="fw-bold">submit a new application</span>.</p>

                <p>After cancellation, {{ learnerName }} will no longer be a part of the upcoming intake at UCT Online High School and going forward you will not be billed tuition fees.</p>

                <div class="mb-3">
                    <label class="form-label" for="reason">Please select a reason for the enrolment cancellation:</label>

                    <select
                        id="reason"
                        class="form-select"
                        :class="{'mb-3': hasError('reason')}"
                        name="reason"
                        required
                        :disabled="success"
                        @input="() => clearError('reason')"
                    >
                        <option selected disabled :value="null">Reason for cancelling enrolment</option>
                        <option v-for="reason in reasons" :value="reason" :key="reason">{{ reason }}</option>
                    </select>

                    <template v-if="hasError('reason')">
                        <error-message
                            v-for="message in getErrors('reason')"
                            :key="message"
                            :message="message"
                            class="text-start alert alert-danger mb-3"
                        ></error-message>
                    </template>
                </div>

                <div class="mb-3">
                    <label for="explanation" class="form-label">Please provide further explanation for the enrolment cancellation:</label>

                    <textarea
                        class="form-control"
                        :class="{'mb-3': hasError('explanation')}"
                        name="explanation"
                        id="explanation"
                        rows="3"
                        maxlength="255"
                        required
                        :disabled="success"
                        @input="() => clearError('explanation')"
                    ></textarea>

                    <template v-if="hasError('explanation')">
                        <error-message
                            v-for="message in getErrors('explanation')"
                            :key="message"
                            :message="message"
                            class="text-start alert alert-danger mb-3"
                        ></error-message>
                    </template>
                </div>
            </template>

            <template v-else-if="success">
                <p>Thank you. Your request to cancel {{ learnerName }}'s enrolment has been successfully submitted to the UCT Online High School Admissions team.</p>

                <p>We're sad to see you go, but we understand that circumstances change.</p>

                <p>The cancellation will be processed shortly. Once processed {{ learnerName }} will no longer be a part of the upcoming intake into UCT Online High School.</p>

                <p>If you have any questions or if we can assist you further, please don't hesitate to reach out to our Admissions team at <a href="mailto:admissions@uctohs.valentureonline.com">admissions@uctohs.valentureonline.com</a>.</p>

                <p>We wish {{ learnerName }} all the best for the future!</p>
            </template>

            <div
                v-if="hasError('generic')"
                class="alert alert-danger mb-0"
            >
                <p>We're sorry! Something went wrong, and your request to cancel {{ learnerName }}'s enrollment could not be submitted.</p>

                <p>Please check your internet connection and try again. If the issue persists, feel free to contact our Admissions team at <a href="mailto:admissions@uctohs.valentureonline.com">admissions@uctohs.valentureonline.com</a>, and we'll help you resolve it as soon as possible.</p>

                <p>We apologise for the inconvenience and appreciate your patience!</p>
            </div>
        </div>

        <div v-else-if="initializing === true" class="modal-body">
            <div class="placeholder-glow">
                <span class="placeholder w-75"></span>
                <span class="placeholder w-50"></span>
                <span class="placeholder w-75"></span>
                <span class="placeholder w-25"></span>
                <span class="placeholder w-25"></span>
            </div>
        </div>

        <div v-else-if="initializing === null" class="modal-body">
            <div class="alert alert-danger">
                <p>We're sorry! Something went wrong.</p>

                <p>Please check your internet connection and try again. If the issue persists, feel free to contact our Admissions team at <a href="mailto:admissions@uctohs.valentureonline.com">admissions@uctohs.valentureonline.com</a>, and we'll help you resolve it as soon as possible.</p>

                <p>We apologise for the inconvenience and appreciate your patience!</p>
            </div>
        </div>

        <div class="footer buttons-group">
            <vi-button
                :disabled="initializing === true || loading"
                :label="success ? 'Close' : 'Keep Enrolment'"
                :theme="'ghost'"
                data-bs-dismiss="modal"
                aria-label="Close"
                class="close-btn"
            ></vi-button>

            <vi-button
                v-if="!success"
                :disabled="initializing === true || loading || success"
                :label="'Confirm Cancellation'"
                :loading="loading"
            ></vi-button>
        </div>
    </form>
</template>

<script>
import error_handling from '../../mixins/error_handling';
import scroll_to from '../../mixins/scroll_to';

export default {
    name: "CancelEnrolment",
    mixins: [
        scroll_to,
        error_handling,
    ],
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        initUrl: {
            type: String,
            required: true,
        },
        learnerName: {
            type: String,
            required: true,
        },
        learnerFirstName: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            // Component State
            initializing: true, // true - busy, false - done(success), null - error
            hasRegistered: false,
            success: false,
            errors: null,
            loading: false,

            // Data
            reasons: [],
            submitUrl: null,
        };
    },
    mounted() {
        let modalNode = document.querySelector(`#nscCancelEnrolment-${this.applicationId}`);

        if (!this.hasRegistered) {
            modalNode.addEventListener('shown.bs.modal', () => {
                this.initializing = true;

                this.init()
                    .then(() => {
                        this.initializing = false;

                        // Reset
                        this.success = false;
                        this.errors = null;
                    })
                    .catch(err => {
                        this.initializing = null;
                    });
            });

            modalNode.addEventListener('hidden.bs.modal', () => {
                this.initializing = true;
            });

            this.hasRegistered = true;
        }
    },
    methods: {
        init() {
            return axios.get(this.initUrl)
                .then(res => {
                    const data = res.data;

                    this.reasons = data.reasons;
                    this.submitUrl = data.submitUrl;
                });
        },
        submit(event) {
            let data = new FormData(event.target);

            this.loading = true;
            this.errors = null;

            axios.post(this.submitUrl, data)
                .then(response => {
                    let responseData = response.data;

                    this.success = true;
                })
                .catch(error => {
                    let responseData = error.response.data;

                    if (responseData.hasOwnProperty('errors') && responseData.errors) {
                        this.errors = responseData.errors;
                    } else {
                        this.errors = {
                            generic: [ 'An error occured' ],
                        };
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.buttons-group {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .close-btn {
        margin-right: auto;
    }
}

textarea {
    resize: none;
}

label {
    color: black;
}
</style>
