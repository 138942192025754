<template>
    <div class="progress-subject-container justify-content-center align-items-start d-flex flex-column h-100 p-3 ps-3 ps-md-4">
        <h6 class="text-white fw-bold">Subjects:</h6>

        <div
          v-for="(subject, index) in subjects"
          :key="index"
          class="subjects text-left mb-md-2  d-flex justify-content-center flex-column"
        >
            <p class="text-white mb-0 text-uppercase lh-1">{{ subject }}</p>
            <span
                class="text-white view-more-button"
                @click="$emit('view-more', { subject })"
                data-bs-toggle="modal"
                data-bs-target="#paceDataGrades"
            ><span class="view-more-border">view more</span></span>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProgressSubjects",
    props: {
        subjects: {
            type: Array,
        },
    },
}

</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';

.progress-subject-container {
    background-color: $blue9A;
    border-radius: 30px 0 30px 15px;

    h6 {
        font-size: 0.875rem; // 14px
        padding-top: 25px;
        margin-bottom: auto;
        // margin-bottom: 50px;

        // @media (max-width: 1263px) {
        //   margin-bottom: 100px;
        // }

        @media (max-width: 991.98px) {
            // margin-bottom: 25px;
            padding-top: 14px;
        }
    }

    p {
        font-size: 0.875rem; // 14px
        margin-bottom: 0.25rem !important;

        @media (max-width: 767.98px) {
            font-size: 0.75rem; // 12px
        }
    }

    .subjects > span {
        font-size: 0.625rem; // 10px
        text-decoration: none;
    }

    .view-more-border {
        border-bottom: 1px solid $white;
    }

    .subjects {
        // margin-bottom: 50px;
        height: 80px;

        // @media (max-width: 767.98px) {
        //   margin-bottom: 30px;
        // }
    }
}

.view-more-button {
    &:hover {
        cursor: pointer;
    }
}

</style>