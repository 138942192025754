<template>
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" :id="`falSubjectConfirmation-${studentId}`" tabindex="-1" role="dialog" :aria-labelledby="`falSubjectConfirmationLabel-${studentId}`" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <p>Thank you for submitting your first additional language subject change.</p>
                    <p>These changes may take up to 24 hours to reflect on the Learner Portal and on the Online Campus.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-light" @click="closeModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FalConfirmation",
        props: {
            studentId: {
                type: String,
                required: true,
            },
        },
        methods: {
            closeModal() {
                $(`#falSubjectConfirmation-${this.studentId}`).modal('hide');
            }
        }
    }
</script>
