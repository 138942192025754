<template>
  <div class="vi-table-row" :class="{ head: isHeading }">
    <vi-table-cell
      v-for="(headingObject, headingId, idx) in headings"
      :key="`${isHeading ? headingObject.title : dataSet.id}` + idx"
      :heading="headingObject"
      :value="isHeading ? headingObject.title : dataSet[headingId]"
      :show-heading="!isHeading"
      :num-columns="numOfColumns"
      :is-heading="isHeading"
      :query-url="queryUrl"
      :email-for-grading-query="emailForGradingQuery"
      :item-id="dataSet.id"
      :assessment="dataSet"
      :assessment-submissions-view="assessmentSubmissionsView"
      :term-year-query-data="termYearQueryData"
      :grading-query-upload-url="gradingQueryUploadUrl"
    />
  </div>
</template>

<script>
export default {
  props: {
    headings: {
      type: Object,
      required: true,
    },
    dataSet: {
      type: Object,
      required: true
    },
    isHeading: {
      type: Boolean,
      default: false,
    },
    queryUrl: {
      type: String,
      required: false,
      default: null,
    },
    emailForGradingQuery: {
      type: String,
      required: false,
      default: null,
    },
    termYearQueryData: {
      type: Array,
      required: false,
      default: () => [],
    },
    assessmentSubmissionsView: {
      type: String,
      required: false,
      default: 'uct_assessment_submissions',
    },
    gradingQueryUploadUrl: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      ref: null,
    };
  },
  computed: {
    numOfColumns() {
      return Object.keys(this.headings).length;
    }
  },
  methods: {
    getValue(heading, originalValue) {
      if (heading.toLowerCase() !== 'status') {
        return originalValue;
      }

      const formatValue = (val, description) => {
        let prefix = `<span class="fw-bold">${val}</span>`;

        if (!description) {
          return prefix;
        }

        return `${prefix} - <span class="fst-italic">${description}</span>`;
      };

      return formatValue(originalValue);
    },
  },
}
</script>

<style lang="scss">
@import '../../../sass/abstract/colours.scss';

.vi-table-row {
  display: flex;

  .vi-table-cell {
    flex-grow: 0;
    flex-shrink: 0;
    overflow-wrap: anywhere;
  }

  &.head {
    font-weight: 600;
  }

  &:not(.head) {
    .vi-table-cell {
      &:nth-child(odd) {
        background-color: $grey-E2;
      }

      &:nth-child(even) {
        background-color: $grey-EF;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $grey-B7;

      @media screen and (max-width: 768px) {
        border-bottom: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .vi-table-row {
    flex-basis: calc((100% - 1rem) / 3);
    flex-direction: column;
    border-radius: 5px;
  }

  .vi-table-row + .vi-table-row {
    margin-bottom: 0.5rem;
  }

  .vi-table-row.head {
    display: none;
  }
}
</style>