<template>
    <div class="select-input">
        <label class="form-label text-white">{{ labelText }}</label>
        <div class="rounded-pill w-100 d-flex mb-3">
            <select
                class="form-control dial-codes-select"
                :disabled="disabled"
                v-bind:value="value"
                @change="changeHandler"
                :required="required"
                :form="formId"
                :name="name"
            >
                <option value="" :selected="!value" disabled>Please select</option>
                <option v-for="(option) in options" :value="option.value" :key="option.value">
                    {{ option.text }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountSelect',
    props: {
        value: {},
        formId: {
            type: String,
            default: null,
        },
        labelText: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        }
    },
    mounted() {},
    methods: {
        changeHandler(event) {
            this.$emit('input', event.target.value);
        },
    },
}
</script>

<style lang="scss" scoped>
.dial-codes-select {
    border-radius: 50rem;

    &:focus {
        box-shadow: none;
    }
}

.tool-tip [disabled] {
    pointer-events: none;
}
</style>