<template>
    <div class="vi-address-group" :key="refreshKey">
        <vi-account-input
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="Address Line 1"
            v-model="addressObject.address"
            :disabled="disabled"
            ref="autoCompleteAccountInput"
            :name="getName('address')"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('address')) && errors[getErrorName('address')] ? errors[getErrorName('address')][0] : null"
            :float="false"
        ></vi-account-input>

        <vi-account-input
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="Address Line 2"
            v-model="addressObject.address_line_2"
            :disabled="disabled"
            :name="getName('address_line_2')"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('address_line_2')) && errors[getErrorName('address_line_2')] ? errors[getErrorName('address_line_2')][0] : null"
            :float="false"
        ></vi-account-input>

        <vi-account-input
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="Suburb"
            v-model="addressObject.address_suburb"
            :disabled="disabled"
            :name="getName('address_suburb')"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('address_suburb')) && errors[getErrorName('address_suburb')] ? errors[getErrorName('address_suburb')][0] : null"
            :float="false"
        ></vi-account-input>

        <vi-account-input
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="City"
            v-model="addressObject.address_city"
            :disabled="disabled"
            :name="getName('address_city')"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('address_city')) && errors[getErrorName('address_city')] ? errors[getErrorName('address_city')][0] : null "
            :float="false"
        ></vi-account-input>


        <!-- <vi-account-input
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="Country"
            v-model="addressObject.country"
            :disabled="disabled"
            :name="getName('country')"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('country')) && errors[getErrorName('country')] ? errors[getErrorName('country')] : null "
        ></vi-account-input> -->

        <account-select
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            labelText="Country"
            :disabled="disabled"
            :options="countryOptions"
            v-model="addressObject.country"
            :name="getName('country')"
            :required="required"
            :float="false"
        ></account-select>

        <account-select
            v-if="addressObject.country === 'South Africa'"
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            labelText="Province"
            :disabled="disabled"
            :options="provinceOptions"
            v-model="addressObject.address_province"
            :name="getName('address_province')"
            :required="required"
            :float="false"
        ></account-select>

        <vi-account-input
            v-else
            class="mb-3 tool-tip addressTooltip"
            :class="inputClass"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="Province"
            v-model="addressObject.address_province"
            :disabled="disabled"
            :name="getName('address_province')"
            :float="false"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('address_province')) && errors[getErrorName('address_province')] ? errors[getErrorName('address_province')][0] : null "
        ></vi-account-input>

        <vi-account-input
            class="mb-3 tool-tip addressTooltip"
            :class="[inputClass, { 'form-floating': false }]"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click edit below to edit this"
            label="Postal Code"
            v-model="addressObject.address_postal_code"
            :disabled="disabled"
            :name="getName('address_postal_code')"
            :required="required"
            :error="errors.hasOwnProperty(getErrorName('address_postal_code')) && errors[getErrorName('address_postal_code')] ? errors[getErrorName('address_postal_code')][0] : null "
            :float="false"
        ></vi-account-input>
    </div>
</template>

<script>
import ViAccountInput from './ViAccountInput.vue';
import google_address_autocomplete from '../../mixins/google_address_autocomplete';

export default {
    components: {
        ViAccountInput,
    },
    mixins: [
        google_address_autocomplete
    ],
    name: 'ViAddressGroup',
    props: {
        guardianUser: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        getName: {
            type: Function,
            default: (fieldName) => fieldName,
        },
        getErrorName : {
            type: Function,
            default: (fieldName) => fieldName,
        },
        required: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: String
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        countries: {
            type: Object,
            required: true,
        },
        provinces: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            refreshKey: 0,
            addressObject: {
                address: this.guardianUser.address,
                address_line_2: this.guardianUser.address_line_2,
                address_suburb: this.guardianUser.address_suburb,
                address_city: this.guardianUser.address_city,
                address_province: this.guardianUser.address_province,
                address_postal_code: this.guardianUser.address_postal_code,
                country: this.guardianUser.country,
            },
        };
    },
    mounted() {
        // Emit initial address.
        this.$emit('address-updated', this.addressObject);

        let autoCompleteInputNode = this.$refs.autoCompleteAccountInput.$el.querySelector('input');

        this.initAutoComplete(autoCompleteInputNode);
    },
    updated() {
        let autoCompleteInputNode = this.$refs.autoCompleteAccountInput.$el.querySelector('input');

        if (!autoCompleteInputNode.hasAttribute(this.placesAttributeMarker)) {
            this.clearAutoComplete();

            this.initAutoComplete(autoCompleteInputNode);
        }
    },
    methods: {
        autoCompleteAddressField(fieldKey, value) {
            this.addressObject[fieldKey] = value;
            this.refreshKey++;
        },
    },
    computed: {
        provinceOptions() {
            const options = [];

            this.provinces.forEach(province => {
                options.push({
                    value: province,
                    text: province,
                });
            });

            return options;
        },
        countryOptions() {
            const options = [];

            Object.values(this.countries).forEach(country => {
                options.push({
                    value: country,
                    text: country,
                });
            });

            return options;
        },
        watchedAddress() {
            return Object.assign({}, this.addressObject);
        }
    },
    watch: {
        watchedAddress: {
            handler(newVal, oldVal) {
                let oldCountry = oldVal.country;
                let newCountry = newVal.country;
                let southAfrica = 'South Africa';

                if ((oldCountry === southAfrica || newVal === southAfrica) && oldCountry !== newCountry) {
                    this.addressObject.address_province = null;
                }

                this.$emit('address-updated', this.addressObject);
            },
            deep: true,
        }
    }
}
</script>

<style lang="scss">
@import '../../../sass/abstract/colours.scss';

.vi-address-group .tool-tip [disabled] {
    pointer-events: none;
}

.vi-address-group.lls {
    .vi-account-input,
    .select-input {
        display: flex;
        flex-wrap: wrap;

        & > input,
        & > div {
            order: 2;
            margin: 0 !important;
        }

        label {
            order: 1;
            padding-left: 0 !important;
            color: $pastel-blue !important;
        }

        select {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid $pastel-blue;
            color: $pastel-blue;
        }
    }
}
</style>