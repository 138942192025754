<template>
    <form @submit.prevent="submit" method="POST" id="form" class="text-start guardian-loging-form-container mx-auto" autocomplete="off">

        <h4 class="h4 text-center mb-2">Learner Identity Verification</h4>
        <p class="text-center mb-4 body-txt-small">As part of the admissions process, UCT Online High School requires you to undergo a document verification process. Please complete the information below and read the instructions carefully before clicking 'Start document verification'.</p>

        <div class="form-floating mb-3">
            <select class="form-select new-input" name="identity_document_type" id="identity_document_type" v-model="form.identity_document_type.value" required>
                <option value="null" selected disabled>Select</option>
                <option v-for="documentType in documentTypes" :value="documentType" :key="`doc-type-${documentType}` ">{{ documentType }}</option>
            </select>

            <label for="identity_document_type">Learner's Identity Document Type</label>
            <span v-if="showErrors && !form.identity_document_type.value" class="text-danger error-message">Please select an identity document type</span>
        </div>

        <div class="form-floating mb-3">
            <input v-model.trim="form.identity_number.value" name="identity_number" id="identity_number" class="form-control new-input" />

            <label for="identity_number">Identity number / Passport number</label>

            <span v-if="showErrors && (!form.identity_number.value || form.identity_number.value.length < 8 || form.identity_number.value.length > 20)" class="text-danger error-message">Please enter a valid ID/passport number</span>
        </div>

        <p class="text-center mb-4 body-txt-small">When you click 'Start document verification' below, you will commence the document verification process. This requires you to upload a clear copy of the identity document type you have indicated above, as well as a passport style photograph of the learner.</p>

        <div class="mb-5">
            <h2 class="h6 text-center">Instructions for submitting a passport style photograph</h2>
            <ul class="body-txt-small">
                <li>Use a white door or a white wall as a background.</li>
                <li>Face the camera in a forward facing manner to avoid shadows and keep your face centered.</li>
                <li>Maintain a neutral facial expression.</li>
                <li>Don't wear sunglasses or other headgear that may cover parts of your face.</li>
            </ul>
        </div>

        <vi-error-flash
            ref="errorFlash"
            v-if="unexpectedError"
            errorMessage="We are unable to save your document verification right now. Please try again later."
        ></vi-error-flash>

        <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
            <a :href="backButtonUrl" class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

            <vi-button-spinner
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'new-btn-lg': true,
                    'mt-2': true,
                }"
                buttonText="Start Document Verification"
                :loading="loading"
            ></vi-button-spinner>
        </div>

        <div class="modal fade" id="hasExistingApplicationForId" tabindex="-1" aria-labelledby="hasExistingApplicationForIdLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-4">
                        We've detected this ID already exists as an existing learner of UCT OHS. Please reach out to our admissions team if you think there has been an error in order to continue this application.
                    </div>
                    <div class="modal-footer form">
                        <button type="button" class="new-btn new-btn-dark new-btn-sm" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import error_handling from '../mixins/error_handling';
import helper_mixin from '../mixins/helper_mixin';
import scroll_to from '../mixins/scroll_to';

const UploadDocumentTypes = Object.freeze({
    BirthCertificate : 'Birth Certificate',
    SouthAfricanID : 'South African ID',
    Passport : 'Passport',
});

export default {
    name: "GuardianLearnerDoumentVerification",
    props: {
        submitUrl: {
            type: String,
            default: ''
        },
        backButtonUrl: {
            type: String,
            default: ''
        },
        documentTypes: {
            type: Object | Array,
            default : () => ({})
        },
        idCheckUrl: {
            type: String,
            required: true,
        },
        notAllowedRedirectUrl: {
            type: String,
            required: true,
        },
        applicationId: {
            type: String,
            required: true,
        },
        documentData: {
            type: Object | Array,
            default : () => ({})
        }
    },
    data: () => ({
        form: {
            identity_document_type: {
                value: null
            },
            identity_number: {
                value: null
            }
        },
        showErrors: false,
        loading: false,
        unexpectedError: false,
        UploadDocumentTypes,

        // Mixin Settings
        errorHandlingSettings: {
            selector: '.error-message',
        }
    }),
    mixins: [
        helper_mixin,
        scroll_to,
        error_handling,
    ],
    mounted () {
        this.form.identity_document_type.value = this.documentData.type;
        this.form.identity_number.value = this.documentData.identity_number;
    },
    computed: {
        /**
         * Check if form is valid
         *
         * @return {boolean}
         */
        formIsValid() {
            return this.form.identity_document_type.value &&
                (this.form.identity_number.value && this.form.identity_number.value.length >= 8 && this.form.identity_number.value.length <= 20);
        },
    },
    methods: {
        isDocumentSelected(documentType) {
            return this.form.identity_document_type.value == documentType;
        },
        /**
        * Submit form
        *
        * @return {void}
        */
        async submit() {

            if (this.isDocumentSelected(UploadDocumentTypes.SouthAfricanID)) {
                let allowedToContinue = await this.checkIfIdDoesNotExist();

                if (!allowedToContinue) {
                    console.error('Not allowed.');
                    return;
                }
            }

            this.loading = true;
            this.unexpectedError = false;

            if (!this.formIsValid) {
                this.showErrors = true;
                this.loading = false;

                this.$nextTick(() => {
                    let firstError = this.firstError();

                    if (firstError) {
                        this.scrollIntoView(firstError.parentNode);
                    }
                });

                return;
            }

            var submitData = {
                identity_document_type : this.form.identity_document_type.value,
                identity_number : this.form.identity_number.value,
            };

            var response = await axios.post(this.submitUrl, submitData);

            if (response.status == 200) {
                if (response.data) {
                    window.location.href = response.data;
                }
            } else {
                this.loading = false;
                this.unexpectedError = true;

                this.scrollIntoView(this.$refs.errorFlash.$el);
            }
        },
        async checkIfIdDoesNotExist() {
            let data = {
                idNumber: this.form.identity_number.value,
                applicationId: this.applicationId,
            };

            let response = {};

            try {
                response = await axios.post(this.idCheckUrl, data);
            } catch (error) {
                return Promise.resolve(true);
            }

            if (response.status === 200) {
                let {
                    hasOpenApplications = false,
                    hasRetries = true,
                } = response.data;

                if (!hasRetries) {
                    // Has no retries. Redirect to dashboard with message.
                    this.setCookie('success-alert', true);

                    window.location = this.notAllowedRedirectUrl;

                    return Promise.resolve(false);
                }

                if (!hasOpenApplications) {
                    // Has no open applications with this ID and can submit.
                    return Promise.resolve(true);
                }

                // Has open applications and can retry, show popup.
                const modalElement = document.querySelector('#hasExistingApplicationForId');
                const modalInstance = new bootstrap.Modal(modalElement, {});

                modalInstance.show();

                return new Promise(resolve => {
                    modalElement.addEventListener('hide.bs.modal', () => {
                        resolve(false);
                    });
                });
            }

            return Promise.resolve(true);
        },
    },
};
</script>

<style lang="scss" scoped>
.error-message {
    font-size: 0.8em;
}
</style>