<template>
    <div class="container text-start">

        <p>You have selected "<span class="fw-bold">{{ selectedOption }}</span>" to this question “Will your learner be staying at UCT Online High School in 2025?”</p>

        <div v-if="selectedOption == 'Yes'">
            <p>We are excited to continue our learning journey with you and your learner in 2025.</p>
        </div>

        <div v-if="selectedOption == 'Unsure' || selectedOption == 'No'">
            <p v-if="selectedOption == 'Unsure'">Thank you for your response. We do hope that you will decide to continue your learning journey with us in 2025. </p>

            <ul v-if="selectedOption == 'No'">
                <li>
                    <span class="fw-bold">Important: Your response to this survey does not count as an official withdrawal request from the school</span>. Should you wish to withdraw your learner, you are required to log a withdrawal request via the Guardian Portal (as per <a href="https://infohub.uctonlinehighschool.com/en/articles/8323884-faqs-about-withdrawing-from-uct-online-high-school" target="_blank">these steps</a>) before <span class="fw-bold">Monday, 4 October 2024. Failure to do so will result in your learner remaining enrolled for the 2025 school year</span>, and you will be liable for all associated school fees. If you require assistance, contact your Support Coach.
                </li>
                <li>
                    A reminder, as per the <a href="https://assets.website-files.com/60dc7a9971e17333e8780665/64a7c1e73b57d3e024a43e64_CAPS%20_%20Fees%20Handbook_14.07.2023.pdf" target="_blank" >Fees Handbook</a>, that a three-month notice period is required when wanting to withdraw your learner from UCT Online High School. 
                </li>
            </ul>

            <div>
                <label for="reasonCategory" class="mb-3" v-if="selectedOption == 'No'">Please choose a category that best reflects your reason for why you intend to withdraw (or have already withdrawn) your learner from UCT Online High School:</label>
                <label for="reasonCategory" class="mb-3" v-if="selectedOption == 'Unsure'">Please choose a category that best reflects your reason for uncertainty about whether you intend for your learner to continue with UCT Online High School next year:</label>

                <select class="d-block form-select w-100" name="reasonCategory" id="reasonCategory" v-model="reasonCategory">
                    <option selected disabled :value="null">Select a reason</option>
                    <option v-for="(reason, display) in reasons" :key="reason" :value="reason">{{ display }}</option>
                </select>

                <span class="text-danger" v-if="displayErrors && !reasonCategory">Please select a reason</span>
            </div>

            <div class="form-group my-4">
                <label for="reasonContext" class="mb-3">Please share any additional context.</label> 
                <textarea class="form-control" id="reasonContext" rows="3" maxlength="200" v-model="reasonContext"></textarea>
                <span class="text-danger" v-if="displayErrors && !reasonContext">Please provide additional context.</span>
            </div>

            <p v-if="selectedOption == 'Unsure'"><strong>If you decide to leave UCT Online High School at the end of 2024, please be sure to formally submit a withdrawal request via the Guardian Portal (as per <a href="https://infohub.uctonlinehighschool.com/en/articles/8323884-faqs-about-withdrawing-from-uct-online-high-school" target="_blank">these steps</a>) by Monday, 4 October 2024. A friendly reminder that a three-month notice period is required for withdrawals.</strong></p>
        </div>

        <vi-alert :type="`vi-error`" v-if="selectedOption == 'No'">
            <div class="form-check me-3">
                <input class="form-check-input" type="checkbox" id="acknowledgeResponseMeaning" name="acknowledgedResponseMeaning" v-model="acknowledgedResponseMeaning">
                <label class="form-check-label" for="acknowledgeResponseMeaning">
                    I acknowledge that the response to this survey does not count as an official withdrawal request from the school.
                </label>
            </div>
        </vi-alert>

        <div class="mt-5 text-center">
            <button class="new-btn new-btn-dark new-btn-lg" @click="submitResponse" :disabled="!hasValidReasons">Save Selection</button>
        </div>
    </div>
</template>
<script>
export default {
    name: "ReEnrolmentStudentResponse",
    props: {
        studentResponse: {
            type: Object,
            required: true,
        },
        reasons: {
            type: Object,
            required: true,
        },
        selectedOption: {
            type: String|null,
            required: true,
        }
    },
    data() {
        return {
            withdrawalReasonOptions: [],
            reasonCategory: null,
            reasonContext: null,
            displayErrors: false,
            acknowledgedResponseMeaning: false,
        };
    },
    computed: {
        hasValidReasons() {
            if (this.selectedOption == 'Yes') return true;

            if (!this.reasonCategory) return false;

            if (this.reasonCategory == 'Other' && !this.reasonContext) return false;

            if (this.selectedOption == 'No' && !this.acknowledgedResponseMeaning) return false;

            return true;
        }
    },
    methods: {
        submitResponse() {
            this.displayErrors = false;

            if (!this.hasValidReasons) {
                this.displayErrors = true;
                return;
            }

            this.$emit('submit-response', {
                reasonCategory: this.reasonCategory,
                reasonContext: this.reasonContext,
                answer: this.selectedOption,
            });
        }
    }
}
</script>
