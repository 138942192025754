<template>
    <div>
        <!-- additional data form -->
        <form ref="demographicForm">
            <fieldset class="additional-data-fieldset"  v-for="(student, key, index) in students">
            <h4 class="text-start mb-3 text-light-blue">{{ student['first_name'] }} {{ student['last_name'] }}</h4>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`gender-input-${key}`">Gender<span class="required">*</span></label>
                    <select class="form-select" :id="`gender-input-${key}`" v-model="fields.gender[key]" required>
                        <option value=''>-</option>
                        <option v-for="(option, key) in genderOptions" :value="key">{{ option }}</option>
                    </select>
                    <div v-if="errors[`gender.${key}`]" class="error">
                        {{ errors[`gender.${key}`][0] }}

                    </div>
                </div>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`race-input-${key}`">Race<span class="required">*</span></label>
                    <select class="form-select" :id="`race-input-${key}`" v-model="fields.race[key]" required>
                        <option value=''>-</option>
                        <option v-for="(option, key) in raceOptions" :value="key">{{ option }}</option>
                    </select>
                    <div v-if="errors[`race.${key}`]" class="error">
                    {{ errors[`race.${key}`][0] }}
                    </div>
                </div>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`citizenship-input-${key}`">
                        Citizenship<span class="required">*</span>
                    </label>
                    <select class="form-select" :id="`citizenship-input-${key}`" placeholder="Select a Country" v-model="fields.citizenship[key]" required>
                        <option value=''>-</option>
                        <option v-for="(option, key) in countryOptions" :value="key">{{ option }}</option>
                    </select>
                    <div v-if="errors[`citizenship.${key}`]" class="error">
                        {{ errors[`citizenship.${key}`][0] }}
                    </div>
                </div>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`id-type-input-${key}`">Learner ID Type<span class="required">*</span></label>
                    <select class="form-select" :id="`id-type-input-${key}`" v-model="fields.learnerIDType[key]" required>
                        <option value=''>-</option>
                        <option v-for="(option, key) in learnerIdTypeOptions" :value="key">{{ option }}</option>
                    </select>
                    <div v-if="errors[`learnerIDType.${key}`]" class="error">
                        {{ errors[`learnerIDType.${key}`][0] }}
                    </div>
                </div>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`id-input-${key}`">ID Number<span class="required">*</span></label>
                    <input class="form-control" type="text" :id="`id-input-${key}`" placeholder="ID Number" v-model="fields.learnerID[key]" required>
                        <div v-if="errors[`learnerID.${key}`]" class="error">
                        {{ errors[`learnerID.${key}`][0] }}
                    </div>
                </div>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`province-input-${key}`">Province<span class="required">*</span></label>
                    <select class="form-select" :id="`province-input-${key}`" v-model="fields.province[key]" required>
                        <option value=''>-</option>
                        <option v-for="(option, key) in provinceOptions" :value="key">{{ option }}</option>
                    </select>
                    <div v-if="errors[`province.${key}`]" class="error">
                        {{ errors[`province.${key}`][0] }}
                    </div>
                </div>
                <div class="mb-3">
                    <label class="mb-2 d-flex" :for="`annual-income-input-${key}`">Combined annual household income<span class="required">*</span></label>
                    <select class="form-select" :id="`annual-income-input-${key}`" v-model="fields.combinedAnnualIncome[key]" required>
                        <option value=''>-</option>
                        <option v-for="(option, key) in combinedIncomeOptions" :value="key">{{ (key+1) < combinedIncomeOptions.length ? 'R ' :''}}{{ option }}</option>
                    </select>
                    <div v-if="errors[`combinedAnnualIncome.${key}`]" class="error">
                        {{ errors[`combinedAnnualIncome.${key}`][0] }}
                    </div>
                </div>


            </fieldset>
            <!-- CTA button -->
            <div class="d-flex justify-content-center justify-content-md-end align-items-center">
                <button type="button" id="add-demographic-button" class="btn-uct rounded border-0 px-3 py-2 text-white text-center " @click="submitForm" :loading="saving">
                {{ saveText }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>

    export default {
        name: "AdditionalDataForm",
        data() {
            return {
                saving: false,
                saved: false,
                saveText: 'Save',
                fields: {
                    'gender' : [],
                    'race' : [],
                    'learnerIDType' : [],
                    'learnerID' : [],
                    'citizenship' : [],
                    'province' : [],
                    'combinedAnnualIncome' : [],
                    'crmUserId' : [],
                    'email' :[],
                    'firstName' :[],
                    'lastName' :[],
                    //'dob':[],
                    'saveText': 'Save',
                },
                errors: {},
            }
        },
        props: [
            'countries',
            'students',
            'gender',
            'race',
            'learnerIdType',
            'province',
            'combinedIncome'
        ],
        watch: {
        },
        methods: {
            beginLoading() {
                // disable the button
                const submitButton = document.getElementById('add-demographic-button');
                submitButton.classList.add('disabled');
                submitButton.disabled = true;

                // disable the form elements
                const fieldSet = document.getElementsByClassName('additional-data-fieldset');
                Array.prototype.forEach.call(fieldSet, function(el) {
                    // Do stuff here
                    el.disabled = true;
                });

                this.saving = true;
                this.saveText = 'Saving...';
                this.errors = {};
            },
            endLoading() {
                // enable the button
                const submitButton = document.getElementById('add-demographic-button');
                submitButton.classList.remove('disabled');
                submitButton.disabled = false;

                // enable the form elements
                const fieldSet = document.getElementsByClassName('additional-data-fieldset');
                Array.prototype.forEach.call(fieldSet, function(el) {
                    // Do stuff here
                    el.disabled = false;
                });

                this.saving = false;
                this.saveText = 'Save';
                // this.errors will be populated by the POST response
            },
            submitForm(e) {
                this.beginLoading();
                axios.post('demographic-data/store', this.fields).then((response) => {
                    if (response.status === 200) {
                        this.errors = {}
                         this.endLoading();
                          const submitButton = document.getElementById('add-demographic-button');
                          submitButton.disabled = true;
                         alert('Learner profile information updated.');

                        // refresh the page
                         //location.reload();
                         location.replace("/uct/guardians");
                    }
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = {}
                        this.errors = error.response.data.errors || {};
                        this.endLoading();
                        return;
                    }

                    this.endLoading();
                    alert('Sorry! An error has occurred while updating your profile. Please contact Student Records for assistance on sro@uctohs.valentureonline.com.');
                });
            },
        },
        mounted() {

            this.students.map((item, index) => {
                this.fields.gender.push((item.gender !== null)  ? item.gender :'')
                this.fields.race.push((item.race !== null) ? item.race :'')
                this.fields.citizenship.push((item.citizenship !== null) ? item.citizenship :'')
                this.fields.learnerIDType.push((item.learner_id_type !== null)? item.learner_id_type :'')
                this.fields.learnerID.push(item.learner_id )
                this.fields.province.push((item.province !== null) ? item.province :'')
                this.fields.combinedAnnualIncome.push((item.combined_annual_income !== null) ? item.combined_annual_income :'')
                this.fields.crmUserId.push(item.crm_user_id)
                this.fields.email.push(item.email)
                this.fields.firstName.push(item.first_name)
                this.fields.lastName.push(item.last_name)
                //this.fields.dob.push(item.dob)
            })

        },
        computed: {
            countryOptions() {
                return this.countries.World;
            },
            genderOptions() {
                return this.gender
            },
            raceOptions() {
                return this.race
            },
            provinceOptions() {
                return this.province
            },
            learnerIdTypeOptions() {
                return this.learnerIdType
            },
            combinedIncomeOptions() {
                return this.combinedIncome
            }
        }
    };
</script>
