<template>
    <div>
        <div ref="virtualClubsModal" class="modal fade" id="virtualClubsModal" tabindex="-1" aria-labelledby="virtualClubsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <form class="modal-content" @submit.prevent="submitVirtualClubs" method="POST" id="form">
                    <div class="modal-header">
                        <h5 class="modal-title" id="virtualClubsModalLabel">Virtual Clubs</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-0">
                        <div v-if="Array.isArray(this.virtualClubs) && this.virtualClubs.length > 0">
                            <div class="modal-banner p-4">
                                <p class="text-white m-0">Welcome to the next season of Virtual Clubs here at UCT Online High School!</p>
                            </div>
                            <div class="modal-card-section p-4">
                                <p class="text-white mb-2 fw-bold">Please select only one club below:</p>
                                <div class="px-0 container">
                                    <div class="row vc-card-row mb-2">
                                        <div
                                            v-for="(option, key) in virtualClubs"
                                            :key="key"
                                            :value="key"
                                            class="col-12 col-sm-6 col-md-4"
                                        >
                                            <vi-selectable-card
                                                :id="key"
                                                :key="key"
                                                :label="option.name"
                                                :trueValue="true"
                                                :falseValue="false"
                                                :isChecked="isOptionSelected(option)"
                                                @input="(isChecked) => handleCheckboxInput(option.id, isChecked)"
                                                :value="option.id"
                                                :shouldBlockSelectedClick="false"
                                                :disabled="isOptionDisabled(option)"
                                            >
                                                <template v-slot:content>
                                                    <p class="text-center text-white">Open spaces left: {{ option.capacity - option.users_count }}</p>
                                                </template>
                                            </vi-selectable-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="p-4 w-100 text-center modal-card-section">
                            <h5 v-if="Array.isArray(this.virtualClubs) && this.virtualClubs.length === 0" class="text-white my-4">Sign-ups are currently closed, please check back in the new term!</h5>

                            <div v-else class="spinner-container">
                                <div class="spinner-border text-light spinner" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-column" v-if="this.virtualClubs">
                        <button id="virtualClubsButton" :disabled="buttonIsDisabled" type="submit" class="new-btn new-btn-dark new-btn-lg mx-auto d-block border">
                            <div v-if="loading" class="spinner-border text-light spinner" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <div v-if="!loading">Sign up</div>
                        </button>

                        <vi-error-flash
                            v-if="unexpectedError"
                            errorMessage="Oops! Something has gone wrong. Please refresh the page and try again."
                        ></vi-error-flash>
                    </div>

                    <div v-else class="modal-footer flex-column">
                        <button class="new-btn new-btn-dark new-btn-lg mx-auto d-block border" disabled>
                           Sign up
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <virtual-clubs-success-modal
            ref="successModal"
        ></virtual-clubs-success-modal>
    </div>
</template>

<script>
import ViSelectableCard from './ViSelectableCard.vue';
import VirtualClubsSuccessModal from './VirtualClubsSuccessModal.vue';

export default {
    components: {
        ViSelectableCard,
        VirtualClubsSuccessModal
    },
    name: "VirtualClubSection",
    data: () => ({
        selectModalInstance: null,
        selectedVirtualClubs: [],
        signedUpVirtualClubs: [],
        loading: false,
        virtualClubs: null,
        showModalB: false,
        unexpectedError: false,
        buttonIsDisabled: false,
        signUpResponse: null,
    }),
    props: {
        fetchAllUrl: {
            type: String,
            required: true,
        },
        signUpUrl: {
            type: String,
            required: true,
        }
    },
    computed: {
    },
    methods: {
        // Load virtual clubs from the back-end.
        // Only to be called once the modal is opened.
        init() {
            axios
                .get(this.fetchAllUrl)
                .then(res => {
                    let {
                        virtualClubs,
                        signedUpVirtualClubs
                    } = res.data;

                    this.virtualClubs = virtualClubs;
                    this.signedUpVirtualClubs = signedUpVirtualClubs.map(club => club.id);
                    this.selectedVirtualClubs = signedUpVirtualClubs.map(club => club.id);

                    // No virtual clubs available.
                    if (Array.isArray(this.virtualClubs) && this.virtualClubs.length === 0) {
                        this.buttonIsDisabled = true;
                    } else {
                        this.buttonIsDisabled = false;
                    }
                });
        },
        handleCheckboxInput(optionId, isChecked) {
            if (!isChecked) {
                let index = this.selectedVirtualClubs.indexOf(optionId);

                if (index >= 0) {
                    this.selectedVirtualClubs.splice(index, 1);
                }
            } else {
                this.selectedVirtualClubs.push(optionId);
            }
        },
        isOptionDisabled(option) {
            // This club has been signed up for. Can't be unselected.
            if (this.signedUpVirtualClubs.includes(option.id)) {
                return true;
            }

            // Require capacity to be set.
            if (!option.capacity) {
                return true;
            }

            // Check that virtual clubs still has capacity for new users.
            if (option.capacity - option.users_count <= 0) {
                return true;
            }

            // Finally only allow 2 selectedVirtualClubs, exclude selected clubs to allow users to toggle them manually.
            return this.selectedVirtualClubs.length == 1 && !this.selectedVirtualClubs.includes(option.id);
        },
        isOptionSelected(option) {
            return this.selectedVirtualClubs.includes(option.id);
        },
        submitVirtualClubs() {
            this.unexpectedError = false;
            this.loading = true;

            axios
                .post(this.signUpUrl, {
                    virtualClubs: this.selectedVirtualClubs.filter(selectedId => !this.signedUpVirtualClubs.includes(selectedId)),
                })
                .then(response => {
                    this.unexpectedError = false;
                    this.loading = false;
                    this.buttonIsDisabled = true;

                    this.successModal.setResponseData(response.data);

                    this.selectModalInstance.hide();
                    this.successModal.show();

                    // Clear internal state.
                    this.virtualClubs = null;
                    this.signedUpVirtualClubs = [];
                    this.selectedVirtualClubs = [];
                })
                .catch(error => {
                    this.unexpectedError = true;
                    this.loading = false;

                    this.selectModalInstance.hide();
                    this.successModal.show();

                    this.buttonIsDisabled = false;
                    this.signUpResponse = error;
                });
        },
    },
    mounted() {
        this.selectModalInstance = new bootstrap.Modal(this.$refs.virtualClubsModal, {});
        this.successModal = this.$refs.successModal;

        this.$refs.virtualClubsModal.addEventListener('show.bs.modal', event => {
            this.init();
        });
    },
}
</script>