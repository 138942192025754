<template>
    <section>
        <scholarship-progress-bar></scholarship-progress-bar>
        <slot></slot>

        <loading-overlay></loading-overlay>
    </section>
</template>

<script>
export default {
    name: "ScholarshipLayout",
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';
</style>
