<template>
    <form @submit.prevent="submit" method="POST" id="form" class="text-start guardian-loging-form-container mx-auto" autocomplete="off">

        <h4 class="h4 text-center mb-3">Submit Learner's Passport Style Photo</h4>

        <div class="mb-5">
            <h2 class="h6 text-center">Instructions for submitting a passport style photograph</h2>
            <ul class="body-txt-small">
                <li>Use a white door or a white wall as a background.</li>
                <li>Face the camera in a forward facing manner to avoid shadows and keep your face centered.</li>
                <li>Maintain a neutral facial expression.</li>
                <li>Don't wear sunglasses or other headgear that may cover parts of your face.</li>
                <li>Photo must be one of the following formats: PNG, JPEG, PDF.</li>
                <li>Photo must be less than 2MB in size.</li>
            </ul>
        </div>

        <div class="file-selector-wrapper mb-5">
            <vi-file-selector
                upload-field-name="passportStylePhoto"
                :handle-upload="handleUpload"
                :max-file-size="2000000"
                accepted-file-types="image/*, .pdf"
            ></vi-file-selector>
        </div>

        <p class="body-txt-small text-center mb-3">If you have any queries or concerns, please contact the Student Records Team at <a href="mailto:sro@uctohs.valentureonline.com">sro@uctohs.valentureonline.com</a></p>

        <div v-if="submitError" ref="submissionError" class="alert alert-danger">
            {{ submitError }}
        </div>

        <vi-error-flash
            v-if="unexpectedError"
            ref="errorFlash"
            errorMessage="We are unable to save your photo right now. Please try again later."
        ></vi-error-flash>

        <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
            <a :href="backButtonUrl" class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

            <button
                @click.prevent="handleNext"
                id="appStepThreeButton"
                :disabled="!hasUploadedFile || loading"
                type="submit"
                class="new-btn new-btn-dark new-btn-sm mt-2"
            >
                <template v-if="!loading">Confirm</template>

                <div v-else class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
        </div>
    </form>
</template>

<script>
import axios from 'axios';
import scroll_to from '../mixins/scroll_to';

    export default {
        name: 'PassportStylePhotoStep',
        mixin: [
            scroll_to,
        ],
        props: {
            applicationId: {
                type: String,
                required: true,
            },
            passportStylePhotoUrl: {
                type: String,
                required: true,
            },
            backButtonUrl: {
                type: String,
                required: true,
            },
            nextButtonUrl: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                hasUploadedFile: false,
                submitError: null,
                loading: false,
                unexpectedError: false,
                disableSubmit: false,
            };
        },
        computed: {},
        methods: {
            async handleUpload(formData) {
                formData.append('applicationId', this.applicationId);

                const headers = {
                    'Content-Type': 'multipart/form-data',
                };

                return axios.post(this.passportStylePhotoUrl, formData, headers)
                    .then(msg => {
                        this.hasUploadedFile = true;

                        return 'Photo successfully uploaded.';
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('message') && error.response.data.message) {
                            return Promise.reject(error.response.data.message);
                        }

                        return Promise.reject('An error has occurred, please retry.');
                    });
            },
            handleNext() {
                if (!this.hasUploadedFile) {
                    return;
                }

                this.loading = true;

                axios.post(this.nextButtonUrl)
                    .then(data => {
                        window.location = data.data.redirect;
                    })
                    .catch(error => {
                        this.unexpectedError = true;
                        this.loading = false;

                        this.$nextTick(() => {
                            this.scrollIntoView(this.$refs.errorFlash.$el);
                        });
                    });
            },
            onBeforeResetHandler() {
                this.hasUploadedFile = false;
            }
        },
    }
</script>