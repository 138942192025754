<template>
    <scholarship-layout>
        <router-view></router-view>
    </scholarship-layout>
</template>

<script>
    export default {
        name: "ScholarshipApplication",
        props: {
            entryRoute: {
                type: String,
                required: true,
                default: 'scholarship-application.eligible.intro-screen'
            },
            endpointsConfig: {
                type: Object,
                default: () => {}
            },
            canMakeNewApplication: {
                type: Boolean,
                default: true
            },
        },
        mounted() {
            this.$router.push({name: this.entryRoute});
            this.$setEndPoints(this.endpointsConfig);
        }
    }
</script>