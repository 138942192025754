<template>
    <div class="vi-manage-exam-sitting my-3">
        <h5 class="fw-bold text-white">Manage Exam Sitting</h5>

        <template v-if="initializing === false">
            <template v-if="!isDisabled">
                <div class="info mb-3">
                    <p v-if="currentExamSitting" class="m-0 text-white">Current Exam Sitting : &nbsp;{{ currentExamSitting.name }}</p>
                </div>

                <small class="d-block mb-3 text-white">
                    Changes to exam sittings are effective within 24 hours from receiving your request. When changing your exam sitting, workplan on the Online Campus will be re-adjusted to start from Module 1 this month, targeting your new targeted exam date.
                </small>

                <div v-if="error" v-html="errorMsg" class="alert alert-danger"></div>

                <div v-if="success" class="alert alert-success">Your exam sitting has been successfully updated.</div>

            </template>

            <template v-else>
                <div class="alert alert-warning">Changing your Exam Sitting has been disabled due to your learning being {{ isPaused ? 'paused' : 'suspended' }}.</div>
            </template>
            <vi-button-spinner
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'w-auto': true,
                    'px-5': true,
                }"
                buttonText="Change Exam Sitting"
                :loading="loading"
                @button-click="() => openExamSittingModal()"
                :disabled="isDisabled || !canUpdateSitting"
            ></vi-button-spinner>
        </template>

        <loading-data v-else-if="initializing"></loading-data>

        <div v-else class="alert alert-danger">
            An error occured loading this section. Please contact support if this problem persists at <a href="mailto:techsupport@uctohs.valentureonline.com">techsupport@uctohs.valentureonline.com</a>.
        </div>

        <!-- Subject Selection Modal -->
        <div ref="examSittingChangeModal" class="modal fade" id="examSittingChangeModal" tabindex="-1" aria-labelledby="examSittingChangeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="examSittingChangeModalLabel">Update Exam Sitting</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body px-5">
                        <form @submit.prevent="submit">
                            <div class="form-group mb-5">
                                <label for="examSitting" class="form-label">Select Exam Sitting</label>

                                <div class="row">
                                    <div v-for="(option, key) in sittingOptions" :key="key" class="col-4">
                                        <vi-selectable-card
                                            class="theme-dark"
                                            :key="key"
                                            :label="option.name"
                                            :trueValue="option.id"
                                            v-model="selectedExamSitting"
                                            :isChecked="selectedExamSitting === option.id"
                                            :disabled="currentExamSitting.id === option.id"
                                            name="selectedExamSitting"
                                        >
                                        </vi-selectable-card>
                                    </div>
                                </div>
                            </div>

                            <vi-alert>
                                <div class="form-check d-flex">
                                    <input class="form-check input" v-model="acknowledgeDisclaimer" type="checkbox" value="" id="acknowledge" required>
                                    <label class="form-check-label ms-2" for="acknowledge">
                                        Your workplan on the Online Campus will be re-adjusted to start from Module 1 this month, targeting your new targeted exam date. Your new workplan will reflect within 24 hours.
                                    </label>
                                </div>
                            </vi-alert>

                            <vi-alert :type="`vi-error`" v-if="submitError">
                                {{ submitError }}
                            </vi-alert>

                            <vi-button-spinner
                                :buttonClass="{
                                    'new-btn': true,
                                    'new-btn-dark': true,
                                    'w-auto': true,
                                    'px-5': true,
                                }"
                                buttonText="Update"
                                :loading="loading"
                            ></vi-button-spinner>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let endpoints = window.endpoints.manageExamSitting;

export default {
    name: 'ManageExamSitting',
    data() {
        return {
            initializing: true,
            loading: true,
            error: false,
            errorMsg: '',
            success: false,
            successMsg: '',
            currentExamSitting: '',
            isDisabled: false,
            isPaused: false,
            canUpdateSitting: false,
            selectedExamSitting: null,
            sittingOptions: [],
            acknowledgeDisclaimer: false,
            submitError: null,
        }
    },
    async mounted() {
        await this.fetchExamSittingInfo();
        
    },
    methods: {
        openExamSittingModal() {
            const modal = new bootstrap.Modal(this.$refs.examSittingChangeModal, {
                backdrop: 'static',
                keyboard: false
            });
            modal.show();
        },
        async fetchExamSittingInfo() {
            try {
                const { data } = await axios.get(endpoints.init);
                
                this.currentExamSitting = data.currentExamSitting;
                this.selectedExamSitting = data.currentExamSitting ? data.currentExamSitting.id : null;
                this.isDisabled = data.isDisabled;
                this.isPaused = data.isPaused;
                this.canUpdateSitting = data.canUpdateSitting;
                this.sittingOptions = data.sittingOptions;

                this.initializing = false;
                this.loading = false;
            } catch (error) {
                this.error = true;
                this.errorMsg = error.response.data.message;

                this.initializing = false;
                this.loading = false;
            }
        },
        async submit() {
            this.loading = true;
            this.submitError = null;

            if (!this.selectedExamSitting) {
                this.submitError = 'Please select an exam sitting.';
                this.loading = false;
                return;
            }

            if (this.selectedExamSitting === this.currentExamSitting.id) {
                this.submitError = 'You have selected the same exam sitting as your current exam sitting.';
                this.loading = false;
                return;
            }

            const formData = {
                exam_sitting: this.selectedExamSitting,
            };

            try {
                const { data } = await axios.post(endpoints.submit, formData);
                await this.fetchExamSittingInfo();

                this.successMsg = data.message;
                this.success = true;

                // closeModal
                const modal = bootstrap.Modal.getInstance(this.$refs.examSittingChangeModal);
                modal.hide();
            } catch (error) {
                if (error.hasOwnProperty('response')) {

                    if (error.response.hasOwnProperty('data')) {
                        this.submitError = error.response.data.message;
                        this.loading = false;
                        return;
                    }
                }
                this.submitError = 'An error occurred. Please try again later.';
            }
            this.loading = false;
        }
    },

}
</script>