<template>
  <div class="vi-table">
    <vi-table-row
      :headings="headings"
      :dataSet="headings"
      :isHeading="true"
      v-if="displayHeadings"
    />

    <vi-table-row
      v-for="(rowData, key) in data"
      :key="rowData.id"
      :headings="headings"
      :dynamic-column="dynamicColumn"
      :data-set="rowData"
      :query-url="queryUrl"
      :email-for-grading-query="emailForGradingQuery"
      :assessment-submissions-view="assessmentSubmissionsView"
      :term-year-query-data="termYearQueryData"
      :grading-query-upload-url="gradingQueryUploadUrl"
    />
  </div>
</template>

<script>
export default {
  props: {
    headings: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true,
    },
    displayHeadings: {
      type: Boolean,
      default: true,
    },
    dynamicColumn: {
      type: Object,
      default: null,
    },
    queryUrl: {
      type: String,
      required: false,
    },
    emailForGradingQuery: {
      type: String,
      required: false,
    },
    termYearQueryData: {
      type: Array,
      required: false,
      default: () => [],
    },
    assessmentSubmissionsView: {
      type: String,
      required: false,
      default: 'uct_assessment_submissions',
    },
    gradingQueryUploadUrl: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      dataSet: this.data,
    };
  },
}
</script>

<style>
.vi-table {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .vi-table {
    flex-wrap: wrap;
  }
}
</style>