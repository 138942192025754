<template>
    <div>
        <form @submit.prevent="validateAndConfirm" method="POST" class="text-start">
            <p v-if="!isFinalSubjectSelection" class="mb-3" >The following subjects are mandatory (compulsory):</p>

            <p v-else class="mb-3"> Select the subjects you <strong>want to take</strong> when you join UCT Online High School. The following subjects are pre-selected with no alternatives. </p>

            <ul class="list-unstyled">
                <li v-for="(subjectName, subjectCode) in preSelectedSubjects" :key="`pre-selected-${subjectCode}`" class="fw-bold">
                <i class="bi bi-check-circle"></i>
                    {{subjectName}}
                </li>
            </ul>

            <p class="my-3">Select one subject from each group below. Please note that two subjects cannot be selected from the same group.</p>

            <div class=" mb-3 mt-5 mt-sm-0">
                <select class="form-select new-input" name="additional_language" v-model="selectedLanguage">
                    <option :value="null" disabled="disabled" selected="selected">Select...</option>
                    <option v-for="(subjectName, subjectCode) in languageSubjects" :value="subjectCode" :key="`langage-${subjectCode}`">{{subjectName}}</option>
                </select>

                <label class="long-label-select" for="additional_language">Please select a First Additional Language (FAL)</label>

                <span v-if="showErrors && !selectedLanguage" class="text-danger">You must select a First Additional Language</span>
            </div>

            <div>
                <div class="form-floating mb-3">
                    <select class="form-select new-input" name="mathematics_subject" v-model="selectedMathematics">
                        <option :value="null" disabled="disabled" selected="selected">Select...</option>
                        <option v-for="(subjectName, subjectCode) in mathematicsSubjects" :value="subjectCode" :key="`mathematics-${subjectCode}`">{{subjectName}}</option>
                    </select>

                    <label for="mathematics_subject">Please select a Mathematics Subject</label>

                    <span v-if="showErrors && !selectedMathematics" class="text-danger">You must select a Mathematics Subject</span>
                </div>

                <div v-if="selectedMathematics">
                    <p class="mb-1">Please select only <u><strong>three</strong></u> additional subjects.</p>
                    <ul class="list-unstyled">
                        <li v-for="(subjectName, subjectCode) in extraSubjects" :key="`extra-${subjectCode}`">

                            <div v-if="isAllowed(subjectCode)" class="form-check">
                                <input type="checkbox" class="form-check-input" :id="`select-subject-${subjectCode}`" :value="subjectCode" v-model="selectedExtras">
                                <label class="form-check-label" :for="`select-subject-${subjectCode}`">
                                    {{subjectName}}
                                </label>
                            </div>

                        </li>
                    </ul>
                    <span v-if="showErrors && selectedExtras.length != 3" class="text-danger">Please select three additional subjects.</span>
                </div>
            </div>

            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to save your subject selection right now. Please try again later."
            ></vi-error-flash>

            <div class="d-flex justify-content-between flex-wrap w-100 mt-5">
                <a :href="backButtonUrl" class="new-btn new-btn-dark new-btn-sm mt-2 me-0 me-sm-2">Back</a>

                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-lg': true,
                        'mt-2': true,
                    }"
                    buttonText="Confirm"
                    :loading="loading"
                ></vi-button-spinner>
            </div>
        </form>

        <div class="modal fade" id="confirmSubjectsModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="confirmSubjectsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md">
                <div class="modal-content">
                    <div v-if="isFinalSubjectSelection" class="modal-body text-center p-4">
                        <p>Please confirm you would like to proceed with the subject confirmations selected. A member of Student Records will review these selections and be in touch if anything needs to be discussed.</p>
                    </div>

                    <div v-else class="modal-body text-center p-4">
                        <p>Thank you for making your subject selections. Please confirm your selection of the following subjects:</p>
                        <ul class="list-unstyled">
                            <li v-for="(subjectName, index) in allSelectedSubjects" :key="`selected-confirm-${index}`">
                                {{subjectName}}
                            </li>
                        </ul>
                        <p class="fw-bold text-dark my-3">What happens next?</p>
                        <p>After you click 'Confirm Subject Selection', your application will be reviewed based on the information you have given us. You will then receive an email within 72 hours about the next steps.</p>

                        <p class="fw-bold text-dark my-3">This is an additional reminder that these subject selections are provisional and you will have the opportunity to review and finalise the selection of subjects during the onboarding phase.</p>

                        <p class="mb-0">If you would like to revisit your subject selection, click 'Go back to subject selection. If you are happy with your subject selection, click 'Confirm subject selection.'</p>
                    </div>
                    <div class="modal-footer p-3 mb-2 justify-content-center">
                        <button type="button" class="btn-outline-uct rounded-pill px-5 py-2 text-decoration-none mt-2 me-sm-2 me-0 ms-0" data-bs-dismiss="modal">Go back to subject selection</button>
                        <button type="button" class="btn-outline-uct-dark rounded-pill px-5 py-2 text-decoration-none mt-2 mx-0" @click="submit">Confirm subject selection</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import helperMixin from "../mixins/helper_mixin";
export default {
    name: 'GuardianLearnerAppCapsSubjectSelection',
    props: {
        submitUrl: {
            type: String,
            default: ''
        },
        backButtonUrl: {
            type: String,
            default: ''
        },
        userId: {
            type: Number,
        },
        subjectSelectionType: {
            type: String,
            default: ''
        },
        application: {
            type: Object,
            default : () => ({})
        },
        preSelectedSubjects: {
            type: Object,
            default : () => ({})
        },
        languageSubjects : {
            type: Object,
            default : () => ({})
        },
        mathematicsSubjects : {
            type: Object | Array,
            default : () => ({})
        },
        extraSubjects : {
            type: Object | Array,
            default : () => ({})
        }
    },
    data: () => ({
        selectedLanguage: null,
        selectedMathematics: null,
        selectedExtras: [],
        showErrors : false,
        loading : false,
        unexpectedError : false,
    }),
    mixins: [helperMixin],
    mounted() {
        this.selectedLanguage = this.application.additional_language ?? null;
        this.selectedMathematics = this.application.mathematics_subject ?? null;
        this.selectedExtras = this.application.additional_subjects ?? [];
    },
    computed: {

        isFinalSubjectSelection() {
            return this.subjectSelectionType == 'final';
        },

        /**
         * Check if form is valid
         *
         * @return {boolean}
         */
        formIsValid() {

            if (this.selectedMathematics == 'MAL') {
                this.selectedExtras = this.selectedExtras.filter(subjectCode => (subjectCode != 'PHS'));
            }

            return this.selectedLanguage != null && this.selectedMathematics != null && this.selectedExtras.length == 3;
        },

        /**
         * Get all subjects
         *
         * @return {object}
         */
        allSubjects() {
            return {
                ...this.preSelectedSubjects,
                ...this.languageSubjects,
                ...(this.mathematicsSubjects ?? {}),
                ...this.extraSubjects
            };
        },

        /**
         * Get all selected subjects
         *
         * @return {string[]}
         */
        allSelectedSubjects() {
            let allSubjectKeys = new Set([
                ...Object.keys(this.preSelectedSubjects),
                ...[this.selectedLanguage],
                ...[this.selectedMathematics],
                ...this.selectedExtras
            ]);

            let allSelectedSubjects = Object.keys(this.allSubjects).filter(key => allSubjectKeys.has(key) ).map(key => this.allSubjects[key]);

            return allSelectedSubjects;
        }
    },
    methods: {

        /**
         * Check if subject is allowed for Caps Fet phase
         *
         * @param {string} subjectCode
         * @return {boolean}
         */
        isAllowed(subjectCode) {
            return this.selectedMathematics == 'MAT' || subjectCode != 'PHS';
        },

        /**
         * Validate form and show confirmation modal
         *
         * @return {void}
         */
        validateAndConfirm() {
            if (!this.formIsValid) {
                this.showErrors = true;
                return;
            }
            var confirmSubjectsModal = new bootstrap.Modal(document.getElementById('confirmSubjectsModal'), {
                keyboard: false
            });

            confirmSubjectsModal.show();
        },

        /**
         * Submit subject selection form
         *
         * @return {void}
         */
        async submit() {
            this.loading = true;
            this.unexpectedError = false;

            const subjectData = {
                id : this.application.id,
                user_id: this.userId,
                subject_selection_type: this.subjectSelectionType,
                subjects: Object.keys(this.preSelectedSubjects),
                additional_language: this.selectedLanguage,
                mathematics_subject: this.selectedMathematics,
                additional_subjects: this.selectedExtras,
            };

            try {
                var response = await axios.post(this.submitUrl, subjectData);

                if (response.status == 200) {
                    if (response.data) {
                        window.unsaved = false;
                        this.triggerGa();
                        this.setCookie('success-alert', true);
                        window.location.href = response.data;
                    }
                } else {
                    this.unexpectedError = true;
                }

            } catch(error) {
                this.unexpectedError = true;
            }
        },
        triggerGa() {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                'event': 'preliminarySubjectSelectionAdultMatric',
                'userid': window.valenture.userId,
            });
        }
    },
}
</script>
