<template>
    <div>
        <vi-alert :type="alertClass">
            <div class="row">
                <div class="col-12">
                    <div class="form-floating">
                        <input
                            type="text"
                            :name="inputId"
                            :id="inputId"
                            class="form-control new-input"
                            :placeholder="title"
                            v-model.trim="code"
                        >

                        <label :for="inputId" class="form-label">{{ title }}</label>
                    </div>

                    <p class="mb-0 mt-2" v-if="responseMessage">{{ responseMessage }}</p>
                </div>
            </div>
        </vi-alert>

        <vi-alert>Promotional discounts will only be applied if all enrolment criteria are met as per <a target="_blank" href="https://cdn.prod.website-files.com/60dc7a9971e17333e8780665/67233e05ca2241ffbef878e8_UCT%20OHS_Tuition%20Fee%20Discount_T%26Cs.pdf">the T&Cs</a>.</vi-alert>
    </div>
</template>
<script>
export default {
    name: 'PromoCode',
    data() {
        return {
            code: '',
            alertClass: 'alert-transparent',
            typingDebounceTimer: 3000,
            responseMessage: '',
        };
    },
    props: {
        inputId: {
            type: String,
            required: true,
        },
        minLength: {
            type: Number,
            default: 3,
        },
        title: {
            type: String,
            required: true,
        },
        validateCodeUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        validateCode() {

            axios.post(this.validateCodeUrl, {
                promoCode: this.code,
            }).then(response => {
               if (response.data.valid) {
                   this.alertClass = 'alert-success';
                   this.responseMessage = response.data.message;

                    this.$emit('update-promo-code', {promoCode: this.code});
               } else {
                    this.alertClass = 'alert-danger';
                    this.responseMessage = response.data.message;
                    this.$emit('update-promo-code', {promoCode: null});
               }
            })
            .catch(error => {
                this.alertClass = 'alert-danger';
                this.$emit('update-promo-code', {promoCode: null});

                if (error.response.status === 400) {
                    this.responseMessage = error.response.data.message;
                    return;
                }

                this.responseMessage = 'An unexpected error occurred while validating the promo code. Please try again later.';
            });
        },
    },
    watch: {
        code(newVal) {
            this.responseMessage = '';
            clearTimeout(this.typingDebounceTimer);

            this.typingDebounceTimer = setTimeout(() => {
                if (newVal.length < this.minLength) {
                    this.alertClass = 'alert-transparent';
                    this.$emit('update-promo-code', {promoCode: null});
                    return;
                }
                
                this.validateCode();
            }, 1000);
        }
    }
}
</script>
