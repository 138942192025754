<template>
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="requestSubjectChange">Request Subject Change</h1>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
            <template v-if="initializing == false">
                <vi-subject-change-requests-deadline-passed
                    v-if="hasDeadlinePassed"
                ></vi-subject-change-requests-deadline-passed>

                <div class="modal-body text-center px-5" v-else-if="limitReached">
                    <p>We regret to inform you that the limit for subject change requests has been reached for this academic year, and we are unable to process any further changes.</p>
                    <p>For additional information on subject changes and submissions dates please visit our info hub article <a href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE" target="_blank">here.</a></p>
                    <p>If you have any questions or need further assistance, please contact your Support Coach.</p>
                </div>

                <div class="modal-body text-center px-5" v-else-if="hasPendingRequest">
                    <p>Your current subject change request is still being processed. We’re unable to submit a new request until the pending one is completed.</p>
                    <p>For detailed information on subject changes, submission dates, and guidelines, please visit our info hub article <a href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE" target="_blank">here.</a></p>
                    <p>If you have any questions or need further assistance, please contact your Support Coach.</p>
                </div>

                <vi-subject-change-requests-success
                    v-else-if="success"
                ></vi-subject-change-requests-success>

                <vi-subject-change-requests-change-request
                    v-else
                    :learner-sf-id="learnerSfId"
                    :grade="learner.grade"
                    :name="learner.name"
                    :request-form-upload-url="requestFormUploadUrl"
                    :motivation-letter-upload-url="motivationLetterUploadUrl"
                    :submit-url="changeRequestSubmitUrl"
                    :motivation-letter-required="motivationLetterRequired"
                    :subject-change-form-url="subjectChangeFormUrl"
                    :motivation-letter-template-url="motivationLetterTemplateUrl"
                    v-on:success="onSuccessHandler"
                ></vi-subject-change-requests-change-request>
            </template>

            <template v-else-if="initializing == null">
                <div class="alert alert-danger">
                    An error has occured. Please refresh the page. If the error persists please reach out to our support team.
                </div>
            </template>

            <template v-else-if="initializing == true">
                <div class="placeholder-glow">
                    <span class="placeholder w-75"></span>
                    <span class="placeholder w-50"></span>
                    <span class="placeholder w-75"></span>
                    <span class="placeholder w-25"></span>
                    <span class="placeholder w-25"></span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalBase",
    props: {
        learnerSfId: {
            type: String,
            required: true,
        },
        initUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Component State
            initializing: true, // true - busy, false - done(success), null - error
            hasRegistered: false,
            success: false,

            // Data
            learner: {
                name: null,
                grade: null,
            },
            hasDeadlinePassed: null,
            motivationLetterUploadUrl: null,
            requestFormUploadUrl: null,
            changeRequestSubmitUrl: null,
            motivationLetterRequired: false,
            subjectChangeFormUrl: null,
            motivationLetterTemplateUrl: null,
            limitReached: false,
            hasPendingRequest: false,
        };
    },
    mounted() {
        let modalNode = document.querySelector(`#requestSubjectChange-${this.learnerSfId}`);

        if (!this.hasRegistered) {
            modalNode.addEventListener('shown.bs.modal', () => {
                this.initializing = true;

                this.init()
                    .then(() => {
                        this.initializing = false;
                        this.success = false; // Component was reinitialized, success needs to be determined again.
                    })
                    .catch(err => {
                        this.initializing = null;
                    });
            });

            this.hasRegistered = true;
        }
    },
    methods: {
        init() {
            return axios.get(this.initUrl)
                .then(res => {
                    const data = res.data;

                    this.hasDeadlinePassed = data.hasDeadlinePassed;

                    this.learner.name = data.learner.name;
                    this.learner.grade = data.learner.grade;

                    this.motivationLetterUploadUrl = data.motivationLetterUploadUrl;
                    this.requestFormUploadUrl = data.requestFormUploadUrl;
                    this.changeRequestSubmitUrl = data.changeRequestSubmitUrl;
                    this.motivationLetterRequired = data.motivationLetterRequired;
                    this.subjectChangeFormUrl = data.subjectChangeFormUrl;
                    this.motivationLetterTemplateUrl = data.motivationLetterTemplateUrl;
                    this.limitReached = data.reachedLimit;
                    this.hasPendingRequest = data.hasPendingRequest;
                });
        },
        onSuccessHandler() {
            this.success = true;

            // Trigger reinitialization of the base card shown in the learner section.
            const event = new Event("subject-change-request:init");

            window.dispatchEvent(event);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
