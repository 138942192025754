<template>
    <div class="container text-start px-5">

        <form v-if="showPreRegistrationForm" @submit.prevent="submit">
            <h1 class="text-center h2">Hello Matric Guardian!</h1>

            <p>We are excited to inform you that we have received your learner’s pre-registration document from SACAI. What a wonderful achievement to be at this point of the academic journey with your learners. We know a lot of hard work has gone into getting here!</p>
            <p>As you prepare with your learner for the final year-end, in-person NSC exams, it is important for you to carefully review the registration information we have documented for your learner. This is the information on file for your learner’s exam registration with SACAI, so it is critical that all information is accurate.</p>
            <p>While we take the utmost care to ensure that each learner’s information is correct and accurate, <strong><u>we do require you, as the guardian, to check and validate that the information is correct.</u></strong></p>
            <p>All pre-registration forms need to be checked by the end of <strong>{{ preRegistrationDeadline }}.</strong> </p>
            <p><strong> We are not able to accept any changes to pre-registration documents after this date and therefore it is critical that the information in the document has been checked.</strong> Should the document not be checked and incorrect information is submitted to the examination body, your learner may have errors on their final certificate or be adversely affected when trying to write their examinations.</p>
            
            <h2 class="h4 mb-3">Step 1: Review your learner’s pre-registration document</h2>
            
            <p>Please complete a thorough review of the information contained within your learner’s pre-registration document, paying careful attention to information contained on each page as outlined below.</p>
            <p>The document can be found <a :href="preRegistrationFormUrl" target="_blank">here</a> .</p>
            
            <h3 class="h6 mb-2">Page 1</h3>
            <ul>
                <li>Your learner’s first name and surname (ensure that the spelling is correct; this needs to be the same as what is currently on your learner’s official ID document).</li>
                <li>Your learner’s ID number</li>
                <li>Your learner’s date of birth</li>
                <li>The list of subjects they are registered for</li>
            </ul>

            <h3 class="h6 mb-2">Page 2</h3>
            <ul>
                <li>Subjects registered with other institutions (if applicable) </li>
            </ul>
            
            <h2 class="h4 mb-3">Step 2: Review outcome</h2>
            
            <p>Once you have checked the pre-registration information, please answer the following question:</p>

            <vi-alert>
                <label for="" class="form-label">Is the information contained within your learner’s G12 pre-registration document accurate?</label>
                <div class="form-check me-3">
                    <input class="form-check-input" type="radio" id="registrationInformationTrue" name="registrationInformationConfirmed" value=true v-model="registrationInformationConfirmed">
                    <label class="form-check-label" for="registrationInformationTrue">
                        Yes - the information contained within is accurate
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" id="registrationInformationFalse" name="registrationInformationConfirmed" value=false v-model="registrationInformationConfirmed">
                    <label class="form-check-label" for="registrationInformationFalse">
                        No - the information contained within is not accurate and updates are required
                    </label>
                </div>

                <p class="text-danger my-2" v-if="showErrors && registrationInformationConfirmed == null">Please confirm if the information in the pre-registration form is correct</p>

            </vi-alert>
            
            <h2 class="h4 mb-3">Step 3: Updates to your learner’s pre-registration document</h2>
            
            <p>In the case that changes need to be made to the information contained in your learner’s pre-registration document, please provide these updates by completing this <a :href="preRegistrationQueriesFormUrl" target="_blank">form</a>.</p>
            
            <h2 class="h4 mb-3">Step 4: Submission</h2>
            
            <p>Once you have completed the steps above, click the submit button below to complete the review of your learner’s pre-registration document.</p>
            
            <button class="uct-btn-link mt-2 mb-4 mx-auto text-center">Submit</button>

            <vi-alert type="error" v-if="unexpectedError">An unexpected error occurred. Please try again.</vi-alert>
        </form>

        <vi-alert v-if="showSuccessAlert" class="text-center">
            <p>Thank you for reviewing the pre-registration document. You have successfully completed the review process.</p>
            <p>Should you have any queries or require further assistance, please do not hesitate to contact us.</p>
            <button class="uct-btn-link mt-2 mb-4 mx-auto text-center" @click="redirectToDashboard">Return to dashboard</button>
        </vi-alert>

        <vi-alert v-if="showQueryForm" class="text-center">
            <p>Thank you for reviewing the pre-registration document. We have noted that you have indicated that there are updates required to the information contained within the document.</p>
            <p>Please complete the form found <a :href="preRegistrationQueriesFormUrl" target="_blank">here</a> to provide the necessary updates.</p>
            <button class="uct-btn-link mt-2 mb-4 mx-auto text-center" @click="redirectToDashboard">Return to dashboard</button>
        </vi-alert>

        <loading-overlay></loading-overlay>
    </div>
</template>

<script>
export default {
    name: "ReviewG12PreRegistrationForm",
    components: {},
    props: {
        preRegistrationSubmitEndpoint: {
            type: String,
            required: true,
        },
        preRegistrationDeadline: {
            type: String,
            required: true,
        },
        preRegistrationFormUrl: {
            type: String,
            required: true,
        },
        preRegistrationQueriesFormUrl: {
            type: String,
            required: true,
        },
        dashboardUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            registrationInformationConfirmed: null,
            showErrors: false,
            showSuccessAlert: false,
            showQueryForm: false,
            showPreRegistrationForm: true,
            unexpectedError: false,
        };
    },
    methods: {
        submit(e) {
            e.preventDefault();
            $('#submitOverlay').modal('show');

            this.showErrors = false;
            this.unexpectedError = false;

            if (this.registrationInformationConfirmed == null) {
                this.showErrors = true;
                $('#submitOverlay').modal('hide');
                return;
            }

            let submitData = {
                preRegistrationFormReviewed: true,
            };

            axios.post(this.preRegistrationSubmitEndpoint, submitData)
                .then(response => {
                    $('#submitOverlay').modal('hide');
                    this.showPreRegistrationForm = false;
                    if (this.registrationInformationConfirmed == 'true') {
                        this.showSuccessAlert = true;
                    } else {
                        this.showQueryForm = true;
                    }
                })
                .catch(error => {
                    $('#submitOverlay').modal('hide');
                    console.error(error);
                    this.showPreRegistrationForm = true;
                    this.unexpectedError = true;
                });
        },

        redirectToDashboard() {
            window.location.replace(this.dashboardUrl);
        }
    },
};
</script>
