<template>
    <div>
        <form @submit.prevent="submit" method="POST" id="form" class="text-start guardian-loging-form-container mx-auto">
            <h4 class="mb-3 text-dark">Information about current schooling</h4>

            <div class="mb-3 form-floating">
                <label for="currently_at_school" class="long-label">Currently at school / home school / online school?</label>
                <select name="currently_at_school" id="currently_at_school" class="form-select new-input long-label-select" v-model.trim="form.currently_at_school.value">
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(item,key) in applicationModel.yesNo" :value="key" :key="key">{{ item }}</option>
                </select>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['currently_at_school']" v-html="errors['currently_at_school'][0]"></p>
            </div>

            <div class="mb-3 form-floating">
                <select name="current_school_type" id="current_school_type" class="form-select new-input" v-model.trim="form.current_school_type.value">
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(item,key) in applicationModel.currentSchoolType" :value="key" :key="key">{{ item }}</option>
                </select>
                <label for="current_school_type" class="form-label">Current school type</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['current_school_type']" v-html="errors['current_school_type'][0]"></p>
            </div>

            <div class="mb-3 form-floating">
                <select name="language_of_instruction" id="language_of_instruction" class="form-select new-input" v-model.trim="form.language_of_instruction.value">
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(item) in languages" :value="item.id" :key="item.id">{{ item.name }}</option>
                </select>
                <label for="language_of_instruction" class="form-label">Language of instruction</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['language_of_instruction']" v-html="errors['language_of_instruction'][0]"></p>
            </div>

            <div class="mb-3 form-floating">
                <select id="curriculumInput" name="curriculum" class="form-select new-input" v-model.trim="form.curriculum.value">
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(item) in applicationModel.curriculumOptions" :value="item.id" :key="item.id">{{ item.name }}</option>
                </select>
                <label for="curriculumInput" class="form-label">Curriculum</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['curriculum']" v-html="errors['curriculum'][0]"></p>
            </div>

            <div class="mb-3 form-floating">
                <select id="currentGrade" name="current_grade" class="form-select new-input" v-model.trim="form.current_grade.value">
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(item,key) in applicationModel.currentGradeLevels"  :value="key" :key="key">{{ item }}</option>
                </select>
                <label for="currentGrade">Learner's current grade or year</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['current_grade']" v-html="errors['current_grade'][0]"></p>
            </div>

            <div class="mb-3 form-floating">
                <select name="most_recent_completed_grade" id="most_recent_completed_grade" class="form-select new-input" v-model.trim="form.most_recent_completed_grade.value">
                    <option :value="null" selected disabled>Please select</option>
                    <option v-for="(grade, key) in applicationModel.mostRecentCompletedLevels" :value="key" :key="key">{{ grade }}</option>
                </select>
                <label for="most_recent_completed_grade" class="form-label">Last grade completed</label>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['most_recent_completed_grade']" v-html="errors['most_recent_completed_grade'][0]"></p>
            </div>

            <h4 class="mb-3 text-dark">Barriers to learning</h4>
            <p class="mt-2">These are mental or physical barriers diagnosed by a registered medical professional that impact a learner's ability to learn.</p>
            <div class="form-floating mb-3">
                <label for="is_special_needs" class="long-label">Any barriers to learning you're aware of?</label>
                <select class="form-select new-input long-label-select" id="is_special_needs" name="is_special_needs" v-model.trim="form.is_special_needs.value" @change="learningBarrierModal">
                    <option value="null" selected disabled>Please select</option>
                    <option v-for="(item,key) in applicationModel.yesNo" :value="key" :key="key">{{ item }}</option>
                </select>
                <p class="invalid-feedback d-block m-0" v-if="errors && errors['is_special_needs']" v-html="errors['is_special_needs'][0]"></p>
            </div>
            <div id="barrier-options" v-if="isShowBarriers">

                <div class="mb-3 form-floating">
                    <select name="special_needs_status" id="special_needs_status" class="form-select new-input" v-model.trim="form.special_needs_status.value">
                        <option :value="null" selected disabled>Please select</option>
                        <option v-for="(item) in applicationModel.isSpecialNeeds" :value="item" :key="item">{{ item }}</option>
                    </select>
                    <label for="special_needs_status" class="form-label">Learning barrier status</label>
                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['special_needs_status']" v-html="errors['special_needs_status'][0]"></p>
                </div>

                <div class="mb-3">
                    <label for="barriers_to_learning" class="col-form-label">Barrier(s) to learning (you may select more than one) </label>
                    <multiselect
                        v-model="form.barriers_to_learning.value"
                        :options="applicationModel.barriers"
                        :custom-label="opt => opt"
                        :multiple="true"
                        :clear-on-select="false"
                        placeholder="Select..."
                        id="barriers_to_learning"
                        :preserve-search="false"
                    ></multiselect>
                     <p class="invalid-feedback d-block m-0" v-if="errors && errors['barriers_to_learning.0']" v-html="errors['barriers_to_learning.0'][0]"></p>
                </div>
            </div>
            <div v-if="form && form.barriers_to_learning  && form.barriers_to_learning.value && form.barriers_to_learning.value.includes('Other')">
                <div class="form-floating mb-3">
                    <input type="text" name="barriers_to_learning_other" id="barriers_to_learning_other" class="form-control new-input" v-model.trim="form.barriers_to_learning_other.value" placeholder="Other Barrier(s) to learning">
                    <label for="barriers_to_learning_other">Other Barrier(s) to learning</label>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['barriers_to_learning_other']" v-html="errors['barriers_to_learning_other'][0]"></p>
                </div>
            </div>

            <vi-error-flash
                v-if="unexpectedError"
                errorMessage="We are unable to save your application right now. Please try again later."
            ></vi-error-flash>
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
                <a :href="backUrl" class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>
                <vi-button-spinner
                    buttonId="appStepThreeButton"
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-lg': true,
                        'mt-2': true,
                    }"
                    :buttonText="nextButtonText"
                    :loading="loading"
                ></vi-button-spinner>
            </div>
        </form>
    </div>
</template>

<script>
    import helperMixin from "../mixins/helper_mixin";
    import Multiselect from 'vue-multiselect'
import scroll_to from '../mixins/scroll_to';
import error_handling from '../mixins/error_handling';
    export default {
        name: "GuardianLearnerAppStepTwoForm",
        data: () => ({
            form: {
                currently_at_school: {
                    value: null,
                },
                current_school_type: {
                    value: null,
                },
                language_of_instruction: {
                    value: null,
                },
                curriculum: {
                    value: null,
                },
                current_grade: {
                    value: null,
                },
                last_year_at_school: {
                    value: null,
                },
                most_recent_completed_grade: {
                    value: null,
                },
                is_special_needs: {
                    value: null,
                },
                special_needs_status: {
                    value: null,
                },
                barriers_to_learning: {
                    value: [],
                },
                barriers_to_learning_other: {
                    value: null,
                },
            },
            errors: null,
            loading: false,
            unexpectedError: false,
            application : null,
            isShowBarriers : false
        }),
        mixins: [
            helperMixin,
            scroll_to,
            error_handling,
        ],
        components: {
            'multiselect' : Multiselect
        },
        props: [
            'userId',
            'languages',
            'applicationModel',
            'applicationId',
            'activeStep',
            'submitRoute',
            'nextButtonText',
            'backUrl',
            'getApplicationRoute',
            'isScholarship'
        ],
        methods: {
            async submit() {
                this.loading = true;
                this.unexpectedError = false;
                let data = {
                    'language_of_instruction' : this.form.language_of_instruction.value,
                    'curriculum': this.form.curriculum.value,
                    'current_grade': this.form.current_grade.value,
                    'last_year_at_school': this.form.last_year_at_school.value,
                    'is_special_needs': this.form.is_special_needs.value,
                    'special_needs_status': this.form.special_needs_status.value,
                    'barriers_to_learning': this.form.barriers_to_learning.value,
                    'barriers_to_learning_other': this.form.barriers_to_learning_other.value,
                }

                data.currently_at_school = this.form.currently_at_school.value
                data.current_school_type =  this.form.current_school_type.value
                data.most_recent_completed_grade =  this.form.most_recent_completed_grade.value
                data.active_step = this.activeStep
                data.user_id = this.userId

                try {
                    let response = await axios.post(this.submitRoute, data);

                    if (response.status === 200) {
                        const currentSchoolType = this.applicationModel.currentSchoolType[this.form.current_school_type.value];
                        const currentCurriculum = this.applicationModel.curriculumOptions
                            .find(option => option.id === Number(this.form.curriculum.value)).name;
                        const currentGrade = this.form.current_grade.value;

                        this.triggerGaEvent(
                            currentSchoolType,
                            currentCurriculum,
                            currentGrade
                        );

                        location.href = response.data.redirect;
                    }

                    this.loading = false
                } catch (error) {
                    const data = error.response.data;

                    if (error.response.status === 422) {
                        this.errors = data.errors

                        this.$nextTick(() => {
                            let firstError = this.firstError();

                            if (firstError) {
                                this.scrollIntoView(firstError.parentNode);
                            }
                        });
                    } else {
                        this.unexpectedError = true;
                    }
                    this.loading = false
                }
            },
            triggerGaEvent(currentSchoolType, currentCurriculum, currentGrade) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'learnerInformation2',
                    'currentSchoolType': currentSchoolType,
                    'curriculum': currentCurriculum,
                    'currentGrade': currentGrade,
                    'userid': window.valenture.userId,
                });
            },
            learningBarrierModal() {
                if(form.is_special_needs.value == 1) {
                    let barriersToLearningModal = document.getElementById('barriersToLearning');
                    $(barriersToLearningModal).modal('show');
                }
            }
        },
        created () {
            // helper mixin function
            this.getApplication(this.getApplicationRoute).then(response => {
                if (response.data.redirect) {
                    location.href = response.data.redirect;
                }
                this.application = response.data.application
                this.loadForm(this.application)
                if(this.application.barriers_to_learning){
                    for (const barrier of this.application.barriers_to_learning) {
                        this.form.barriers_to_learning.value.push(parseInt(barrier))
                    }
                }
            })
            .catch(error => {
                this.unexpectedError = true;
                console.error(error);
            })
        },
        watch: {
            'form.is_special_needs.value': {
                handler: function (val) {
                    this.isShowBarriers =  val == 1 ? true : false
                },
                deep: true
            },
        }
    };
</script>