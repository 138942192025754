<template>
    <form @submit.prevent="submitForm">
        <div class="container p-5">

            <h1 class="h2 mb-5 text-center">Transfer Guardian Information</h1>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-floating input-first-name">
                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            class="form-control new-input"
                            placeholder="First Name"
                            v-model.trim="formData.firstName"
                            :readonly="formData.firstName"
                        >

                        <label for="firstName" class="form-label">First name</label>
                    </div>

                    <template v-if="hasError('firstName')">
                        <error-message
                            v-for="message in getErrors('firstName')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-floating input-last-name">
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            class="form-control new-input"
                            placeholder="Last Name"
                            v-model.trim="formData.lastName"
                            :readonly="formData.lastName"
                        >
                        <label for="lastName" class="form-label">Last Name</label>
                    </div>

                    <template v-if="hasError('lastName')">
                        <error-message
                            v-for="message in getErrors('lastName')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-12">
                    <div class="input-date-of-birth">
                        <label class="form-label d-block text-start">Date of Birth</label>

                        <div class="form-row d-flex">
                            <div class="form-floating me-2">
                                <select
                                    class="form-select new-input"
                                    name="birthYear"
                                    id="birthYear"
                                    v-model.trim="birthYear"
                                    @change="clearError('dateOfBirth')"
                                >
                                    <option :value="null" selected disabled>YYYY</option>
                                    <option v-for="year in getBirthYears()" :value="year" :key="`year-${year}`">{{ year }}</option>
                                </select>

                                <label for="birthYear" >Year</label>
                            </div>

                            <div class="form-floating me-2">
                                <select
                                    class="form-select new-input"
                                    name="birthMonth"
                                    id="birthMonth"
                                    v-model.trim="birthMonth"
                                    :disabled="!birthYear"
                                    @change="clearError('dateOfBirth')"
                                >
                                    <option :value="null" selected disabled>MM</option>
                                    <option v-for="(month, key) in months" :value="key + 1" :key="`month-${month}`">{{ month }}</option>
                                </select>

                                <label for="birthMonth" >Month</label>
                            </div>

                            <div class="form-floating">
                                <select
                                    class="form-select new-input"
                                    name="birthDay"
                                    id="birthDay"
                                    v-model.trim="birthDay"
                                    :disabled="!birthMonth"
                                    @change="clearError('dateOfBirth')"
                                >
                                    <option :value="null" selected disabled>DD</option>
                                    <option v-for="day in getDays(birthYear, birthMonth)" :value="day" :key="`day-${day}`">{{ day }}</option>
                                </select>

                                <label for="birthDay">Day</label>
                            </div>
                        </div>
                    </div>

                    <template v-if="hasError('dateOfBirth')">
                        <error-message
                            v-for="message in getErrors('dateOfBirth')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-12">

                    <div class="form-floating">
                        <select
                            class="form-select new-input"
                            id="idTypeSelect"
                            name="idType"
                            v-model.trim="formData.idType"
                            @change="clearError('idType')"
                        >
                            <option :value="null" selected disabled>Please select</option>
                            <option v-for="option in documentTypes" :value="option" :key="option">{{ option }}</option>
                        </select>

                        <label for="idTypeSelect">Identification Number Type</label>
                    </div>

                    <template v-if="hasError('idType')">
                        <error-message
                            v-for="message in getErrors('idType')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-12">
                    <div class="form-floating">
                        <input
                            type="text"
                            name="idNumber"
                            id="id_or_passport"
                            class="form-control new-input"
                            placeholder="Last Name"
                            v-model.trim="formData.idNumber"
                            @keydown="clearError('idNumber')"
                        >
                        <label for="id_or_passport" class="form-label">{{ formData.idType ?? 'ID or Passport' }}</label>
                    </div>

                    <template v-if="hasError('idNumber')">
                        <error-message
                            v-for="message in getErrors('idNumber')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-3 dialing-inputs">
                    <div class="dialing-inputs-code">
                        <div class="form-floating">
                            <select
                                class="form-select new-input"
                                id="phoneCountryCodeSelect"
                                name="phoneCountryCode"
                                v-model.trim="formData.phoneCountryCode"
                                @change="clearError('phoneCountryCode')"
                            >
                                <option :value="null" selected disabled>Please select</option>
                                <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                            </select>

                            <label for="phoneCountryCodeSelect">Dialing Code</label>
                        </div>
                    </div>

                    <input type="hidden" name="phoneCountryCode" v-model.trim="formData.phoneCountryCode">

                    <template v-if="hasError('phoneCountryCode')">
                        <error-message
                            v-for="message in getErrors('phoneCountryCode')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-9">
                    <div class="dialing-inputs-number">
                        <div class="form-floating">
                            <input
                                type="tel"
                                class="form-control new-input"
                                id="mobileNumberInput"
                                name="mobileNumber"
                                placeholder="Mobile Number"
                                v-model.trim="formData.phone"
                                @keydown="clearError('phone')"
                            />

                            <label for="mobileNumberInput">Mobile Number</label>
                        </div>
                    </div>

                    <template v-if="hasError('phone')">
                        <error-message
                            v-for="message in getErrors('phone')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>

                </div>

                <div class="col-12">
                    <h2  class="h4 mb-3 text-dark">Relationship to Learner</h2>
                    <p>Please indicate how you (the guardian) are related to the learner. e.g. I am the learner's mother, father, uncle or legal guardian</p>

                    <div class="form-floating mb-3">
                        <select
                            class="form-select new-input"
                            name="relationToLearner"
                            id="relationToLearner"
                            v-model.trim="formData.relationToLearner"
                        >
                            <option :value="null">Select...</option>
                            <option v-for="(item) in relationships" :value="item" :key="item">{{ item }}</option>
                        </select>
                        <label for="relationToLearner" class="form-label">Relationship to learner</label>
                    </div>

                    <template v-if="hasError('relationToLearner')">
                        <error-message
                            v-for="message in getErrors('relationToLearner')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>

                </div>

                <div class="col-12">
                    <h2  class="h4 mb-3 text-dark">Guardian Address</h2>

                    <vi-address-field-group
                        id="guardianAddress"
                        :location-bounds="location ? location.getBounds() : null"
                        :errors="addressErrors"
                        :address-fields="formData.address"
                        v-bind.sync="formData.address"
                        :countries="countries"
                        :provinces="provinces"
                    >
                    </vi-address-field-group>
                </div>

                <payment-methods
                    v-if="card"
                    :is-active="loading"
                    class="fw-bold text-dark"
                    :card="card"
                ></payment-methods>

                <payment-methods
                    v-else
                    :is-active="loading"
                    class="fw-bold text-dark"
                ></payment-methods>

                <div class="col-12">
                    <h3 class="h6 fw-bold">Terms and Conditions</h3>
                    <p>By ticking the boxes below, you are agreeing to the information outlined in our terms and conditions, handbooks, and policies.</p>

                    <h3 class="h6 fw-bold">Notice: Key terms in the Terms and Conditions</h3>
                    <p>We know this is a lot to read, so we've picked out a few key terms which should be specifically brought to your attention. We do encourage you to read all documents to ensure that you are fully informed on the terms of our offering.</p>

                    <h3 class="h6 fw-bold">Our Agreement with you:</h3>
                    <p>These terms and conditions, together with associated policies, form our contract with you for UCT Online High School.</p>

                    <h3 class="h6 fw-bold">Withdrawals:</h3>
                    <p>Three calendar months (approximately one term) notice is required for withdrawal from the school.</p>

                    <h3 class="h6 fw-bold">Payment of Fees:</h3>
                    <p>You agree to payment of fees on time. Please ensure you are well acquainted with these fees and available payment methods. In certain circumstances, access to the learning platform may be suspended due to non-payment of fees.</p>
                </div>

                <div class="col-12">
                    <vi-alert>
                        <div class="form-floating ps-1">
                            <div class="form-check">
                                <input
                                    id="termsAndConditions"
                                    type="checkbox"
                                    name="termsAndConditions"
                                    required
                                    class="form-check-input"
                                    v-model="termsAndConditions"
                                >

                                <label for="termsAndConditions" class="consent-label fs-14 form-check-label">I have read and accepted the <a href="https://www.uctonlinehighschool.com/policies" target="_blank">UCT Online High School Terms & Conditions </a></label>
                            </div>
                        </div>
                    </vi-alert>
                    
                    <vi-alert>
                        <div class="form-floating ps-1">
                            <div class="form-check">
                                <input
                                    id="admissionsPolicy"
                                    type="checkbox"
                                    name="admissionsPolicy"
                                    required
                                    class="form-check-input"
                                    v-model="admissionsPolicy"
                                >

                                <label for="admissionsPolicy" class="consent-label fs-14 form-check-label">I have read and accepted the <a href="https://www.uctonlinehighschool.com/policies" target="_blank">UCT Online High School Handbooks and Policies</a></label>
                            </div>
                        </div>
                    </vi-alert>
                </div>
            </div>  

            <div class="row">
                <div class="col-12" v-if="card">

                    <vi-button-spinner
                        data-action="confirm"
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                        }"
                        :buttonText="`Confirm Transfer Request`"
                        :loading="loading"
                    ></vi-button-spinner>

                </div>

                <div class="col-12">
                    <vi-button-spinner
                        data-action="addPaymentMethod"
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                        }"
                        :buttonText="'Add/Edit Card and Confirm Transfer Request'"
                        :loading="loading"
                    ></vi-button-spinner>
                </div>
            </div>

            <div v-if="cardError" ref="errorAlert" class="alert alert-danger mt-4">
                <p v-if="errorMsg" v-html="errorMsg" class="m-0"></p>

                <template v-else>
                    Something went wrong. Please retry or <a href="mailto:techsupport@uctohs.valentureonline.com">contact us for help</a>.
                </template>
            </div>

            <div v-if="unexpectedError" class="alert alert-danger mt-4">
                <p class="m-0">Something went wrong. Please retry or <a href="mailto:techsupport@uctohs.valentureonline.com">contact us for help</a>.</p>
            </div>
        </div>
    </form>
</template>
<script>
import axios from 'axios';
import scroll_to from '../../mixins/scroll_to';
import error_handling from '../../mixins/error_handling';

    export default {
        name: "Information",
        mixins: [
            scroll_to,
            error_handling,
        ],
        props: {
            guardianInformation: {
                type: Object,
                required: true
            },
            submitUrl: {
                type: String,
                required: true
            },
            documentTypes: {
                type: Array,
                required: true
            },
            relationships: {
                type: Object,
                required: true
            },
            dialCodes: {
                type: Object,
                required: true
            },
            countries: {
                type: Object,
                required: true
            },
            provinces: {
                type: Array,
                required: true
            },
            anualIncomes: {
                type: Array,
                required: true
            },
            chargebeeKey: {
                type: String,
                required: true,
            },
            chargebeeSite: {
                type: String,
                required: true,
            },
            card: {
                type: Object|null,
                required: false,
            },
            verifyUrl: {
                type: String,
                required: true,
            },
            successPage: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                formData: {
                    requestId: null,
                    id: null,
                    firstName: null,
                    lastName: null,
                    phoneCountryCode: null,
                    phone: null,
                    address: {
                        address: null,
                        address_line_2: null,
                        address_suburb: null,
                        address_city: null,
                        address_province: null,
                        country: null,
                        address_postal_code: null
                    },
                    dateOfBirth: null,
                    relationToLearner: null,
                    idType: null,
                    idNumber: null,
                },
                errors: {},
                loading: false,
                birthYear: null,
                birthMonth: null,
                birthDay: null,
                location: null,
                addressErrors: {},
                months:  [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                chargeBee: null,
                loading: false,
                pendingVerification: false,
                verifying: false,
                errorMsg: null,
                cardError: false,
                unexpectedError: false,
                termsAndConditions: false,
                admissionsPolicy: false,
            }
        },
        created() {
            this.getGeoLocate();
            this.formData = this.guardianInformation;

            if (this.formData.dateOfBirth) {
                let dateOfBirth = this.formData.dateOfBirth.split('-');
                this.birthYear = dateOfBirth[0];

                if (dateOfBirth[1][0] === '0') {
                    dateOfBirth[1] = dateOfBirth[1].replace('0', '');
                }
        
                this.birthMonth = parseInt(dateOfBirth[1]);
                this.birthDay = dateOfBirth[2];
            }

            this.chargeBee = Chargebee.init({
                site: this.chargebeeSite,
                publishableKey: this.chargebeeKey,
                iframeOnly: true,
            });
        },
        methods: {
            submitForm(event) {
                this.loading = true;
                this.unexpectedError = false;

                this.formData.dateOfBirth = `${this.birthYear}-${(this.birthMonth + 1 )}-${this.birthDay}`;
                let action = event.submitter.getAttribute('data-action');

                axios.post(this.submitUrl, this.formData)
                    .then(response => {

                        if (action == 'confirm') {
                            window.location.href = this.successPage;
                            return;
                        }

                        if (!this.isMobile && response.data.hasOwnProperty('hostedPage') && response.data.hasOwnProperty('successRedirect')) {

                            window.addEventListener('beforeunload', this.handleUnload);

                            let {
                                hostedPage,
                                successRedirect,
                            } = response.data;

                            this.chargeBee.openCheckout({
                                hostedPage: async () => {
                                    return hostedPage;
                                },
                                close: async () => {
                                    this.verifying = true;

                                    try {
                                        // Verify that card has been added.
                                        let response = await axios.get(this.verifyUrl);
                                        window.removeEventListener('beforeunload', this.handleUnload);

                                        if (response.data.hasOwnProperty('isPending') && response.data.isPending) {
                                            this.pendingVerification = response.data.isPending;
                                        } else {
                                            window.location.href = successRedirect;
                                        }

                                    } catch (error) {
                                        this.loading = false;
                                        this.verifying = false;
                                        this.errorMsg = error.response.data.error;
                                        this.cardError = true;

                                        this.$nextTick(() => this.scrollIntoView(this.$refs.errorAlert));

                                        return;
                                    }
                                },
                            });

                        } else {
                            this.loading = false;
                            this.redirecting = true;

                            window.location.href = response.data.redirect;
                        }
                    })
                    .catch(error => {

                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.addressErrors = {
                                address_line_1: this.errors['address.address'] ? this.errors['address.address'] : null,
                                suburb: this.errors['address.address_suburb'] ? this.errors['address.address_suburb'] : null,
                                city: this.errors['address.address_city'] ? this.errors['address.address_city'] : null,
                                province: this.errors['address.address_province'] ? this.errors['address.address_province'] : null,
                                country: this.errors['address.country'] ? this.errors['address.country'] : null,
                                postal_code: this.errors['address.address_postal_code'] ? this.errors['address.address_postal_code'] : null,
                            };

                            this.$nextTick(() => this.scrollIntoView(this.firstError()));

                            this.loading = false;
                            return;
                        }

                        this.unexpectedError = true;
                        console.error(error);
                        this.loading = false;
                    })
            },
            getBirthYears() {
                let pastHundreadyears = (new Date()).getFullYear() - 10;
                let years = Array.from({ length : 100 }, (v , k) => pastHundreadyears - k);

                return years;
            },
            getDays(year, month) {
                return new Date(year, month, 0).getDate();
            },
            getGeoLocate() {
                //do we support geolocation
                if(!("geolocation" in navigator)) {
                    console.error(err.message);
                    return;
                }
                // get position
                navigator.geolocation.getCurrentPosition(pos => {
                    let geolocation = {
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude
                    };
                    this.location = new google.maps.Circle({
                        center: geolocation,
                        radius: pos.coords.accuracy
                    });
                }, err => {
                    console.log(err.message);
                })
            },
            handleUnload(event) {
                var confirmationMessage = 'Are you sure?';

                if (this.redirecting) {
                    confirmationMessage = 'Please wait while we redirect you to the next step.';
                } else {
                    return;
                }

                (event || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
            },
        },
        computed: {
            isMobile() {
                return window.innerWidth < 480;
            },
        }

    }
</script>
