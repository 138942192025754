<template>
    <div class="button-container mt-4 mb-3">
        <form @submit.prevent="onSubmit">
            <div v-for="(field, index) in fields" :key="index">
                <p class="mb-2 fw-bold">{{ field.label }}</p>

                <p
                    v-if="field.type !== 'checkbox'"
                    :for="field.name"
                    v-html="field.description"
                    class="fst-italic mb-2"
                ></p>

                <div :class="field.type !== 'checkbox' ? 'input-group' : 'form-check'">
                    <vi-uploadable-card
                        v-if="field.type === 'file'"
                        :name="field.name"
                        :heading="field.label"
                        v-on:file-chosen="(event) => handleFileChange(event, field.name, field.required)"
                        class="form-control mb-3 ps-0"
                        :class="hasUploadedFile ? 'uploaded' : ''"
                        :uploaded="hasUploadedFile"
                        :isUploading="uploading"
                    ></vi-uploadable-card>

                    <select
                        v-else-if="field.type === 'select'"
                        :name="field.name"
                        v-model="fieldValues[field.name]"
                        class="form-select mb-3 ps-0"
                        required
                    >
                        <option :value="null" disabled>Please select</option>

                        <option
                            v-for="(option, index) in field.options"
                            :key="index"
                            :value="option"
                        >
                            {{ option }}
                        </option>
                    </select>

                    <input
                        v-else
                        :type="field.type"
                        :name="field.name"
                        :default="field.default"
                        v-model="fieldValues[field.name]"
                        class="form-control mb-3 ps-0"
                        :class="field.type === 'checkbox' ? 'form-check-input p-0' : 'new-input'"
                        required
                        :placeholder="field.type !== 'checkbox' ? 'Please enter here' : ''"
                    >
                </div>
            </div>

            <div v-if="showButtons" class="form-group mt-4 grading-query-footer">
                <button
                    @click="$emit('update:show', false)"
                    type="button"
                    class="new-btn btn-danger new-btn-sm mt-2"
                >
                    Cancel
                </button>

                <vi-button-spinner
                    :buttonClass="{
                        'new-btn': true,
                        'new-btn-dark': true,
                        'new-btn-sm': true,
                        'mt-2': true,
                    }"
                    buttonText="Submit"
                    :loading="loading"
                ></vi-button-spinner>
            </div>

            <vi-error-flash
                v-if="unexpectedError"
                :errorMessage="errorMessage"
            ></vi-error-flash>
        </form>
    </div>
</template>

<script>
import viGradingQueryConfig from "../../config/vi-grading-query";

export default {
    name: "ViGradingQueryAdditionalInfo",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        optionSelected: {
            type: String,
            default: null,
        },
        assessment: {
            type: Object,
            default: () => ({}),
        },
        queryUrl: {
            type: String,
            required: true,
        },
        emailForGradingQuery: {
            type: String,
            required: true,
        },
        queryType: {
            type: String,
        },
        assessmentSubmissionsView: {
            type: String,
            required: false,
            default: 'uct_assessment_submissions',
        },
        uploadUrl: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            fields: [],
            fieldValues: {},
            fileFields: {},
            loading: false,
            showButtons: false,
            unexpectedError: false,
            errorMessage: '',
            hasUploadedFile: false,
            filePath: '',
            uploading: false,
        };
    },
    watch: {
        optionSelected(newVal) {
            this.fields = viGradingQueryConfig.dynamicFields.find(option => option.label === newVal)?.fields || [];

            this.fieldValues = {};

            this.fields.forEach(field => {
                if (field.type === 'select') {
                    this.$set(this.fieldValues, field.name, null);
                }
            });

            this.showButtons = true;

            this.unexpectedError = false;
        },
        showAdditionalInfo(newVal) {
            this.showAdditionalInfo = newVal;

            this.showButtons = true;
        },
        show(newVal) {
            if (newVal) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },
    methods: {
        async onSubmit(event) {
            event.preventDefault();

            this.loading = true;
            this.unexpectedError = false;

            if (this.fileUploadRequired && !this.hasUploadedFile) {
                this.loading = false;
                this.errorMessage = "Please ensure you upload supporting documentation.";
                this.unexpectedError = true;
                return;
            }

            const data = {
                learner_email: this.emailForGradingQuery,
                assessment_id: this.assessment.id,
                assignment_type: this.assessment[`${this.assessmentSubmissionsView}.assignment_type`],
                course_subject: this.assessment[`${this.assessmentSubmissionsView}.course_subject`],
                curriculum: this.assessment[`${this.assessmentSubmissionsView}.curriculum`],
                item_grade: this.assessment[`${this.assessmentSubmissionsView}.item_grade`],
                item_name: this.assessment[`${this.assessmentSubmissionsView}.item_name`],
                module_number: this.assessment[`${this.assessmentSubmissionsView}.module_number`],
                scheduled_submission_at_date: this.assessment[`${this.assessmentSubmissionsView}.scheduled_submission_at_date`],
                submission_at_date: this.assessment[`${this.assessmentSubmissionsView}.submission_at_date`],
                submission_status: this.assessment[`${this.assessmentSubmissionsView}.submission_status`],
                term: this.assessment[`${this.assessmentSubmissionsView}.term`],
                submission_url: this.assessment[`${this.assessmentSubmissionsView}.submission_url`],
                query_reason: this.optionSelected,
                query_type: this.queryType,
                file_upload_required: this.fileUploadRequired,
                file_path: this.filePath,
                dynamicFields: {},
                course_grade_string: this.assessment[`${this.assessmentSubmissionsView}.course_grade_string`],
            };

            for (const [key, value] of Object.entries(this.fieldValues)) {
                if (typeof value === 'boolean') {
                    data.dynamicFields[key] = value ? 'Yes' : 'No';
                } else {
                    data.dynamicFields[key] = value;
                }
            }

            try {
                const response = await axios.post(this.queryUrl, data);

                this.$emit("submissionSuccess", true);
            } catch (error) {
                this.loading = false;

                if (error.response && error.response.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors && errors.supportingDocumentation) {
                        this.errorMessage = errors.supportingDocumentation.join(' ');
                    } else {
                        this.errorMessage = "There are some validation errors. Please check your inputs.";
                    }
                } else {
                    this.errorMessage = "An unexpected error has occurred. Please try again later.";
                }

                this.unexpectedError = true;
            }
        },
        async handleFileChange(payload, fieldName, fieldRequired) {
            this.uploading = true;
            this.fileFields[fieldName] = payload.file[0];

            const formData = new FormData();
            formData.append('file', payload.file[0]);

            try {
                const uploadResponse = await axios.post(this.uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.filePath = uploadResponse.data.file_path;
                this.hasUploadedFile = true;
            } catch (error) {
                this.loading = false;

                if (error.response && error.response.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors && errors.supportingDocumentation) {
                        this.errorMessage = errors.supportingDocumentation.join(' ');
                    } else {
                        this.errorMessage = "There are some validation errors. Please check your inputs.";
                    }
                } else {
                    this.errorMessage = "An unexpected error has occurred. Please try again later.";
                }

                this.unexpectedError = true;
            }

            this.uploading = false;
        },
    },
    mounted() {
        this.fields = viGradingQueryConfig.dynamicFields.find(option => option.label === this.optionSelected)?.fields || [];

        this.fields.forEach(field => {
            if (field.type === 'select') {
                this.$set(this.fieldValues, field.name, null);
            }
        });

        this.showButtons = this.optionSelected ? true : false;
    },
    computed: {
        fileUploadRequired() {
            return this.fields.some(field => field.type === 'file' && field.required);
        }
    }
};
</script>

<style scoped lang="scss">
#grading-query-additional-information {
    z-index: 10000;
}
.button-container {
    margin: 0 auto;
}
.grading-query-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    width: -moz-available;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 6%;

    @media screen and (max-width: 768px) {
        margin: 0 34px;
    }
}
</style>
