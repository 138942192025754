<template></template>
<script>
export default {
    name: "AssessmentResubmitInit",
    props: {
        endpointsConfig: {
            type: Object,
            default: () => {},
            required: false,
        },
    },
    mounted () {
        this.$setEndPoints(this.endpointsConfig); 
    },
}
</script>