<template>
    <div :class="['selectable-card', (tag ? tag.toLowerCase() : ''), {'disabled': disabled, 'is-selected': isChecked, 'has-countdown': hasCountdown}]">
        <div v-if="tag" class="tag">{{ tag }}</div>

        <div class="body">
            <h5 class="title text-center" v-html="label"></h5>

            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>

        <div class="footer">
            <input
                :checked="isChecked"
                :value="value"
                @change="changeHandler"
                type="checkbox"
                :name="name ?? id"
                :id="id"
                :disabled="disabled"
            />

            <label :for="id" @click="clickHandler" :class="{'label-check': isChecked, 'label-text': !isChecked}" ref="initialScrollTarget">
                <span v-if="isChecked"><i class="bi bi-check-lg"></i></span>

                <span v-else>Select</span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ViSelectableCard',
        data() {
            return {
                id: null,
                checked: false,
            };
        },
        mounted() {
            this.id = `selectable-card-${this._uid}`;
            this.checked = this.isChecked;
        },
        props: {
            value: {
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            trueValue: {
                // default: true,
                required: true,
            },
            falseValue: {
                default: null,
            },
            isChecked: {
                type: Boolean,
                default: false,
            },
            shouldBlockSelectedClick: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            name: {
                type: String,
                default: null,
            },
            hasCountdown: {
                type: Boolean,
                default: false,
            },
            tag: {
                type: String,
                default: null,
            }
        },
        computed: {
        },
        methods: {
            changeHandler(event) {
                if (this.disabled || (this.checked && this.shouldBlockSelectedClick)) {
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                }

                let isChecked = event.target.checked;
                this.checked = event.target.checked;

                this.$emit('input', isChecked ? this.trueValue : this.falseValue);
            },
            clickHandler(event) {
                if ((this.checked && this.shouldBlockSelectedClick) || this.disabled) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            },
        },
        watch: {
            isChecked(newValue, oldValue) {
                this.checked = newValue;
            }
        }
    };
</script>

<style lang="scss" scoped>
@import "../../sass/abstract/colours.scss";

$light-blue: #e6f7fe;
$dark-blue: #1998d5;

$default-primary-color: map-get($primary-themes, "caps");


.selectable-card {
    background-color: $light-blue;
    color: $dark-blue;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 3px solid $white;
    box-shadow: 0px 10px 20px 0px scale-color($black, $alpha: -80%);

    transition: 200ms all ease;

    &:not(.has-countdown) {
       height: 100%;
    }

    &.disabled {
        position: relative;
        overflow: inherit;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: -3px;
            left: -3px;
            background-color: scale-color($black, $lightness: 30%, $alpha: -50%);
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border-radius: 20px;
        }
    }

    &:not(.theme-caps):not(.theme-caie):not(.theme-adult-matric).is-selected {
        background-color: $dark-blue;
        color: $white;
        border: 3px solid $dark-blue;
    }
}

.body,
.footer {
    padding: 0.5rem;
}

.body {
    padding-bottom: 0.25rem;
}

.footer {
    padding-top: 0.25rem;
    display: flex;
    justify-content: center;
}

.body * {
    margin: 0;
    padding: 0;
}

.title {
    font-size: 1.25rem;
}

.content {
    font-size: 14px;
}

.title,
.content {
    margin-bottom: 0.75rem;
}

input {
    display: none;
}

label {
    user-select: none;
    padding: 0.25rem 2rem;
    border-radius: 50px;
    border: 2px solid $dark-blue;
    color: $white;
    background-color: $dark-blue;
    text-transform: uppercase;
    // font-weight: 700;

    transition: 200ms all ease;

    &:hover {
        cursor: pointer;
    }
}


.label {
    &-text {}

    &-check {
        background-color: transparent;
        color: $white;
        padding: 0.25rem 0.5rem;
        border-color: $white;
    }
}

.theme {
    &-caps {
        $primary-color: map-get($primary-themes, "caps");

        &.selectable-card {
            color: $white;
            background-color: $primary-color;
            border-color: scale-color($primary-color, $lightness: -30%);
        }

        .label {
            &-text {
                background-color: $white;
                color: scale-color($primary-color, $lightness: -20%);
                border-color: $white;
                font-weight: 700;
            }

            &-check {
                background-color: $primary-color;
                color: $white;
            }
        }
    }

    &-caie {
        $primary-color: map-get($primary-themes, "caie");

        &.selectable-card {
            color: $white;
            background-color: $primary-color;
            border-color: scale-color($primary-color, $lightness: -30%);
        }

        .label {
            &-text {
                background-color: $white;
                color: scale-color($primary-color, $lightness: -20%);
                border-color: $white;
                font-weight: 700;
            }

            &-check {
                background-color: map-get($primary-themes, "caie");
                color: $white;
            }
        }
    }

    &-adult-matric {
        $primary-color: map-get($primary-themes, "adult-matric");

        &.selectable-card {
            color: $white;
            background-color: $primary-color;
            border-color: scale-color($primary-color, $lightness: -30%);
        }

        .label {
            &-text {
                color: scale-color($primary-color, $lightness: -20%);
                background-color: $white;
                border-color: $white;
                font-weight: 700;
            }

            &-check {
                color: $white;
                background-color: $primary-color;
            }
        }
    }

    &-dark {
        $light: $card-light-blue;
        $dark: darken($card-dark-blue, 10%);

        &.selectable-card {
            color: $dark;
            background-color: $light;
            border-color: $light;

            &.is-selected {
                @media screen and (min-width: 425px) {
                    transform: scale(1.075);
                }
            }

            &.is-selected {
                background-color: $dark !important;
                color: $light !important;
                border-color: $dark !important;

                .label {
                    &-text,
                    &-check {
                        border-color: $light;
                    }
                }
            }
        }

        .label {
            &-text {
                color: $light;
                background-color: $dark;
                border-color: $dark;
                font-weight: 400;
            }

            &-check {
                color: $light;
                background-color: $dark;
            }
        }
    }
}

.tag {
    position: absolute;
    top: 4px;
    left: -26px;
    font-size: 12px;
    padding: 0.25em 0.5rem;
    background-color: $white;
    border-radius: 4px;
    color: $black;
    text-transform: capitalize;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
}

</style>
