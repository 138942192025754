<template>
    <div class="progress-table position-relative mb-md-0">
        <progress-modules
            :modules="modulesList"
            :student-email="studentEmail"
        ></progress-modules>

        <progress-table-row
            v-for="(subject, index) in subjects" :key="index"
            :modules="modules[subject]"
            :modules-list="modulesList"
            :current-module="currentModule"
            :student-email="studentEmail"
        ></progress-table-row>

        <!-- <div class="scroll-indicator">
            <i class="bi bi-arrow-left-short"></i>
            <i class="bi bi-hand-index"></i>
            <i class="bi bi-arrow-right-short"></i>
        </div> -->
    </div>
</template>

<script>

export default {
    name: "TermlyProgressTable",
    props: {
        subjects: {
            type: Array,
            required: true,
        },
        modules: {
            type: Object,
            required: true,
        },
        currentModule: {
            type: String,
            required: true,
        },
        modulesList: {
            type: Array,
            required: true,
        },
        studentEmail: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    mounted() {}
}

</script>

<style lang="scss" scoped>

// .scroll-indicator {
//     position: sticky;
//     bottom: 0;
//     left: 0;
//     right: 0;
// }

.progress-table {
    display: grid;
    overflow-y: auto;

    @media (max-width: 1199.98px) {
    }

    @media (max-width: 767.98px) {
        margin-bottom: 18px;
    }
}

.mobile-shadow {
    position: absolute;
    width: 10px;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(to right, transparent , grey);;
}

</style>
