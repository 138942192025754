export default {
    data() {
        return {
            autoComplete: null,
            locationBounds: null,
            placesAttributeMarker: 'data-autocomplete-attached',
        };
    },
    created() {
        if (!this.hasOwnProperty('autoCompleteAddressField')) {
            throw new Error('autoCompleteAddressField function needs to be defined on the component that uses the google_address_autocomplete mixin. With the following signature autoCompleteAddressField(key, value).');
        }

        this.getGeoLocate();
    },
    beforeDestroy() {
        if (this.autoComplete) {
            google.maps.event.clearInstanceListeners(this.autoComplete);
        }
    },
    methods: {
        clearAutoComplete() {
            if (this.autoComplete) {
                this.autoComplete = null;
            }
        },
        initAutoComplete(inputNode) {
            if (!this.hasOwnProperty('autoCompleteAddressField')) {
                throw new Error('autoCompleteAddressField function needs to be defined on the component that uses the google_address_autocomplete mixin. With the following signature autoCompleteAddressField(key, value).');
            }

            const options = {
                types: ["address"],
                fields: ["address_components"],
                bounds: this.locationBounds
            };

            this.autoComplete = new google.maps.places.Autocomplete(
                inputNode,
                options
            );

            inputNode.setAttribute(this.placesAttributeMarker, true);

            google.maps.event.addListener(this.autoComplete, "place_changed", () => {

                let place = {
                    address_components: [],
                    ...this.autoComplete.getPlace()
                }

                this.populateAddress(place);
            });
        },
        getGeoLocate() {
            // Is geolocation supported?
            if (!("geolocation" in navigator)) {
                return;
            }

            navigator.geolocation.getCurrentPosition(
                pos => {
                    let geolocation = {
                        lat: pos.coords.latitude,
                        lng: pos.coords.longitude
                    };
                    this.locationBounds = new google.maps.Circle({
                        center: geolocation,
                        radius: pos.coords.accuracy
                    }).getBounds();
                },
                err => {
                    console.error(err.message);
                }
            );
        },
        populateAddress(place) {
            const mapping = {
                route: {'address': this.address },
                sublocality_level_1: {'address_suburb': this.address_suburb},
                sublocality_level_2: {'address_suburb': this.address_suburb},
                locality: {'address_city': this.address_city},
                administrative_area_level_1: {'address_province': this.address_province },
                postal_code: {'address_postal_code': this.address_postal_code },
                country: {'country': this.country},
            }

            for (const type in mapping) {
                let addresskey = this.getFirstElementFromObject(mapping[type],'key');

                this[addresskey] = "";

                this.autoCompleteAddressField(addresskey, '');
            }

            let placeComponentValue = null;
            let street = null;

            place.address_components.forEach(component => {
                component.types.forEach((type) => {
                    if (type == 'street_number') {
                        street = component.long_name;
                    }

                    if (mapping.hasOwnProperty(type)) {
                        placeComponentValue = component.long_name;

                        if (type == 'route') {
                            placeComponentValue = this.createAddressLineFirst(street, placeComponentValue);
                        }

                        let addresskey = this.getFirstElementFromObject(mapping[type],'key');

                        this[addresskey] = placeComponentValue;

                        this.autoCompleteAddressField(addresskey, placeComponentValue);
                    }
                });
            });
        },
        createAddressLineFirst(street, route) {
            return street ? `${street} ${route}` : `${route}`;
        },
        getFirstElementFromObject(obj,$type = null) {
            if ($type === 'key') {
                return Object.keys(obj)[0];
            }

            return Object.values(obj)[0];
        },
    },
    watch: {
        locationBounds: function (val) {
            this.autoComplete.setBounds(val);
        },
    },
}
