<template>
    <div class="guardian-loging-form-container mx-auto w-100">
        <form @submit.prevent="submit" class="text-start" id="form">
            <h4 class="text-pastel-blue mb-2">Upload CSV for new Adult Matric learners</h4>

            <p>
                <a :href="exampleDownloadUrl" target="_blank">Download example CSV file</a>
            </p>

            <div class="form-group pb-4">
                <label for="scholarship">Enter account ID for corporate sponsorship</label>
                <input type="text" name="scholarship" id="scholarship" class="form-control" v-model="scholarship">
            </div>

            <vi-file-selector
                upload-field-name="file"
                :handle-upload="handleUpload"
                :max-file-size="8000000"
                accepted-file-types=".csv"
                :show-gallery="false"
            ></vi-file-selector>

            <div v-if="successMessage" v-html="successMessage" class="border p-4 text-break"></div>
            <div v-if="success" class="mh-30 p-3 overflow-auto">
                <div class="alert alert-warning">Please confirm that the following information is correct before proceeding.<br>{{ csvData.length }} users will be imported.</div>
                <div class="row font-weight-bold">
                    <div class="col-3">First Name</div>
                    <div class="col-3">Last Name</div>
                    <div class="col-3">Email</div>
                    <div class="col-3">Mobile</div>
                </div>
                <div v-for="(item, i) in csvData" :key="i" class="row csv_data text-break" data-toggle="tooltip" data-placement="top">
                    <div class="col-3">{{ item.first_name }}</div>
                    <div class="col-3">{{ item.last_name }}</div>
                    <div class="col-3">{{ item.email }}</div>
                    <div class="col-3">{{ item.mobile_number }}</div>
                    <vi-progress-bar
                        :id="'progressbar-' + i"
                    ></vi-progress-bar>
                </div>
            </div>

            <div v-if="errorMessage">
                <div v-html="errorMessage" class="border p-1 text-break"></div>
            </div>

            <div class="text-left w-100 my-4">
                <button :disabled="loading" type="submit" class="new-btn new-btn-dark new-btn-sm mx-auto">Confirm</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'AdultMatricBulkUpload',
    components: {},
    props: {
        uploadUrl: {
            type: String,
            required: true,
        },
        saveUrl: {
            type: String,
            required: true,
        },
        exampleDownloadUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            errorMessage: null,
            isFileUploaded: false,
            loading: true,
            success: false,
            csvData: null,
            successMessage: null,
            scholarship: '',
            rowData: [],
            progressBarLength: 0,
        }
    },
    methods: {
        async submit() {
            this.loading = true;

            const element = document.getElementsByClassName('csv_data');

            for (let i = 0; i < element.length; i++) {
                element[i].style.color = 'orange';
                element[i].title = 'Queued';
            }

            let numRows = this.csvData.length;

            for (let i = 0; i < numRows; i++) {
                this.progressBarLength = ((i+1) / numRows) * 100;

                const viProgressBar = document.getElementsByClassName('vi_progress_bar');

                for (let j = 0; j < viProgressBar.length; j++) {
                    viProgressBar[j].style.width = 0;
                }

                const viProgressBarChild = document.getElementsByClassName('progress-bar');

                viProgressBar[i].style.width = '100%';

                viProgressBarChild[i].style.width = this.progressBarLength + '%';

                element[i].style.color = 'grey';
                element[i].title = 'In progress';

                let row = this.csvData[i];
                row['scholarship'] = this.scholarship;

                try {
                    await axios.post(this.saveUrl, { csvData: row }).then(response => {
                        element[i].style.color = 'green';
                        element[i].title = 'Imported';
                    })
                }
                catch (error) {
                    this.successMessage = i + ' user(s) have been imported.';
                    this.errorMessage = "Error on row " + (i+1) + " (" + this.csvData[i].email + "): " + error.response.data.message;
                    break;
                }
            }

            if (!this.errorMessage) {
                this.successMessage = numRows + ' user(s) have been imported.';
                this.success = false;
            }
        },
        async handleUpload(formData) {
            this.isFileUploaded = true;
            this.successMessage = null;
            this.errorMessage = null;
            this.loading = true;

            return axios.post(this.uploadUrl, formData).then(response => {
                this.success = true;
                this.csvData = response.data;

                if (this.csvData.length > 0) {
                    this.loading = false;
                } else {
                    this.successMessage = 'No new users found in uploaded file. Click Retry to upload again.';
                }
            }).catch(error => {
                console.log('error', error);
                this.errorMessage = error.response.data.message;
            });
        },
    }
}
</script>

<style scoped>
.mh-30 {
    max-height: 30vw;
}
</style>