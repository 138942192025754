<template>
    <button
        :class="`theme-${theme}`"
        @click="onClick"
    >
        <template v-if="!loading">
          <slot name="label">{{ label }}</slot>
        </template>

        <slot v-else name="loader">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </slot>
    </button>
</template>

<script>
export default {
    name: "Button",
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        theme: {
            type: String,
            default: 'dark',
            validator: function (value) {
                // The value must match one of these strings
                return ['dark', 'ghost'].includes(value)
            },
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        onClick: {
            type: Function,
            required: false,
            default: () => {},
        }
    },
    data() {
        return {};
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
@import "resources/sass/abstract/colours.scss";
@import "resources/sass/fonts.scss";

button {
    font-family: $primary-font;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
    display: block;
    border: 2px solid $black;
    border-radius: 50px;
    font-size: 16px;
    transition-property: background-color, color, border-color;
    transition-duration: 250ms;
    transition-timing-function: ease;

    .spinner-border {
        width: 16px;
        height: 16px;
        border-width: 2px;
        border-right-color: transparent !important;
    }

    &.theme {
        &-dark {
            background-color: $pastel-blue;
            color: $white;
            border-color: $pastel-blue;

            .spinner-border {
                border-color: $white;
            }

            &:hover {
                background-color: $pastel-light-blue;
                color: $white;
                border-color: $pastel-light-blue;

                .spinner-border {
                    border-color: $white;
                }
            }
            /* $grey: grey;
                $lightgrey: #f5f5f5;
                $medium-grey: #555555;
                */

            &[disabled] {
                background-color: $grey;
                color: $lightgrey;
                border-color: $grey;

                .spinner-border {
                    border-color: $lightgrey;
                }

                &:hover {
                    background-color: $grey;
                    color: $lightgrey;
                    border-color: $grey;
                    cursor: not-allowed;

                    .spinner-border {
                        border-color: $grey;
                    }
                }
            }
        }

        &-ghost {
            background-color: transparent;
            color: $pastel-blue;
            border-color: $pastel-blue;

            .spinner-border {
                border-color: $pastel-blue;
            }

            &:hover {
                background-color: $pastel-blue;
                color: $white;
                border-color: $pastel-blue;

                .spinner-border {
                    border-color: $white;
                }
            }

            &[disabled] {
                background-color: transparent;
                color: $grey;
                border-color: $grey;

                .spinner-border {
                    border-color: $grey;
                }

                &:hover {
                    background-color: transparent;
                    color: $grey;
                    border-color: $grey;
                    cursor: not-allowed;

                    .spinner-border {
                        border-color: $grey;
                    }
                }
            }
        }
    }
}

</style>
