<template>
    <div class="w-100 mt-3 d-flex flex-row justify-content-around align-items-center">
        <div
            v-for="mod in modules"
            class="d-flex justify-content-center rounded-top module-cell p-0 me-2 me-md-0 cursor-pointer"
            :class="{'activeTrue': false}"
            :key="mod"
            @click="moduleClick(mod)"
        >
            <span :data-student-email="studentEmail" :data-module-number="mod" class="module-text fw-bold p-2" role="button" v-on:mouseover="event => toggleHover(event)">{{ `M${mod}` }}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProgressModules",
    props: {
        modules: {
            type: Array,
            required: true,
        },
        studentEmail: {
            type: String,
            required: true,
        },
    },
    methods: {
        toggleHover(event) {
            const moduleHoverEvent = new CustomEvent('moduleHover', { detail: parseInt(event.target.dataset.moduleNumber) });
            document.dispatchEvent(moduleHoverEvent);
        },
        moduleClick(moduleNumber) {
            $(`#weekly-attendance`).modal('show', { backdrop: 'static' });

            const lookerEvent = new CustomEvent('weekly-attendance.open', {
                detail: {
                    moduleNumber: moduleNumber,
                    studentEmail: this.studentEmail,
                }
            });

            // Dispatch the event.
            document.body.dispatchEvent(lookerEvent);
        },
    }
}

</script>

<style lang="scss" scoped>

@import 'resources/sass/abstract/colours.scss';
@import 'resources/sass/abstract/term-progress.scss';

span {
    font-size: 0.75rem; // 12px
}

.module-cell {
    max-width: $tpCellHeight;
    min-width: $tpCellHeight;
}

.module-text {
    color: $pastel-blue;
    text-decoration: none;
}

.activeTrue {
    background-color: $white;
}

.activeFalse {
    background-color: transparent;
}

</style>
