<template>
    <div class="vi-deadline-passed">
        <h3>Submission Deadline Passed</h3>

        <p>We regret to inform you that the deadline for submitting a subject change request has passed for this academic year therefore we are unable to accept any new requests.</p>

        <p>For additional information on subject changes and submissions dates please visit our info hub article <a href="https://infohub.uctonlinehighschool.com/en/articles/8317607-understanding-subject-changes-and-additions&sa=D&source=docs&ust=1721309808742283&usg=AOvVaw2rfRoavEV5odwG4ngU9dmE" target="_blank" rel="noopener noreferrer">here</a>.</p>

        <p>If you have any questions or need further clarity, please contact your Support Coach.</p>
    </div>
</template>

<script>
export default {
    name: "DeadlinePassed",
    props: {},
    data() {
        return {};
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 0.5em;
}

p {
    margin-bottom: 1em;
}

.vi-deadline-passed {
    max-width: 75ch;
    margin: 0 auto;
}
</style>
