<template>
    <div v-if="show" @click.self="$emit('update:show', false)" class="modal-mask" tabindex="-1" aria-hidden="true">
        <!-- <div class="modal-dialog modal-xl modal-dialog-scrollable modal-fullscreen"> -->
            <div
                class="modal-dialog modal-xl modal-dialog-scrollable"
                :class="{
                    'modal-fullscreen': !submissionSuccess,
                    'modal-dialog-centered': submissionSuccess
                }"
            >
            <div class="modal-content">
                <div class="modal-header modal-extra-padding">
                    <h5 class="modal-title text-light-blue">Assessment Query</h5>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="$emit('update:show', false)" aria-label="Close"></button>
                </div>

                <template v-if="!submissionSuccess">
                    <div class="modal-body modal-margin-bottom modal-extra-padding">
                        <h5 class="mb-3 text-light-blue">Please select the type of query:</h5>

                        <slot></slot>

                        <div class="query-summary-block shadow mb-4 mt-3">
                            <h5 class="text-white py-4 px-3 mb-0">This is the assignment for which you are raising a query:</h5>

                            <div class="query-summary-inner p-4">
                                <p class="mb-1"><span class="fw-bold">Assignment Type:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.assignment_type`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Course Subject:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.course_subject`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Curriculum:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.curriculum`] }}</p>
                            </div>

                            <div class="p-4">
                                <p class="mb-1"><span class="fw-bold">Item Grade:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.item_grade`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Item Name:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.item_name`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Module Number:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.module_number`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Scheduled Submission Date:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.scheduled_submission_at_date`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Submission Date:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.submission_at_date`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Submission Status:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.submission_status`] }}</p>
                                <p class="mb-1"><span class="fw-bold">Submission URL:</span> {{ this.assessment[`${this.assessmentSubmissionsView}.submission_url`] }}</p>
                            </div>
                        </div>

                        <div
                            v-html="ticketCopy"
                            id="grading-query-additional-info"
                            class="query-additional-info-text mt-4"
                        ></div>

                        <vi-grading-query-additional-info
                            v-if="queryType != 'resubmissionRequest'"
                            :show="showAdditionalInfo"
                            @update:show="closeBoth"
                            :option-selected="selectedQueryReason"
                            :assessment="assessment"
                            :query-url="queryUrl"
                            :email-for-grading-query="emailForGradingQuery"
                            @submissionSuccess="handleSubmissionSuccess"
                            :query-type="queryType"
                            :assessment-submissions-view="assessmentSubmissionsView"
                            :upload-url="gradingQueryUploadUrl"
                        ></vi-grading-query-additional-info>

                        <assessment-resubmit
                            v-else
                            @update:show="closeBoth"
                            :assessment="assessment"
                        ></assessment-resubmit>
                    </div>
                </template>

                <template v-else>
                    <div class="modal-body modal-extra-padding">
                        <div class="text-center mt-4">
                            <h3 class="text-light-blue"><i class="bi bi-check-circle-fill me-2 text-light-blue"></i>Success!</h3>

                            <p class="success-descritpion mx-auto mt-4">Thank you, you have successfully submitted an assessment related request and it will now be investigated. Please expect a response from your Support Coach. If you have any other queries, please complete another query form.</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedOption: null,
            submissionSuccess: null,
            ticketFields: '',
            ticketCopy: '',
        };
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        showAdditionalInfo: {
            type: Boolean,
            default: false,
        },
        dynamicFields: {
            type: Array,
            default: () => [],
        },
        selectedQueryReason: {
            type: String,
            default: null,
        },
        assessment: {
            type: Object,
            default: () => {},
        },
        queryUrl: {
            type: String,
            required: true,
        },
        emailForGradingQuery: {
            type: String,
            required: true,
            default: null,
        },
        queryType: {
            type: String,
        },
        assessmentSubmissionsView: {
            type: String,
            required: false,
            default: 'uct_assessment_submissions',
        },
        gradingQueryUploadUrl: {
            type: String,
            required: true,
        },
        isResubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    watch: {
        show(newVal) {
            if (newVal) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
                this.selectedOption = null;
            }
        },
    },
    methods: {
        selectOption(option) {
            this.selectedOption = option;
            this.$emit("update:showAdditionalInfo", true);
            this.$emit("option-selected", { itemId: this.itemId, option });
        },
        handleSubmissionSuccess(url) {
            this.submissionSuccess = url;
        },
        closeBoth() {
            this.$emit('update:show', false);
        },
    },
    mounted() {
        this.ticketFields += '<span class="fw-bold">Assignment Type:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.assignment_type`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Course Subject:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.course_subject`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Curriculum:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.curriculum`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Item Grade:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.item_grade`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Item Name:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.item_name`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Module Number:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.module_number`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Scheduled Submission Date:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.scheduled_submission_at_date`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Submission Date:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.submission_at_date`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Submission Status:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.submission_status`] ?? 'N/A') + '<br>';
        this.ticketFields += '<span class="fw-bold">Submission URL:</span> ' + (this.assessment[`${this.assessmentSubmissionsView}.submission_url`] ?? 'N/A') + '<br>';
    },
    watch: {
        selectedQueryReason(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.ticketCopy = this.dynamicFields.find(dynamicField => dynamicField.label === newVal)?.description;
            }

            this.$nextTick(() => {
                const el = document.getElementById('grading-query-additional-info');
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import 'resources/sass/abstract/colours.scss';

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.modal-margin-bottom {
    margin-bottom: 4em;

    @media screen and (max-width: 575px) {
        margin-bottom: 7.5em;
    }
}

.modal-extra-padding {
    padding: 2rem;
}

.query-additional-info-text {
    margin: 10px 0 30px 0;
    color: $dark-grey;
}

// Assessment query modal content
.query-summary-block {
    background-color: $pastel-blue;
    color: $white;
    border-radius: 30px;

    .query-summary-inner {
        background-color: $primary;
    }
}

.success-descritpion {
    max-width: 600px;
}

</style>
