<template>
    <div
        v-if="!hideComponent"
        class="vi-re-enrolment-survey overflow-hidden"
    >
        <h5 class="vi-re-enrolment-survey-title">We're gearing up for 2023!</h5>

        <template v-if="!showSuccessMessage">
            <form @submit.prevent="submit">
                <div class="form-group">
                    <input
                        type="checkbox"
                        name="yes"
                        :id="id + 'yes-option'"
                        :checked="selectedValue == 'yes'"
                        @change.prevent="selectValue('yes')"
                    >
                    <label :for="id + 'yes-option'">My child will be continuing with UCT Online High School in 2023</label>
                </div>

                <div class="form-group">
                    <input
                        type="checkbox"
                        name="unsure"
                        :id="id + 'unsure-option'"
                        :checked="selectedValue == 'unsure'"
                        @change.prevent="selectValue('unsure')"
                    >
                    <label :for="id + 'unsure-option'">I'm unsure if my child will be continuing with UCT Online High School in 2023</label>
                </div>

                <p v-if="hasErrorOccured" class="error">Please select one of the above values.</p>

                <p v-if="hasRequestFailed" class="error">Please reload the page and try again.</p>

                <button :disabled="isLoading" class="btn-uct-dark">OK</button>
            </form>
        </template>

        <template v-else>
            <p>Thank you, your preference has been saved!</p>
        </template>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "ViReEnrolmentSurvey",
    props: {
        surveyUrl: {
            required: true,
            type: String,
        },
        studentId: {
            required: true,
            type: String,
        }
    },
    data() {
        return {
            selectedValue: null,
            hasErrorOccured: false,
            hideComponent: false,
            hasRequestFailed: false,
            id: null,
            isLoading: false,
            showSuccessMessage: false,
        };
    },
    mounted() {
        this.id = this._uid
    },
    methods: {
        async submit() {
            this.isLoading = true;

            if (!this.selectedValue) {
                this.hasErrorOccured = true;
                this.isLoading = false;
                return;
            }

            try {
                const response = await axios.post(this.surveyUrl, {
                    'option': this.selectedValue,
                    'studentId': this.studentId,
                });

                this.isLoading = false;
                this.showSuccessMessage = true;

                setTimeout(() => {
                    if (response.status == 200) {
                        this.hideComponent = true;
                        this.hideNotificationBadge();
                    } else {
                        this.hasRequestFailed = true;
                    }
                }, 3000);
            } catch (error) {
                console.error(error);
                this.hasRequestFailed = true;
                this.isLoading = false;
            }
        },
        selectValue(val) {
            if (this.selectedValue !== val) {
                this.hasErrorOccured = false;
            }

            this.selectedValue = this.selectedValue === val ? null : val;
        },
        hideNotificationBadge() {
            let notificationBadgeEl = document.querySelector(`#heading-${this.studentId} .enrolment-notification-badge`);

            notificationBadgeEl.remove();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../sass/abstract/colours.scss";
@import "../../sass/utilities/rem-calc.scss";

.vi-re-enrolment-survey {
    $accentBorder: 28px solid $peach;

    display: flex;
    border-radius: 30px;
    overflow: hidden;
    background-color: $white;
    flex-direction: column;

    @media screen and (min-width: 960px) {
        flex-direction: row;
        border-right: $accentBorder;
    }

    @media screen and (max-width: 960px) {
        border-bottom: $accentBorder;
    }

    & > * {
        padding: 1rem;
    }

    &-title {
        font-weight: 600;
        color: $white;
        margin: 0;
        background-color: $peach;

        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.error {
    font-size: rem-calc(14);
    color: $red;
}

.form-group {
    display: flex;

    & + & {
        margin-top: rem-calc(8);
    }

    input {
        margin-right: rem-calc(8);
    }

    label {
        font-size: rem-calc(14);
        color: $pastel-blue;
    }
}

button {
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    display: block;
    margin: 0 auto;
    margin-top: 0.5rem;
}
</style>