<template>
    <div class="mb-5">
        <h4 v-if="title" class="mb-3 text-dark">{{ title }}</h4>

        <div class="form-floating mb-3">
            <input type="text" class="form-control new-input" id="userName" placeholder="First Name" :value="firstName" disabled/>
            <label for="userName">First name</label>
        </div>

        <div class="form-floating mb-3">
            <input type="text" class="form-control new-input" id="userSurname" placeholder="Surname" :value="lastName" disabled/>
            <label for="userSurname">Surname</label>
        </div>

        <div class="form-floating mb-3" v-if="applicationCurriculumBlock">
            <input type="text" class="form-control new-input" id="appCurriculum" placeholder="Application ID" :value="appCurriculum" disabled/>
            <label for="appCurriculum">Learner's Curriculum</label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UserDetails",
        data: () => ({
            user_first_name: "",
            user_surname: "",
        }),
        props: {
            firstName: {
                type: String,
                required: true,
            },
            lastName: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                default: null
            },
            appCurriculum: String,
            applicationCurriculumBlock: {
                type: Boolean,
                default: false,
            },
        },
        mounted() {},
    }
</script>

<style scoped>

input:disabled {
    background-color: transparent;
    border-bottom: none;
}

</style>
