<template>
    <section>
        <div class="mb-3 large-dropdown">

            <!-- brand new school dropdown -->
            <div class="school-dropdown w-100">
                <button @click.prevent="toggleSchoolDropdown" :disabled="disabled"
                    class="new-btn new-btn-dark rounded py-3 px-4 shadow w-100">Search for school <i class="bi bi-search ms-2"></i></button>

                <div class="w-100 school-dropdown-content" :class="{ 'show': isDropdownVisible }">
                    <input id="schoolSearchInput" ref="dropdownSearchInput" class="p-2 w-100" type="text" placeholder="Start typing to search..." @keyup="debounce(() => getSchool($event))();">

                    <div class="school-dropdown-content-list">
                        <template v-if="!loadingSchoolData">
                            <div
                                v-for="(option, key) in activeSchoolData"
                                class="dropdown-item"
                                :data-value="option.schoolname"
                                :key="key"
                                @click="(event) => selectSchool(option)"
                                >
                                    {{option.schoolname }}
                                </div>

                            <div class="dropdown-item" @click.prevent="selectDefault">I'd like to enter my school manually</div>
                        </template>

                        <div v-if="loadingSchoolData" class="dropdown-item d-flex justify-content-center">
                            <div class="spinner-border school-loader" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- name of current school -->
        <div class="form-floating mb-3" data-bs-toggle="tooltip" data-bs-title="Please search for the school first">
            <input id="current-school" :disabled="!isSchoolFieldsEditable" type="text" name="current-school"
                class="form-control new-input" placeholder="Current School" v-model.trim="form.current_school" @change="handleChange">

            <label for="current-school" class="form-label long-label">Name of learner's current school (required for learner portfolio)</label>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['school.Name']"
                v-html="errors['school.Name'][0]"></p>
        </div>

        <div class="form-floating mb-3" data-bs-toggle="tooltip" data-bs-title="Please search for the school first">
            <input id="school-email" :disabled="!isSchoolFieldsEditable" type="email" name="school-email"
                class="form-control new-input" placeholder="School email address" v-model.trim="form.current_school_email" @change="handleChange">

            <label for="school-email" class="form-label long-label">School email address (required for learner portfolio)</label>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['school.Email']"
                v-html="errors['school.Email'][0]"></p>
        </div>

        <div class="form-floating mb-3" data-bs-toggle="tooltip" data-bs-title="Please search for the school first">
            <input id="school-number" :disabled="!isSchoolFieldsEditable" type="text" name="school-number"
                class="form-control new-input" placeholder="School phone number"
                v-model.trim="form.current_school_phone_number" @change="handleChange">

            <label for="school-number" class="form-label long-label">School phone number (required for learner portfolio)</label>

            <p class="invalid-feedback d-block m-0" v-if="errors && errors['school.PhoneNumber']"
                v-html="errors['school.PhoneNumber'][0]"></p>
        </div>

        <div class="form-floating mb-3"  data-bs-toggle="tooltip" data-bs-title="Please search for the school first"
            tabindex="0">
            <select id="current-school-province"
                :disabled="!isSchoolFieldsEditable"
                class="form-select new-input long-label-select"
                name="current-school-province"
                v-model.trim="form.current_school_province"
                @change="handleChange"
            >
                <option value="null" selected disabled>Please select</option>
                <option v-for="(option, key) in provinceOptions" :value="option" :key="key">{{ option }}</option>
            </select>

            <label class="long-label" for="current-school-province">Province of learner's current school (required for learner portfolio)</label>

            <p
                class="invalid-feedback d-block m-0"
                v-if="errors && errors['school.Province']"
                v-html="errors['school.Province'][0]">
            </p>
        </div>
    </section>
</template>
<script>
    export default {
        name: "ViSchoolsDropdown",
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: Object,
                required: true
            },
            label: {
                type: String,
                default: 'Name of learner\'s current school (required for learner portfolio)',
                required: false
            },
            schoolSearchUrl: {
                type: String,
                required: true
            },
            schoolSelected: {
                type: Function,
                required: false
            },
            errors: {
                type: Object,
                required: false
            },
            hideEmail: {
                type: Boolean,
                default: false,
            },
            hidePhone: {
                type: Boolean,
                default: false,
            },
            hideProvince: {
                type: Boolean,
                default: false,
            },
            disabled : {
                type: Boolean,
                default: false,
            }
        },
        data () {
            return {

                form : {
                    current_school: null,
                    current_school_province: null,
                    current_school_phone_number: null,
                    current_school_email: null,
                },
                provinceOptions : [
                    'Eastern Cape',
                    'Free State',
                    'Gauteng',
                    'KwaZulu-Natal',
                    'Limpopo',
                    'Mpumalanga',
                    'North West',
                    'Northern Cape',
                    'Western Cape',
                ],

                selectedSchool: null,
                schoolData: [],
                schoolNotFound: false,
                isDropdownVisible: false,
                isSchoolFieldsEditable: false,
                loadingSchoolData: false,
            }
        },
        computed : {
            activeSchoolData() {
                if (this.schoolData.length > 0) {
                    this.initialSchoolsData = []; // Empty initial school data once data is loaded from the API.

                    return this.schoolData;
                }

                return this.initialSchoolsData;
            },
        },
        mounted() {
            this.form = this.modelValue;
        },
        methods: {
            handleChange() {
                this.$emit('change', this.form);
            },
            getSchool(event) {
                const value = event.target.value;

                if (value.length > 2) {
                    let url = `${this.schoolSearchUrl}?name=${value}`;

                    this.schoolNotFound = false;
                    this.loadingSchoolData = true;

                    axios.get(url)
                        .then( (res)=> {
                            this.schoolData = res.data;

                            if (!this.schoolData.length) {
                                this.schoolNotFound = true;
                            }
                        }).catch(function (err) {
                            console.error(err);
                        })
                        .finally(() => {
                            this.loadingSchoolData = false;
                        });
                }
            },
            debounce(func, timeout = 700) {
                let timer;
                return (...args) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => { func.apply(this, args); }, timeout);
                };
            },
            toggleSchoolDropdown() {
                this.isDropdownVisible = !this.isDropdownVisible;

                if (this.isDropdownVisible) {
                    // setTimeout required to allow for input to become visible on the UI
                    // can only focus on a form control element that's visible
                    setTimeout(() => {
                        this.$refs.dropdownSearchInput.focus();
                    }, 20);
                }
            },
            // Guardian chose a school.
            selectSchool(schoolOption) {
                this.toggleSchoolDropdown();

                this.isSchoolFieldsEditable = false;

                // Get selected province option.
                let standardizeName = (name) => name.toLowerCase().replace(/[^\w]*/gm, '');
                let province = this.provinceOptions.find(option => standardizeName(option) === standardizeName(schoolOption.schoolprovince));

                this.form.current_school_email = schoolOption.schoolemail;
                this.form.current_school_phone_number = schoolOption.schoolphone;
                this.form.current_school_province = province;
                this.form.current_school = schoolOption.schoolname;
            },
            // School is not in the list.
            selectDefault(event) {
                this.toggleSchoolDropdown();

                this.isSchoolFieldsEditable = true;

                const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

                tooltipElements.forEach((element) => {
                    const tooltip = bootstrap.Tooltip.getInstance(element);
                    if (tooltip) {
                        tooltip.dispose();
                    }
                });

                this.form = this.modelValue;
                this.$emit('change', this.form);
            }
        }

    }
</script>
<style scoped lang="scss">

    @import 'resources/sass/abstract/colours.scss';

    #schoolSearchInput {
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid $grey;

        &:focus {
            outline: none;
        }
    }

    .school-dropdown {
        position: relative;
        display: inline-block;
    }

    .school-dropdown-content {
        display: none;
        position: absolute;
        background-color: $white;
        min-width: 290px;
        border: 1px solid $border-grey;
        z-index: 1;
        border-radius: 5px;
        box-shadow: 6px 8px 16px -3px rgba(0,0,0,0.3);


        @media screen and (max-width: 768px) {
            min-width: 236px;
        }

        &-list {
            max-height: 180px; // Trial and error, allows for 5 items to display.
            overflow-y: auto;
        }
    }

    .school-dropdown-content .dropdown-item {
        color: $black;
        padding: 6px 10px;
        text-decoration: none;
        display: block;
    }

    .school-dropdown .dropdown-item:hover {
        background-color: $lightgrey;
        cursor: pointer;
    }

    .show {
        display: block;
    }

    input[type="checkbox"] {
        -webkit-appearance: initial;
        appearance: initial;
        position: relative;
        background-image: none !important;
    }

    input[type="checkbox"]:checked:after {
        content: "X";
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }

    select[disabled] {
        // Required for bootstrap tooltips to work on disabled controls.
        pointer-events: none;
    }

    @media screen and (max-width : 690px) {
        .long-label {
            line-height: 18px;
        }

        .form-floating {
            .new-input {
                padding-top: 36px;
                height: 65px;
            }
        }   
    }

    .spinner-border {
        &.school-loader {
            width: 1.25rem; // 20px
            height: 1.25rem; // 20px
            border-width: 0.2rem;
            border-color: $pastel-blue;
            border-right-color: transparent;
        }
    }

</style>
