<template>
    <div class="form-floating field has-addons">
        <div class="form-floating mb-3">
            <input
                :type="inputType"
                class="form-control new-input"
                :id="fieldId"
                :placeholder="label"
                v-on:input="$emit('input', $event.target.value)"
            />

            <label
                :for="fieldId">{{ label }}
            </label>

            <p class="invalid-feedback d-block m-0">{{ errorMessage }}</p>
        </div>
        <div>
            <span
                class="show-password-button"
                @click="updateToggle"
            >
                <span class="icon is-small is-right">
                    <i
                        class="bi"
                        :class="{ 'bi-eye-slash-fill': toggle, 'bi-eye-fill': !toggle }"
                    ></i>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            toggle: false,
        };
    },
    props: {
        fieldId: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        errorMessage: {
            type: String,
            required: true
        }
    },
    methods: {
        updateToggle() {
            this.toggle = !this.toggle;
        }
    },
    computed: {
        inputType() {
            return this.toggle ? 'text' : 'password';
        },
    }
}
</script>
