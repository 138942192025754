<template>
    <div class="modal" id="reenrolmentSuccess" tabindex="-1" aria-labelledby="reenrolmentSuccessLabel" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title">Survey Completed</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
            </div>

            <div class="modal-body text-center">
                <p>Thank you for your submission. Your response has been successfully received and an email sent to you for reference.</p>
                <p>If you have any further questions or need assistance, please contact your Support Coach. </p>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
    export default {
        name: "ReEnrolmentSuccess",
        methods: {
            closeModal() {
                $('#reenrolmentSuccess').modal('hide');
                console.log('survey-closed');
            }
        }
    }
</script>
