<template>
    <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="requestGuardianTransfer">Primary Guardian Change Request</h1>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div v-if="!hasRequest && !success" class="modal-body">
            <template v-if="initializing == false">
                <p>A guardian transfer is required when you as the Primary Guardian choose to move all guardian responsibilities for this learner to another person.</p>

                <p>These responsibilities include:</p>

                <ul>
                    <li>Receiving and responding to all UCT Online High School communications;</li>
                    <li>Paying any fees that may be due for the learner, including any outstanding balances currently on the account;</li>
                    <li>Accessing the Guardian Portal weekly to check the learner's submissions and grades;</li>
                    <li>Accessing key information on the Guardian Portal;</li>
                    <li>Supporting the learner by checking-in with the learner, reading reports, and reaching out to the relevant school departments as needed.</li>
                </ul>

                <div class="form-field mb-3">
                    <label for="transferRequestReasons" class="fw-bold mb-2">Why are you transferring?</label>

                    <select
                        id="transferRequestReasons"
                        required
                        class="d-block form-select w-100"
                        :class="{'invalid': hasError('reason')}"
                        @change="clearError('reason')"
                        name="reason"
                    >
                        <option selected disabled :value="null">Select reason for transfer</option>
                        <option v-for="(reason, id) in reasons" :key="id" :value="id">{{ reason }}</option>
                    </select>

                    <span class="text-danger error-message">Please select a reason for transferring</span>
                </div>

                <vi-alert>
                    If a change of contact or payment details is required, you can update your contact details 
                    on the portal on the 'My Account' section. A Guardian Transfer is only required if you are 
                    transferring all guardian responsibilities to another person.
                </vi-alert>

                <div class="form-field mb-3">
                    <label for="transferGuardianTo" class="form-label fw-bold">Please enter the email address of the new guardian you are transferring the learner to:</label>

                    <input
                        id="transferGuardianTo"
                        class="form-control mb-1"
                        :class="{'invalid': hasError('email')}"
                        @input="clearError('email')"
                        type="email"
                        required
                        name="email"
                    >

                    <span class="text-danger error-message" v-if="hasError('email')">{{ errors.email[0] }}</span>
                </div>

                <vi-alert>
                    <div class="form-checkbox mb-3">
                        <input
                            id="acknowledgement"
                            type="checkbox"
                            name="acknowledgement"
                            required
                            :class="{'invalid': hasError('acknowledgement')}"
                            @change="clearError('acknowledgement')"
                        >

                        <label class="fw-bold" for="acknowledgement">I acknowledge that I will lose full access to supporting this learner as I am requesting to transfer the responsibilities of the Primary Guardian role to the above appointed guardian.</label>

                        <span class="text-danger error-message">Please acknowledge the transfer of responsibilities</span>
                    </div>
                </vi-alert>

            </template>

            <template v-else-if="initializing == null">
                <div class="alert alert-danger">
                    An error has occured. Please refresh the page. If the error persists please reach out to our support team.
                </div>
            </template>

            <template v-else-if="initializing == true">
                <div class="placeholder-glow">
                    <span class="placeholder w-75"></span>
                    <span class="placeholder w-50"></span>
                    <span class="placeholder w-75"></span>
                    <span class="placeholder w-25"></span>
                </div>
            </template>

            <div v-if="error" class="alert alert-danger">
                An error has occured. Please refresh the page and try again. If the problem persists please reach out to our support team.
            </div>
        </div>

        <div v-else-if="success" class="modal-body">
            <div class="alert alert-success m-0">
                <p>Your transfer request has been successfully logged.</p>

                <p>An email has been sent to the new guardian to approve this request. It will expire after 24 hours. If not accepted within that time frame, you may make another request to the same, or another guardian. If you have made a mistake, please reach out to our support team</p>
            </div>
        </div>

        <div v-else-if="hasRequest" class="modal-body">
            <div class="alert alert-danger m-0">You already have a request in progress with us. If this seems incorrect please reach out to our support team</div>
        </div>

        <div class="modal-footer">
            <button data-bs-dismiss="modal" aria-label="Close" class="btn-outline-uct rounded-pill px-5 py-2 text-decoration-none mt-2 me-sm-2 me-0 ms-0">
                {{ success ? 'Close' : 'Cancel' }}
            </button>

            <vi-button-spinner
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'new-btn-lg': true,
                    'm-0': true,
                    'd-none' : hasRequest || success
                }"
                :buttonText="'Request Transfer'"
                :loading="loading || initializing"
                :disabled="hasRequest || success"
            ></vi-button-spinner>
        </div>
    </form>
</template>

<script>
import error_handling from '../../../mixins/error_handling';
import scroll_to from '../../../mixins/scroll_to';

export default {
    name: "GuardianTransferModal",
    mixins: [
        error_handling,
        scroll_to,
    ],
    props: {
        learnerSfId: {
            type: String,
            required: true,
        },
        initUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Component State
            initializing: true, // true - busy, false - done(success), null - error
            hasRegistered: false, // Used to prevent registering multiple listeners on the modal events.
            success: false,
            loading: false,
            errors: null,
            error: false,

            // Data
            hasRequest: false,
            reasons: [],
            submitUrl: null,

            // Modal
            modalNode: null,
        };
    },
    mounted() {
        this.modalNode = document.querySelector(`#requestGuardianTransfer-${this.learnerSfId}`);

        if (!this.hasRegistered) {
            this.modalNode.addEventListener('shown.bs.modal', this.initListener);

            this.hasRegistered = true;
        }
    },
    destroyed() {
        if (this.modalNode) {
            this.modalNode.removeEventListener('shown.bs.modal', this.initListener);
        }
    },
    methods: {
        init() {
            return axios.get(this.initUrl)
                .then(res => {
                    const data = res.data;

                    // this.request = data.request;
                    this.reasons = data.reasons;
                    this.submitUrl = data.submitUrl;
                    this.hasRequest = data.hasRequest;
                });
        },
        initListener() {
            this.initializing = true;
            this.loading = true;
            this.success = false; // Component was reinitialized, success needs to be determined again.
            this.loading = false;
            this.error = false;
            this.hasRequest = false;

            this.init()
                .then(() => {
                    this.initializing = false;

                    this.$nextTick(() => {
                        this.registerInputListeners();
                    });
                })
                .catch(err => {
                    this.initializing = null;
                });
        },
        submit(event) {
            this.loading = true

            let data = new FormData(event.target);

            axios.post(this.submitUrl, data)
                .then(response => {
                    let data = response.data;

                    this.success = true;
                })
                .catch(error => {
                    let response = error.response;
                    let data = response.data;

                    if (data.hasOwnProperty('errors') && response.status === 422) {
                        this.errors = data.errors;
                    } else {
                        this.error = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        doesErrorsExist() {
            // Check if errors have been logged
            if (this.errors !== null) {
                return false;
            }

            return true;
        },
        registerInputListeners() {
            let inputs = Array.from(this.$el.querySelectorAll('input, select'));

            inputs.forEach(input => {
                input.addEventListener('invalid', event => {
                    input.classList.add('invalid');

                    setTimeout(() => {
                        this.scrollIntoView(this.firstError());
                    }, 10);
                }, false);

                input.addEventListener('input', event => {
                    input.classList.remove('invalid');
                }, false);

                input.addEventListener('blur', event => {
                    input.checkValidity();
                })
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.form-checkbox {
    display: grid;
    grid-template-columns: min-content auto;
    column-gap: 0.75rem;

    input {
        transform: scale(1.25);
    }

    .error-message {
        grid-column: 1 / 3;
    }
}

.modal-body {
    max-width: 75ch;
    min-width: 45ch;
    margin: 0 auto;

    .placeholder-glow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .placeholder {
            margin-bottom: 0.5rem;
        }
    }
}

.error-message {
    font-size: 0.8em;
}

input ~ .error-message,
select ~ .error-message {
    display: none;
}

input.invalid ~ .error-message,
select.invalid ~ .error-message {
    display: block;
}

label {
    color: $black;
}
</style>
