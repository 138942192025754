<template>
    <div class="container p-0">
        <div class="student-progress-card">
            <div class="student-progress-card-header d-flex align-items-center flex-wrap">
                <div>Progress</div>
                <div class="refresh-time">Updated every 2 hours</div>
            </div>

            <test-email-field v-if="isTestSite" v-on:reload-data="updateSubstituteEmail" ></test-email-field>

            <div v-if="loading" class="d-flex justify-content-center py-3">
                <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>


            <div v-else class="student-progress-card-body">
                <ul v-if="pacingData.length" class="pb-0">
                    <li v-for="data in pacingData" :key="data.friendlyName">
                        <div class="subject">
                            <p class="subject-name fw-bold">{{ data.friendlyName }}</p>
                            <div class="module-stats">
                                <p class="subject-modules-total"><span class="fw-bold">{{ data.totalModules }}</span> Modules in Total</p>
                                <ul class="subject-modules">
                                    <li class="subject-modules-completed"><span class="fw-bold">{{ data.pacing.data.summary.completed }}</span>&nbsp;Completed</li>
                                    <li class="subject-modules-upcoming"><span class="fw-bold">{{ data.pacing.data.summary.todo }}</span>&nbsp;Upcoming</li>
                                </ul>
                            </div>
                        </div>
                        <div class="graph">
                            <canvas :class="`guardian-course-chart pacing-${studentId}`" :id="`chart-${data.pacing.chart_id}`" :data-pacing=JSON.stringify(data.pacing) width="84" height="84"></canvas>
                        </div>
                        <div
                            class="next fw-bold"
                            data-bs-toggle="modal"
                            data-bs-target="#paceDataGrades"
                            :data-student-email="studentEmail"
                        >VIEW MORE</div>
                    </li>
                </ul>

                <div v-else class="container">
                    <div class="row mb-3">
                        <div v-if="showFuture" class="col-12 teacher-block">
                            <h4 class="text-pastel-blue mb-2">We are just as excited as you are!</h4>

                            <p class="mb-2">School is scheduled to start on {{ startDate }}.</p>

                            <p class="mb-0">Your profile and all your information will be available from 07h00 (SAST) on this day.</p>
                        </div>

                        <div v-if="showPacingError" class="mb-0 mt-3">Something went wrong while trying to find this information for you. Sorry about that! Please try again a bit later. If the error persists, please <a href="mailto:techsupport@uctohs.valentureonline.com" class="text-decoration-underline" target='_blank'>contact our support team</a>.</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        name: "PacingData",

        data: () => ({
            loading: false,
            showFuture: false,
            showPacingError: null,
            startDate: null,
            substituteEmail: null,
            pacingData: [],
        }),

        props: {
            studentEmail: {
                type: String,
                required: true,
                default: null
            },
            studentId: {
                type: String,
                required: true,
                default: null
            },
            currentProgramEnrolment: {
                type: Object,
                required: false,
                default: null
            },
            initialPaceData: {
                type: Object | Array,
                required: true,
                default: () => ({}),
            },
            fetchPaceUrl: {
                type: String,
                required: false,
                default: null
            },
            isTestSite: {
                type: Boolean,
                required: false,
                default: false
            },
        },

        mounted() {
            this.pacingData = this.initialPaceData;

            this.$nextTick(() => {
                this.processPaceData();
            })
        },
        methods: {
            updateSubstituteEmail(email) {
                this.substituteEmail = email;
                this.fetchPace();
            },
            fetchPace() {
                this.loading = true;

                let data = {
                    student_id: this.studentId,
                    student_email: this.substituteEmail,
                }

                axios.get(this.fetchPaceUrl, { params: data })
                    .then(res => {
                        this.pacingData = res.data.data;
                        this.loading = false;
                    })
                    .catch(err => {
                        this.showPacingError = true;
                        this.loading = false;
                    })
                    .finally(() => {
                        this.processPaceData();
                    }); 
            },
            processPaceData() {
                if (this.pacingData.length) {
                    var event = new CustomEvent('pacing-data-loaded', {
                        detail: {
                            domClass: `.pacing-${this.studentId}`,
                        }
                    });

                    document.dispatchEvent(event);

                    return;
                }

                if (!this.currentProgramEnrolment) {
                    this.showPacingError = true;
                    return;
                }

                let Learner_Start_Date__c = this.currentProgramEnrolment.Learner_Start_Date__c || null;
                let Application__c = this.currentProgramEnrolment.Application__c || null;

                if (!Application__c) {
                    this.showPacingError = true;
                    return;
                }

                if (!Learner_Start_Date__c) {
                    this.showPacingError = true;
                    return;
                }

                this.startDate = new Date(Learner_Start_Date__c).toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                });
                let today = new Date().getTime();
                let startDateTime = new Date(Learner_Start_Date__c).getTime();

                if (today > startDateTime) {
                    this.showPacingError = true;
                } else {
                    this.showFuture = true;
                }
            }
        }
    }
</script>
