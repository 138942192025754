<template>
    <div class="modal fade success-modal" id="llsSuccess" tabindex="-1" aria-labelledby="llsSuccessLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border border-3 border-white">
                <div class="modal-body text-center p-4 position-relative pb-2">
                    <div class="modal-icon-block success-modal-tick">
                        <img :src="'/portal-data/uct/information.png'" alt="Information Icon">
                    </div>
                    <h3 class="text-white fw-bold mb-3">Hi there!</h3>
                    <h5 class="mb-0 text-white mb-3">We noticed you may be eligible for our Learning Liberated Scholarship and Bursary programme!</h5>
                </div>
                <div class="modal-footer text-center border-0 pb-4 flex-col">
                    <button type="button" class="btn-light-uct px-5 py-2 w-auto rounded-pill mx-auto" data-bs-dismiss="modal" aria-label="Close">Start a Bursary application</button>
                    <button 
                        type="button" 
                        class="new-btn ohs-white-outline-btn rounded-pill px-5 py-2 w-auto rounded-pill mx-auto"
                        @click="returnToApplicationClick()"
                    >
                        Continue with School application
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "LlsWelcomeModal",
    computed: {
        returnToApplicationInformation() {
            return this.$endPointsConfig.returnToApplication
        },
    },
    methods: {
        returnToApplicationClick() {
            window.location.href = this.returnToApplicationInformation.destinationLink;
        },
    },
}

</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';
.modal-icon-block {
    background-color: $pastel-blue;
}
</style>
