<template>
    <div class="form-check my-1">
        <input class="form-check-input" :id="id"  type="checkbox" :checked="isChecked" @change="updateInput"/>
        <label class="form-check-label" :for="id"> {{ label }} </label>
        <span v-if="errors.length > 0" class="text-danger">
            <p v-for="error in errors" :key="error" class="invalid-feedback d-block mt-2 mb-0">{{ error }}</p>
        </span>
    </div>
</template>
<script>
    export default {
        name: 'ScholarshipCheckbox',
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            modelValue: {
                type: Boolean,
                default: false,
            },
            id: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            errors: {
                type: Array,
                default: () => [],
            },

        },
        computed : {
            isChecked() {
                return this.modelValue
            }
        },
        methods: {
            handleInput() {
                this.$emit('input', event.target.checked)
            },
            updateInput(event) {
                let isChecked = event.target.checked
                this.$emit('change', isChecked)
            }
        }
    }
</script>