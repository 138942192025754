<template>
    <div>
        <router-view v-if="student.withdrawalData.status == 'Enrolled' || student.withdrawalData.status == 'Pending Withdrawal'"></router-view>

        <vi-alert type="error" v-else>
            We are unable to process your request at this time. Please contact your learner’s Support Coach for assistance.
        </vi-alert>
    </div>
</template>
<script>
export default {
    name: "Withdrawal",
    data () {
        return {
            studentId: null,
        }
    },
    props: {
        student: {
            type: Object,
            default: () => {}
        },
    },
    mounted () {
        this.show();
    },
    methods: {
        show() {

            if (this.student.withdrawalData.status == 'Enrolled') {
                if (this.$route.params.studentId == this.student.id && this.$route.name == 'withdrawal.info') {
                    return;
                }

                if (this.student) {
                    this.$router.push({name: 'withdrawal.info', params: {
                        studentId: this.student.id,
                        student: this.student
                    }}).catch();
                }
            } else if (this.student.withdrawalData.status == 'Pending Withdrawal') {
                if (this.$route.params.studentId == this.student.id && this.$route.name == 'withdrawal.cancel') {
                    return;
                }

                if (this.student) {
                    this.$router.push({name: 'withdrawal.cancel', params: {
                        studentId: this.student.id,
                        student: this.student
                    }}).catch();
                }
            }  
        },
    }
}
</script>
