<template>
    <div class="final-report-submission-step text-start guardian-loging-form-container mx-auto form">
        <slot name="header"></slot>

        <div class="mb-3"
            v-for="uploadField in uploadFields"
            :key="uploadField.field"
        >
            <p class="mb-2 fw-bold text-center">{{ uploadField.heading }}</p>
            <vi-file-selector
                :upload-status="uploadField.uploadState.status"
                :rejected-reason="uploadField.uploadState.rejectedReason"
                :upload-field-name="uploadField.field"
                :handle-upload="handleUpload"
                :max-file-size="8000000"
                accepted-file-types="image/*, .pdf"
            ></vi-file-selector>
        </div>

        <slot name="footer"></slot>

        <p v-if="hasVerificationError" ref="uploadError" class="alert alert-danger">
            An error occured while trying to submit your final report. Please try again. If the error persists, please contact our support team.
        </p>

        <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
            <a
                :href="backButtonUrl"
                class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0"
            >Back</a>

            <button
                :disabled="!hasUploadedAllFiles"
                @click.prevent="openModal"
                type="submit"
                class="new-btn new-btn-dark new-btn-lg mt-2"
            >Next</button>
        </div>

        <div id="finalReportSubmissionModal" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center p-4">
                        <p class="mb-0">
                            Please confirm the {{ uploadFields.length > 1 ? 'documents' : 'document' }} you are uploading is the correct grade report{{ uploadFields.length > 1 ? 's' : '' }}, incorrect term reports will not be reviewed.
                        </p>
                    </div>
                    <div class="modal-footer p-3 mb-2 justify-content-center">
                        <button
                            type="button"
                            class="btn-outline-uct rounded-pill px-5 py-2 text-decoration-none mt-2 me-2"
                            data-bs-dismiss="modal"
                        >Back</button>

                        <button
                            type="button"
                            class="btn-outline-uct-dark rounded-pill px-5 py-2 text-decoration-none mt-2"
                            @click="confirmClickHandler"
                        >Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import scroll_to from '../mixins/scroll_to';

export default {
    name: 'FinalReportSubmission',
    mixins: [
        scroll_to,
    ],
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        uploadUrl: {
            type: String,
            required: true,
        },
        uploadFields: {
            type: Array,
            required: true,
        },
        backButtonUrl: {
            type: String,
            required: true,
        },
        redirectUrl: {
            type: String,
            required: true,
        },
        // Notify of success.
        completeUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Used to keep track of which files have been uploaded.
            fileUploadMonitor: {},
            hasUploadedAllFiles: false,
            confirmationModal: null,
            hasVerificationError: false,
        };
    },
    methods: {
        async confirmClickHandler() {
            try {
                this.hasVerificationError = false;
                var response = await axios.post(this.completeUrl);
                window.location.href = response.data.redirectUrl;
            } catch (error) {
                this.hasVerificationError = true;
                this.closeModal();

                this.$nextTick(() => {
                    this.scrollIntoView(this.$refs.uploadError);
                });
            }
        },
        openModal() {
            this.confirmationModal.show();
        },
        closeModal() {
            this.confirmationModal.hide();
        },
        handleUpload(formData) {
            const currentUploadField = this.getCurrentUploadField(formData);
            const currentDocumentId = this.uploadFields.find(uploadField => uploadField.field === currentUploadField).uploadState.ContentDocumentId;

            formData.append('applicationId', this.applicationId);
            formData.append('uploadFileField', currentUploadField);
            formData.append('fileId', currentDocumentId ?? '');

            const headers = {
                'Content-Type': 'multipart/form-data',
            };

            return axios.post(this.uploadUrl, formData, headers)
                .then(msg => {
                    this.fileUploadMonitor[currentUploadField] = true;

                    this.checkUploadedFiles();

                    return 'Document successfully uploaded.';
                })
                .catch(error => {
                    console.error(error);

                    return 'An error has occurred, please retry.';
                });
        },
        getCurrentUploadField(formData) {
            for (const uploadField of this.uploadFields) {
                if (formData.has(uploadField.field)) {
                    return uploadField.field;
                }
            }
        },
        checkUploadedFiles() {
            this.hasUploadedAllFiles = Object.values(this.fileUploadMonitor)
                .every(val => val === true);
        }
    },
    computed: {},
    created() {
        for (const uploadField of this.uploadFields) {
            this.fileUploadMonitor[uploadField.field] = false;
        }
    },
    mounted() {

        this.uploadFields.forEach(uploadField => {
            if (uploadField.uploadState.status === 'Accepted' || uploadField.uploadState.status === 'Uploaded') {
                this.fileUploadMonitor[uploadField.field] = true;
            }
        });

        this.checkUploadedFiles();

        this.confirmationModal = new bootstrap.Modal('#finalReportSubmissionModal', {});
    }
}
</script>
