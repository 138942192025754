<template>
    <div class="id-or-passport-input">
        <account-radio
            :options="idTypeOptions"
            label-text="Identification Number Type"
            v-model="documentType"
            :required="true"
            form-id="identification-form"
            :disabled="disabled"
            name="VMS_ID_Type__c"
            tooltip="Click edit below to edit this"
        ></account-radio>

        <account-input
            ref="accountInput"
            form-id="identification-form"
            label-text="ID or Passport"
            name="ID_or_Passport_Number__c"
            :init-value="idNumber"
            :on-submit="onSubmit"
            class="mb-3 input-light"
            inputClass="account-input-container-light"
            @disabled="isDisabled => disabled = isDisabled"
        ></account-input>
    </div>
</template>

<script>
import AccountRadio from './AccountRadio.vue';
import document_type_mixin from '../../mixins/document_type_mixin';

export default {
    name: 'IdOrPassportInput',
    mixins: [
        document_type_mixin
    ],
    components: {
        AccountRadio
    },
    data() {
        return {
            documentType: this.idType,
            idTypeOptions: null,
            disabled: true,
        };
    },
    props: {
        onSubmit: {
            type: Function,
            required: true,
        },
        idType: {
            default: null,
        },
        idNumber: {
            default: null,
        },
    },
    methods: {
        focusScroll() {
            this.$refs.accountInput.$el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    },
    mounted() {
        let inputElements = document.querySelectorAll('input[name="VMS_ID_Type__c"]');
        if (inputElements.length > 0) {
            inputElements[0].checked = true;
        }
    },
    created() {
        this.idTypeOptions = this.getUploadDocumentTypeOptions(
            this.UploadDocumentTypes.BirthCertificate,
        );
        this.idTypeOptions.reverse();
    },
}
</script>