<template>
    <fieldset ref="fieldset" :form="formId">
        <legend class="form-label text-white">{{ labelText }}</legend>
        <div class="rounded-pill w-100 mb-3">
            <div class="form-check"
                v-for="(option) in options"
                :key="option.value"
            >
                <input
                    class="form-check-input"
                    type="radio"
                    :name="name"
                    :id="option.value"
                    :value="option.value"
                    :checked="shouldBeChecked(option.value)"
                    @change="event => pickedValue = event.target.value"
                    :form="formId"
                    :disabled="disabled"
                    :required="required"
                >
                <label class="form-check-label text-white" :for="option.text">
                    {{ option.text }}
                </label>
            </div>
        </div>
    </fieldset>
</template>

<script>
export default {
    name: 'AccountRadio',
    data() {
        return {
            tooltipInstance: null,
            pickedValue: null,
        };
    },
    props: {
        value: {},
        formId: {
            type: String,
            default: null,
        },
        labelText: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: false,
            default: null,
        }
    },
    mounted() {
        this.tooltipInstance = this.initTooltip(this.$refs.fieldset, this.tooltip);
    },
    methods: {
        initTooltip(targetNode, tooltip) {
            if (!targetNode || !tooltip) {
                return;
            }

            const instance = new bootstrap.Tooltip(targetNode, {
                placement: 'bottom',
                title: tooltip,
            });

            return instance;
        },
        shouldBeChecked(value) {
            return this.value === value;
        }
    },
    watch: {
        disabled(value) {
            if (!this.tooltipInstance) {
                return;
            }

            if (value) {
                this.tooltipInstance.enable();
            } else {
                this.tooltipInstance.disable();
            }
        },
        pickedValue(val) {
            this.$emit('input', val);
        },
    }
}
</script>

<style lang="scss" scoped>

.tool-tip [disabled] {
    pointer-events: none;
}
</style>