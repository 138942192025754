<template>
    <div class="matric-exam-entry-schedule">
        <template v-if="changesNeeded === null">
            <p>Hello Matric Guardian</p>

            <p>We are reaching out to inform you that we have received the Entry Schedules with venue allocations from SACAI. An Entry Schedule contains a learner's details, the subjects they are registered to write (including any subjects they are currently taking through an external service provider), and the examination venue allocated to the learner.</p>

            <p>As you prepare with your learner for the final year-end, in-person NSC exams, it is important for you to carefully review the Entry Schedule information. This is the information on file for your learner's exam registration with SACAI, so it is critical that all information is accurate.</p>

            <p>While we take the utmost care to ensure that each learner's information is correct and accurate, <span class="fw-bold">we do require you, as the guardian, to check and validate that the information is correct.</span></p>

            <p>All Entry Schedules forms need to be checked by 5pm on <span class="fw-bold">{{ reviewDeadline }}</span>.</p>

            <p><span class="fw-bold">We are not able to accept any changes to Entry Schedules documents after this date and therefore it is critical that the information in the document has been checked.</span> Should the document not be checked and incorrect information is submitted to the examination body,  your learner may have errors on their final certificate or be adversely affected when trying to write their examinations.</p>

            <h2>Venue Allocations</h2>

            <p>It is important to note that SACAI allocates learners to examination venues. There are a number of factors taken into consideration when allocations are done. In addition, SACAI needs to take into account the capacity of an examination venue, subjects being written, and the ability of a venue to accommodate concession candidates amongst others.</p>

            <p>While SACAI has made every reasonable effort to accommodate families, there will be instances where SACAI is not able to accommodate learners at any of their preferred venues. Unfortunately, unless there is an exceptional reason for requesting a venue change, at this juncture, it will not be possible to change the allocated venues.</p>

            <p>Below are the acceptable exceptional circumstances that will be considered by SACAI for a venue allocation change. We will require documentation to validate the exam venue change request.</p>

            <ul>
                <li>The learner has recently moved and the new home address is now more than 80km away from the allocated venue.</li>
                <li>There has been a change in family/guardianship circumstances and the learner now resides more than 80km away from the allocated venue.</li>
                <li>The allocated examination venue is more than 80km away from the learner's residential address AND there is a closer venue.</li>
            </ul>

            <h2>Step 1: Review your learner's Entry Schedule document</h2>

            <p>Please complete a thorough review of the information contained within your learner's Entry Schedule document, paying careful attention to information contained on each page as outlined below.</p>

            <p>The document can be found <a :href="scheduleUrl" target="_blank" rel="noopener noreferrer">here</a>.</p>

            <h3>Page 1</h3>

            <ol>
                <li>Your learner's first name and surname (ensure that the spelling is correct; this needs to be the same as what is currently on your learner's official ID document).</li>
                <li>Your learner's ID number</li>
                <li>Your learner's date of birth</li>
                <li>The list of subjects they are registered for</li>
            </ol>

            <h3>Page 2</h3>

            <ol start="5">
                <li>Subjects registered with other institutions (if applicable)</li>
            </ol>

            <h2>Step 2: Review outcome</h2>

            <p>Once you have checked the Entry Schedule information, please answer the following question:</p>

            <p>Is the information contained within your learner's G12 Entry Schedule document accurate?</p>

            <form
                ref="changesForm"
                @submit.prevent="onSubmit"
                id="reviewForm"
            >
                <label>
                    <input type="radio" name="reviewed" id="reviewYes" value="yes" @input="changesError = false">&nbsp;Yes - the information contained within is accurate
                </label>

                <label>
                    <input type="radio" name="reviewed" id="reviewNo" value="no" @input="changesError = false">&nbsp;No - the information contained within is not accurate and updates are required
                </label>

                <p class="invalid-feedback d-block m-0 mt-1" v-if="changesError">Please state whether or not the information within the document is correct.</p>
            </form>

            <h2>Step 3: Updates to your learner's Entry Schedule document</h2>

            <p>In the case that changes need to be made to the information contained in your learner's Entry Schedule document, please provide these updates by completing this <a :href="changesDocument" target="_blank" rel="noopener noreferrer">form</a>.</p>

            <h2>Step 4: Submission</h2>

            <p>Once you have completed the steps above, click the submit button below to complete the review of your learner's pre-registration document.</p>
        </template>

        <!-- Successful form submission -->
        <template v-if="changesNeeded === false">
            <div class="changes-needed">
                <h1>Thank You!</h1>

                <p>You have successfully reviewed the Matric Exam Entry Schedule document for {{ leanerName }}.</p>

                <p>Should you have any queries or require further assistance, please do not hesitate to contact us.</p>
            </div>
        </template>

        <!-- Failed to submit for -->
        <template v-if="changesNeeded === true">
            <div class="changes-needed">
                <h1>Thank You!</h1>

                <p>You have successfully reviewed the Matric Exam Entry Schedule document for {{ leanerName }}.</p>

                <p>We have noted that you have indicated that there are updates required to the information contained within the document.</p>

                <div class="alert alert-warning">
                    Please make sure you've completed the form linked below before leaving this page.
                </div>
            </div>
        </template>

        <div v-if="error" class="alert alert-danger">
            An error has occured. Please contact support at <a href="mailto:support@valentureinstitute.com">support@valentureinstitute.com</a> if the problem persists.
        </div>

        <div class="buttons-wrapper">

            <vi-button-spinner
                v-if="changesNeeded === null"
                buttonText="Submit"
                :loading="loading"
                form="reviewForm"
            ></vi-button-spinner>

            <a v-else :href="dashboardUrl" :class="['new-btn', 'new-btn-dark', 'new-btn-sm', { 'ghost-mode': changesNeeded }]">Return to dashboard</a>

            <a v-if="changesNeeded" :href="changesDocument" class="new-btn new-btn-dark new-btn-sm">Form to submit changes</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatricExamEntrySchedule',
    props: {
        submitUrl: {
            type: String,
            required: true,
        },
        scheduleUrl: {
            type: String,
            required: true,
        },
        reviewDeadline: {
            type: String,
            required: true,
        },
        dashboardUrl: {
            type: String,
            required: true,
        },
        leanerName: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            changesNeeded: null,
            changesDocument: 'https://docs.google.com/forms/d/e/1FAIpQLSfowiACcHOuNrwxUbhhHeLlE8did7UXRIpG0hRJmmXG61rLfw/viewform?usp=sf_link',
            error: false,
            changesError: false,

            loading: false,
        };
    },
    methods: {
        onSubmit(event) {
            let formData = new FormData(event.target);

            if (!formData.has('reviewed')) {
                this.changesError = true;
                this.scrollIntoView(this.$refs.changesForm);
                return;
            } else {
                this.changesError = false;
            }

            this.loading = true;
            this.error = false;

            axios.post(this.submitUrl, formData)
                .then((response) => {
                    this.changesNeeded = formData.get('reviewed') === 'no';
                })
                .catch((error) => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        scrollIntoView(node) {
            node.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../sass/abstract/colours.scss";

.matric-exam-entry-schedule {
    padding: 3% 6%;
    min-height: 100%;
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 1em;
    margin-top: 2em;
}

h3 {
    font-size: 1.25rem;
    margin-bottom: 1em;
}

p,
li,
label {
    font-size: 1rem;
}

p {
    margin-bottom: 1em;

    // a {
    //     color: $pastel-light-blue;
    //     text-decoration: underline;
    // }
}

li,
label {
    margin-bottom: 0.25em;
    color: $black;
}

.buttons-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    @media screen and (max-width: 768px) {
        padding-bottom: 3rem;
        flex-direction: column;
        align-items: center;
    }
}

.new-btn {
    max-width: fit-content;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    border: 2px solid $pastel-blue;

    & + & {
        @media screen and (max-width: 768px) {
            margin-top: 1rem;
        }

        @media screen and (min-width: 768px) {
            margin-left: 2rem;
        }
    }

    &:hover {
        cursor: pointer;
        border-color: $pastel-light-blue;
    }

    &.ghost-mode {
        background-color: transparent;
        border-color: $pastel-light-blue;
        color: $pastel-light-blue;

        &:hover {
            background-color: $pastel-light-blue;
            color: $white;
        }
    }
}

.changes-needed {
    text-align: center;

    h1 {
        text-align: center;
        margin-bottom: 1em;
        color: $pastel-blue;
    }
}
</style>
