<template>
    <div class="modal fade" :id="`studentReportModal-${reports.studentId}`" tabindex="-1" aria-labelledby="studentReportModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">

            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title" id="studentReportModalLabel">{{ headerPrefix + '-' + reports.preferredName }}</h5>
                    <div class="d-flex flex-row align-items-center">
                        <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>

                <div class="modal-body">

                    <div class="accordion accordion-flush" id="accordionFlushExample">

                        <div v-if="!reports.programEnrolments.length" class="accordion-item">
                            <div class="accordion-body">
                                <p>Something went wrong while fetching report data. Please contact your support coach for help.</p>
                            </div>
                        </div>

                        <template v-else>

                            <div v-for="programEnrolment in reports.programEnrolments" class="accordion-item">
                                <h2 class="accordion-header" :id="`report-header-${programEnrolment.id}`">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#report-${programEnrolment.id}`" aria-expanded="false" :aria-controls="`report-${programEnrolment.id}`">
                                        {{ programEnrolment.name }}
                                    </button>
                                </h2>

                                <div :id="`report-${programEnrolment.id}`" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        
                                            <p v-if="!programEnrolment.reports.length">Your term reports are not ready yet.</p>

                                            <ul v-else>
                                                <li v-for="termReport in programEnrolment.reports">
                                                    <a :href="termReport.downloadLink">
                                                        {{ termReport.name }}
                                                    </a>
                                                </li>
                                            </ul>

                                    </div>
                                </div>
                            </div>
                        </template>

                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Reports",

        data: () => ({
            loading: false,
        }),

        props: {
            headerPrefix: {
                type: String,
                default: null,
            },
            reports: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },

        mounted() {
        }
    }
</script>
