<template>
    <div id="gradingQueryNoticeModal" ref="gradingModal" class="modal fade" tabindex="-1" aria-labelledby="gradingQueryNoticeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="gradingQueryNoticeModalLabel">Assessment Query</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>
                <div v-if="hasEnrolledStudent" class="modal-body">
                    <div class="teacher-block mb-3">
                        <div class="col">
                            <p class="mb-2 d-flex flex-row justify-content-start align-items-center text-decoration-none w-100">
                                Please select the learner whose assessment you would like to query and click on the assessment in the Progress card.
                            </p>
                        </div>
                    </div>
                    <div class="student-list-container">
                        <vi-student-list
                            :students="students"
                            @close-modal-and-click-student="handleCloseModalAndClickStudent"
                        ></vi-student-list>
                    </div>
                </div>
                <div v-else class="modal-body">
                    <div class="teacher-block mb-3">
                        <div class="col">
                            <p class="mb-2 d-flex flex-row justify-content-start align-items-center text-decoration-none w-100">
                                There are no enrolled learners associated with your account.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'students': {
            type: Array,
            required: true
        },
        'hasEnrolledStudent': {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            gradingModal: null,
        }
    },
    mounted() {
        if (typeof bootstrap !== 'undefined') {
            this.gradingModal = new bootstrap.Modal(this.$refs.gradingModal);
        }
    },
    methods: {
        handleCloseModalAndClickStudent(studentEmail) {
            this.closeModal();

            const studentElement = document.getElementById(`heading-${studentEmail}`);
            if (studentElement) {
                studentElement.click();
            }

            const lookerEvent = new CustomEvent('vi-looker.open', {
                detail: {
                    studentEmail,
                    openGradingQueries: true,
                    id: 'assessment-submissions',
                }
            });

            document.body.dispatchEvent(lookerEvent);

            $('#paceDataGrades').modal('show');
        },
        closeModal() {
            this.gradingModal.hide();
        }
    }
};
</script>

<style scoped lang="scss">

    .student-list-container {
        padding: 0 12px;
    }

</style>
