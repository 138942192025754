<template>
    <div class="text-center px-3">
        <form id="learnerPortfolioForm" method="POST" @submit.prevent="uploadFiles($event)" :class="isUploading ? 'hidden' : ''">
            <VueFileAgent
            ref="vueFileAgent"
            :theme="'grid'"
            :multiple="true"
            :deletable="true"
            :meta="true"
            :accept="'.jpg,.jpeg,.png,.pdf,.bmp,.tiff,.doc,.docx,.pages,.txt,.rft,.xls,.xlsx,.wpd,.wps,.odt,.ppt,.pptx,.gdoc'"
            :maxSize="'8MB'"
            :maxFiles="20"
            :helpText="'Click or drop files here'"
            :errorText="{
                type: 'Invalid file type. Only images, pdf and doc are allowed',
                size: 'Files should not exceed 8MB in size',
            }"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            @upload="onUpload($event)"
            @upload:error="onUploadError($event)"
            v-model="fileRecords"
            id="portfolioUploaderInput"
            ></VueFileAgent>

            <!-- we need to add the google site key for the reCAPTCHA -->

            <button class="btn-uct rounded border-0 px-4 py-2 mt-4 text-white g-recaptcha" :disabled="!fileRecordsForUpload.length">
                Upload {{ fileRecordsForUpload.length }} files
            </button>
        </form>
        <div class="w-100" :class="isUploading ? '' : 'hidden'">
            <div class="rocket-animation-container position-relative d-inline-block">
                <img class="mb-3" :src="'/portal-data/uct/rocket-white.svg'" alt="Dashboard Graphic" width="80">
                <!-- <div class="rocket-line"></div>
                <div class="rocket-line-2"></div> -->
                <div class="rocket-circle"></div>
                <div class="rocket-circle-2"></div>
                <div class="rocket-circle-3"></div>
            </div>
            <p>Uploading Files...</p>
        </div>
    </div>
</template>

<script>

    export default {
        name: "LearnerPortfolioUploader",
        data() {
            return {
                fileRecordsForUpload: [], // maintain an upload queue
                fileRecords: [],
                uploadUrl: '/uct/learner-portfolio-upload',
                deleteUrl : '/uct/learner-portfolio-upload/file/delete',
                successCallbackUrl : '/uct/learner-portfolio-upload/document-uploaded',
                uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
                isUploading: false
            }
        },

        created() {
            window.addEventListener('beforeunload', this.beforeWindowUnload)
        },
        beforeDestroy() {
            window.removeEventListener('beforeunload', this.beforeWindowUnload)
        },
        methods: {
            uploadFiles: function () {
              this.isUploading = true
              let contact = this.$attrs['contact']
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload, function createFormData(fileData){
                    var formData = new FormData();
                    formData.append('contact', contact);
                    formData.append('filename', fileData.file.name);
                    formData.append('file', fileData.file);
                    return formData;
                });
                this.fileRecordsForUpload = [];

                const elements = document.getElementsByClassName('has-error');
                let i = 0;
                while(elements.length > 0){
                    this.fileRecords.splice(0, 1);
                    elements[0].parentNode.remove();
                    i++;
                }
            },
             onUpload(responses) {
                 let error = false;
                 for (var i = 0; i < responses.length; i++) {
                    if (responses[i].error) {
                        if(!error){
                            error = true;
                        }
                        continue;
                    }else{
                        this.fileRecords[i]['fileId'] = responses[i].data.id
                        // this.fileRecords.splice(i, 1);
                    }
                 }
                 if(!error){
                    let data = {
                        contactId : this.$attrs['contact']
                    }
                    axios
                        .post(this.successCallbackUrl, data)
                        .then(response => {
                            window.removeEventListener('beforeunload', this.beforeWindowUnload)
                            //alert('Documents Uploaded Successfully');

                            // refresh the page
                            let url = `/uct/learner-portfolio/success?portfolio_id=${this.$attrs['portfolio']}&name=${this.$attrs['name']}`
                            window.location.replace(url);
                        })
                        .catch(error => {
                            this.isUploading = false;
                            alert('Sorry! An error has occurred while uploading.');
                        });
                 }else{
                    this.isUploading = false;
                    alert('Sorry! An error has occurred while uploading.');
                 }
            },
            onUploadError(failedResponses) {
                this.isUploading = false;
                //console.log(failedResponses)
                let elements = document.getElementsByClassName('file-error-message');

                for (var i = 0; i < failedResponses.length; i++) {
                    elements[i].innerText = failedResponses[i]['response']['data']['errors']['file'][0];
                }

                // alert('Sorry! An error has occurred while uploading.');
            },
            deleteUploadedFile: function (fileRecord) {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.deleteUpload(this.deleteUrl, this.uploadHeaders, fileRecord);
            },
            filesSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },
            onBeforeDelete: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                    this.fileRecordsForUpload.splice(i, 1);
                    var k = this.fileRecords.indexOf(fileRecord);
                    if (k !== -1) this.fileRecords.splice(k, 1);
                } else {
                    if (confirm('Are you sure you want to delete?')) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                }
            },
            fileDeleted: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }
            },
            beforeWindowUnload(e) {
                e.preventDefault()
                e.returnValue = ''
            },
        },
    }
</script>