<template>
    <div class="vi-subject-change-request learner-card shadow">
        <h5 class="text-pastel-blue fw-bold mb-3">Request Subject Change</h5>

        <div v-if="status.existingRequest && status.existingRequest != null" class="requests">
            <div
                class="request"
            >
                <div><span>Request Date:</span>&nbsp;{{ status.existingRequest.Request_Date__c }}</div>
                <div><span>Request Ref:</span>&nbsp;{{ status.existingRequest.Name }}</div>
                <div><span>Request Status:</span>&nbsp;{{ status.existingRequest.Status__c }}</div>
            </div>
        </div>

        <div v-if="error" class="alert alert-danger mb-3">
            An error occured trying to load your existing requests. If this problem persists please contact support.
        </div>

        <button v-if="!loading" :id="`subjectChangeButton-${student.id}`" class="btn uct-dark rounded-pill w-100 px-4" @click="openSubjectChangeModal">Request Subject Change</button>

        <vi-fal-subject-change-modal
            :student-id="student.id"
            :student-name="student.name"
            :student-grade="student.currentProgramEnrolment.hed__Account__r.Name"
            :changable-fals="changableFals"
            :current-fal="currentFal"
        ></vi-fal-subject-change-modal>

        <vi-fal-subject-change-confirmation
            :student-id="student.id"
        ></vi-fal-subject-change-confirmation>

        <vi-fal-subject-change-existing
            :student-id="student.id"
        ></vi-fal-subject-change-existing>

        <vi-fal-subject-change-deadline-passed
            :student-id="student.id"
            :deadlines="deadlines"
        ></vi-fal-subject-change-deadline-passed>
    </div>
</template>

<script>
export default {
    name: "FalRequest",
    props: {
        student: {
            type: Object,
            required: true,
        }

    },
    data() {
        return {
            status: {
                canChange: false,
                reason: null,
                existingRequest: null,
            },
            changableFals: {},
            currentFal: {},
            deadlines: [],
            error: false,
            loading: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        openSubjectChangeModal() {
            if (this.status.existingRequest) {
                $(`#falSubjectExisting-${this.student.id}`).modal('show');
                return;
            }

            if (!this.status.canChange && this.status.reason === 'invalid-date') {
                $(`#falSubjectDeadlinePassed-${this.student.id}`).modal('show');
                return;
            }

            $(`#requestFalSubjectChange-${this.student.id}`).modal('show');
        },
        async init() {
            this.loading = true;

            axios.get(`${window.endpoints.falSubjectChangeRequest.init}?learnerSfId=${this.student.id}`)
                .then(response => {
                    this.status = response.data.status;
                    this.changableFals = response.data.changableFals;
                    this.currentFal = response.data.currentFal;
                    this.deadlines = response.data.deadlines;
                })
                .catch(error => {
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';

.uct-dark {
    background-color: $pastel-blue;
    color: $white;

    &:hover {
        background-color: $pastel-light-blue;
    }
}

.requests {
    margin-bottom: 1rem;
}

.request {
    font-size: 14px;
    padding: 0.5rem 1rem;
    background-color: lighten($bluef7, 5%);
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    span {
        font-weight: 600;
    }
}

</style>
