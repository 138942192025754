<template>
    <div class="guardian-basic-info-section position-relative">
        <h2 class="mb-4 text-white pb-4">My Details</h2>
        <div>
            <!-- success message -->
            <div class="alert alert-success mb-4 text-center" v-if="isSuccessful" id="successAlert">
                <span v-if="customSuccessMessage" v-html="customSuccessMessage"></span>

                <template v-else>Details updated successfully.</template>
            </div>

            <!-- error message -->
            <div class="alert alert-danger text-center" v-if="errorMessage" v-html="errorMessage"></div>

            <div class="row">
                <!-- user image upload -->
                <div class="col-12 col-md-2 col-lg-2">
                    <div class="me-0 me-sm-4 d-flex flex-row justify-content-starts justify-content-md-between align-items-start">
                        <img class="user-icon-img" :src="'/portal-data/uct/user-face-icon.svg'" alt="Profile Image">
                    </div>
                </div>
                <!-- user name update inputs -->
                <div class="col-12 col-md-5 col-lg-5">
                    <template v-if="false"> <!-- Temp: Removing the first and last name edits. -->
                        <account-input
                            label-text="First Name"
                            name="FirstName"
                            :required="true"
                            :init-value="guardian.FirstName"
                            :on-submit="onSubmitHandler.bind(this)"
                            class="mb-3 input-light"
                            inputClass="account-input-container-light"
                            :error="this.errors['FirstName'] ? this.errors['FirstName'][0] : null"
                        ></account-input>

                        <account-input
                            label-text="Last Name"
                            name="LastName"
                            :required="true"
                            :init-value="guardian.LastName"
                            :on-submit="onSubmitHandler.bind(this)"
                            class="mb-3 input-light"
                            inputClass="account-input-container-light"
                            :error="this.errors['LastName'] ? this.errors['LastName'][0] : null"
                        ></account-input>
                    </template>

                    <div :class="{'disabled-section': hasExternalAuthProvider}">
                        <account-input
                            label-text="Email"
                            name="email"
                            :init-value="guardian.hed__WorkEmail__c"
                            :on-submit="onSubmitEmailHandler.bind(this)"
                            class="mb-3 input-light"
                            inputClass="account-input-container-light"
                        ></account-input>

                    </div>

                    <div v-if="hasExternalAuthProvider" class="alert alert-info small">
                        Your email is managed by {{ externalAuthProviderName }}. Please refer to the support provided by {{ externalAuthProviderName }} to change your email address.
                    </div>

                    <hr class="dividing-line">

                    <phone-number-account-input
                        :dial-codes="dialCodes"
                        :init-dial-code-value="guardian.mobile_number_country_code.mobile_number_country"
                        :init-mobile-number-value="guardian.mobile_number.mobile_number"
                        :on-submit="onSubmitHandler.bind(this)"
                        :error="this.errors['mobile_number'] ? this.errors['mobile_number'][0] : null"
                        class="mb-3"
                        inputClass="account-input-container-light"
                        :guardian-contact="guardianContact"
                    ></phone-number-account-input>

                    <hr class="dividing-line">

                    <id-or-passport-input
                        ref="idOrPassportInput"
                        :id-number="guardian.ID_or_Passport_Number__c"
                        :id-type="guardian.VMS_ID_Type__c"
                        :on-submit="onSubmitEventHandler.bind(this)"
                        :class="alertIdOrPassportInput || idNumber === null && hasEnrolledLearner ? 'alert-input-theme' : ''"
                    ></id-or-passport-input>
                </div>

                <div v-if="true" class="col-12 col-md-5 col-lg-5">
                    <hr class="dividing-line d-block d-lg-none mt-0">

                    <vi-account-address
                        :guardian-user="guardianUser"
                        :url="updateAddressUrl"
                        v-on:success="(message) => setUpdateResult(message, true)"
                        v-on:error="(message) => setUpdateResult(message, false)"
                        class="rounded-white-input"
                        id="accountAddressGroup"
                        :provinces="provinces"
                        :countries="countries"
                    ></vi-account-address>
                </div>
            </div>
        </div>

        <!-- close icon -->
        <a :href="homeLink">
            <i class="bi bi-x-lg text-white position-absolute close-icon"></i>
        </a>
    </div>
</template>

<script>
import ViAccountAddress from '../account/ViAccountAddress.vue';
import AccountInput from './AccountInput.vue';
import PhoneNumberAccountInput from './PhoneNumberAccountInput.vue';

export default {
    name: "guardianBasicInfoSection",
    components: {
        AccountInput,
        PhoneNumberAccountInput,
        ViAccountAddress
    },
    data() {
        return {
            test: 'this is a test string',
            disabled: false,
            guardian: this.guardianContact,
            isSuccessful: false,
            errors: {},
            loading: false,
            errorMessage: null,
            emailUpdateResult: {
                message: null,
                status: false,
            },
            customSuccessMessage: null,
            alertIdOrPassportInput: false,
        };
    },
    props: {
        guardianContact: {
            type: Object,
            required: true,
        },
        hasEnrolledLearner: {
            type: Boolean,
        },
        guardianUser: {
            type: Object,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        homeLink: {
            type: String,
        },
        inputClass: {
            type: String,
        },
        updateEmailUrl: {
            type: String,
            required: true,
        },
        updateAddressUrl: {
            type: String,
            required: true,
        },
        idNumber: {
            default: null,
        },
        countries: {
            type: Object,
            required: true,
        },
        provinces: {
            type: Array,
            required: true,
        },
        hasExternalAuthProvider: {
            type: Boolean,
            default: false,
        },
        externalAuthProviderName: {
            type: String,
            default: null,
        }
    },
    mounted() {
        this.registerIdAlertHandler();
    },
    methods: {
        registerIdAlertHandler() {
            document.body.addEventListener('id:alert', event => {
                this.alertIdOrPassportInput = true;
                this.$refs.idOrPassportInput.focusScroll();

                setTimeout(() => {
                    this.alertIdOrPassportInput = false;
                }, 2000);
            });
        },
        onSubmitHandler(field, value = null) {
            let payload = {
                id: this.guardian.Id,
            };

            if (!value && typeof field === 'object') {

                for (const key in field) {
                    payload[key] = field[key];
                }

            } else {
                payload[field] = value;
            }

            this.loading = true;
            return axios
                .post(this.submitUrl, payload)
                .then((response) => {
                    this.isSuccessful = true;
                    this.errors = {};
                    this.loading = false;
                    this.errorMessage = null;
                    setTimeout(() => {
                        this.isSuccessful = false;
                    }, 3000);

                    return response;
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = {}
                        this.errors = error.response.data.errors || {};

                        let resErrors = error.response.data.errors;

                        let errorFields = [];
                        let errorToDisplay = null;

                        if (typeof field === 'object') {
                            errorFields = Object.keys(field);
                        } else {
                            errorFields = [ field ];
                        }

                        for (const errorField of errorFields) {
                            if (resErrors.hasOwnProperty(errorField) && resErrors[errorField].length > 0) {
                                errorToDisplay = error.response.data.errors[errorField][0] || {};
                                break;
                            }
                        }

                        if (errorToDisplay === null) {
                            errorToDisplay = 'An error has occurred, please retry.';
                        }

                        this.errorMessage = errorToDisplay;

                        return error;
                    }

                    return error;
                });
        },
        onSubmitEmailHandler(field, value) {
            let payload = {
                id: this.guardian.Id,
            };

            payload[field] = value;

            return axios.post(this.updateEmailUrl, payload)
                .then(response => {
                    if (response.status !== 200) {
                        this.setUpdateResult(response.data.message, false);
                    }

                    this.setUpdateResult(response.data.message, true);

                    return response;
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('exception')) {
                        this.setUpdateResult('An error occurred please retry. If the problem persists, please contact support.', false);
                        return error;
                    }

                    this.setUpdateResult(error.response.data.message, false);

                    return error;
                });
        },
        onSubmitEventHandler(field, val, event) {
            let payload = new FormData(event.srcElement);

            payload.append('id', this.guardian.Id);

            this.loading = true;

            return axios
                .post(this.submitUrl, payload)
                .then((response) => {
                    this.isSuccessful = true;
                    this.errors = {};
                    this.loading = false;
                    this.errorMessage = null;
                    setTimeout(() => {
                        this.isSuccessful = false;
                    }, 3000);
                    // reload page after updating the ID number
                    window.onbeforeunload = null;
                    window.location.reload();
                    return response;
                }).catch((error) => {
                    if (Object.values(error.response.data.errors).length) {
                        this.errorMessage = Object.values(error.response.data.errors)[0][0];
                    }

                    return error;
                });
        },
        setUpdateResult(message, status) {
                if (!status) {
                    this.errorMessage = message;
                } else {
                    this.customSuccessMessage = message;
                }

                setTimeout(() => {
                    this.errors = {};
                    this.loading = false;
                    this.errorMessage = null;
                    this.customSuccessMessage = null;
                    this.isSuccessful = false;
                }, 6000);

                this.isSuccessful = status;
            }
    },
}

</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours.scss';
.guardian-basic-info-section {
    background-color: $pastel-blue;
    padding: 70px 40px;
}

.guardian-img {
    background-color: $pastel-light-blue;
    height: 100px;
    width: 100px;
    outline: dashed 4px $white;
    border-radius: 50%;
}

.user-icon-img {
    height: 100px;
    width: 100px;
}
.close-icon {
    top: 70px;
    right: 40px;
    font-size: 1.25rem;
}

.email-alert {
    font-size: 14px;
}

.dividing-line {
    width: 100%;
    height: 1px;
    background-color: $white;
    margin: 2.1875rem 0 2.1875rem 0; // 35px
    opacity: 1;
}

.disabled-section {
    filter: opacity(0.7);
    pointer-events: none;
}

</style>