<template>
  <div>
    <input
        v-for="(digit, index) in digits"
        :key="index"
        :value="digit"
        @input="updateDigit(index, $event.target.value)"
        @keyup="keyUp(index, $event.key)"
        @click="highlightAll($event)"
        @paste="clearAll()"
        ref="inputs"
        :class="blockClass"
        type="number">
  </div>
</template>

<script>
export default {
  data() {
    return {
      digits: Array(this.blocks).fill(''),
      backKeys: ['Backspace', 'ArrowLeft', 'Delete'],
      forwardKeys: ['ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'],
      submitKeys: ['Enter']
    }
  },
  props: {
    code: {
      type: String,
      default: ''
    },
    returnValue: {
      type: String,
      default: ''
    },
    blocks: {
      type: Number,
      default: 6
    },
    blockClass: {
      type: String,
      default: 'block'
    }
  },
  mounted() {
    this.$refs.inputs[0].focus();
  },
  methods: {
    updateDigit(index, value) {
      if (value.length === this.blocks) {
        this.digits = value.split('').concat(Array(this.blocks - value.length).fill(''));
        this.$refs.inputs[0].focus();
      } else if (value.length > this.blocks) {
        this.digits = value.split('').slice(0, this.blocks);
        this.$refs.inputs[0].focus();
      } else {
        this.digits.splice(index, 1, value[value.length - 1]);
        const nextIndex = (index < this.blocks - 1) ? (index + 1) : 0;
        this.$refs.inputs[nextIndex].focus();
      }

      const fullCode = this.digits.join('');
      this.$emit('full-code', fullCode);
    },
    keyUp(index, key) {
      if (this.submitKeys.includes(key)) {
        this.$emit('submit-code');
      }

      if (this.backKeys.includes(key)) {
        const prevIndex = (index === 0) ? (this.blocks - 1) : (index - 1);
        this.digits.splice(prevIndex, 1, '');
        this.$refs.inputs[prevIndex].focus();
      }

      if (this.forwardKeys.includes(key)) {
        const nextIndex = (index === this.blocks - 1) ? 0 : (index + 1);
        this.digits.splice(nextIndex, 1, '');
        this.$refs.inputs[nextIndex].focus();
      }
    },
    highlightAll(event) {
      event.target.select();
    },
    clearAll() {
      this.digits = Array(this.blocks).fill('');
      this.$refs.inputs[0].focus();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../sass/abstract/colours.scss';

.input-block {
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
}
.input-block:focus {
  background-color: $pastel-light-blue;
}
</style>