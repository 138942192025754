<template>
    <div class="reenrolment-survey">
      <!-- Main Survey Modal -->
      <div class="modal fade" id="reenrolmentSurvey" tabindex="-1" aria-labelledby="reenrolmentSurveyLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <form @submit.prevent="submit" class="modal-content" novalidate>
            <div class="modal-header">
              <h5 class="modal-title" id="reenrolment-surveyLabel">Will your learner be staying at UCT Online High School in 2025?</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" @click="() => closeMainModal('later')"></button>
            </div>
  
            <div class="modal-body">
              <div>
                <p>It's time for UCT Online High School to start preparing for the 2025 academic year. In doing so, we need to gain clarity on which of our current families are intending on continuing their journey with us in 2025.</p>
  
                <p>Completion of this survey <span class="fw-bold">before Friday, 30 August 2024</span> will enter you into a raffle to <span class="fw-bold">win free tuition for 2025</span> at UCT Online High School. <a href="https://docs.google.com/document/d/1njdzTsSFCHZJQWS7sPAuVENaldoRDbJYpIVjAN_-XWU/export?format=pdf" target="_blank">T&C's apply.</a> </p>

                <p>The following options are available for you to select. Read these carefully before selecting your response:</p>

                <ul>
                    <li>Select “<span class="fw-bold">Yes</span>” - if you are intending for your learner to continue schooling at UCT Online High School in 2025. </li>
                    <li>Select “<span class="fw-bold">Unsure</span>” - if you are still deciding on whether your learner will continue at UCT Online High School in 2025. </li>
                    <li>
                        Select “<span class="fw-bold">No</span>” - if you intend to formally withdraw your learner from UCT Online High School before or at the end of the 2024 school year.

                        <ul>
                            <li><span class="fw-bold">Note</span>: Choosing this option for this survey <span class="fw-bold">does not count as an official withdrawal request</span>.</li>
                        </ul>
                    </li>
                </ul>
  
                <p class="fw-bold">Please Note:</p>

                <p>Regardless of which option you choose, your learner will be automatically enrolled for the 2025 academic year <u>unless</u> you submit a withdrawal request via the Guardian Portal (as per <a href="https://infohub.uctonlinehighschool.com/en/articles/8323884-faqs-about-withdrawing-from-uct-online-high-school" target="_blank">these steps</a>) by Monday, 4 October 2024.</p>
  
                <p>Consistent with our annual process and standard practice across all schools, we anticipate a fee increase for the 2025 school year. Fees for 2025 will be confirmed by the end of September 2024.</p>
              </div>
  
              <hr class="my-4">
  
              <!-- Student response options -->
              <div v-for="(option, key) in studentResponses" :key="key" class="d-flex justify-content-between align-items-center w-100 flex-sm-row flex-column mb-2">
                <p class="mb-2">{{ option.student.name }}</p>
                <input type="hidden" :name="'students[' + key + '][programEnrolmentId]'" :value="option.student.currentProgramEnrolment.Id">
                <div class="d-flex radio-btn-group">
                  <div v-for="answer in ['Yes', 'No', 'Unsure']" :key="answer" class="form-check form-check-inline radio-btn p-0 me-2">
                    <input class="form-check-input" type="radio" 
                           :id="option.student.id + '-' + answer.toLowerCase()" 
                           :value="answer"
                           :name="'students[' + key + '][answer]'"
                           v-model="option.tempAnswer"
                           @change="showStudentResponse(option, answer)"
                        >
                    <label class="form-check-label fw-bold" :for="option.student.id + '-' + answer.toLowerCase()" role="button">{{ answer }}</label>
                  </div>
                </div>
              </div>
  
              <div v-if="error" class="alert alert-danger mt-4" role="alert">
                Please make sure you have selected an answer for all of your learners above.
              </div>
            </div>
  
            <div class="modal-footer justify-content-center" v-if="!hasInvalidResponses()">
              <vi-button-spinner
                :buttonClass="{
                    'new-btn': true,
                    'new-btn-dark': true,
                    'w-auto': true,
                    'px-4' : true
                }"
                buttonText="Submit my response"
                :loading="loading"
                :disabled="hasInvalidResponses()"
              ></vi-button-spinner>
            </div>
          </form>
        </div>
      </div>
  
      <!-- Student Response Modal -->
      <div class="modal" id="reenrolment-studentResponse" tabindex="-1" aria-labelledby="reenrolment-studentResponseLabel" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Confirmation</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeResponseStudentModal"></button>
            </div>
            <div class="modal-body text-center">
              <re-enrolment-student-response
                :key="refresh"
                :student-response="currentStudentResponse"
                :selected-option="currentSelectedOption"
                :reasons="reasons"
                @submit-response="saveReason"
              ></re-enrolment-student-response>
            </div>
          </div>
        </div>
      </div>

      <re-enrolment-unsaved-changes></re-enrolment-unsaved-changes>
      <re-enrolment-success></re-enrolment-success>
    </div>
  </template>
  
  <script>
  export default {
    name: "ReEnrolmentSurvey",
    props: {
      students: {
        type: Array,
        required: true,
        default: () => [],
      },
      reasons: {
        type: Object,
        default: () => ({}),
      },
      submitUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        error: false,
        loading: false,
        refresh: 0,
        studentResponses: [],
        currentStudentResponse: {},
        currentSelectedOption: null,
      }
    },
    methods: {
      submit() {
        this.error = false;
        this.loading = true;
  
        if (this.hasInvalidResponses()) {
          this.error = true;
          this.loading = false;
          return;
        }
  
        const submitData = {
          students: this.studentResponses.map(({ student, answer, reason, context }) => ({
            studentId: student.id,
            programEnrolmentId: student.currentProgramEnrolment.Id,
            answer,
            reason,
            context,
          }))
        };
  
        axios.post(this.submitUrl, submitData)
          .then(() => {
            this.error = false;
            this.showSuccessModal();
          })
          .catch((error) => {
            console.error(error);
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      saveReason(reason) {
        const index = this.studentResponses.findIndex(response => response.student.id === this.currentStudentResponse.student.id);

        this.studentResponses[index] = { 
          ...this.studentResponses[index],
          answer: reason.answer,
          reason: reason.reasonCategory,
          context: reason.reasonContext,
          tempAnswer: reason.answer,
        };

        this.toggleStudentModal('hide');
      },
      closeResponseStudentModal() {
        const index = this.studentResponses.findIndex(response => response.student.id === this.currentStudentResponse.student.id);
        this.studentResponses[index].tempAnswer = this.studentResponses[index].answer;
        this.toggleStudentModal('hide');
      },
      showStudentResponse(studentResponse, selectedOption) {
        this.currentStudentResponse = { ...studentResponse, tempAnswer: selectedOption };
        this.currentSelectedOption = selectedOption;
        this.refresh++;
        this.toggleStudentModal('show');
      },
      toggleStudentModal(action) {
        $('#reenrolmentSurvey').modal(action === 'show' ? 'hide' : 'show');
        $('#reenrolment-studentResponse').modal(action);
        this.$forceUpdate();
      },
      showSuccessModal() {
        $('#reenrolmentSurvey').modal('hide');
        $('#reenrolmentSuccess').modal('show');
      },
      closeMainModal() {

        $('#reenrolmentSurvey').modal('hide');

        if (this.hasUnsavedChanges) {
          $('#reenrolmentUnsavedChanges').modal('show');
          return;
        }

        console.log('survey-closed');
      },
      hasInvalidResponses() {
        return this.studentResponses.some(response => {
          const isAnswered = response.answer !== null;
          const needsReason = (response.answer === 'No' || response.answer === 'Unsure');
          const hasReason = response.reason != null;
          const needsContext = needsReason && response.reason === 'Other';
          const hasContext = response.context != null;
  
          return !isAnswered || (needsReason && !hasReason) || (needsContext && !hasContext);
        });
      }
    },
    mounted() {
      this.studentResponses = this.enrolledStudents.map(student => ({
        student,
        answer: null,
        tempAnswer: null,
        reason: null,
        context: null,
      }));
    },
    computed: {
      enrolledStudents() {
        return this.students.filter(student => 
          student.currentProgramEnrolment && 
          student.currentProgramEnrolment.hed__Enrollment_Status__c === 'Enrolled'
        );
      },
      hasUnsavedChanges() {
        return this.studentResponses.some(response => response.answer !== null);
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import '../../../sass/abstract/colours.scss';
  
  .active {
      border-radius: 26px;
      color: $white;
      text-decoration: none;
      text-align: center;
      padding: 10px;
      width: 100%;
      text-transform: uppercase;
      background-color: $pastel-light-blue;
  }
  
  // radio button to look like a normal button
  .radio-btn-group {
      input[type="radio"] {
          display: none;
      }
  
      label {
          border-radius: 26px;
          color: $white;
          text-decoration: none;
          text-align: center;
          padding: 10px;
          width: 100%;
          text-transform: uppercase;
          background-color: $pastel-blue;
          transition: 0.3s ease;
  
          &:hover {
              transition: 0.3s ease;
              background-color: $pastel-light-blue;
              color: $white;
          }
      }
  
      input[type="radio"]:checked~label {
          background-color: $pastel-light-blue;
      }
  }
  </style>
