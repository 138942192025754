<template>

    <div class="modal fade" :id="`guardiansSupportCoachModal-${studentId}`" tabindex="-1"
        aria-labelledby="guardiansSupportCoachModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div v-if="supportCoach.name && supportCoach.intercom" class="w-100">
                        <div class="d-flex flex-row align-items-center justify-content-between w-100">
                            <h5 class="modal-title" id="guardiansSupportCoachModalLabel">{{ supportCoach.student_name }}'s {{ isAdultMatric ?'Support Advisor' : 'Support Coach' }}</h5>
                            <div class="d-flex flex-row align-items-center">
                                <div class="position-relative trigger-large-img">
                                <img :src="supportCoach.image" alt="User Icon" width="45"
                                    class="rounded-circle mx-2 sm-user-photo">
                                <div class="large-img-hover border shadow rounded">
                                    <img :src="supportCoach.image" alt="User Icon" width="100">
                                </div>
                            </div>
                            <button type="button" class="btn-close ms-0" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div v-else class="w-100">
                        <div class="d-flex flex-row align-items-center justify-content-between w-100">
                            <h5 class="modal-title" id="guardiansSupportCoachModalLabel">Error</h5>
                            <button type="button" class="btn-close ms-0" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">

                    <div v-if="supportCoach.name && supportCoach.intercom" class="teacher-block mb-3">

                        <h5 class="mc-2">{{ supportCoach.name }}</h5>

                        <div class="col mb-2">
                            <form :id="`mobile-form-${supportCoach.student_crm_id}`" method="post" novalidate>

                                <div :class="`scanner ${supportCoach.intercom.phone ? '' : 'hidden'}`">

                                    <p class="mb-2 d-flex flex-row justify-content-start align-items-center text-decoration-none w-100">

                                        <img :src="supportCoach.whatsapp_icon" alt="Whatsapp Icon" width="25">
                                        <span class="m-0 ps-3">Start a WhatsApp chat by scanning this code and sending the pre-created message:</span>

                                    </p>

                                    <canvas class="qr-code d-block m-auto my-3"
                                        :id="`qrcode-${supportCoach.student_crm_id}`"></canvas>
                                </div>

                                <div :class="`mobile ${supportCoach.intercom.phone ? 'hidden' : ''}`">

                                    <div class="message"></div>
                                    <p
                                        class="mb-2 d-flex flex-row justify-content-start align-items-center text-decoration-none w-100">
                                        <img :src="supportCoach.whatsapp_icon" alt="Whatsapp Icon" width="25">
                                        <span class="m-0 ps-3">Please enter your phone number so that we can set up your profile:</span>
                                    </p>

                                    <div class="row mb-2">
                                        <div class="col-12 col-md-6">
                                            <input type='hidden' name="crm_id" :value="guardian.id" />
                                            <input type='hidden' name="contact_id" :value="guardian.intercom.id" />
                                            <input type='hidden' name="type" value="guardian-object" />

                                            <div class="form-floating mb-3">

                                                <select class="form-select new-input" name="mobile_number_country" v-model="mobileNumberCountry">
                                                    <option v-for="(value,  dialCode) in dialCodes" :value="dialCode" :key="`code-${dialCode}`">{{ value }}</option>
                                                </select>

                                                <label for="mobile_number_country">Code</label>
                                            </div>

                                        </div>
                                        <div class="col-12 col-md-6">

                                            <div class="form-floating">
                                                <input type="tel" name="mobile_number" id="mobile_number"
                                                    class="form-control new-input" v-model.trim="mobileNumber">
                                                <label for="mobile_number" class="form-label">Mobile number*</label>
                                            </div>

                                        </div>

                                        <div class="w-100 text-end">
                                            <button type="button" class="btn-uct rounded border-0 px-4 py-2" @click="submitMobileForm()">Save</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </div>

                        <div v-if="supportCoach.email" class="col mb-2">
                            <a :href="`mailto:${supportCoach.email}`" target="_blank"
                                class="mb-2 d-flex flex-row justify-content-start align-items-center text-decoration-none">
                                <img :src="supportCoach.email_image" alt="Email Icon" width="25">
                                <p class="m-0 ps-3 text-break">{{ supportCoach.email }}</p>
                            </a>

                            <div v-if="!isAdultMatric">
                                <button :data-bs-target="`#guardiansSupportCoachBookingModal-${supportCoach.student_crm_id}`" data-bs-toggle="modal">
                                    <i class="bi bi-calendar vi-calendar"></i> Book a meeting
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-else class="teacher-block mb-3">
                        Something went wrong while trying to find this information for you. Sorry about that! Please try
                        again a bit later. If the error persists, please <a
                            href="//mail.google.com/mail?fs=1&tf=cm&source=mailto&to=<?php echo urlencode('techsupport@uctohs.valentureonline.com')?>"
                            class="text-decoration-underline" target='_blank'>contact our support team.</a>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "SupportCoach",

    data: () => ({
        loading: false,
        mobileNumberCountry: 'ZA',
        mobileNumber: null,
        qrCode: null,
    }),

    props: {

        supportCoach: {
            type: Object | Array,
            default: () => ({}),
            required: true
        },
        dialCodes: {
            type: Object | Array,
            default: () => ({}),
            required: true
        },
        studentId: {
            type: String,
            required: true,
            default: null
        },
        isAdultMatric :{
            type: Boolean,
            default: false
        },
        guardian: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },

    mounted() {
        let openButton = document.querySelectorAll(`[data-bs-target="#guardiansSupportCoachModal-${this.supportCoach.student_crm_id}"]`)[0];

        if (openButton) {
            openButton.addEventListener('click', (event) => {
                this.openCoachModal();
            });
        }
    },

    created() {
    },

    methods: {


        submitMobileForm() {

            let ScModal = $(`#guardiansSupportCoachModal-${this.supportCoach.student_crm_id}`);
            let form = $(`#mobile-form-${this.supportCoach.student_crm_id}`);

            $(ScModal).find('.close').prop('disabled',true);
            let formData = $(form)[0];

            // Create an FormData object
            let data = new FormData(formData);

            $(form).find('.btn-uct').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="visually-hidden"> Loading...</span>').attr('disabled',true);

            $.ajax({
                type: "POST",
                url: this.supportCoach.submit_number_url,
                data: data,
                processData: false,
                contentType: false,
                success: function (data) {
                    $('.scanner').removeClass('hidden')
                    $('.mobile').addClass('hidden')
                    $(ScModal).find('.close').prop('disabled',false);
                    $(form).find('.btn-uct').html('Save').attr('disabled',false);
                },
                error: function (e) {
                    let error = typeof e.responseJSON.mobile_number !='undefined' ? e.responseJSON.mobile_number[0]:e.responseJSON.error
                    $(form).find('.message').html(`<div class="alert alert-danger" role="alert">${error}</div>`);
                    $(ScModal).find('.close').prop('disabled',false);
                    $(form).find('.btn-uct').html('Save').attr('disabled',false);
                }
            });
        },

        openCoachModal() {
            this.qrCode = new QRious({
                element: document.getElementById(`qrcode-${this.supportCoach.student_crm_id}`),
                background: '#ffffff',
                backgroundAlpha: 1,
                foreground: '#0099D9',
                foregroundAlpha: 1,
                level: 'H',
                size: 170,
                value: "https://wa.me/27213002463?text=" + encodeURIComponent(JSON.stringify(`Hi, it’s ${this.guardian.name} , my email address is ${this.guardian.email}. I’d like to chat to you about ${this.supportCoach.student_name}`))
            });

            console.log(this.qrCode);

            $('.message').html(``);
            $('#mobile_number').val('');
        }
    }
}
</script>
