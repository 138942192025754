<template>
  <div class="vi-looker">
    <vi-alert
      v-if="showGradingQueriesClosedText"
    >
      <div>
        Please note that Assessment Queries for the current term have now closed. Should you have any queries for assessments relating to the current term, you will need to submit them as part of the formal appeals process. Information regarding appeals for the current term will be communicated to you via email when term reports are released. Please reach out to your support coach if you have any questions.
      </div>
    </vi-alert>

    <div class="vi-looker-admin" v-if="!isProduction">
      <div v-if="showAdmin" class="vi-looker-admin-body">
        <div class="form-group pb-2">
          <label class="form-label" for="substituteEmail">Substitute Email</label>
          <input
            class="form-control"
            type="email"
            name="substituteEmail"
            id="substituteEmail"
            placeholder="Email to use for this looker dashboard"
            v-model="substituteEmail"
          >
        </div>
        <p>Current Source URL: {{ currentSourceUrl }}</p>

        <div>
          <button type="button" class="btn btn-info" @click.prevent="adminReloadData">Reload Dashboard</button>
        </div>
      </div>
      <i class="bi bi-gear" @click.prevent="toggleAdmin"></i>
    </div>
    <div v-if="error" class="vi-looker-error alert alert-danger">
      {{ error }}
    </div>

    <div class="vi-looker-loading" v-else-if="isLoading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-else-if="data.length === 0 && dataFiltered.length === 0" class="vi-looker-error pt-4 text-center">
      {{ noDataMessage }}
    </div>

    <div v-else>
      <div class="vi-filters">
        <div v-for="(filter, filterKey, idx) in filters" class="vi-filter" :key="idx">
          <div class="vi-filter-title">{{ filter.title }}</div>

          <div class="vi-filter-options">
            <div class="vi-filter-option-select-wrapper" v-if="filter.type == 'select'">
              <i class="bi bi-chevron-down"></i>

              <select class="vi-filter-option option-select" @change="onSelectFilterChange($event, filterKey)" :name="filterKey">
                <template
                  v-for="(option) in filter.options"
                >
                  <option
                    v-if="filterKey === assessmentSubmissionsView + '.scheduled_submission_at_year' || hasYearFilter && option.hasOwnProperty('years') && option.years.includes(getSelectedYear())"
                    :selected="option.selected"
                    :value="option.value"
                    :key="option.value"
                  >{{ option.title ? option.title : option.value }}</option>
                </template>
              </select>
            </div>

            <template
              v-else
            >
              <template
                v-for="(option) in filter.options"
              >
                <div
                  v-if="filterKey === assessmentSubmissionsView + '.scheduled_submission_at_year' || hasYearFilter && option.hasOwnProperty('years') && option.years.includes(getSelectedYear())"
                  :key="(option).id"
                  @click="onFilterSelected(filterKey, option.value)"
                  class="vi-filter-option option-chip" :class="{ 'is-selected': option.selected }"
                  >{{ option.title ? option.title : option.value }}
                </div>
              </template>
            </template>
          </div>

          <!-- <div class="vi-filter-extra" v-if="filterKey === 'uct_student_module_completion.term'">
            this is for terms
          </div> -->
        </div>
      </div>

      <template
        v-if="Array.isArray(tables) && tables.length"
      >
        <div
          v-for="(table, idx) in tables"
          :key="table.heading + idx"
          class="vi-table-wrapper"
        >
          <h3
            :key="table.heading + idx"
            v-show="dataFiltered.filter(record => filterForTable(record, table))"
          >{{  table.heading }}</h3>

          <vi-table
            :headings="headingsFiltered"
            v-show="dataFiltered.filter(record => filterForTable(record, table))"
            :data="dataFiltered.filter(record => filterForTable(record, table))"
            :display-headings="true"
            :assessment-submissions-view="assessmentSubmissionsView"
            :dynamic-column="dynamicColumn"
            :query-url="queryUrl"
            :email-for-grading-query="emailForGradingQuery"
            :term-year-query-data="termYearQueryData"
            :grading-query-upload-url="gradingQueryUploadUrl"
          />
        </div>
      </template>

      <template
        v-else
      >
        <template v-if="dataFiltered.length !== 0">
          <vi-table
            :headings="headingsFiltered"
            :data="dataFiltered"
            :display-headings="true"
            :dynamic-column="dynamicColumn"
            :query-url="queryUrl"
            :email-for-grading-query="emailForGradingQuery"
            :assessment-submissions-view="assessmentSubmissionsView"
            :term-year-query-data="termYearQueryData"
            :grading-query-upload-url="gradingQueryUploadUrl"
          />
        </template>

        <div v-else class="vi-looker-error pt-4 text-center">
          No data available.
        </div>
      </template>
    </div>

    <p v-if="footerMessage" class="pt-4 mb-0 pb-3 text-center">{{ footerMessage }}</p>
  </div>
</template>

<script>
import ViTable from './ViTable.vue';
import * as Sentry from "@sentry/vue";


const PRESET_FILTERS = Object.freeze({
  selectAll: 'vi-select-all',
  isPresetValue(val) {
    return Object.values(this).includes(val);
  }
});

export default {
  props: {
    lookerUrl: {
      type: String,
      required: true,
    },
    isProduction: {
      type: Boolean,
      default: true,
    },

    // Used to identify and setup open listeners for this Looker View.
    id: {
      required: true,
      default: '',
    },
    queryUrl: {
      type: String,
      required: false,
      default: null,
    },
    assessmentSubmissionsView: {
      type: String,
      required: false,
      default: 'uct_assessment_submissions',
    },
    gradingQueryUploadUrl: {
      type: String,
      required: false,
      default: '',
    }
  },
  data() {
    return {
      filters: {},
      headings: {},
      data: [],
      noDataMessage: "No data available for selected student.",
      footerMessage: null,
      error: null,
      isLoading: null,
      currentSourceUrl: null,
      showAdmin: false,
      substituteEmail: null,
      tables: [],

      // Expose constants to the template.
      PRESET_FILTERS,
      dynamicColumn: null,
      emailForGradingQuery: null,
      termYearQueryData: [],
      showGradingQueriesClosedText: false,
      openGradingQueries: false,
    };
  },
  methods: {
    getSelectedYear() {
      let yearOptions = this.filters[this.assessmentSubmissionsView + '.scheduled_submission_at_year']['options'];

      let selectedOption = yearOptions.find(option => option.selected);

      if (!selectedOption) {
        return null;
      }

      return selectedOption.value;
    },
    hasYearFilter() {
      if (!this.filters) {
        return false;
      }

      let fields = Object.keys(this.filters);

      return fields.includes(this.assessmentSubmissionsView + '.scheduled_submission_at_year');
    },
    toggleAdmin() {
      this.showAdmin = !this.showAdmin;
    },
    onSelectFilterChange(event, filterKey) {
      this.filters[filterKey].options.forEach(option => option.selected = false);
      this.onFilterSelected(filterKey, event.target.value);
    },
    onFilterSelected(filterKey, filterValue) {
      const filterOptions = this.filters[filterKey].options;

      filterOptions.forEach(option => {
        if (option.value == filterValue) {
          option.selected = !option.selected;
        }
      });
    },
    getSelectedFilters() {
      // Compile a generic filters object of all the true filters.
      let selectedFilters = {};

      for (const filterKey in this.filters) {
        if (Object.hasOwnProperty.call(this.filters, filterKey)) {
          let options = this.filters[filterKey].options;

          options = options.filter(option => option.selected).map(option => option.value);

          if (options.length < 1) {
            continue;
          }

          selectedFilters[filterKey] = options;
        }
      }

      return selectedFilters;
    },
    doStringFilterCheck(target, filterValues) {
      target = target.toString().toLowerCase();

      return filterValues.reduce((result, filterValue) => {
        filterValue = filterValue.toString().toLowerCase();

        if (filterValue === PRESET_FILTERS.selectAll) {
          return true;
        }

        if (result) {
          return result;
        }

        return filterValue == target;
      }, false);
    },
    async loadData(url) {
      if (!this.shouldReloadData(url)) {
        return;
      }

      this.isLoading = true;

      let response;

      try {
        response = await axios.get(url);
      } catch (error) {
        Sentry.captureException(error);

        try {
            this.error = error.responwse.status === 401 ? 'Your login has expired, please refresh this page to view the data' : 'Error loading data';
        } catch (err) {
            Sentry.withScope((scope) => {
                scope.setContext('Data', {
                    'Original Error': error,
                });

                Sentry.captureException(error);
            });

            this.error = 'An unexpected error has occured. Please refresh the page. If the problem persists, please reach out to support.';
        }

        this.isLoading = false;

        console.error(error);

        return;
      }

      if (response.hasOwnProperty('data') && response.data.hasOwnProperty('data')) {
        const studentData = response.data.data;

        this.headings = studentData.headings;

        this.filters = studentData.filters;

        this.tables = studentData.tables;

        if (this.termYearQueryData.length === 0 && studentData.termYearQueryData) {
          this.termYearQueryData = studentData.termYearQueryData;
        }

        const specialPropToCheckFor = this.assessmentSubmissionsView + '.course_subject';

        if (this.filters.hasOwnProperty(specialPropToCheckFor)) {
          let years = [];


          this.filters[specialPropToCheckFor].options.forEach(option => {
            if (option.hasOwnProperty("years")) {
              years = [ ...years, ...option.years ];
            }
          });

          this.filters[specialPropToCheckFor].options = [
            {
              "title": "All",
              "display": true,
              "selected": false,
              "value": PRESET_FILTERS.selectAll,
              "years": Array.from(new Set(years)),
            },
            ...this.filters[specialPropToCheckFor].options
          ];
        }

        studentData.data.forEach(obj => {
          if (!obj.hasOwnProperty('id')) {
            obj.id = this.uuidv4();
          }
        });

        this.data = studentData.data;

        if (studentData.hasOwnProperty('messages')) {

          if (studentData.messages.hasOwnProperty('noDataMessage')) {
            this.noDataMessage = studentData.messages.noDataMessage  ?? this.noDataMessage;
          }

          if (studentData.messages.hasOwnProperty('footerMessage')) {
            this.footerMessage = studentData.messages.footerMessage  ?? this.footerMessage;
          }

        }

        this.currentSourceUrl = url;
      }

      this.isLoading = false;
    },
    selectFilter(filterName, value, shouldSelectFirst = false) {
      let hasSelectedFilter = false;

      if (!this.filters[filterName]) {
        return;
      }

      this.filters[filterName].options.forEach(option => {
        let doesMatch = option.value === value;

        option.selected = doesMatch;

        if (!hasSelectedFilter) {
          hasSelectedFilter = doesMatch;
        }
      });

      // No filter has been selected and shouldSelectFirst is true
      if (!hasSelectedFilter && shouldSelectFirst && this.filters[filterName].options.length > 0) {
        this.filters[filterName].options[0].selected = true;
      }
    },
    shouldReloadData(url) {
      return url !== this.currentSourceUrl
    },
    async adminReloadData() {
      if (!this.substituteEmail) {
        return;
      }

      this.currentSourceUrl = this.lookerUrl;
      let currentUrl = new URL(this.currentSourceUrl);
      let currentParams = new URLSearchParams(currentUrl.search);

      if (currentParams.has('email')) {
        currentParams.set('email', this.substituteEmail);
      }

      let newUrl = `${currentUrl.origin}${currentUrl.pathname}?${currentParams}`;

      await this.loadData(newUrl);

      if (this.filters.hasOwnProperty(this.assessmentSubmissionsView + '.course_subject')) {
        this.selectFilter(this.assessmentSubmissionsView + '.course_subject', this.assessmentSubmissionsView + '.course_subject',  true);
      }

      this.currentSourceUrl = newUrl;
    },
    uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    filterForTable(record, table) {
      let result = true;

      for (const field in table.filterFields) {
        if (!Object.hasOwnProperty.call(record, field)) {
          return false;
        }

        result = result && record[field] === table.filterFields[field];
      }

      return result;
    },
    showGradingQueriesClosed() {
      // Temporarily disabling until we enable this with appeals
      // this.showGradingQueriesClosedText = true;
      // return;

      let shouldShowCloseQueriesClosed = false;
      const today = new Date();

      const termStartExists = this.termYearQueryData[0] !== null;
      const termEndExists = this.termYearQueryData[1] !== null;

      if (!termStartExists || !termEndExists) {
        shouldShowCloseQueriesClosed = true;
      } else {
        const termStart = new Date(this.termYearQueryData[0]);
        const termEnd = new Date(this.termYearQueryData[1]);

        shouldShowCloseQueriesClosed = today < termStart || today > termEnd;
      }

      this.showGradingQueriesClosedText = shouldShowCloseQueriesClosed;
    }
  },
  computed: {
    dataFiltered() {
      const selectedFilters = this.getSelectedFilters();

      if (Object.keys(selectedFilters).length < 1) {
        return this.data;
      }

      const hasSubjectSelected = selectedFilters.hasOwnProperty(this.assessmentSubmissionsView + '.course_subject');

      if (hasSubjectSelected) {
        const hasSubjectAllSelected = selectedFilters[this.assessmentSubmissionsView + '.course_subject'].includes(PRESET_FILTERS.selectAll);

        this.headings[this.assessmentSubmissionsView + '.course_subject'].hide = !hasSubjectAllSelected;
      }

      return this.data.filter(row => {
        let result = true;

        for (const filterField in selectedFilters) {
          if (!Object.hasOwnProperty.call(row, filterField) || !Object.hasOwnProperty.call(selectedFilters, filterField)) {
            return false;
          }

          const value = row[filterField];

          let filterResult = false;

          if (value) {
            filterResult = this.doStringFilterCheck(value, selectedFilters[filterField])
          }

          result = result && filterResult;
        }

        return result;
      });
    },
    headingsFiltered() {
      let filteredHeadings = {};

      for (const key in this.headings) {
        if (Object.hasOwnProperty.call(this.headings, key)) {
          const heading = this.headings[key];

          if (heading.hasOwnProperty('hide') && heading.hide) {
            continue;
          }

          filteredHeadings[key] = heading;
        }
      }

      const url = new URL(window.location.href);
      const pathname = url.pathname;
      if ((this.openGradingQueries || pathname.endsWith('/grading-query')) && this.id === 'assessment-submissions') {
        filteredHeadings['grading_query'] = {
          hide: false,
          style: [],
          title: "Assessment Query",
          valueMap: []
        };

        this.showGradingQueriesClosed();
      } else {
        this.showGradingQueriesClosedText = false;
      }

      return filteredHeadings;
    }
  },
  mounted() {
    document.body.addEventListener(`vi-looker.open`, async (event) => {
      let {
        studentEmail,
        subjectName,
        id,
        openGradingQueries = true,
      } = event.detail;

      if (id !== this.id) {
        return;
      }

      let url = this.lookerUrl;

      if (studentEmail) {
        url = url.replace('studentEmail', studentEmail);
        this.emailForGradingQuery = studentEmail;
      }

      if (openGradingQueries) {
        this.openGradingQueries = openGradingQueries;
      }

      await this.loadData(url);

      if (this.filters.hasOwnProperty(this.assessmentSubmissionsView + '.course_subject') && subjectName) {
        this.selectFilter(this.assessmentSubmissionsView + '.course_subject', subjectName.trim(),  true);
      }
    });
  },
  components: { ViTable },
}
</script>

<style lang="scss">
@import '../../../sass/abstract/colours.scss';
@import '../../../sass/fonts.scss';
@import '../../../sass/utilities/rem-calc.scss';

.vi-looker {
  height: 100%;
}

.vi-looker-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .spinner-border {
    color: $pastel-blue;
  }
}

.is-selected {
  border: 1px solid green;
}

.hidden {
  display: none;
}

.vi-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.vi-filter {
  padding: 0.5rem;

  @media screen and (max-width: 768px) {
    flex-grow: 1;
  }
}

.vi-filter-title {
  margin-bottom: 0.25rem;
}

.vi-filter-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.vi-filter-option {
  @media screen and (max-width: 768px) {
    &:not(select) {
      margin-bottom: 0.5rem;
    }
  }

  &.option {
    &-select,
    &-chip {
      padding: 0.5rem 1.5rem;
    }

    &-chip {
      border-radius: 2rem;
      background-color: $medium-grey;
      border: 1px solid $medium-grey;
      color: $white;
      text-transform: uppercase;
      font-family: $primary-font;
      font-weight: 500;
      font-size: rem-calc(14);

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &.is-selected {
        background-color: $pastel-light-blue;
        border-color: $pastel-light-blue;
        color: $white;
      }
    }

    &-select {
      border: 2px solid $grey-92;
      color: $dark-grey;
      padding-right: 2rem;
      background-color: transparent;
      z-index: 10;

      &:focus,
      &:active,
      &:focus-visible {
        border-color: $grey-92;
        outline: none;
      }
    }
  }

  &-select-wrapper {
    background-color: $white;
    position: relative;
    display: grid;

    .bi-chevron-down {
      $border: 4px solid $pastel-blue;

      display: block;
      color: $pastel-blue;
      font-size: 2rem;
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 768px) {
  .vi-filter {
    border-bottom: 1px solid $grey-B7;
  }
}

.bi-gear {
  color: $pastel-blue;
  font-size: rem-calc(24);

  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    cursor: pointer;
    color: $pastel-light-blue;
  }
}

.vi-looker-admin {
  position: relative;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.vi-table-wrapper ~ .vi-table-wrapper {
    margin-top: 2rem;
}
</style>
