<template>
    <div class="accordion-body my-documents-wrapper">
        <template v-if="hasDocuments && !error">
            <div v-for="file in files" :key="file.id" class="document-card" :title="file.name">
                <div class="document-name">{{ file.name }}</div>
                <a :href="file.downloadUrl" target="_blank" class="document-link">Download</a>
            </div>
        </template>

        <div v-else-if="error" class="alert alert-danger">
            Failed to load documents for this learner. Please refresh the page and if the problem persists please contact support.
        </div>

        <div v-else-if="isInitializing" class="placeholder-wave">
            <span class="placeholder col-10 placeholder-sm mb-2"></span> <br>
            <span class="placeholder col-7 placeholder-sm mb-2"></span> <br>
            <span class="placeholder col-4 placeholder-sm"></span>
        </div>

        <div v-else class="alert alert-info">No Documents</div>
    </div>
</template>

<script>
export default {
    name: 'DocumentsWrapper',
    props: {
        initUrl: {
            type: String,
            required: true,
        },
        collapseId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            hasRegistered: false,

            hasDocuments: false,
            files: null,
            error: false,
            isInitializing: false,
        };
    },
    mounted() {
        let node = document.querySelector(`#${this.collapseId}`);

        if (!this.hasRegistered) {
            node.addEventListener('show.bs.collapse', () => {
                this.isInitializing = true;

                this.init()
                    .then(() => {})
                    .catch(err => {
                        this.error = true;
                    })
                    .finally(() => {
                        this.isInitializing = false;
                    });
            });

            node.addEventListener('hide.bs.collapse', () => {
                this.isInitializing = false;
                this.error = false;
                this.hasDocuments = false;
                this.files = null;
            });

            this.hasRegistered = true;
        }
    },
    methods: {
        init() {
            return axios.get(this.initUrl)
                .then(res => {
                    const data = res.data;

                    this.files = data.files;
                    this.hasDocuments = data.hasDocuments;

                });
        }
    },
}
</script>

<style lang="scss" scoped>
@import 'resources/sass/abstract/colours';

.my-documents-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    @media screen and (max-width: 960px) {
        // background-color: orange !important;
        grid-template-columns: 1fr;
    }
}

.alert {
    grid-column: 1 / 3;
}

.document {
    &-card {
        display: flex;
        width: 100%;
        box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 63% / 0.36),
                    0.8px 1.6px 2px -0.8px hsl(0deg 0% 63% / 0.36),
                    2.1px 4.1px 5.2px -1.7px hsl(0deg 0% 63% / 0.36),
                    5px 10px 12.6px -2.5px hsl(0deg 0% 63% / 0.36);
        border-radius: 32px;
        overflow: hidden;
    }

    &-name,
    &-link {
        padding: 1rem;
    }

    &-name {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &-link {
        color: $white;
        background-color: $pastel-blue;
        text-decoration: none;
        transition: background-color 250ms ease;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $pastel-light-blue;
        }
    }
}
</style>
