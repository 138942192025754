<template>
    <form :id="formId" @submit.prevent="onSubmitHandler">
        <label :for="name" class="form-label">{{ labelText }}</label>
        <div class="rounded-pill w-100 d-flex" :class="inputClass">

            <input
                ref="input"
                class="form-control"
                :type="type"
                :name="name"
                :id="name"
                :disabled="isDisabled"
                v-model="value"
                :required="required"
            />

            <button type="submit" @click="onButtonClickHandler" class="btn edit-btn px-3 text-white">
                <span v-show="!loading">{{ isDisabled ? 'Edit' : 'Save' }}</span>
                <div v-show="loading" class="spinner-border text-light spinner" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </button>
        </div>

        <div class="invalid-feedback d-block" v-html="error"></div>
    </form>
</template>

<script>
  export default {
    name: 'AccountInput',
    data() {
        return {
            value: this.initValue,
            isDisabled: true,
            loading: false,
            errors: {},
        };
    },
    props: {
        formId: {
            type: String,
            default: null,
        },
        labelText: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            required: false,
            default: null
        },
        inputClass: {
            type: String,
        },
        initValue: {
            type: String,
            required: false,
            default: null,
        },
        // Function passed as onSubmit handler should always return a promise.
        onSubmit: {
            type: Function,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        toggleDisable() {
            this.isDisabled = !this.isDisabled;
            this.$emit('disabled', this.isDisabled);
        },
        onSubmitHandler(event) {
            this.loading = true;
            this.onSubmit(this.name, this.value, event).then((result) => {
                this.loading = false;

                if (result.hasOwnProperty('status') && result.status === 200) {
                    window.unsaved = false;
                    this.toggleDisable();
                } else {
                    this.$refs.input.focus();
                }

                return result;
            }).catch((error) => {
                this.loading = false;
                this.$refs.input.focus();

                return error;
            });
        },
        onButtonClickHandler(event) {
            if (this.isDisabled) {
                event.preventDefault();
                event.stopPropagation();
                this.toggleDisable();
            };

            if(!this.isDisabled) {
                window.unsaved = true;

                function unloadPage(event) {
                    if (window.unsaved) {
                        event.preventDefault();
                        return event.returnValue = "Are you sure you want to exit?";
                    }
                }

                window.onbeforeunload = unloadPage;
            } else {
                window.unsaved = false;
            };

            // toggle tooltip
            // $('#idTooltip').tooltip('toggleEnabled');
        },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    @import 'resources/sass/abstract/colours.scss';
    .edit-icon {
        font-size: 1.125rem;
    }

    input, input:focus {
        outline: none;
        border: none;
        box-shadow: none;
        border: none;
        background-color: $white;
        border-radius: 0;
        padding-left: 10px;
        transition: 0.3s ease;
        border-radius: 50rem 0 0 50rem;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;

        &::placeholder {
            color: $white;
        }

        &:disabled, &[readonly] {
            outline: none;
            border: none;
            box-shadow: none;
            color: $white;
            background-color: transparent;
            border-radius: 0;
            transition: 0.3s ease;
        }
    }

    .input-light {
        label {
            color: $white;
        }

        input {
            color: $uctDarkBlue;

            &::placeholder {
                color: $white;
            }

            &:disabled, &[readonly] {
                color: $white;
            }
        }
    }

    .input-dark {
        label {
            color: $uctDarkBlue;
        }

        input {
            color: $uctDarkBlue;

            &::placeholder {
                color: $pastel-blue;
            }

            &:disabled, &[readonly] {
                color: $pastel-blue;
                background-color: $light-grey-100;
                border-radius: 50rem 0 0 50rem;
            }
        }
    }

    .edit-btn {
        border: none;
        border-radius: 0;
        border-left: 1px solid $white;
        outline: none;
        box-shadow: none;
        background-color: $pastel-light-blue;
        border-radius: 0 50rem 50rem 0;
        min-width: 80px;
    }

    .btn-white {
        background-color: $white;
        color: $pastel-blue;
        padding: 10px 40px;
        margin-top: 30px;
    }

    .spinner {
      width: 20px;
      height: 20px;
    }

    .account-input-container-dark {
        border: 1px solid $uctDarkBlue;
    }

    .account-input-container-light {
        border: 1px solid $white;
    }

    .account-input-container-light + .invalid-feedback {
        color: $white;
    }

</style>
